import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import AccordionHeader from "./Header"
import AccordionContent from "./Content"
import { appHeaderHeightMobile, appHeaderMainHeight } from "../App/index"

export interface AccordionGroupProps {
  active: boolean
  children?: ReactNode
  className?: string
  id: string | number
  onClick: (id: string | number) => void
  stickyHeader?: boolean
  title: string | ReactNode
  headerBgColor?: string
}

const AccordionGroup = ({
  active,
  children,
  className,
  id,
  onClick,
  stickyHeader = true,
  title,
  headerBgColor,
}: AccordionGroupProps) => {
  return (
    <AccordionGroupStyled
      className={`${className} accordion-group`}
      sticky={stickyHeader}
      stickyOffsetMobile={appHeaderHeightMobile}
      stickyOffsetStandard={appHeaderMainHeight}
    >
      <AccordionHeader
        active={active}
        className="accordion-group__header"
        id={id}
        onClick={onClick}
        bgColor={headerBgColor}
      >
        {title}
      </AccordionHeader>
      <AccordionContent active={active}>{children}</AccordionContent>
    </AccordionGroupStyled>
  )
}

export default AccordionGroup

interface GroupSC {
  sticky: boolean
  stickyOffsetMobile: number
  stickyOffsetStandard: number
}

const AccordionGroupStyled = styled.section<GroupSC>`
  .accordion-group__header {
    position: ${({ sticky }) => (sticky ? "sticky" : "static")};
    top: ${({ sticky, stickyOffsetMobile }) =>
      sticky ? rem(stickyOffsetMobile - 1) : "0"};

    @media ${media.stUp} {
      top: ${({ sticky, stickyOffsetStandard }) =>
        sticky ? rem(stickyOffsetStandard - 1) : "0"};
    }
  }

  .accordion-header__button {
    color: var(--color-gray800);
  }
`
