import React, { forwardRef, ReactNode, Ref } from "react"
import styled from "styled-components"
import { rem } from "polished"

export interface DeviceIPhone11MaxProps {
  bgColor?: string
  children?: ReactNode
  className?: string
  frameColor?: "light" | "dark"
  shadow?: string
}

const DeviceIPhone11Max = forwardRef(
  (
    {
      bgColor = "var(--color-content-bg)",
      children,
      className,
      frameColor = "dark",
      shadow = "var(--shadow)",
    }: DeviceIPhone11MaxProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <DeviceIPhone11MaxStyled
        bgColor={bgColor}
        className={`${className || ""} iphone11`}
        frameColor={frameColor}
        ref={ref}
        shadow={shadow}
      >
        <div className="iphone11__screen">{children}</div>
      </DeviceIPhone11MaxStyled>
    )
  }
)

export default DeviceIPhone11Max

interface DeviceSC {
  bgColor: string
  frameColor: "light" | "dark"
  shadow: string
}

const DeviceIPhone11MaxStyled = styled.div<DeviceSC>`
  --frame-color: ${({ frameColor }) =>
    frameColor === "dark" ? `var(--color-gray900)` : `var(--color-gray100)`};

  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 216.43%;
  overflow: hidden;
  border: 6px solid var(--frame-color);
  border-radius: 12%/5%;
  box-shadow: ${({ shadow }) => shadow};

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
  }

  &::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    padding-bottom: 6%;
    background-color: var(--frame-color);
    border-bottom-right-radius: ${rem(20)};
    border-bottom-left-radius: ${rem(20)};
  }

  &::after {
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: ${rem(8)};
    border-radius: ${rem(4)};
    background-color: var(--color-gray200);
  }

  .iphone11__screen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${({ bgColor }) => bgColor};
    border: 1px solid green;
  }
`
