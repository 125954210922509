import React from "react"
import { IconProps } from "./iconTypes"

const IconChevronCircleRight = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 32 32"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <circle cx="16" cy="16" r="15.5" stroke={color} fill="none"/>
<path d="M14.0939 22C14.2018 22 14.3052 21.9588 14.3812 21.8855L20.2164 16.2608C20.374 16.1095 20.374 15.8645 20.2164 15.7132L14.3625 10.1171C14.2607 10.017 14.1105 9.9772 13.9695 10.0128C13.8285 10.0484 13.7184 10.154 13.6813 10.2891C13.6442 10.4242 13.6859 10.5679 13.7904 10.6654L19.3574 15.9888L13.8069 21.3393C13.6918 21.4503 13.6577 21.6168 13.7205 21.7613C13.7832 21.9057 13.9305 21.9999 14.0939 22Z" />
  </svg>
)

export default IconChevronCircleRight