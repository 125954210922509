import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const RegBodyMedStyle = css<TypographyTypeProps>`
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: 400;
`

const RegBodyMed = styled.p<TypographyTypeProps>`
  ${RegBodyMedStyle}
`
RegBodyMed.displayName = "RegBodyMed"

export default RegBodyMed
