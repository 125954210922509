import React, { ReactNode } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { IconChevronRight } from "../Icon/index"

export interface AccordionHeaderProps {
  active: boolean
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div"
  bgColor?: string
  className?: string
  children: string | ReactNode
  id: string | number
  onClick: (id: string | number) => void
}

const AccordionHeader = ({
  active,
  as,
  bgColor,
  className,
  children,
  id,
  onClick,
}: AccordionHeaderProps) => {
  return (
    <AccordionHeaderStyled
      active={active}
      as={as}
      bgColor={bgColor}
      className={
        `${className} accordion-header` + (active ? " active" : " inactive")
      }
    >
      <button
        className="accordion-header__button"
        type="button"
        onClick={() => onClick(id)}
      >
        <span className="accordion-header__layout">
          {children}
          <motion.span
            initial={{ rotate: active ? 90 : 0 }}
            animate={{ rotate: active ? 90 : 0 }}
            style={{ lineHeight: "0" }}
          >
            <IconChevronRight />
          </motion.span>
        </span>
      </button>
    </AccordionHeaderStyled>
  )
}

export default AccordionHeader

interface HeaderSC {
  active: boolean
  bgColor?: string
}

const AccordionHeaderStyled = styled.h2<HeaderSC>`
  background: ${({ bgColor }) => bgColor};
  border-top: 1px solid var(--color-border-light);
  border-bottom: 1px solid
    ${({ active }) => (active ? "var(--color-border-light)" : "transparent")};
  .accordion-header__button {
    display: block;
    width: 100%;
    padding: ${rem(8)};

    @media ${media.stUp} {
      padding: ${rem(8)} ${rem(16)};
    }
  }

  .accordion-header__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    fill: var(--color-link);
  }
`
