import React, { ReactNode } from "react"
import { ThemeProvider as SCThemeProvider } from "styled-components"

import { default as defaultTheme } from "../theme/index"

interface ThemeProviderProps {
  theme?: any
  children: ReactNode
}

const ThemeProvider = ({
  theme = defaultTheme,
  children,
}: ThemeProviderProps) => {
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
}

export default ThemeProvider
