import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdCapsMedStyle = css<TypographyTypeProps>`
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: 600;
  text-transform: uppercase;
`

const SemiBdCapsMed = styled.p<TypographyTypeProps>`
  ${SemiBdCapsMedStyle}
`
SemiBdCapsMed.displayName = "SemiBdCapsMed"

export default SemiBdCapsMed