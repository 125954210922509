import React from "react"
import { IconProps } from "./iconTypes"

const IconExclamationCircleSolid = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M19.7422 4.23873c-1.0167-1.02397-2.2255-1.83716-3.5571-2.39293-1.3316-.55577-2.7598-.8432-4.2028-.84578-1.4429-.002589-2.87219.27971-4.20579.83071-1.3336.55099-2.54531 1.35984-3.56562 2.38016C3.19057 5.2312 2.38172 6.44291 1.83073 7.77651c-.551 1.3336-.833299 2.76289-.83071 4.20579.00258 1.443.29001 2.8712.84578 4.2028s1.36896 2.5404 2.39293 3.5571c1.01665 1.0239 2.22545 1.8371 3.55707 2.3929 1.33161.5558 2.7598.8432 4.2028.8458 1.4429.0026 2.8722-.2797 4.2058-.8307 1.3336-.551 2.5453-1.3599 3.5656-2.3802 1.0203-1.0203 1.8292-2.232 2.3802-3.5656.551-1.3336.8333-2.7629.8307-4.2058-.0026-1.443-.29-2.87119-.8458-4.2028-.5558-1.33162-1.369-2.54042-2.3929-3.55707zM11.9904 19.01c-.2595 0-.5133-.077-.7291-.2212-.2159-.1442-.3841-.3492-.4834-.589-.0994-.2399-.1254-.5038-.0747-.7584.0506-.2546.1756-.4884.3592-.672.1835-.1836.4174-.3086.672-.3592.2546-.0506.5185-.0247.7583.0747.2399.0993.4448.2676.5891.4834.1442.2158.2212.4696.2212.7292 0 .3481-.1383.6819-.3845.9281-.2461.2461-.58.3844-.9281.3844zm1.3126-6.2747c0 .3481-.1383.6819-.3845.9281-.2461.2461-.58.3844-.9281.3844-.348 0-.6819-.1383-.928-.3844-.2462-.2462-.3845-.58-.3845-.9281V6.28342c0-.3481.1383-.68194.3845-.92808.2461-.24614.58-.38442.928-.38442.3481 0 .682.13828.9281.38442.2462.24614.3845.57998.3845.92808v6.45188z" />
  </svg>
)

export default IconExclamationCircleSolid
