import { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"

const SharedDynamicPropsStyled = css<TypographyTypeProps>`
  margin-bottom: ${props => {
    if (props.mobileMarginBottom) {
      return rem(props.mobileMarginBottom)
    }
    return props.marginBottom ? `${rem(props.marginBottom)}` : `auto`
  }};
  font-weight: ${props => (props.emphasize ? "700" : "600")};
  color: ${props => (props.color ? props.color : "inherit")};
  ${props => {
    if (props.onDark) {
      return css`
        color: ${props => props.theme.colors.fontColorOnDark};
      `
    }
  }}
  text-transform: ${props => {
    if (!props.transform) {
      props.transform === "initial"
    }
    return props.transform
  }};
  
  @meida ${media.stUp} {
    ${(props: any) => {
      if (props.desktopMarginBottom) {
        return `margin-bottom: ${rem(props.desktopMarginBottom)}`
      }
    }}
  }

`
export default SharedDynamicPropsStyled
