import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import SectionFeatureTableGroupHeader from "./GroupHeader"
import SectionFeatureTableFeature from "./Feature"
import SectionFeatureTableGroupFooter from "./GroupFooter"
import { SectionFeatureTableGroup } from "./index"

interface SectionFeatureTableFeatureListProps {
  className?: string
  groups?: SectionFeatureTableGroup[]
}

const SectionFeatureTableFeatureList = ({
  className,
  groups,
}: SectionFeatureTableFeatureListProps) => {
  return (
    <SectionFeatureTableFeatureListStyled className={className}>
      {groups && groups.length > 0 ? (
        <ol className="feature-list__groups">
          {groups.map((group, index) => (
            <li className="feature-list__group" key={`group-${index}`}>
              <SectionFeatureTableGroupHeader
                cta={group.groupCta}
                ctaPath={group.groupCtaPath}
                description={group.groupDescription}
                stHeight={200}
                title={group.groupTitle}
              />
              {group.features && group.features.length > 0 ? (
                <ul className="feature-list__feature-list">
                  {group.features.map((feature, featureIndex) => (
                    <li
                      className="feature-list__feature-list__item"
                      key={featureIndex}
                    >
                      <SectionFeatureTableFeature
                        description={feature.description}
                        included={feature.included}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
              <SectionFeatureTableGroupFooter
                cta={group.groupCta}
                ctaPath={group.groupCtaPath}
              />
            </li>
          ))}
        </ol>
      ) : null}
    </SectionFeatureTableFeatureListStyled>
  )
}

export default SectionFeatureTableFeatureList

const SectionFeatureTableFeatureListStyled = styled.div`
  .feature-list__group {
    margin-bottom: ${rem(48)};
  }

  .feature-list__feature-list {
    background: var(--color-content-bg);
  }

  .feature-list__feature-list__item {
    border-bottom: 1px solid var(--color-border-light);

    &:last-child {
      border-bottom: 0;
    }
  }
`
