import React, { forwardRef, ReactNode, Ref } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import AlertBox, { AlertBoxStyle, AlertBoxType } from "../../Alert/Box"
import { IconExclamationCircleOutline } from "../../Icon/index"
import { BodyText } from "../../Typography/index"
import FormInputBinary from "./Binary"

type FormInputBinaryGroupOption = {
  value: string
  label: string
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void
  ref?: Ref<HTMLInputElement>
  required?: boolean
}

interface FormInputBinaryGroupProps {
  error?: boolean
  errorBoxStyle?: AlertBoxStyle
  errorBoxType?: AlertBoxType
  errorIcon?: ReactNode
  errorMessage?: string
  id: string
  label?: string
  options: FormInputBinaryGroupOption[]
  required?: boolean
  type: "checkbox" | "radio"
}

const FormInputBinaryGroup = forwardRef(
  (
    {
      error,
      errorBoxStyle = "default",
      errorBoxType = "danger",
      errorIcon,
      errorMessage,
      id,
      label,
      options,
      required = false,
      type,
    }: FormInputBinaryGroupProps,
    ref?: Ref<HTMLDivElement>
  ) => {
    const alertBoxIcon = errorIcon || <IconExclamationCircleOutline />

    return (
      <FormInputBinaryGroupStyled ref={ref}>
        <BodyText className="group-label u-text-bold">
          {label} {required && <span className="u-text-italic">Required</span>}
        </BodyText>
        <AlertBox
          show={!!(error && errorMessage)}
          alertBoxStyle={errorBoxStyle}
          icon={alertBoxIcon}
          type={errorBoxType}
        >
          {errorMessage}
        </AlertBox>
        <div className="group-inputs">
          {options.map(option => (
            <FormInputBinary
              className="group-inputs__input"
              id={`${id}-${option.value}`}
              key={`${id}-${option.value}`}
              name={`${id}-${option.value}`}
              label={option.label}
              onChange={option.onChange}
              type={type}
              value={option.value}
              ref={option.ref}
              required={option.required}
            />
          ))}
        </div>
      </FormInputBinaryGroupStyled>
    )
  }
)

export default FormInputBinaryGroup

const FormInputBinaryGroupStyled = styled.div`
  margin-bottom: ${rem(24)};

  .group-label {
    margin-bottom: ${rem(24)};
  }

  .group-inputs {
    @media ${media.stUp} {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
    }
  }

  .group-inputs__input {
    @media ${media.stUp} {
      flex: 0 0 33.3333%;
    }
  }
`
