import React from "react"
import { IconProps } from "./iconTypes"

const IconDollarSign = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M17.017 16.04c0-1.454-.653-2.599-1.942-3.408-1.014-.636-2.216-.921-2.933-1.09l-.012-.004c-.695-.165-1.745-.413-2.595-.944-.949-.593-1.41-1.392-1.41-2.441a2.409 2.409 0 01.753-1.725c.664-.637 1.734-.973 3.094-.973 3.445 0 3.91 2.565 3.927 2.671a.564.564 0 101.112-.17c-.006-.038-.151-.944-.855-1.838-.585-.741-1.67-1.613-3.585-1.765v-2.79a.563.563 0 00-1.125 0v2.782c-1.427.083-2.552.509-3.347 1.271A3.54 3.54 0 007 8.153c0 1.448.652 2.59 1.94 3.395 1.006.629 2.164.903 2.93 1.085l.013.003c2.082.493 4.01 1.247 4.01 3.403a2.41 2.41 0 01-.754 1.724c-.664.637-1.734.974-3.093.974-3.44 0-3.908-2.557-3.928-2.675a.562.562 0 00-1.111.173c.005.038.15.945.855 1.838.584.742 1.67 1.613 3.584 1.766v2.695a.563.563 0 001.125 0v-2.688c1.427-.082 2.552-.508 3.347-1.27a3.54 3.54 0 001.1-2.537z" />
  </svg>
)

export default IconDollarSign
