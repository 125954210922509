import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import type { SanityImg } from "../../types/sanityImg"

import { IconChevronRight } from "../Icon/index"
import { Caption, Title4 } from "../Typography"
import MediaImgZoom from "../Media/ImgZoom"

export interface ArticlePreviewVideo {
  className?: string
  img?: SanityImg
  /**
   * Zoom media, on hover, focus, etc.
   * @default false
   * */
  mediaZoom?: boolean
  /** Formatted time string */
  time?: string
  /** ISO datetime string  */
  timeIso?: string
  title: string
}

/**
 * Component to render video previews
 */
const ArticlePreviewVideo = ({
  className,
  img,
  mediaZoom = false,
  time,
  timeIso,
  title,
}: ArticlePreviewVideo) => {
  return (
    <ArticlePreviewVideoStyled className={`${className} preview-video`}>
      <div className="preview-video__media">
        {img && (
          <MediaImgZoom height={320} img={img} width={570} zoom={mediaZoom} />
        )}
      </div>
      <span className="preview-video__icon">
        <IconChevronRight />
      </span>
      <figcaption className="preview-video__caption">
        <Title4>{title}</Title4>
        {time && (
          <Caption
            as="time"
            className="preview-video__caption__time"
            dateTime={timeIso}
          >
            {time}
          </Caption>
        )}
      </figcaption>
    </ArticlePreviewVideoStyled>
  )
}

export default ArticlePreviewVideo

const ArticlePreviewVideoStyled = styled.figure`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-content-bg-invert);
    opacity: 0.65;
  }

  .preview-video__icon {
    position: absolute;
    top: ${rem(16)};
    right: ${rem(16)};
    z-index: 1;

    .icon {
      width: ${rem(48)};
      height: ${rem(48)};
      fill: var(--color-text-subtract);
    }
  }

  .preview-video__caption {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: ${rem(16)} ${rem(8)};
    color: var(--color-text-subtract);

    @media ${media.stUp} {
      padding: ${rem(16)};
    }
  }
`
