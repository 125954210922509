import React from "react"
import { IconProps } from "./iconTypes"

const IconDoorOpen = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M14.9803 13.6936C14.9803 14.46 14.5289 15.1041 13.9627 15.1327C13.3857 15.1622 12.9103 14.5434 12.9103 13.7508C12.9103 12.9581 13.3857 12.3136 13.9627 12.3108C14.5313 12.308 14.9803 12.9272 14.9803 13.6936ZM18.488 0.993286C18.887 0.993162 19.2697 1.1516 19.5518 1.43372C19.8339 1.71585 19.9923 2.09852 19.9922 2.4975V21.4889C19.9923 21.8879 19.8339 22.2706 19.5518 22.5527C19.2697 22.8348 18.887 22.9933 18.488 22.9931H5.5069C4.67615 22.9931 4.00269 22.3197 4.00269 21.4889V2.4975C4.00269 1.66675 4.67615 0.993286 5.5069 0.993286H18.488ZM18.488 2.02454H13.2783L14.8388 2.24672C15.8278 2.38782 16.5778 3.38532 16.5778 4.56704V18.9731H18.961V2.49751C18.961 2.23629 18.7492 2.02454 18.488 2.02454ZM6.05253 21.96C6.09769 21.96 6.14279 21.9568 6.18753 21.9506L14.693 20.738C15.1674 20.6705 15.5466 20.0892 15.5466 19.4386V4.56704C15.5466 3.91641 15.1674 3.33516 14.693 3.26766L6.18565 2.05594C6.14157 2.04959 6.09707 2.04646 6.05253 2.04657C5.48909 2.04657 5.04472 2.56735 5.04472 3.2761V20.7305C5.04472 21.4392 5.49003 21.96 6.05253 21.96ZM18.488 21.96V21.9619C18.6138 21.9619 18.7343 21.9118 18.8231 21.8227C18.9119 21.7336 18.9615 21.6128 18.961 21.487L18.961 20.0025H16.5188C16.3247 20.9236 15.6671 21.6389 14.8388 21.757L13.4138 21.96H18.488Z" />
  </svg>
)

export default IconDoorOpen
