import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import FormValidationErrorMessage from "../Validation/ErrorMessage"

export interface InputScaffoldingProps {
  children: ReactNode
  error?: Boolean
  errorMessage?: string
  id: string
  isFocused?: Boolean
  label?: string
  required?: Boolean
}

const InputScaffolding = ({
  children,
  error = false,
  errorMessage,
  id,
  isFocused,
  label,
  required = false,
}: InputScaffoldingProps) => {
  return (
    <InputScaffoldingStyled isFocused={isFocused} error={error}>
      {label && (
        <InputScaffoldingLabelStyled htmlFor={id} isFocused={isFocused}>
          <span>{label}</span>
          {required && (
            <InputScaffoldingLableRequiredStyled>
              Required
            </InputScaffoldingLableRequiredStyled>
          )}
        </InputScaffoldingLabelStyled>
      )}
      <InputScaffoldingInputContainerStyled>
        {children}
      </InputScaffoldingInputContainerStyled>
      {error && errorMessage && (
        <FormValidationErrorMessage message={errorMessage} />
      )}
    </InputScaffoldingStyled>
  )
}

export default InputScaffolding

interface ScaffoldSC {
  isFocused?: Boolean
  error?: Boolean
}

const InputScaffoldingStyled = styled.div<ScaffoldSC>`
  margin-bottom: ${rem(24)};
  padding: ${rem(4)} ${rem(16)};
  text-align: left;
  background: ${(props) => props.theme.colors.gray.white};
  border: 1px solid;
  border-color: ${(props) => {
    if (props.error) {
      return props.theme.colors.symbolic.danger
    }
    if (props.isFocused) {
      return props.theme.colors.brand.primaryOnLight
    }
    return props.theme.colors.gray.gray300
  }};
  border-radius: ${rem(4)};
  box-shadow: ${(props) =>
    props.isFocused
      ? props.theme.shadows.shadow
      : props.theme.shadows.shadowLight};
`

const InputScaffoldingLabelStyled = styled.label<{ isFocused?: Boolean }>`
  display: flex;
  font-size: ${rem(11)};
  font-weight: 600;
  color: ${(props) =>
    props.isFocused
      ? props.theme.colors.brand.primary
      : props.theme.colors.gray.gray600};
`

const InputScaffoldingLableRequiredStyled = styled.span`
  display: inline-flex;
  margin-left: ${rem(4)};
  font-weight: 400;
  font-style: italic;
`

const InputScaffoldingInputContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input,
  select,
  textarea {
    display: block;
    width: 100%;
    margin: 0;
    padding: ${rem(8)} 0;
    white-space: normal;
    line-height: 1;
    font-size: ${rem(16)};
    font-weight: 600;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    color: ${(props) => props.theme.colors.gray.gray800};
    border: 0;
    background: none;

    &:focus {
      outline: 0;
    }

    ::-webkit-input-placeholder,
    ::placeholder {
      font-weight: 300;
      color: ${(props) => props.theme.colors.gray.gray500};
    }
    :-ms-input-placeholder {
      font-weight: 300;
      color: ${(props) => props.theme.colors.gray.gray500};
    }
  }

  input,
  textarea {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type="reset"],
  select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  textarea {
    vertical-align: top;
    overflow: auto;
  }

  select {
    margin-top: ${rem(6)};
    -webkit-appearance: none;

    ::-ms-expand {
      display: none;
    }
  }

  select[multiple] {
    vertical-align: top;
  }
`
