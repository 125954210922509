import React from "react"
import { IconProps } from "./iconTypes"

const IconArrowRightBold = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.0716 2.21769L11.5546 2.34709L11.0716 2.2177C10.9821 2.55181 11.0776 2.90832 11.3222 3.15294L11.3225 3.15326L19.2012 11.0188L2.46811 11.0188C1.93337 11.0188 1.5 11.4523 1.5 11.987C1.5 12.5216 1.93337 12.9551 2.46811 12.9551L19.1978 12.9551L11.3492 20.8536L11.3491 20.8536L11.3447 20.8581C10.9761 21.2386 10.9817 21.8448 11.3574 22.2184C11.7331 22.592 12.3394 22.5941 12.7177 22.2232L12.7178 22.2232L12.7224 22.2186L22.2185 12.6604C22.5949 12.2817 22.5937 11.6698 22.2159 11.2927L12.6915 1.78364C12.6914 1.78356 12.6913 1.78341 12.6912 1.78333C12.4466 1.53888 12.0903 1.44349 11.7562 1.53301L11.8857 2.01597L11.7562 1.53301C11.4221 1.62255 11.1611 1.88357 11.0716 2.21769Z" />
  </svg>
)

export default IconArrowRightBold
