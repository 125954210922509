import React, { ReactNode } from "react"
import styled from "styled-components"

export interface MediaSvgMaskProps {
  /** Aspect ratio of svg element or desired container */
  aspectRatio: number
  children?: ReactNode
  className?: string
  backgroundColor?: string
  /** Optional background image to be applied to container */
  backgroundImg?: string
  backgroundImgSize?: string
  /** Url to svg source */
  svg: string
}

/**
 * Component that takes an svg url
 * and masks out the background
 */
const MediaSvgMask = ({
  aspectRatio,
  backgroundColor = "var(--color-primary)",
  backgroundImg,
  backgroundImgSize = "cover",
  children,
  className,
  svg,
}: MediaSvgMaskProps) => {
  return (
    <MediaSvgMaskStyled
      backgroundColor={backgroundColor}
      backgroundImg={backgroundImg}
      backgroundImgSize={backgroundImgSize}
      className={`${className} svg-mask`}
      style={{ paddingBottom: `${100 / aspectRatio}%` }}
      svg={svg}
    >
      <img alt="" className="svg-mask__fallback" src={svg} />
      {children}
    </MediaSvgMaskStyled>
  )
}

export default MediaSvgMask

interface MaskSC {
  backgroundColor: string
  backgroundImg?: string
  backgroundImgSize: string
  svg: string
}

const MediaSvgMaskStyled = styled.div<MaskSC>`
  position: relative;

  @supports (mask: url("./img.svg")) {
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-image: ${({ backgroundImg }) =>
      backgroundImg ? `url(${backgroundImg})` : "none"};
    background-size: ${({ backgroundImgSize }) => backgroundImgSize};
    mask: ${({ svg }) => `url(${svg})`};
  }

  .svg-mask__fallback {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%, -50%);

    @supports (mask: url("./img.svg")) {
      display: none;
    }
  }
`
