import React from "react"
import { IconProps } from "./iconTypes"

const IconWaterDrop = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M16.6187 15.9687C16.6187 15.71 16.8284 15.5004 17.087 15.5004C17.3456 15.5005 17.5553 15.7102 17.5553 15.9688C17.555 18.6126 15.4047 20.7629 12.761 20.7629C12.5023 20.7629 12.2927 20.5532 12.2927 20.2946C12.2927 20.036 12.5023 19.8263 12.761 19.8263C14.8874 19.8263 16.6184 18.0953 16.6187 15.9687ZM10.5939 3.89551C9.72139 5.0708 8.87278 6.28361 8.09803 7.48627C7.13541 8.98057 6.3436 10.3722 5.77325 11.6088C5.13521 12.9922 4.79999 14.1318 4.79999 14.9398C4.79999 18.8335 8.03255 22.0097 12.012 22.0097C15.9914 22.0097 19.2237 18.8335 19.2234 14.9398C19.2234 14.1318 18.8882 12.9922 18.2502 11.6089C17.6798 10.3723 16.8881 8.98064 15.9255 7.48637C15.1507 6.28372 14.3021 5.0709 13.4296 3.89563C12.8742 3.14744 12.3866 2.51784 12.0117 2.04952C11.6367 2.51798 11.1493 3.14728 10.5939 3.89551ZM12.012 22.9463C7.51889 22.9463 3.8634 19.3544 3.8634 14.9398C3.8634 10.8118 10.5669 2.35207 11.3311 1.40119C11.4956 1.19663 11.7466 1.07703 12.0117 1.07703C12.2768 1.07703 12.5278 1.19635 12.6923 1.40119C13.4568 2.35236 20.16 10.8118 20.16 14.9398C20.1603 19.3544 16.5051 22.9463 12.012 22.9463Z" />
  </svg>
)

export default IconWaterDrop
