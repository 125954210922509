import React from "react"
import { IconProps } from "./iconTypes"

const IconPlayCircle = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.9797 22.7297C6.04268 22.7297 1.22974 17.9168 1.22974 11.9797C1.22974 6.04268 6.04268 1.22974 11.9797 1.22974C17.9168 1.22974 22.7297 6.04268 22.7297 11.9797C22.7297 17.9168 17.9168 22.7297 11.9797 22.7297ZM11.9797 21.8683C17.4411 21.8683 21.8683 17.4411 21.8683 11.9797C21.8683 6.51841 17.4411 2.09113 11.9797 2.09113C6.51841 2.09113 2.09113 6.51841 2.09113 11.9797C2.09113 17.4411 6.51841 21.8683 11.9797 21.8683ZM9.91588 8.36798L15.5915 11.9797L9.91588 15.5915V8.36798Z" />
  </svg>
)

export default IconPlayCircle
