import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"
import SanityImage from "gatsby-plugin-sanity-image"

import { media } from "@sr/common"

import BlockContentBlock from "../../BlockContent/Block"
import ButtonGroup, { ButtonGroupInstance } from "../../Button/Group"
import AppLayoutWidth from "../../App/Layout/Width"
import { SanityImg, WidthConstraintType } from "../../../types/index"
import { SemiBdTitle2 } from "../../Typography/index"
export interface SectionSplitContentMultimediaContainedProps {
  /** Sets BG color for selected Color Theme */
  bgColor?: string
  /** Array of buttons to be rendered as CTAs */
  buttons?: ButtonGroupInstance[]
  children?: ReactNode
  className?: string
  /** Sanity block content to be rendered through serialization */
  blockContent?: any[]
  /** Select color theme for section */
  colorTheme?: string
  /** Constrain width of section content columns
   * @default "max"
   */
  contentWidth?: WidthConstraintType
  /** Content to be rendered as top-level heading */
  header?: string | ReactNode
  /** Provide an image */
  mediaImg?: SanityImg
  /** Switch section on */
  on?: boolean
  /** Set media to left of content on desktop
   * @default false
   */
  reverseStandard?: boolean
}

/**
 * Split content video section component
 * - Renders split sections for video and content
 */
const SectionSplitContentMultimediaContained = ({
  buttons,
  blockContent,
  colorTheme = "white",
  contentWidth,
  header,
  mediaImg,
  reverseStandard = false,
}: SectionSplitContentMultimediaContainedProps) => {
  const flexDirectionSt = reverseStandard ? "row-reverse" : "row"
  const headerColorTheme =
    colorTheme === "primary" || colorTheme === "gray900"
      ? "var(--color-white)"
      : "var(--color-gray900)"

  const calloutColorTheme =
    colorTheme === "primary" ? "var(--color-white)" : "var(--color-primary)"

  const bodyColorTheme =
    colorTheme === "primary" || colorTheme === "gray900"
      ? "var(--color-white)"
      : "var(--color-gray900)"

  const getColorTheme = (theme: string) => {
    switch (theme) {
      case "gray050":
        return "--color-gray050"
      case "gray900":
        return "--color-gray900"
      case "primary":
        return "--color-primary"
      default:
        return "--color-white"
    }
  }
  return (
    <SectionSplitContentMultimediaContainedStyled
      headerColorTheme={headerColorTheme}
      calloutColorTheme={calloutColorTheme}
      flexDirectionSt={flexDirectionSt}
      bodyColorTheme={bodyColorTheme}
      style={{
        backgroundColor: `var(${getColorTheme(colorTheme)})`,
      }}
    >
      <AppLayoutWidth
        className="split-content-multimedia-contained__inner"
        constraint={contentWidth}
      >
        <div className="split-content-multimedia-contained__columns">
          <div className="split-content-multimedia-contained__content">
            {header && (
              <SemiBdTitle2 className="split-content-multimedia-contained__header">
                {header}
              </SemiBdTitle2>
            )}
            {blockContent && (
              <BlockContentBlock
                className="split-content-multimedia-contained__body"
                blocks={blockContent}
              />
            )}
            {buttons && buttons.length > 0 && (
              <ButtonGroup
                buttons={buttons}
                className="split-content-multimedia-contained__buttons"
              />
            )}
          </div>
          <div className="split-content-multimedia-contained__img">
            {mediaImg && mediaImg.asset && (
              <SanityImage
                alt={mediaImg.alt || ""}
                asset={mediaImg.asset}
                className="img"
                crop={mediaImg.crop}
                hotspot={mediaImg.hotspot}
                width={300}
                height={300}
              />
            )}
          </div>
        </div>
      </AppLayoutWidth>
    </SectionSplitContentMultimediaContainedStyled>
  )
}
export default SectionSplitContentMultimediaContained

interface SectionSC {
  headerColorTheme: string
  calloutColorTheme: string
  bodyColorTheme: string
  flexDirectionSt: "row" | "row-reverse"
}

const SectionSplitContentMultimediaContainedStyled = styled.section<SectionSC>`
  position: relative;
  z-index: 1;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${rem(80)} 0;

  .split-content-multimedia-contained__header {
    color: ${({ headerColorTheme }) => headerColorTheme};
  }

  .callout {
    color: ${({ calloutColorTheme }) => calloutColorTheme};
  }

  .split-content-multimedia-contained__body {
    color: ${({ bodyColorTheme }) => bodyColorTheme};
  }

  .split-content-multimedia-contained__columns {
    display: flex;
    flex-direction: column-reverse;

    @media ${media.stUp} {
      gap: 8%;
      flex-direction: ${({ flexDirectionSt }) => flexDirectionSt};
      align-items: center;
    }
  }

  .split-content-multimedia-contained__content {
    @media ${media.stUp} {
      width: 50%;
      padding: 0;
    }
  }

  .split-content-multimedia-contained__img {
    padding-bottom: ${rem(32)};

    @media ${media.stUp} {
      padding-bottom: 0;
      width: 50%;
    }
  }
`
