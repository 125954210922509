import React, { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface NavIconBarItemProps {
  className?: string
  icon?: ReactNode
  /** Sets a dark background and adjusts item's contrast
   * @default false
   */
  onDark?: boolean
  title: ReactNode | string
  path: string
}
export type NavIconBarItemType = NavIconBarItemProps

const NavIconBarItem = ({
  className,
  icon,
  onDark = false,
  title,
  path,
}: NavIconBarItemProps) => {
  return (
    <NavIconBarItemStyled
      className={`${className || ""}icon-bar-item`}
      isOnDark={onDark}
    >
      <Link to={path} className="icon-bar-item__a u-text-decoration-none">
        {icon}
        <h4 className="icon-bar-item__title">{title}</h4>
      </Link>
    </NavIconBarItemStyled>
  )
}

export default NavIconBarItem

interface ItemSC {
  isOnDark: boolean
}

const NavIconBarItemStyled = styled.li<ItemSC>`
  .icon-bar-item__a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: ${rem(48)};
    padding: ${rem(16)} ${rem(4)};

    @media ${media.smMidUp} {
      padding-top: 3vh;
      padding-bottom: 3vh;
    }

    @media ${media.stUp} {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .icon {
    width: ${rem(40)};
    height: ${rem(40)};
    margin-bottom: 1vh;
    fill: var(--color-link);

    @media ${media.stUp} {
      width: ${rem(56)};
      height: ${rem(56)};
    }
  }

  .icon-bar-item__title {
    font-size: ${rem(13)};
    color: ${({ isOnDark }) =>
      isOnDark ? "var(--color-text-subtract)" : "var(--color-text)"};
  }
`
