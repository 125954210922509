import React, { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { SanityImg } from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import ArticlePreview from "../../Article/Preview"
import ArticlePreviewVideo from "../../Article/PreviewVideo"
import CardLift from "../../Card/Lift"
import SectionFeaturedEntriesFirst from "./First"

export interface SectionFeaturedEntriesEntryShape {
  excerpt?: string
  id: string
  feature?: SanityImg
  path: string
  publishDate: string
  publishDateIso: string
  title: string
  type: string
}

export interface SectionFeaturedEntriesProps {
  /** Background color of section
   * @default transparent
   */
  bgColor?: string
  children?: ReactNode
  className?: string
  entries?: SectionFeaturedEntriesEntryShape[]
  header?: string | ReactNode
  /** Padding values for container */
  paddingBottomMobile?: string
  paddingBottomStandard?: string
  paddingTopMobile?: string
  paddingTopStandard?: string
  /** Set text on dark background?
   * @default false
   */
  textOnDark?: boolean
}

/**
 * Component for rendering a short list of recent or featured entries
 */
const SectionFeaturedEntries = ({
  bgColor = "var(--color-content-bg)",
  children,
  className,
  entries,
  header,
  paddingBottomMobile = rem(48),
  paddingBottomStandard = `10vh`,
  paddingTopMobile = rem(48),
  paddingTopStandard = `10vh`,
  textOnDark = false,
}: SectionFeaturedEntriesProps) => {
  if (!entries || entries.length === 0) return null
  const firstEntry = entries[0]
  const restOfEntries = entries.filter((entry) => entry.id !== firstEntry.id)

  return (
    <SectionFeaturedEntriesStyled
      bgColor={bgColor}
      className={`${className || ""}featured-entries`}
      paddingBM={paddingBottomMobile}
      paddingBSt={paddingBottomStandard}
      paddingTM={paddingTopMobile}
      paddingTSt={paddingTopStandard}
      textOnDark={textOnDark}
    >
      <AppLayoutWidth constraint="xl">
        <div className="featured-entries__layout">
          {firstEntry && (
            <SectionFeaturedEntriesFirst
              articleImg={firstEntry.feature}
              articlePublishDate={firstEntry.publishDate}
              articlePublishDateIso={firstEntry.publishDateIso}
              articleTitle={firstEntry.title}
              className="featured-entries__first"
              path={firstEntry.path}
              header={header}
            />
          )}
          {restOfEntries && restOfEntries.length > 0 ? (
            <ul className="featured-entries__list">
              {restOfEntries.map((entry) => (
                <li className="featured-entries__list__item" key={entry.id}>
                  <Link
                    className="u-text-decoration-none featured-entries__link"
                    to={entry.path}
                  >
                    <CardLift>
                      {entry.type === "newsArticle" && (
                        <ArticlePreview
                          img={entry.feature}
                          time={entry.publishDate}
                          timeIso={entry.publishDateIso}
                          title={entry.title}
                        />
                      )}
                      {entry.type === "newsVideo" && (
                        <ArticlePreviewVideo
                          img={entry.feature}
                          time={entry.publishDate}
                          timeIso={entry.publishDateIso}
                          title={entry.title}
                        />
                      )}
                    </CardLift>
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
          {children}
        </div>
      </AppLayoutWidth>
    </SectionFeaturedEntriesStyled>
  )
}

export default SectionFeaturedEntries

interface SectionSC {
  bgColor: string
  paddingBM: string
  paddingBSt: string
  paddingTM: string
  paddingTSt: string
  textOnDark: boolean
}

const SectionFeaturedEntriesStyled = styled.section<SectionSC>`
  position: relative;
  z-index: 1;
  padding-top: ${({ paddingTM }) => paddingTM};
  padding-bottom: ${({ paddingBM }) => paddingBM};
  color: ${({ textOnDark }) =>
    textOnDark ? `var(--color-text-subtract)` : `var(--color-text)`};
  background-color: ${({ bgColor }) => bgColor};

  @media ${media.stUp} {
    padding-top: ${({ paddingTSt }) => paddingTSt};
    padding-bottom: ${({ paddingBSt }) => paddingBSt};
  }

  .featured-entries__layout {
    @media ${media.stUp} {
      display: flex;
      align-items: stretch;
      gap: ${rem(56)};
    }
  }

  .featured-entries__first {
    @media ${media.stUp} {
      flex: 1 0 calc(50% - ${rem(56)});
    }
  }

  .featured-entries__list {
    padding: ${rem(24)} 0 0;

    @media ${media.stUp} {
      flex: 1 0 calc(50% - ${rem(56)});
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      padding: ${rem(116)} 0 0;
    }
  }

  .featured-entries__list__item {
    margin-bottom: ${rem(24)};

    @media ${media.stUp} {
      margin-bottom: ${rem(48)};
    }

    &:last-child {
      margin-bottom: 0;

      @media ${media.stUp} {
        margin-bottom: 0;
      }
    }
  }

  .featured-entries__header {
    margin-bottom: ${rem(24)};

    @media ${media.stUp} {
      margin-bottom: 0;
    }
  }

  .featured-entries__link {
    display: block;
    color: ${({ textOnDark }) =>
      textOnDark ? `var(--color-text-subtract)` : `var(--color-text)`};
  }
`
