/*////////////////////////////////////////////
// GLOBAL BOX AND TEXT SHADOWS
////////////////////////////////////////////*/
import colors from "./colors"
import { rgba } from "polished"

const { black: themeBlack } = colors.gray
const { white: themeWhite } = colors.gray

const shadow = `0 5px 25px ${rgba(themeBlack, 0.2)}`
const shadowBottom = `0 25px 25px ${rgba(themeBlack, 0.2)}`
const shadowLight = `0 0 9px ${rgba(themeBlack, 0.22)}`
const shadowHard = `2px 2px 0 ${rgba(themeBlack, 0.22)}`
const shadowHardLeft = `-2px -2px 0 ${rgba(themeBlack, 0.22)}`
const shadowTop = `0 4px 9px ${rgba(themeBlack, 0.22)}`
const shadowInset = `inset 0 0 4px ${rgba(themeBlack, 0.5)}`

export const shadows = {
  shadow,
  shadowBottom,
  shadowLight,
  shadowHard,
  shadowHardLeft,
  shadowTop,
  shadowInset,
}

const textShadow = `1px 1px ${rgba(themeBlack, 0.2)}`
const textShadowOffset = `-1px -1px ${rgba(themeBlack, 0.3)}`
const textShadowRemove = `0 0 ${rgba(themeBlack, 0)}`
const textShadowBlur = `0 2px 4px ${rgba(themeBlack, 0.5)}`
const textShadowWhite = `1px 1px ${rgba(themeWhite, 0.65)}`

export const textShadows = {
  textShadow,
  textShadowOffset,
  textShadowRemove,
  textShadowBlur,
  textShadowWhite,
}
