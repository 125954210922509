import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { BioShape } from "./index"
import { RegBody, Caption } from "../../Typography/index"

export interface SectionBioCardProps {
  bio: BioShape
  className?: string
}

const SectionBioCard = ({ bio, className }: SectionBioCardProps) => {
  if (!bio) return null
  const { img, name, title } = bio
  return (
    <SectionBioCardStyled className={className}>
      {img && img.asset ? (
        <SanityImage
          alt={img.alt || ""}
          asset={img.asset}
          className="img"
          crop={img.crop}
          hotspot={img.hotspot}
          width={300}
          height={300}
        />
      ) : null}
      <figcaption>
        <RegBody as="h2">{name}</RegBody>
        <Caption color="var(--color-primary)" as="h3">
          {title}
        </Caption>
      </figcaption>
    </SectionBioCardStyled>
  )
}

export default SectionBioCard

const SectionBioCardStyled = styled.figure`
  &:hover {
    h2 {
      text-decoration: underline;
    }

    .img {
      box-shadow: var(--shadow);
    }
  }

  .img {
    margin-bottom: ${rem(16)};

    @media ${media.stUp} {
      margin-bottom: ${rem(24)};
      transition: box-shadow 250ms ease;
    }
  }
`
