import React from "react"
import { IconProps } from "./iconTypes"

const IconClock = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.0028 1.00879C18.0693 1.00879 22.9874 5.92691 22.9874 11.9935C22.9874 18.06 18.0693 22.9777 12.0028 22.9777C5.93619 22.9777 1.01807 18.0605 1.01807 11.9935C1.01807 5.92645 5.93619 1.00879 12.0028 1.00879ZM19.041 19.0313C19.95 18.1245 20.6745 17.0499 21.1743 15.8672C23.1619 11.162 21.252 5.71672 16.7608 3.28387C12.2697 0.851014 6.66531 2.22586 3.80992 6.46095C0.954531 10.696 1.78148 16.407 5.72087 19.6582C9.66026 22.9094 15.4244 22.6381 19.041 19.0313ZM11.9999 2.98316C12.2847 2.98316 12.5156 3.21402 12.5156 3.49879V12.0047C12.5156 12.2895 12.2847 12.5204 11.9999 12.5204H11.9957C11.859 12.5204 11.7278 12.4661 11.631 12.3694L8.44354 9.18191C8.24675 8.97975 8.24898 8.65697 8.44854 8.45754C8.64809 8.25812 8.97087 8.25609 9.17291 8.45301L11.4843 10.7644V3.49879C11.4843 3.21402 11.7152 2.98316 11.9999 2.98316ZM11.9999 16.9688C12.2847 16.9688 12.5156 17.1996 12.5156 17.4844V20.4844C12.5156 20.7692 12.2847 21 11.9999 21C11.7152 21 11.4843 20.7692 11.4843 20.4844V17.4844C11.4843 17.1996 11.7152 16.9688 11.9999 16.9688ZM6.50525 11.4844C6.79003 11.4844 7.02088 11.7153 7.02088 12C7.02088 12.2848 6.79003 12.5157 6.50525 12.5157H3.50525C3.22048 12.5157 2.98963 12.2848 2.98963 12C2.98963 11.7153 3.22048 11.4844 3.50525 11.4844H6.50525ZM20.5171 11.4844C20.8019 11.4844 21.0328 11.7153 21.0328 12C21.0328 12.2848 20.8019 12.5157 20.5171 12.5157H17.5171C17.2324 12.5157 17.0015 12.2848 17.0015 12C17.0015 11.7153 17.2324 11.4844 17.5171 11.4844H20.5171Z" />
  </svg>
)

export default IconClock
