import React, { ReactNode } from "react"
import { Link } from "gatsby"

export interface LinkWrapperPropsShape {
  link?:
    | {
        _type: "internalLink"
        reference: {
          path: {
            current: string
          }
        }
      }
    | { _type: "externalLink"; url: string; blank: boolean }
  internalLink?: boolean
  children: ReactNode
}

const LinkWrapper = ({ link, children }: any) => {
  if (link?._type === "internalLink" && link?.reference?.path?.current) {
    return <Link to={link?.reference?.path?.current}>{children}</Link>
  }
  if (link?._type === "externalLink" && link?.url) {
    return (
      <a href={link?.url} target={link?.blank ? "_blank" : undefined}>
        {children}
      </a>
    )
  } else {
    return <>{children}</>
  }
}

export default LinkWrapper
