import React from "react"
import { IconProps } from "./iconTypes"

const IconArrowLeft = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M1.11837602,11.625 L11.6099385,1.15171875 C11.7402288,1.02142846 11.9301311,0.970544303 12.1081109,1.01823386 C12.2860908,1.06592342 12.4251089,1.20494151 12.4727984,1.38292136 C12.520488,1.56090121 12.4696038,1.75080346 12.3393135,1.88109375 L2.71868852,11.484375 L22.4816573,11.484375 C22.7664291,11.484375 22.9972823,11.7152282 22.9972823,12 C22.9972823,12.2847718 22.7664291,12.515625 22.4816573,12.515625 L2.72993852,12.515625 L12.308376,22.1535938 C12.5047474,22.356247 12.5017391,22.6791075 12.3016257,22.8780665 C12.1015122,23.0770254 11.7786397,23.0781656 11.577126,22.880625 L1.11696977,12.3534375 C0.916504095,12.1517739 0.917133218,11.8258881 1.11837602,11.625 Z" />
  </svg>
)

export default IconArrowLeft
