import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface AppFooterCustomersProps {
  className?: string
}

const AppFooterCustomers = ({ className }: AppFooterCustomersProps) => {
  return (
    <AppFooterCustomersStyled className={`${className} footer-customers`}>
      <Link to="/customers/" className="footer-customers__title">
        Customers
      </Link>
      <div>
        <Link
          className="footer-customers__link"
          to="/customers/channel-partners-installers/"
        >
          Partner Program
        </Link>
        <Link className="footer-customers__link" to="/customers/homebuilders/">
          Homebuilders
        </Link>
        <Link className="footer-customers__link" to="/customers/multifamily/">
          Multifamily
        </Link>
        <Link
          className="footer-customers__link"
          to="/customers/single-family-rentals/"
        >
          Single Family Rentals
        </Link>
        <Link
          className="footer-customers__link"
          to="/customers/student-housing/"
        >
          Student Housing
        </Link>

        <Link className="footer-customers__link" to="/case-studies/">
          Case Studies
        </Link>
        <Link className="footer-customers__link" to="/whitepapers/">
          White Papers
        </Link>
      </div>
    </AppFooterCustomersStyled>
  )
}

export default AppFooterCustomers

const AppFooterCustomersStyled = styled.div`
  padding: ${rem(16)} 0 0;

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    width: var(--st-column-default);
  }

  .footer-customers__title {
    padding: ${rem(8)} 0;
    font-weight: bold;
    color: var(--color-gray200);
  }

  .footer-customers__link {
    padding: ${rem(8)} 0;
    line-height: 150%;
    color: var(--color-gray200);
  }

  .footer-case-studies__title {
    padding-top: ${rem(24)};
    font-weight: bold;
    font-size: ${rem(14)};
    color: var(--color-gray200);
  }
`
