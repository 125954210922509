import React from "react"
import { IconProps } from "./iconTypes"

const IconPower = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.9808 12.0028C11.696 12.0028 11.4651 11.772 11.4651 11.4872V2.98407C11.4651 2.6993 11.696 2.46844 11.9808 2.46844C12.2655 2.46844 12.4964 2.6993 12.4964 2.98407V11.4872C12.4964 11.772 12.2655 12.0028 11.9808 12.0028ZM18.3333 5.66063C18.4332 5.56158 18.5693 5.50766 18.7099 5.51138C18.8505 5.5151 18.9836 5.57613 19.0781 5.68032C20.6311 7.41609 21.4881 9.66436 21.4847 11.9934C21.4847 17.1778 17.3241 21.3905 12.1594 21.4739C8.40113 21.536 4.96105 19.3722 3.38951 15.9577C1.81797 12.5433 2.4117 8.52287 4.90312 5.70844C4.99707 5.60192 5.1308 5.53887 5.27276 5.53417C5.41471 5.52946 5.55232 5.58353 5.65312 5.6836C5.84775 5.87763 5.85599 6.19021 5.67187 6.39423C2.69795 9.75784 2.87348 14.8598 6.07154 18.0111C9.26959 21.1623 14.3736 21.2627 17.693 18.2395C21.0124 15.2164 21.3883 10.1252 18.5489 6.64735C18.473 6.55391 18.3948 6.46251 18.3145 6.37313C18.1292 6.16885 18.1375 5.85489 18.3333 5.66063Z" />
  </svg>
)

export default IconPower
