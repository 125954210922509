import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { media, useMedia, useSanity } from "@sr/common"

import { SanityImg } from "../../types/index"

const mobileBgImgWidthDefault = 500
const mobileBgImgHeightDefault = 800
const standardBgImgWidthDefault = 2000
const standardBgImgHeightDefault = 1000

interface MediaBgImgBgImgAsset extends SanityImg {
  alt?: string
}
export interface MediaBgImgProps {
  /** Sets a blur effect over background image
   * Converts number to pixel value
   * @default 0
   */
  bgBlur?: number
  /** Mobile breakpoint height of background image
   * @default 800
   */
  bgImgMobileHeight?: number
  /** Mobile breakpoint width of background image
   * @default 500
   */
  bgImgMobileWidth?: number
  /** Standard breakpoint height of background image
   * @default 1000
   */
  bgImgStandardHeight?: number
  /** Standard breakpoint width of background image
   * @default 2000
   */
  bgImgStandardWidth?: number
  className?: string
  bgImg: MediaBgImgBgImgAsset
  /** Color to be overlayed on top of image
   * @default "transparent"
   */
  overlayColor?: string
  /** Opacity of color overlay on top of image/media
   * @default 0
   */
  overlayOpacity?: number
}

/**
 * A component for rendering background images within
 * a containing component
 */
const MediaBgImg = ({
  bgBlur = 1,
  className,
  bgImg,
  bgImgMobileHeight = mobileBgImgHeightDefault,
  bgImgMobileWidth = mobileBgImgWidthDefault,
  bgImgStandardHeight = standardBgImgHeightDefault,
  bgImgStandardWidth = standardBgImgWidthDefault,
  overlayColor = "transparent",
  overlayOpacity = 0,
}: MediaBgImgProps) => {
  if (!bgImg || !bgImg.asset || !bgImg.asset._id) return null

  const isMobile: boolean = !useMedia(media.stUp)

  /** Sanity img url API doesn't handle 0 set as blur value
   * if 0 is passed, convert to 1
   */
  const sanityBgBlur: number = bgBlur === 0 || bgBlur === null ? 1 : bgBlur

  const { imgBuilder } = useSanity()
  const mobileBgImg = imgBuilder(bgImg)
    .size(bgImgMobileWidth, bgImgMobileHeight)
    .blur(sanityBgBlur)
    .quality(70)
    .auto("format")
    .url()
  const standardBgImg = imgBuilder(bgImg)
    .size(bgImgStandardWidth, bgImgStandardHeight)
    .blur(sanityBgBlur)
    .quality(70)
    .auto("format")
    .url()

  // use state and effect to set image and defaults
  // prevents SSR bug by relying on useEffect
  const [mediaBgImg, setMediaBgImg] = useState(mobileBgImg)
  useEffect(() => {
    setMediaBgImg(isMobile ? mobileBgImg : standardBgImg)
  }, [isMobile])

  return (
    <MediaBgImgStyled
      className={`${className || ""} bg-img`}
      style={{ backgroundImage: `url(${mediaBgImg})` || "" }}
    >
      {mobileBgImg && (
        <img
          src={mobileBgImg}
          alt=""
          width={mobileBgImgWidthDefault}
          height={mobileBgImgHeightDefault}
          style={{ display: "none" }}
        />
      )}
      {standardBgImg && (
        <img
          src={standardBgImg}
          alt=""
          width={standardBgImgWidthDefault}
          height={standardBgImgHeightDefault}
          style={{ display: "none" }}
        />
      )}
      <div
        className="bg-img__overlay"
        style={{
          backgroundColor: overlayColor,
          opacity: overlayOpacity / 100,
        }}
      />
    </MediaBgImgStyled>
  )
}

export default MediaBgImg

const MediaBgImgStyled = styled.div`
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;

  /* overlay */
  .bg-img__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`
