import React from "react"
import { IconProps } from "./iconTypes"

const IconHome = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M15.5222 21.9651H19.9922C20.2567 21.9587 20.4662 21.7398 20.461 21.4753V8.52702C20.4648 8.05915 20.2066 7.62845 19.7921 7.4114L12.5316 3.64405C12.3615 3.55612 12.1728 3.51 11.9813 3.50952C11.7897 3.50993 11.601 3.55622 11.431 3.64452L4.18363 7.41046C3.76965 7.62793 3.51187 8.05847 3.51566 8.52608V21.4753C3.51042 21.7398 3.71996 21.9587 3.98441 21.9651H8.49097V16.9866C8.49097 16.1581 9.16255 15.4866 9.99097 15.4866H14.0222C14.8507 15.4866 15.5222 16.1581 15.5222 16.9866V21.9651ZM14.0199 16.5178H9.99097C9.73209 16.5178 9.52222 16.7277 9.52222 16.9866V21.9619H14.4886V16.9866C14.4886 16.7277 14.2788 16.5178 14.0199 16.5178ZM11.9813 2.47827C12.3382 2.4783 12.6898 2.56432 13.0064 2.72905L20.2669 6.49593C21.0222 6.89144 21.4946 7.67445 21.4922 8.52702V21.4753C21.4979 22.3095 20.8264 22.9905 19.9922 22.9964H3.98441C3.15021 22.9905 2.4787 22.3095 2.48441 21.4753V8.52608C2.48224 7.67368 2.95459 6.89089 3.70972 6.49546L10.9552 2.72952C11.2721 2.56458 11.624 2.4784 11.9813 2.47827Z" />
  </svg>
)

export default IconHome
