import React, { useState } from "react"
import { AnimatePresence, motion, Variants } from "framer-motion"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { AppLayoutWidth } from "../../App/Layout/index"
import BlockContentInline from "../../BlockContent/Inline"
import { SectionHotspotSliderSlideType } from "./index"

type dragDirectionType = "left" | "right"

export interface SectionHotspotSliderSlidesProps {
  className?: string
  currentSlide: number
  onSlideDrag: (direction: dragDirectionType) => void
  onSlideSelect: (id: number) => void
  slides: SectionHotspotSliderSlideType[]
}

const SectionHotspotSliderSlides = ({
  className,
  currentSlide,
  onSlideDrag,
  onSlideSelect,
  slides,
}: SectionHotspotSliderSlidesProps) => {
  if (!slides || slides.length === 0) return null

  const [dragDirection, setDragDirection] = useState<dragDirectionType>("left")

  const variants: Variants = {
    enter: (dragDirection = "left") => ({
      x: dragDirection === "left" ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (dragDirection = "left") => ({
      x: dragDirection === "left" ? "-100%" : "100%",
      opacity: 0,
    }),
  }

  return (
    <SectionHotspotSliderSlidesStyled className={className}>
      <ol className="list">
        <AnimatePresence>
          {slides.map(
            (slide, index) =>
              currentSlide === index && (
                <motion.li
                  className="item"
                  key={index}
                  custom={dragDirection}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  drag="x"
                  dragConstraints={{ left: 0.2, right: 0.2 }}
                  dragElastic={1}
                  onDragEnd={(_, { offset }) => {
                    const direction = offset.x < 0 ? "left" : "right"
                    setDragDirection(direction)
                    onSlideDrag(direction)
                  }}
                >
                  <AppLayoutWidth constraint="readable" paddings={[0, 0]}>
                    {slide.img && slide?.img?.asset ? (
                      <SanityImage
                        alt={slide.img.alt || ""}
                        asset={slide.img.asset}
                        className="img"
                        crop={slide.img.crop}
                        hotspot={slide.img.hotspot}
                        width={500}
                        height={300}
                      />
                    ) : null}
                    <h4 className="title">{slide.header}</h4>
                    {slide.content && slide.content.length > 0 ? (
                      <BlockContentInline blocks={slide.content} />
                    ) : null}
                  </AppLayoutWidth>
                </motion.li>
              )
          )}
        </AnimatePresence>
      </ol>
      <SectionHotspotSliderSlidesPagerStyled className="pagination">
        {slides.map((_slide, index) => (
          <li
            className={`pagination-item ${
              currentSlide === index ? "active" : ""
            }`}
            key={index}
          >
            <button className="button" onClick={() => onSlideSelect(index)}>
              <span className="dot" />
              <span className="u-hidden-visually">View slide {index}</span>
            </button>
          </li>
        ))}
      </SectionHotspotSliderSlidesPagerStyled>
    </SectionHotspotSliderSlidesStyled>
  )
}

export default SectionHotspotSliderSlides

const SectionHotspotSliderSlidesStyled = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)};
  background: var(--color-interactive-bg);
  border-left: 1px solid var(--color-border-light);

  @media ${media.stUp} {
    padding: ${rem(24)};
  }

  @media ${media.lgUp} {
    padding: ${rem(72)};
  }

  .list {
    position: relative;
    width: 100%;
    min-height: 100%;
    flex: 1 0 100%;
  }

  .item {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: grab;

    &:focus {
      cursor: grabbing;
    }
  }

  .item__img {
    margin-bottom: ${rem(16)};
  }

  .title {
    margin-bottom: ${rem(16)};
    font-size: ${rem(16)};
    font-weight: 600;

    @media ${media.stUp} {
      margin-bottom: ${rem(24)};
      font-size: ${rem(21)};
    }
  }

  p {
    font-size: ${rem(13)};
    line-height: ${rem(21)};

    @media ${media.stUp} {
      font-size: ${rem(21)};
      line-height: ${rem(32)};
    }
  }

  .pager {
    flex: 1 0 auto;
  }
`

const SectionHotspotSliderSlidesPagerStyled = styled.ol`
  display: flex;
  align-items: center;

  .button {
    display: block;
    width: ${rem(48)};
    height: ${rem(48)};
    text-align: center;
    line-height: ${rem(48)};

    @media ${media.stUp} {
      width: ${rem(24)};
      height: ${rem(24)};
      line-height: ${rem(24)};
    }

    &:focus {
      outline: none;
    }
  }

  .dot {
    display: block;
    width: ${rem(8)};
    height: ${rem(8)};
    margin: auto;
    border-radius: 50%;
    background: var(--color-gray200);
  }

  .active {
    .dot {
      background: var(--color-gray500);
    }
  }
`
