import React from "react"
import ReactPlayer from "react-player/vimeo"
import styled from "styled-components"

export interface MediaVimeoProps {
  className?: string
  /**
   * Override options default Vimeo options
   * playerVars: Override the default player vars
   * embedOptions: Override the default embed options
   * onUnstarted: Called when state changes to unstarted (usually when video fails to autoplay)
   */
  config?: { [key: string]: any }
  /**
   * Set to true or false to display native player controls
   * @default false
   * */
  controls?: boolean
  /**
   * Set the height of the player
   * Will be set to 100% if `responsive` prop is passed
   * @default "360px"
   */
  height?: string
  /**
   * Set to true to show just the video thumbnail, which loads the full player on click
   * @default false
   * */
  light?: boolean
  /**
   * Set to true or false to loop the media
   * @default false
   * */
  loop?: boolean
  /**
   * Mutes the player
   * only works if `volume` is set
   * @default false
   */
  muted?: boolean
  /**
   * Called when media is loaded and ready to play.
   * If playing is set to true, media will play immediately
   */
  onReady?: () => void
  /**
   * 	Set the playback rate of the player
   * @default 1
   */
  playbackRate?: number
  /**
   * Set to true or false to pause or play the media
   * @default false
   */
  playing?: boolean
  /**
   * The time between onProgress callbacks, in milliseconds
   * @default 1000
   */
  progressInterval?: number
  /**
   * Set the player in a flexible container
   * @default true
   */
  responsive?: boolean
  url: string
  /**
   * 	Set the volume of the player, between 0 and 1
   *  @default null
   */
  volume?: number
  /**
   * Set the width of the player
   * Will be set to 100% if `responsive` prop is passed
   * @default "640px"
   * */
  width?: string
}

const MediaVimeo = ({
  className,
  config,
  controls = false,
  height = "360px",
  light = false,
  loop = false,
  muted = false,
  onReady,
  playbackRate = 1,
  playing = false,
  progressInterval = 1000,
  responsive = true,
  url,
  volume = undefined,
  width = "640px",
}: MediaVimeoProps) => {
  const playerWidth = responsive ? "100%" : width
  const playerHeight = responsive ? "100%" : height

  return (
    <MediaVimeoStyled className={className} responsive={responsive}>
      <ReactPlayer
        className="vimeo-player__player"
        controls={controls}
        config={config}
        height={playerHeight}
        light={light}
        loop={loop}
        muted={muted}
        onReady={onReady}
        playbackRate={playbackRate}
        playing={playing}
        progressInterval={progressInterval}
        url={url}
        volume={volume}
        width={playerWidth}
      />
    </MediaVimeoStyled>
  )
}

export default MediaVimeo

interface VimeoSC {
  responsive: boolean
}

const MediaVimeoStyled = styled.div<VimeoSC>`
  line-height: 0;
  background: var(--color-content-bg-invert);

  ${({ responsive }) => {
    if (responsive) {
      return `
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      `
    }
    return ``
  }}

  .vimeo-player__player {
    ${({ responsive }) => {
      if (responsive) {
        return `
          position: absolute;
          top: 0;
          left: 0;
        `
      }
      return ``
    }}
  }
`
