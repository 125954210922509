import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const RegBodyStyle = css<TypographyTypeProps>`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 400;

`

const RegBody = styled.p<TypographyTypeProps>`
  ${RegBodyStyle}
`
RegBody.displayName = "RegBody"

export default RegBody
