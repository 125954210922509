import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

const baseLineHeight = 16

export const Title5Style = css<TypographyTypeProps>`
  margin-bottom: ${props =>
    props.marginBottom
      ? `${rem(props.marginBottom)}`
      : `${rem(baseLineHeight * 8)}`};
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  ${SharedDynamicPropsStyled}
`

const Title5 = styled.h5<TypographyTypeProps>`
  ${Title5Style}
`
Title5.displayName = "Title5"

export default Title5
