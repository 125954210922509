import React from "react"
import { IconProps } from "./iconTypes"

const IconWindowShadeClosed = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M0.816735 5.65317H23.1833V3.81138H0.816735V5.65317ZM21.5236 6.49285L2.47501 6.49422L2.47644 14.678L21.5236 14.6766V6.49285ZM12 19.3026C11.4767 19.3026 11.0509 19.7256 11.0509 20.2456C11.0509 20.7656 11.4767 21.1886 12 21.1886C12.5233 21.1886 12.9491 20.7656 12.9491 20.2456C12.9491 19.7256 12.5233 19.3026 12 19.3026ZM12 22C11.0263 22 10.2341 21.213 10.2341 20.2456C10.2341 19.4178 10.8141 18.7221 11.5917 18.5385V15.4881H2.47501C2.02545 15.4881 1.65971 15.1247 1.65971 14.678V6.49285C1.65971 6.48342 1.65986 6.47399 1.66022 6.46461H0.787689C0.35334 6.46461 0 6.11356 0 5.68203V3.78257C0 3.35105 0.35334 3 0.787689 3H23.2123C23.6467 3 24 3.35105 24 3.78257V5.68203C24 6.11356 23.6467 6.46461 23.2123 6.46461H22.3398C22.3401 6.47399 22.3403 6.48342 22.3403 6.49285V14.678C22.3403 15.1247 21.9745 15.4881 21.5249 15.4881H12.4084V18.5385C13.1859 18.7221 13.7659 19.4178 13.7659 20.2456C13.7659 21.213 12.9737 22 12 22Z" />
  </svg>
)

export default IconWindowShadeClosed
