/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Global context provider
import React from "react"
import { GlobalStateContextProvider } from "@sr/gatsby-theme-ui"

export const wrapRootElement = ({ element }, config) => {
  const { localDev } = config

  // If developing theme locally, the config object params will be empty
  // and we are in LOCAL_DEV mode. Therefore, wrap context provider
  if (typeof localDev === "undefined" || localDev === true) {
    return <GlobalStateContextProvider>{element}</GlobalStateContextProvider>
  }
  // Otherwise, the theme is being used in a site project and will use
  // the site's gatsby-browser.js and gatsby-ssr.js file to wrap context provider
  else {
    return element
  }
}
