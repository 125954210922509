import React from "react"
import { IconProps } from "./iconTypes"

const IconCamera = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.0086 16.7491C12.6256 16.7492 13.2288 16.5663 13.7419 16.2235C14.2549 15.8808 14.6549 15.3936 14.891 14.8235C15.1272 14.2535 15.189 13.6262 15.0687 13.0211C14.9483 12.4159 14.6512 11.86 14.2149 11.4237C13.7786 10.9874 13.2227 10.6903 12.6175 10.5699C12.0124 10.4496 11.3851 10.5114 10.815 10.7476C10.245 10.9837 9.75781 11.3836 9.41506 11.8967C9.07232 12.4098 8.88943 13.013 8.88953 13.63C8.89052 14.4569 9.21945 15.2497 9.80417 15.8344C10.3889 16.4191 11.1817 16.7481 12.0086 16.7491ZM12.0086 11.5492C12.4201 11.5492 12.8224 11.6713 13.1646 11.8999C13.5068 12.1285 13.7735 12.4535 13.931 12.8337C14.0885 13.2139 14.1297 13.6323 14.0494 14.0359C13.9691 14.4396 13.7709 14.8103 13.4799 15.1013C13.1889 15.3923 12.8182 15.5905 12.4145 15.6708C12.0109 15.7511 11.5925 15.7099 11.2123 15.5524C10.8321 15.3949 10.5071 15.1282 10.2785 14.786C10.0498 14.4438 9.9278 14.0415 9.9278 13.63C9.92842 13.0783 10.1478 12.5494 10.5379 12.1594C10.928 11.7693 11.4569 11.5498 12.0086 11.5492Z" />
    <path d="M14.7865 17.7867C13.9642 18.3361 12.9975 18.6292 12.0086 18.6291C10.6832 18.6276 9.41249 18.1005 8.47528 17.1632C7.53807 16.226 7.01089 14.9553 7.0094 13.6299C7.00931 12.641 7.30247 11.6743 7.8518 10.8521C8.40114 10.0298 9.18198 9.3889 10.0956 9.01043C11.0092 8.63196 12.0145 8.53291 12.9844 8.72581C13.9543 8.91871 14.8452 9.39489 15.5444 10.0941C16.2436 10.7934 16.7198 11.6843 16.9127 12.6542C17.1056 13.6241 17.0066 14.6294 16.6281 15.543C16.2496 16.4565 15.6087 17.2374 14.7865 17.7867ZM14.2092 10.3365C13.5578 9.90128 12.792 9.66898 12.0086 9.66898C10.9585 9.6701 9.95162 10.0878 9.20905 10.8303C8.46647 11.5729 8.0488 12.5798 8.04768 13.6299C8.04768 14.4133 8.27998 15.1791 8.71522 15.8305C9.15045 16.4819 9.76907 16.9895 10.4928 17.2893C11.2166 17.5891 12.013 17.6676 12.7814 17.5147C13.5497 17.3619 14.2555 16.9847 14.8094 16.4307C15.3634 15.8768 15.7406 15.171 15.8934 14.4027C16.0463 13.6343 15.9678 12.8379 15.668 12.1141C15.3683 11.3904 14.8606 10.7718 14.2092 10.3365Z" />
    <path d="M20.2296 11.5013C19.9764 11.6705 19.6786 11.7607 19.374 11.7606C18.9658 11.7601 18.5745 11.5977 18.2859 11.309C17.9973 11.0203 17.835 10.6289 17.8347 10.2207C17.8347 9.91618 17.925 9.61845 18.0942 9.36521C18.2634 9.11198 18.5039 8.91461 18.7853 8.79808C19.0667 8.68154 19.3764 8.65108 19.6751 8.71053C19.9738 8.76998 20.2482 8.91669 20.4635 9.13208C20.6788 9.34748 20.8254 9.6219 20.8848 9.92063C20.9442 10.2194 20.9136 10.529 20.797 10.8103C20.6804 11.0917 20.4829 11.3322 20.2296 11.5013ZM19.7088 9.72019C19.6097 9.65404 19.4932 9.61878 19.374 9.61888C19.2144 9.619 19.0614 9.68245 18.9486 9.7953C18.8357 9.90814 18.7723 10.0612 18.7722 10.2207C18.7721 10.3399 18.8073 10.4564 18.8735 10.5556C18.9396 10.6547 19.0337 10.732 19.1438 10.7776C19.2539 10.8233 19.375 10.8352 19.4919 10.812C19.6088 10.7888 19.7161 10.7314 19.8004 10.6471C19.8847 10.5629 19.9421 10.4555 19.9653 10.3386C19.9885 10.2217 19.9765 10.1006 19.9309 9.99049C19.8853 9.88041 19.808 9.78634 19.7088 9.72019Z" />
    <path d="M15.828 6.49703H22.082C22.3304 6.49703 22.5687 6.59561 22.7444 6.77112C22.9202 6.94663 23.0192 7.18473 23.0195 7.43313V19.8264C23.0195 20.075 22.9208 20.3135 22.7449 20.4893C22.5691 20.6651 22.3307 20.7639 22.082 20.7639H1.9375C1.68886 20.7639 1.4504 20.6651 1.27459 20.4893C1.09877 20.3135 1 20.075 1 19.8264V7.43453C1 7.18589 1.09877 6.94743 1.27459 6.77162C1.4504 6.5958 1.68886 6.49703 1.9375 6.49703H2.03125V6.43891C2.0315 6.24591 2.10827 6.0609 2.24474 5.92443C2.38121 5.78796 2.56623 5.71119 2.75922 5.71094H4.32766C4.52089 5.71119 4.70612 5.78816 4.84263 5.92493C4.97914 6.0617 5.05575 6.24708 5.05562 6.44031V6.49844H8.16859L8.65797 4.67031C8.7096 4.47812 8.82313 4.30831 8.98099 4.18713C9.13885 4.06596 9.33225 4.00019 9.53125 4H14.4634C14.6624 4.00027 14.8558 4.06607 15.0136 4.18723C15.1715 4.30839 15.285 4.47816 15.3367 4.67031L15.828 6.49703ZM14.2947 5.125H9.70094L9.33344 6.49703H14.6622L14.2947 5.125ZM2.15406 19.6094H21.8631V7.65625H2.15406V19.6094Z" />
  </svg>
)

export default IconCamera
