/**
 * @deprecated Deprecated in favor of new Hero component
 */

import React, { ReactNode } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import {
  AlignXType,
  SanityImg,
  WidthConstraintType,
} from "../../../types/index"
import MediaBgImg from "../../Media/BgImg"
import MediaBgVideo, {
  MediaBgVideoProps,
  MediaBgVideoOnTimeUpdate,
} from "../../Media/BgVideo"
import { BdTitle1, BdTitle3, RegBody } from "../../Typography/index"
import AppLayoutWidth from "../../App/Layout/Width"
import ButtonGroup, { ButtonGroupInstance } from "../../Button/Group"

type SectionHeroLegacyMobileHeight = number
type SectionHeroLegacyStandardHeight = number

type SectionHeroLegacyContentAlignType = AlignXType

interface SectionHeroLegacyContentBgImageShape extends SanityImg {
  alt?: string
}

export interface SectionHeroLegacyProps {
  /** Sets a blur effect over background media
   * Converts number to pixel value
   * @default 0
   */
  bgBlur?: number
  /** Background color for component  */
  bgColor?: string
  /** Provide a component to be rendered as the background */
  bgComponent?: ReactNode
  /** Array of image objects for background image.
   * [mobileObject, standardObject]
   */
  bgImg?: SectionHeroLegacyContentBgImageShape
  /** Array of mobile image dimensions [width, height] */
  bgImgMobileDimensions?: [number, number]
  /** Array of standard viewport size image dimensions [width, height] */
  bgImgStandardDimensions?: [number, number]
  /** Video object for background video */
  bgVideo?: MediaBgVideoProps
  /** Show background video on mobile
   * @default false
   */
  bgVideoMobile?: boolean
  /** Funciton to call when video playpack time is updated */
  bgVideoOnTimeUpdate?: MediaBgVideoOnTimeUpdate
  /** Array of buttons to be rendered as CTAs */
  buttons?: ButtonGroupInstance[]
  /**
   * Child component to be rendered as hero content
   * if provided, you will need to handle constraining
   * the width and alignment via the child component
   */
  children?: ReactNode
  childrenAlign?: SectionHeroLegacyContentAlignType
  className?: string
  /**
   * Controls the horizontal alignment of content
   * @default "left"
   */
  contentAlign?: SectionHeroLegacyContentAlignType
  /** Provide a component to be rendered as the content */
  contentComponent?: ReactNode
  /** Constrain the width of the content section
   * @default "max"
   */
  contentWidth?: WidthConstraintType
  header?: string | ReactNode
  /** Array of heights to set hero max-height
   * converted to vh units
   * @default [90, 90]
   */
  heights?: [SectionHeroLegacyMobileHeight, SectionHeroLegacyStandardHeight]
  onDark?: boolean
  /** Color to be overlayed on top of image/media
   * @default "transparent"
   */
  overlayColor?: string
  /** Opacity of color overlay on top of image/media
   * @default 0
   */
  overlayOpacity?: number
  /** Set app header to overlay or stack */
  setHeaderOverlay?: boolean
  subheader?: string | ReactNode
  text?: string | ReactNode
}

const SectionHeroLegacy = ({
  bgBlur = 0,
  bgColor = "var(--color-content-bg-invert)",
  bgComponent,
  bgImg,
  bgImgMobileDimensions,
  bgImgStandardDimensions,
  bgVideo,
  bgVideoMobile = false,
  bgVideoOnTimeUpdate,
  buttons,
  className,
  contentAlign = "start",
  contentComponent,
  contentWidth = "max",
  children,
  childrenAlign = "center",
  header,
  heights = [90, 90],
  onDark = true,
  overlayColor,
  overlayOpacity,
  subheader,
  text,
}: SectionHeroLegacyProps) => {
  const [sectionRef, inView] = useInView({ threshold: 0.1 })

  return (
    <SectionHeroLegacyStyled
      alignChildren={childrenAlign}
      alignContent={contentAlign}
      bgColor={bgColor}
      bgVideoMobile={bgVideoMobile}
      className={`${className} hero`}
      heightM={heights[0]}
      heightSt={heights[1]}
      isOnDark={onDark}
      ref={sectionRef}
    >
      {children ? (
        <>{children}</>
      ) : (
        <AppLayoutWidth constraint="max" className="hero__content-wrapper">
          <AppLayoutWidth
            constraint={contentWidth}
            className="hero__content"
            paddings={[0, 0]}
          >
            {header && <BdTitle1 className="hero__header">{header}</BdTitle1>}
            {subheader && (
              <BdTitle3 className="hero__subheader">{subheader}</BdTitle3>
            )}
            {text && (
              <AppLayoutWidth
                center={contentAlign === "center"}
                constraint="readable"
                paddings={[0, 0]}
              >
                {typeof text === "string" && <RegBody>{text}</RegBody>}
                {typeof text === "object" && <>{text}</>}
              </AppLayoutWidth>
            )}
            {buttons && buttons.length > 0 && (
              <ButtonGroup buttons={buttons} className="hero__buttons" />
            )}
            {contentComponent}
          </AppLayoutWidth>
        </AppLayoutWidth>
      )}
      {bgImg && (
        <MediaBgImg
          bgBlur={bgBlur}
          className="hero__bg-img"
          bgImg={bgImg}
          bgImgMobileHeight={bgImgMobileDimensions?.[1]}
          bgImgMobileWidth={bgImgMobileDimensions?.[0]}
          bgImgStandardHeight={bgImgStandardDimensions?.[1]}
          bgImgStandardWidth={bgImgStandardDimensions?.[0]}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
        />
      )}
      {bgVideo && (
        <MediaBgVideo
          bgBlur={bgBlur}
          className="hero__bg-video"
          autoPlay={bgVideo.autoPlay}
          loop={bgVideo.loop}
          onTimeUpdate={bgVideoOnTimeUpdate}
          overlayColor={bgVideo.overlayColor || overlayColor}
          overlayOpacity={bgVideo.overlayOpacity || overlayOpacity}
          pause={!inView}
          source={bgVideo.source}
        />
      )}
      {bgComponent && <div className="hero__bg-component">{bgComponent}</div>}
    </SectionHeroLegacyStyled>
  )
}

export default SectionHeroLegacy

interface SectionHeroLegacySC {
  alignChildren: SectionHeroLegacyContentAlignType
  alignContent: SectionHeroLegacyContentAlignType
  bgColor: string
  bgVideoMobile: boolean
  heightM: number
  heightSt: number
  isOnDark: boolean
}

const SectionHeroLegacyStyled = styled.section<SectionHeroLegacySC>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignChildren }) => {
    switch (alignChildren) {
      case "start":
        return "flex-start"
      case "center":
        return "center"
      case "end":
        return "flex-end"
      default:
        return "normal"
    }
  }};
  justify-content: center;
  min-height: ${({ heightM }) => `${heightM}vh`};
  color: ${({ isOnDark }) =>
    isOnDark ? "var(--color-text-subtract)" : "var(--color-text)"};
  background-color: ${({ bgColor }) => bgColor};
  transition: min-height 500ms ease-in-out;

  @media ${media.stUp} {
    ${({ heightSt }) => (heightSt ? `min-height: ${heightSt}vh` : null)};
  }

  .hero__content-wrapper {
    @media ${media.stUp} {
      display: flex;
      flex-direction: column;
      align-items: ${({ alignContent }) => {
        switch (alignContent) {
          case "start":
            return "flex-start"
          case "center":
            return "center"
          case "end":
            return "flex-end"
          default:
            return "normal"
        }
      }};
      justify-content: center;
    }
  }

  .hero__content {
    margin: 0;
    margin-bottom: ${rem(32)};
    text-align: ${({ alignContent }) => {
      switch (alignContent) {
        case "start":
          return "left"
        case "end":
          return "left"
        case "center":
          return "center"
        default:
          return "left"
      }
    }};

    @media ${media.stUp} {
      margin-bottom: ${rem(48)};
    }
  }

  .hero__header {
    margin-bottom: ${rem(24)};
  }

  .hero__subheader {
    margin-bottom: ${rem(16)};

    @media ${media.stUp} {
      margin-bottom: ${rem(24)};
    }
  }

  .hero__buttons {
    margin-top: ${rem(24)};

    @media ${media.stUp} {
      margin-top: ${rem(32)};
    }
  }

  .hero__bg-img,
  .hero__bg-component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .hero__bg-video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: ${({ bgVideoMobile }) => (bgVideoMobile ? "block" : "none")};

    @media ${media.stUp} {
      display: block;
    }
  }
`
