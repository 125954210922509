import { createElement, FunctionComponent } from "react"

import * as IconList from "./index"
import { IconName, IconProps } from "./iconTypes"

const iconMapperKeys = Object.keys(IconList)

const iconMapper = (name: IconName, props?: IconProps) => {
  if (!iconMapperKeys.includes(name)) return null
  const Element: FunctionComponent = IconList[name]

  return createElement(Element, props, null)
}

export default iconMapper
