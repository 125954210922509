import React from "react"
import { IconProps } from "./iconTypes"

const IconCalendar = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M8.47264 11.0302H6.00421V13.5H8.47264V11.0302ZM9.10546 9.9989C9.21117 9.99878 9.31258 10.0407 9.38733 10.1155C9.46208 10.1902 9.50402 10.2916 9.50389 10.3973V14.1314C9.50402 14.2371 9.46208 14.3385 9.38733 14.4133C9.31258 14.488 9.21117 14.53 9.10546 14.5298H5.37139C5.26568 14.53 5.16427 14.488 5.08952 14.4133C5.01477 14.3385 4.97283 14.2371 4.97296 14.1314V10.3973C4.97283 10.2916 5.01477 10.1902 5.08952 10.1155C5.16427 10.0407 5.26568 9.99878 5.37139 9.9989H9.10546ZM19.9772 2.49328C20.8136 2.49328 21.4917 3.17136 21.4917 4.00781V19.9786C21.4917 20.815 20.8136 21.4931 19.9772 21.4931H4.00593C3.60425 21.4931 3.21902 21.3336 2.93499 21.0495C2.65096 20.7655 2.49139 20.3803 2.49139 19.9786V4.00781C2.49139 3.17136 3.16947 2.49328 4.00593 2.49328H5.44218V1.48969C5.44218 1.20491 5.67303 0.97406 5.9578 0.97406C6.24257 0.97406 6.47343 1.20491 6.47343 1.48969V2.49328H18V1.48969C18 1.20491 18.2308 0.97406 18.5156 0.97406C18.8004 0.97406 19.0312 1.20491 19.0312 1.48969V2.49328H19.9772ZM4.00593 3.52453C3.73912 3.52479 3.5229 3.74101 3.52264 4.00781V7.94531H20.4605V4.00781C20.4602 3.74101 20.244 3.52479 19.9772 3.52453H4.00593ZM19.9772 20.4619C20.244 20.4616 20.4602 20.2454 20.4605 19.9786V8.97656H3.52264V19.9786C3.5229 20.2454 3.73912 20.4616 4.00593 20.4619H19.9772Z" />
  </svg>
)

export default IconCalendar
