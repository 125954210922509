import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import { IconCheckCircleSolid, IconCloseCircleSolid } from "../../Icon/index"
import { SectionFeatureTableGroup } from "./index"
import SectionFeatureTableGroupHeader from "./GroupHeader"
import { Caption } from "../../Typography/index"

interface SectionFeatureTableFeatureGridProps {
  className?: string
  groups?: SectionFeatureTableGroup[]
}

const SectionFeatureTableFeatureGrid = ({
  className,
  groups,
}: SectionFeatureTableFeatureGridProps) => {
  const groupHeaderStFlexBasis =
    groups && groups.length > 0 ? `${100 / (groups.length + 1)}%` : "100%"

  return (
    <SectionFeatureTableFeatureGridStyled
      className={className}
      groupHeaderStFlexBasis={groupHeaderStFlexBasis}
    >
      {groups && groups.length > 0 ? (
        <>
          <header className="feature-table-grid__header">
            <ol className="feature-table-grid__list-header">
              {groups.map((group, index) => (
                <li
                  className="feature-table-grid__list-header__item"
                  key={`grid-header-${index}`}
                >
                  <SectionFeatureTableGroupHeader
                    cta={group.groupCta}
                    ctaPath={group.groupCtaPath}
                    description={group.groupDescription}
                    stHeight={248}
                    title={group.groupTitle}
                  />
                </li>
              ))}
            </ol>
          </header>
          <ol className="feature-table-grid__list-content">
            {groups &&
            groups.length > 0 &&
            groups[0].features &&
            groups[0].features.length > 0
              ? groups[0].features.map((feature, index) => (
                  <li
                    className="feature-table-grid__list-feature"
                    key={`grid-feature-${index}`}
                  >
                    <Caption
                      as="h4"
                      className="feature-table-grid__list-feature__header"
                    >
                      {feature.description}
                    </Caption>
                    <div className="feature-table-grid__list-feature__included">
                      {groups.map((group, groupIndex) => (
                        <p
                          className="feature-table-grid__list-feature__included__item"
                          key={`group-feature-included-${groupIndex}`}
                        >
                          {group.features && group.features.length > 0 ? (
                            group.features[index].included ? (
                              <>
                                <IconCheckCircleSolid color="var(--color-primary)" />
                                <span className="u-hidden-visually">
                                  Included
                                </span>
                              </>
                            ) : (
                              <>
                                <IconCloseCircleSolid color="var(--color-danger)" />
                                <span className="u-hidden-visually">
                                  Not Included
                                </span>
                              </>
                            )
                          ) : null}
                        </p>
                      ))}
                    </div>
                  </li>
                ))
              : null}
          </ol>
        </>
      ) : null}
    </SectionFeatureTableFeatureGridStyled>
  )
}

export default SectionFeatureTableFeatureGrid

interface GridSC {
  groupHeaderStFlexBasis: string
}

const SectionFeatureTableFeatureGridStyled = styled.div<GridSC>`
  .feature-table-grid__header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .feature-table-grid__list-header {
    flex: 0 0 75%;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    width: 75%;
  }

  .feature-table-grid__list-header__item {
    flex: 1 0 ${({ groupHeaderStFlexBasis }) => groupHeaderStFlexBasis};
    border-left: 1px solid var(--color-border-light);

    &:first-child {
      border-left: 1px solid transparent;
    }
  }

  .feature-table-grid__list-feature {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: var(--color-content-bg);
    border-bottom: 1px solid var(--color-border-light);

    &:last-child {
      border-bottom: none;
    }
  }

  .feature-table-grid__list-feature__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ${rem(16)};
  }

  .feature-table-grid__list-feature__included {
    flex: 0 0 75%;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    width: 75%;
  }

  .feature-table-grid__list-feature__included__item {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${rem(16)};
    border-left: 1px solid var(--color-border-light);
  }
`
