import React from "react"
import { IconProps } from "./iconTypes"

const IconNewFeature = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M2 7H9V14H2V7ZM2 15H9V22H2V15ZM10 15H17V22H10V15ZM8 13V8H3V13H8ZM8 21V16H3V21H8ZM16 21V16H11V21H16ZM23 6.6257C23 6.75978 22.9312 6.86034 22.7937 6.99441L19.9735 9.67598L20.627 13.4637V13.6313C20.627 13.7318 20.627 13.8324 20.5582 13.8994C20.4894 13.9665 20.4206 14 20.3175 14C20.2143 14 20.1111 13.9665 20.0079 13.8994L16.5 12.1229L12.9921 13.8994C12.8889 13.9665 12.7857 14 12.6825 14C12.5794 14 12.5106 13.9665 12.4418 13.8994C12.4074 13.8324 12.373 13.7318 12.373 13.6313V13.4637L13.0265 9.67598L10.2063 6.99441C10.0688 6.86034 10 6.72626 10 6.6257C10 6.4581 10.172 6.32402 10.4471 6.2905L14.3677 5.75419L16.1217 2.30168C16.2249 2.10056 16.3624 2 16.5 2C16.6376 2 16.7751 2.10056 16.8783 2.30168L18.6323 5.75419L22.5529 6.2905C22.8624 6.32402 23 6.42458 23 6.6257Z" />
  </svg>
)

export default IconNewFeature
