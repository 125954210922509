import React from "react"
import { IconProps } from "./iconTypes"

const IconIntercom = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M18.3501 2.03875C18.6904 2.03875 18.967 2.31578 18.967 2.65563V21.9161C18.967 22.2564 18.6899 22.533 18.3501 22.533H5.63852C5.2982 22.533 5.02164 22.256 5.02164 21.9161V2.65609C5.02164 2.31578 5.29867 2.03922 5.63852 2.03922L18.3501 2.03875ZM18.3501 1H5.63852C4.72398 1 3.98242 1.74156 3.98242 2.65609V21.9166C3.98242 22.8311 4.72398 23.5727 5.63852 23.5727H18.3501C19.2646 23.5727 20.0062 22.8311 20.0062 21.9166V2.65609C20.0062 1.74156 19.2646 1 18.3501 1Z" />
    <path d="M12.3313 8.84264C12.0332 8.84264 11.7814 9.09436 11.7814 9.39248C11.7814 9.69061 12.0332 9.94233 12.3313 9.94233C13.2918 9.94233 14.0727 10.7237 14.0727 11.6837C14.0727 11.9818 14.3244 12.2336 14.6225 12.2336C14.9207 12.2336 15.1724 11.9818 15.1724 11.6837C15.1724 10.93 14.8757 10.2175 14.3366 9.67842C13.7971 9.13936 13.085 8.84264 12.3313 8.84264Z" />
    <path d="M11.5813 15.2748C11.8794 15.2748 12.1312 15.0231 12.1312 14.725C12.1312 14.4269 11.8794 14.1752 11.5813 14.1752C10.6208 14.1752 9.8399 13.3937 9.8399 12.4337C9.8399 12.1356 9.58818 11.8839 9.29006 11.8839C8.99193 11.8839 8.74021 12.1356 8.74021 12.4337C8.74021 13.1875 9.03693 13.9 9.576 14.4391C10.1151 14.9781 10.8271 15.2748 11.5813 15.2748Z" />
    <path d="M15.6561 8.35891C14.6647 7.3675 13.3508 6.82141 11.9563 6.82141C11.6582 6.82141 11.4064 7.07313 11.4064 7.37125C11.4064 7.66938 11.6582 7.9211 11.9563 7.9211C13.063 7.9211 14.1022 8.35141 14.8832 9.13235C15.6641 9.91328 16.0944 10.953 16.0944 12.0592C16.0944 12.3573 16.3461 12.6091 16.6443 12.6091C16.9424 12.6091 17.1941 12.3573 17.1941 12.0592C17.1936 10.6642 16.6475 9.35031 15.6561 8.35891Z" />
    <path d="M11.9563 16.1969C10.8496 16.1969 9.81036 15.7665 9.02943 14.9856C8.24849 14.2047 7.81818 13.165 7.81818 12.0587C7.81818 11.7606 7.56646 11.5089 7.26834 11.5089C6.97021 11.5089 6.71849 11.7606 6.71849 12.0587C6.71849 13.4533 7.26459 14.7672 8.25599 15.7586C9.24739 16.75 10.5613 17.2961 11.9558 17.2961C12.2539 17.2961 12.5057 17.0443 12.5057 16.7462C12.5057 16.4486 12.2539 16.1969 11.9563 16.1969Z" />
    <path d="M11.9563 10.7313C11.2241 10.7313 10.6288 11.3266 10.6288 12.0588C10.6288 12.7909 11.2241 13.3863 11.9563 13.3863C12.6759 13.3863 13.2838 12.7783 13.2838 12.0588C13.2838 11.3266 12.688 10.7313 11.9563 10.7313Z" />
  </svg>
)

export default IconIntercom
