import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

interface FormValidationErrorMessageProps {
  children?: ReactNode
  message?: string
  role?: string
}

const FormValidationErrorMessage = ({
  children,
  message,
  role = "alert",
}: FormValidationErrorMessageProps) => {
  if (!message && !children) {
    return null
  }

  return (
    <FormValidationErrorMessageStyled role={role}>
      {message || children}
    </FormValidationErrorMessageStyled>
  )
}

export default FormValidationErrorMessage

const FormValidationErrorMessageStyled = styled.p`
  font-size: ${rem(14)};
  font-weight: 600;
  color: var(--color-danger);
`
