import React from "react"
import { IconProps } from "./iconTypes"

const IconLock = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M18.475 9.97877C18.8741 9.97877 19.257 10.1374 19.5391 10.4198C19.8212 10.7022 19.9795 11.0852 19.9792 11.4844V19.9725C19.9792 20.8033 19.3057 21.4767 18.475 21.4767H5.52011C4.68953 21.4765 4.01636 20.8031 4.01636 19.9725V11.4844C4.01598 11.0853 4.17424 10.7024 4.45627 10.42C4.73831 10.1376 5.121 9.97889 5.52011 9.97877H7.99651V6.45799C7.99639 5.40777 8.4135 4.40053 9.15607 3.65787C9.89864 2.91521 10.9058 2.49799 11.956 2.49799H12.0662C13.1165 2.49799 14.1237 2.9152 14.8663 3.65784C15.609 4.40049 16.0262 5.40773 16.0262 6.45799V9.97877H18.475ZM9.02776 6.45799V9.9647H14.995V6.45799C14.9931 4.84123 13.683 3.53104 12.0662 3.52924H11.956C10.3394 3.53104 9.02931 4.84131 9.02776 6.45799ZM18.9479 19.9725V11.4844C18.9474 11.2234 18.736 11.0119 18.475 11.0114H5.52011C5.25918 11.0119 5.04787 11.2235 5.04761 11.4844V19.9725C5.04787 20.2334 5.25918 20.445 5.52011 20.4455H18.475C18.736 20.445 18.9474 20.2335 18.9479 19.9725ZM14.0031 14.7328C14.0028 15.6436 13.3893 16.44 12.5087 16.6725C12.5117 16.6943 12.5132 16.7162 12.5134 16.7381V18.4688C12.5134 18.7535 12.2825 18.9844 11.9978 18.9844C11.713 18.9844 11.4821 18.7535 11.4821 18.4688V16.7381C11.4823 16.7162 11.4838 16.6943 11.4868 16.6725C10.4804 16.4073 9.84414 15.4163 10.0219 14.3908C10.1997 13.3654 11.1324 12.6464 12.1694 12.7354C13.2063 12.8245 14.0028 13.6921 14.0031 14.7328ZM12.0001 15.7069C12.5379 15.7064 12.9737 15.2706 12.9742 14.7328C12.9742 14.1949 12.5381 13.7588 12.0001 13.7588C11.4621 13.7588 11.026 14.1949 11.026 14.7328C11.026 15.2708 11.4621 15.7069 12.0001 15.7069Z" />
  </svg>
)

export default IconLock
