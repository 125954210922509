import React from "react"
import { IconProps } from "./iconTypes"

const IconFacebook = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M15.0473 5.58802H16.8V2.53522C16.4976 2.49362 15.4577 2.40002 14.2466 2.40002C11.7196 2.40002 9.98857 3.98962 9.98857 6.91122V9.60002H7.2V13.0128H9.98857V21.6H13.4075V13.0136H16.0833L16.508 9.60082H13.4067V7.24962C13.4075 6.26322 13.6731 5.58802 15.0473 5.58802Z" />
  </svg>
)

export default IconFacebook
