import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { colors } from "../../../theme/index"
import { WidthConstraintType } from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import { SemiBdTitle1 } from "../../Typography/index"
import BlockContentBlock from "../../BlockContent/Block"
import ButtonGroup, { ButtonGroupInstance } from "../../Button/Group"

/**
 * Renders content section of SplitContent component
 */
export interface SectionSplitContentContentProps {
  blockContent?: any[]
  buttons?: ButtonGroupInstance[]
  children?: ReactNode
  className?: string
  contentBgColor?: string
  contentTextOnDark?: boolean
  contentWidth?: WidthConstraintType
  header?: string | ReactNode
  padRightSt?: string
  padLeftSt?: string
  reverseStandard?: boolean
}

const SectionSplitContentContent = ({
  blockContent,
  buttons,
  children,
  className,
  contentBgColor,
  contentTextOnDark = false,
  contentWidth = "readableLg",
  header,
  padRightSt = "0",
  padLeftSt = "0",
  reverseStandard = false,
}: SectionSplitContentContentProps) => {
  const alignItemsSt = reverseStandard ? "flex-start" : "flex-end"

  let fixCalloutTextColor = false
  if (contentBgColor === colors.brand.primary) {
    fixCalloutTextColor = true
  }

  return (
    <SectionSplitContentContentStyled
      alignItemsSt={alignItemsSt}
      className={`${className} split-content-content`}
      fixCalloutTextColor={fixCalloutTextColor}
      padRightSt={padRightSt}
      padLeftSt={padLeftSt}
      textOnDark={contentTextOnDark}
    >
      <AppLayoutWidth
        className="split-content-content__inner"
        constraint={contentWidth}
      >
        {header && (
          <div className="split-content-content__header">
            {typeof header === "string" ? (
              <SemiBdTitle1 as="h2">{header}</SemiBdTitle1>
            ) : (
              <>{header}</>
            )}
          </div>
        )}
        {blockContent && blockContent.length > 0 && (
          <BlockContentBlock blocks={blockContent} />
        )}
        {children}
        {buttons && buttons.length > 0 && (
          <ButtonGroup buttons={buttons} className="color-block__buttons" />
        )}
      </AppLayoutWidth>
    </SectionSplitContentContentStyled>
  )
}

export default SectionSplitContentContent

interface ContentSC {
  alignItemsSt: "flex-start" | "flex-end"
  fixCalloutTextColor: boolean
  padRightSt: string
  padLeftSt: string
  textOnDark: boolean
}

const SectionSplitContentContentStyled = styled.div<ContentSC>`
  color: ${({ textOnDark }) =>
    textOnDark ? "var(--color-text-subtract)" : "inherit"};

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItemsSt }) => alignItemsSt};
    justify-content: center;
  }

  .split-content-content__inner {
    margin: 0;
    padding: 0;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};

    @media ${media.stUp} {
      padding-top: ${rem(48)};
      padding-right: ${({ padRightSt }) => padRightSt};
      padding-bottom: ${rem(48)};
      padding-left: ${({ padLeftSt }) => padLeftSt};
    }
  }

  .split-content-content__header {
    margin-bottom: ${rem(16)};

    @media ${media.stUp} {
      margin-bottom: ${rem(24)};
    }
  }

  /** Fixes callout color if bg set to primary */
  .callout {
    ${({ fixCalloutTextColor }) => {
      if (fixCalloutTextColor) {
        return `color: var(--color-text-subtract)`
      }
      return null
    }};
  }
`
