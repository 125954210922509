import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdTitle5Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-bold);
  font-style: normal;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 700;

  @media ${media.stUp} {
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  }
`

const BdTitle5 = styled.h5<TypographyTypeProps>`
  ${BdTitle5Style}
`
BdTitle5.displayName = "BdTitle5"

export default BdTitle5
