import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const RegBodySmStyle = css<TypographyTypeProps>`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  font-weight: 400;
`

const RegBodySm = styled.p<TypographyTypeProps>`
  ${RegBodySmStyle}
`
RegBodySm.displayName = "RegBodySm"

export default RegBodySm
