import React, { ReactNode, useState, useEffect } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import { IconClose } from "../Icon"
import { BodyText } from "../Typography/index"
import { useFormFields, useMailChimpForm } from "use-mailchimp-form"
import { Link } from "@reach/router"

export interface ModalProps {
  embeddedFormDesktop?: string
  embeddedFormMobile?: string
  className?: string
  handleClose?: () => any
  setIsFormSubmitted?: (arg0: boolean) => Function
  open?: boolean
  header?: string | ReactNode
  subheader?: string | ReactNode
  body?: any
}

//this component requires gatsby-plugin-portal to be configured in the respective site's gatsby-config.js (see sites/default/gatsby-config.js for example)

const Modal = ({
  className,
  handleClose,
  setIsFormSubmitted,
  open,
  header,
  subheader,
  body,
}: ModalProps) => {
  if (!open) return null
  const [isFormVisible, setIsFormVisible] = useState(true)

  const url =
    "https://smartrent.us13.list-manage.com/subscribe/post?u=df216b75b6e814b716161606d&amp;id=e01ddced56&amp;f_id=00da0ce3f0"

  const { error, success, message, handleSubmit } = useMailChimpForm(url)
  const { fields, handleFieldChange }: any = useFormFields({
    FNAME: "",
    LNAME: "",
    EMAIL: "",
    IAM: "Resident",
  })

  useEffect(() => {
    if (success) {
      setIsFormVisible(false)
      setIsFormSubmitted && setIsFormSubmitted(true)
    }
  }, [success])

  const modalContentForm = (
    <div className="modal-content">
      {header && <h1 className="modal__header">{header}</h1>}
      {subheader && <h3 className="modal__subheader">{subheader}</h3>}
      {body && (
        <BodyText
          className="modal__body"
          dangerouslySetInnerHTML={{ __html: body }}
        ></BodyText>
      )}
      <div id="embed-signup" className="embed-signup">
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit(fields)
          }}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="embed-signup_scroll">
            <div className="field-group">
              <label htmlFor="mce-FNAME" className="form-label">
                First Name <span className="asterisk">*</span>
              </label>
              <input
                type="text"
                name="FNAME"
                className="form-input"
                id="FNAME"
                value={fields.FNAME}
                onChange={handleFieldChange}
                required
              />
              <span id="mce-FNAME-HELPERTEXT" className="helper_text"></span>
            </div>
            <div className="field-group">
              <label htmlFor="mce-LNAME" className="form-label">
                Last Name{" "}
              </label>
              <input
                type="text"
                name="LNAME"
                className="form-input"
                id="LNAME"
                value={fields.LNAME}
                onChange={handleFieldChange}
              />
              <span id="mce-LNAME-HELPERTEXT" className="helper_text"></span>
            </div>
            <div className="field-group">
              <label htmlFor="mce-EMAIL" className="form-label">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="form-input"
                id="EMAIL"
                value={fields.EMAIL}
                onChange={handleFieldChange}
                required
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            </div>
            <div className="field-group">
              <label htmlFor="mce-IAM" className="form-label">
                I am a...{" "}
              </label>
              <select
                name="IAM"
                className="form-input"
                id="IAM"
                value={fields.IAM}
                onChange={handleFieldChange}
                required
              >
                <option value="Resident">Resident</option>
                <option value="Property Manager">Property Manager</option>
                <option value="Owner/Operator">Owner/Operator</option>
              </select>
              <span id="mce-IAM-HELPERTEXT" className="helper_text"></span>
            </div>
            <div className="field-group form-group">
              <strong className="form-label">Reach Out To Me For... </strong>
              <ul>
                <li>
                  <input
                    type="checkbox"
                    className="checkbox"
                    value="1"
                    name="group[699497][1]"
                    id="group[699497][1]"
                    onChange={handleFieldChange}
                  />
                  <label htmlFor="group[699497][1]">Early App Access</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    className="checkbox"
                    value="2"
                    name="group[699497][2]"
                    id="group[699497][2]"
                    onChange={handleFieldChange}
                  />
                  <label htmlFor="group[699497][2]">New Feature Feedback</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    className="checkbox"
                    value="4"
                    name="group[699497][4]"
                    id="group[699497][4]"
                    onChange={handleFieldChange}
                  />
                  <label htmlFor="group[699497][4]">Product Demos</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    className="checkbox"
                    value="8"
                    name="group[699497][8]"
                    id="group[699497][8]"
                    onChange={handleFieldChange}
                  />
                  <label htmlFor="group[699497][8]">Surveys</label>
                </li>
              </ul>
              <span
                id="mce-group[699497]-HELPERTEXT"
                className="helper_text"
              ></span>
            </div>
            <div
              id="mergeRow-gdpr"
              className="mergeRow gdpr-mergeRow content__gdprBlock field-group"
            >
              <div className="content__gdpr">
                <label className="form-label">Marketing Permissions</label>
                <p>
                  Please select all the ways you would like to hear from
                  SmartRent:
                </p>
                <fieldset name="interestgroup_field">
                  <label className="gdpr" htmlFor="gdpr[65445]">
                    <input
                      type="checkbox"
                      name="gdpr[65445]"
                      className="checkbox"
                      id="gdpr[65445]"
                      value="Y"
                      onChange={handleFieldChange}
                      required
                    />
                    <span>Email</span>{" "}
                  </label>
                </fieldset>
                <p>
                  You can opt-out at any time by clicking on the unsubscribe
                  link in the footer of our emails.{" "}
                  <Link to="/privacy-us/">Privacy Policy</Link>
                  {" and "}
                  <Link to="/terms/">Terms of Service</Link>
                </p>
              </div>
              <div className="content__gdprLegal">
                <p>
                  We use Mailchimp as our marketing platform. By clicking below
                  to subscribe, you acknowledge that your information will be
                  transferred to Mailchimp for processing.{" "}
                  <a href="https://mailchimp.com/legal/terms" target="_blank">
                    Learn more about SmartRent's privacy practices here.
                  </a>
                </p>
              </div>
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response"></div>
              <div className="response" id="mce-success-response"></div>
            </div>
            <div className="submit-container">
              <input
                type="submit"
                value="Sign Me Up"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="submit"
              />
            </div>
          </div>
        </form>
      </div>
      {error && message}
    </div>
  )

  const modalContentThankYou = (
    <div className="thank-you-container">
      <h1 className="thank-you-header">Subscription Confirmed</h1>
      <p className="thank-you-body">
        Your subscription to our list has been confirmed. Thank you for
        subscribing!
      </p>
    </div>
  )

  return ReactDOM.createPortal(
    <ModalStyled className={`${className} modal`}>
      <div className="modal-backdrop" onClick={handleClose}></div>
      <div className="modal" id="modal-scroll">
        <IconClose className="modal-button__close" onClick={handleClose} />
        {isFormVisible ? modalContentForm : modalContentThankYou}
      </div>
    </ModalStyled>,
    document.body as Element | DocumentFragment
  )
}

export default Modal

const ModalStyled = styled.section`
  .embed-signup {
    display: flex;
    text-align: left;
    padding: 0;
  }

  .field-group {
    display: flex;
    flex-direction: column;
    margin: ${rem(10)} 0;
  }

  .asterisk {
    color: red;
  }

  .form-label {
    font-size: ${rem(16)};
    font-weight: 600;
    margin-bottom: ${rem(8)};
  }

  .form-input {
    height: ${rem(48)};
    font-size: ${rem(16)};
    border-radius: ${rem(6)};
    border: 1px solid #cad0da;
    background: none;
  }

  .checkbox {
    width: ${rem(24)};
    height: ${rem(24)};
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${rem(12)};
  }

  .gdpr {
    display: flex;
    align-items: center;
    gap: ${rem(12)};
  }

  .submit-container {
    display: flex;
    justify-content: center;
    padding: ${rem(32)} 0;
  }

  .submit {
    width: ${rem(245)};
    height: ${rem(40)};
    text-transform: uppercase;
    background: var(--color-primary);
    border: none;
    color: white;
    font-weight: 600;
    border-radius: ${rem(3)};
    font-size: ${rem(16)};
  }

  .thank-you-container {
    height: ${rem(600)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${rem(20)};

    .thank-you-header {
      font-size: ${rem(40)};
      font-weight: 600;
      line-height: ${rem(48)};
      text-align: center;
      color: var(--color-primary);
      margin-bottom: ${rem(38)};
    }

    .thank-you-body {
      text-align: center;
    }
  }

  .modal-button__close {
    position: fixed;
    right: ${rem(18)};
    top: ${rem(18)};
    width: ${rem(40)};
    height: ${rem(40)};
    padding: ${rem(8)};
    fill: var(--color-gray500);
    border: ${rem(2)} solid grey;
    border-radius: ${rem(8)};
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(45, 50, 56, 0.7);
    backdrop-filter: blur(10px);
    z-index: 1000;
  }

  .modal {
    padding-top: ${rem(50)};
    width: 80vw;
    height: 80vh;
    position: fixed;
    z-index: 1010;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: ${rem(6)};
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    padding: ${rem(16)};
  }

  .modal__header {
    font-size: ${rem(28)};
    line-height: ${rem(40)};
    font-weight: 600;
    max-width: ${rem(250)};
    margin: ${rem(16)} 0;
  }

  .modal__body {
    width: ${rem(265)};
  }

  @media ${media.stUp} {
    .modal {
      width: ${rem(1096)};
      height: auto;
      max-height: 78vh;
      border-radius: ${rem(20)};
    }
    .modal-content {
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: ${rem(24)};
    }

    .embed-signup {
      padding: 0 50px;
    }

    .modal__header {
      font-size: ${rem(40)};
      line-height: ${rem(48)};
      max-width: ${rem(490)};
      margin: 0;
    }

    .modal__body {
      width: ${rem(566)};
      margin: ${rem(32)} 0;
    }

    .thank-you-container {
      .thank-you-header {
        font-size: ${rem(56)};
        margin-bottom: ${rem(50)};
      }

      .thank-you-body {
        width: 35%;
      }
    }
  }
`
