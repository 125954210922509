import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdCapsMedStyle = css<TypographyTypeProps>`
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: 700;
  text-transform: uppercase;
`

const BdCapsMed = styled.p<TypographyTypeProps>`
  ${BdCapsMedStyle}
`
BdCapsMed.displayName = "BdCapsMed"

export default BdCapsMed