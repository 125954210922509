import React from "react"
import { IconProps } from "./iconTypes"

const IconExclamationCircleOutline = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.019 14.007a.562.562 0 00.562-.562V6.99a.562.562 0 00-1.125 0v6.454a.563.563 0 00.563.562zM12.792 16.373a.773.773 0 11-1.547 0 .773.773 0 011.547 0z" />
    <path d="M19.714 4.21A10.962 10.962 0 104.212 19.715 10.962 10.962 0 0019.714 4.211zm-7.751 17.596c-5.428 0-9.844-4.416-9.844-9.844 0-5.428 4.415-9.843 9.844-9.843 5.428 0 9.843 4.415 9.843 9.843 0 5.429-4.416 9.844-9.844 9.844z" />
  </svg>
)

export default IconExclamationCircleOutline
