import React from "react"
import { IconProps } from "./iconTypes"

const IconChevronLeft = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M14.9775301,18 C14.8741962,18 14.7751257,17.9588087 14.7023223,17.8854769 L9.11324254,12.2608068 C8.96225249,12.1094993 8.96225249,11.8645178 9.11324254,11.7132102 L14.7202936,6.11708288 C14.8177983,6.01704054 14.9615926,5.97720472 15.0966768,6.01281218 C15.231761,6.04841963 15.3372347,6.15396111 15.3727553,6.28906817 C15.4082759,6.42417524 15.3683476,6.5679439 15.2682426,6.66538425 L9.93604698,11.9887704 L15.2523856,17.3392898 C15.3626337,17.450338 15.3952977,17.616785 15.3351947,17.7612634 C15.2750917,17.9057418 15.1340113,18 14.9775301,18 Z" />
  </svg>
)

export default IconChevronLeft
