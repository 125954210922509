import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media } from "@sr/common"
import { Link } from "gatsby"
import { SemiBdTitle3, SemiBdTitle5, RegBody } from "../../Typography"
import { Button } from "../../Button"
import { IconChevronCircleRight } from "../../Icon"
export interface ContentStripPropsShape {
  body?: string
  introText?: string
  title: string
  introTextColor?: string
  titleColor?: string
  buttons?: Button[]
  boxShadow?: boolean
  border?: boolean
  centerAligned?: boolean
  maxWidth?: number
}

export interface Button {
  btnColor?: string
  btnTextColor?: string
  text: string
  link: string
  internal: boolean
  displayAsIcon?: boolean
}

const displayButton = ({
  btnColor,
  btnTextColor,
  text,
  link,
  internal,
  displayAsIcon = false,
}: Button) => {
  return (
    <>
      {/* if it is an internal link uses Gatsby Link for faster routing, if not uses regular a tag
        Added Text decoration none to inline style here because it was getting added and without
        inline styles it wasn't getting removed
      */}
      {internal ? (
        <Link
          to={link}
          className={
            displayAsIcon
              ? "content-strip__icon-link"
              : "content-strip__button-link"
          }
          style={{ textDecoration: "none" }}
        >
          {displayAsIcon ? (
            <IconChevronCircleRight
              style={{ fill: btnColor }}
              color={btnColor}
            />
          ) : null}
          <SemiBdTitle5
            style={{
              color: btnTextColor,
              background: displayAsIcon ? "" : btnColor,
              textDecoration: "none",
            }}
            className={
              displayAsIcon ? "content-strip__icon" : "content-strip__button"
            }
          >
            {text}
          </SemiBdTitle5>
        </Link>
      ) : (
        <a
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          className={
            displayAsIcon
              ? "content-strip__icon-link"
              : "content-strip__button-link"
          }
        >
          {displayAsIcon ? (
            <div className="content-strip__icon-container">
              <IconChevronCircleRight
                style={{ fill: btnColor }}
                color={btnColor}
              />
            </div>
          ) : null}
          <SemiBdTitle5
            className={
              displayAsIcon ? "content-strip__icon" : "content-strip__button"
            }
          >
            {text}
          </SemiBdTitle5>
        </a>
      )}
    </>
  )
}

const ContentStrip = ({
  centerAligned = false,
  body,
  border = false,
  buttons,
  boxShadow = false,
  maxWidth = 1200,
  introText,
  title,
  introTextColor,
  titleColor,
}: ContentStripPropsShape) => {
  return (
    <ContentStripStyled
      centerAligned={centerAligned}
      boxShadow={boxShadow}
      border={border}
      maxWidth={maxWidth}
      introTextColor={introTextColor}
      titleColor={titleColor}
    >
      <div className="content-strip__text-container">
        {/* Displays intro text, if added */}
        {introText ? (
          <SemiBdTitle5 className="content-strip__intro-text">
            {introText}
          </SemiBdTitle5>
        ) : null}
        {/* Title required */}
        <SemiBdTitle3>{title}</SemiBdTitle3>
        {/* Displays Body, if added */}
        {body ? <RegBody>{body}</RegBody> : null}
        {/* Displays Buttons, if added */}
        {buttons ? (
          <div className="content-strip__button-container">
            {buttons.map((button, index) => {
              return <div key={index}>{displayButton(button)}</div>
            })}
          </div>
        ) : null}
      </div>
    </ContentStripStyled>
  )
}

export default ContentStrip

interface SectionContentStripSC {
  centerAligned: boolean
  boxShadow?: boolean
  border?: boolean
  maxWidth?: number | string
  introTextColor?: string
  titleColor?: string
}

const ContentStripStyled = styled.section<SectionContentStripSC>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${rem(0)} ${rem(16)};

  @media ${media.midUp} {
    margin: ${rem(112)} ${rem(64)} 0;
  }

  .content-strip__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    text-align: ${({ centerAligned }) => (centerAligned ? "center" : "left")};
    box-shadow: ${({ boxShadow }) =>
      boxShadow ? "0px 4px 8px 0px rgba(45, 50, 57, 0.20)" : "none"};
    border: ${({ border }) =>
      border ? "1px solid var(--color-gray100)" : "none"};
    margin-top: ${rem(72)};
    border-radius: ${rem(8)};

    @media ${media.midUp} {
      margin: 0;
    }

    @media ${media.stMidUp} {
      text-align: center;
    }
  }

  h5 {
    @media ${media.midUp} {
      font-size: ${rem(24)};
    }
  }

  h3 {
    margin-top: ${rem(8)};
    color: ${({titleColor}) => (titleColor ? `${titleColor}` : null)};

    @media ${media.midUp} {
      margin-top: ${rem(16)};
      font-size: ${rem(40)};
      line-height: ${rem(48)};
    }
  }

  .content-strip__intro-text {
    color: ${({introTextColor}) => (introTextColor ? `${introTextColor}` : null)};
    width: 100%;
  }

  p {
    margin-top: ${rem(16)};

    @media ${media.stMidUp} {
      margin-top: ${rem(24)};
      max-width: ${rem(672)};
    }
  }

  .content-strip__button-container {
    display: flex;
    flex-direction: column;
    align-items: ${({ centerAligned }) => (centerAligned ? "center" : "start")};
    justify-content: ${({ centerAligned }) =>
      centerAligned ? "center" : "start"};
    gap: ${rem(16)};
    width: 100%;
    margin-top: ${rem(40)};

    @media ${media.midUp} {
      flex-direction: row;
    }

    @media ${media.stMidUp} {
      justify-content: center;
    }
  }

  .content-strip__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${rem(32)};
    height: ${rem(32)};
    border-radius: 50%;
  }

  .content-strip__icon-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${rem(8)};

    svg {
      height: ${rem(32)};
      width: ${rem(32)};
    }
  }

  .content-strip__button-link {
    display: flex;
    max-width: fit-content;
    width: fit-content;
  }

  .content-strip__button {
    padding: ${rem(8)} ${rem(32)};
    justify-content: center;
    align-items: center;
    border-radius: ${rem(4)};
    box-shadow: 0px 4px 8px 0px rgba(45, 50, 57, 0.2);
  }
`
