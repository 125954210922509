import React from "react"
import { IconProps } from "./iconTypes"

const IconSmrtOperations = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <g clip-path="url(#clip0_2403_2857)">
      <path d="M24 0H0V24H24V0Z" fill="#231F20" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0544 20.6116L12.0547 20.6108C12.0547 20.6108 12.0544 20.6114 12.0537 20.6114L12.0544 20.6116Z"
        fill="#0081CB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5469 4.73438H7.45312V13.7821H9.38211V6.6536H16.5469V4.73438ZM7.45312 19.2656H16.5469V10.2179H14.6179V17.3464H7.45312V19.2656Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2403_2857">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconSmrtOperations
