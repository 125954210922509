import React from "react"
import styled from "styled-components"

import { IconArrowLeft, IconArrowRight } from "../Icon"

export interface NavDotProps {
  className?: string
  direction?: "left" | "right"
  size?: number
  color?: string
}

const NavCircleArrow = ({
  className,
  direction = "left",
  size = 24,
  color = "var(--color-black)",
}: NavDotProps) => {
  return (
    <NavCircleArrowStyled
      className={className || ""}
      style={{ width: `${size}px`, height: `${size}px`, background: color }}
    >
      {direction === "left" ? (
        <IconArrowLeft className="nav-circle-arrow__icon" size={size * 0.6} />
      ) : (
        <IconArrowRight className="nav-circle-arrow__icon" size={size * 0.6} />
      )}
    </NavCircleArrowStyled>
  )
}

export default NavCircleArrow

const NavCircleArrowStyled = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--color-black);
  fill: var(--color-white);
`
