import React from "react"
import { IconProps } from "./iconTypes"

const IconPerson = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M20.9733 21.4889H3.02673C2.74196 21.4889 2.51111 21.258 2.51111 20.9733V18.7303C2.51111 17.1834 3.78611 15.8555 6.1983 14.8894C8.33861 14.032 10.6613 13.7278 12 13.7278C13.3388 13.7278 15.6614 14.032 17.8017 14.8894C20.2139 15.8555 21.4889 17.1839 21.4889 18.7303V20.9733C21.4889 21.258 21.2581 21.4889 20.9733 21.4889ZM3.54236 20.4577H20.4577V18.7303C20.4577 16.2666 14.9817 14.7591 12 14.7591C9.0183 14.7591 3.54236 16.2656 3.54236 18.7303V20.4577ZM12 12.5156C9.23721 12.5156 6.99751 10.2759 6.99751 7.51312C6.99751 4.75032 9.23721 2.51062 12 2.51062C14.7628 2.51062 17.0025 4.75032 17.0025 7.51312C16.9994 10.2746 14.7615 12.5125 12 12.5156ZM12 3.54234C10.3938 3.54234 8.94566 4.50995 8.33101 5.99397C7.71637 7.47798 8.05622 9.18612 9.19208 10.3219C10.3279 11.4576 12.0361 11.7972 13.5201 11.1824C15.004 10.5676 15.9715 9.11938 15.9713 7.51312C15.9687 5.32101 14.1921 3.54466 12 3.54234Z" />
  </svg>
)

export default IconPerson
