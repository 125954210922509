import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdTitle4Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-semibold);
  font-style: normal;
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: 600;
  scroll-margin-top: ${rem(
    72
  )}; /*fixes anchor link scrolling too far due to main site nav height */

  @media ${media.stUp} {
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  }
`

const SemiBdTitle4 = styled.h4<TypographyTypeProps>`
  ${SemiBdTitle4Style}
`
SemiBdTitle4.displayName = "SemiBdTitle4"

export default SemiBdTitle4
