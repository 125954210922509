import React from "react"
import { DotProps } from "react-multi-carousel/lib/types"

import NavDot from "../../Nav/Dot"

export interface SectionCardSliderDotProps extends DotProps {
  className?: string
}

const SectionCardSliderDot = ({
  className,
  onClick,
  active,
  index,
}: SectionCardSliderDotProps) => {
  return (
    <NavDot
      active={active}
      className={className}
      index={index}
      onClick={() => (onClick ? onClick() : null)}
    />
  )
}

export default SectionCardSliderDot
