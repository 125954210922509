import React, { useState } from "react"
import styled from "styled-components"
import { rem } from "polished"
import { motion } from "framer-motion"

import { IconChevronRight, RegBody } from "@sr/gatsby-theme-ui"
import { type NavigationSubSectionContentNavigationItems } from "../navData"

import { Link } from "@reach/router"

export interface AppHeaderNavMobileLoginAccordionProps {
  nav: string
  subNavItems: NavigationSubSectionContentNavigationItems[]
}

const AppHeaderNavMobileLoginAccordion = ({
  nav,
  subNavItems,
}: AppHeaderNavMobileLoginAccordionProps) => {
  const [active, setActive] = useState<boolean>(false)

  const handleClick = () => {
    setActive(!active)
  }

  return (
    <AppHeaderNavMobileLoginAccordionStyled>
      <div className="header__mobile-login-accordion__group-container">
        <div
          className="header__mobile-login-accordion__item-container"
          onClick={() => handleClick()}
        >
          <RegBody
            className={`header__mobile-login-accordion__item-title ${
              active ? "active" : ""
            }`}
          >
            {nav}
          </RegBody>
          <motion.span
            initial={{
              rotate: active ? 90 : 0,
            }}
            animate={{
              rotate: active ? 90 : 0,
            }}
            style={{ lineHeight: "0" }}
          >
            <IconChevronRight
              color={active ? "var(--color-primary)" : undefined}
            />
          </motion.span>
        </div>

        <motion.div
          initial={{ height: active ? "auto" : 0 }}
          animate={{ height: active ? "auto" : 0 }}
          transition={{ duration: 0.4 }}
        >
          <div className="header__mobile-login-accordion-group__inner-container">
            {subNavItems.map(
              (item: NavigationSubSectionContentNavigationItems, i: number) => {
                return (
                  <div
                    className="header__mobile-login-accordion-group__link-container"
                    key={i}
                  >
                    {item?.icon && item.icon()}
                    <a
                      className="header__mobile-login-accordion-group__link"
                      href={item.link}
                    >
                      <RegBody>{item.nav}</RegBody>
                    </a>
                  </div>
                )
              }
            )}
          </div>
        </motion.div>
      </div>
    </AppHeaderNavMobileLoginAccordionStyled>
  )
}

export default AppHeaderNavMobileLoginAccordion

const AppHeaderNavMobileLoginAccordionStyled = styled.div`
  width: 100%;
  overflow: hidden;

  .header__mobile-login-accordion__item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .header__mobile-login-accordion__item-title.active {
    color: var(--color-primary) !important;
    font-weight: 700;
  }

  .header__mobile-login-accordion-group__inner-container {
    padding-top: ${rem(16)};
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};

    .header__mobile-login-accordion-group__link-container {
      display: flex;
      gap: ${rem(16)};

      .header__mobile-login-accordion-group__link {
        color: var(--color-gray800);
      }
    }
  }
`
