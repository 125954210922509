import React, { ReactNode, useState } from "react"
import styled from "styled-components"

import { media } from "@sr/common"
import { rem } from "polished"

import AppLayoutWidth from "../App/Layout/Width"
import { IconChevronDown } from "../Icon"
import { BodyText } from "../Typography/index"

export interface SupportStatusIndicatorProps {
  body?: string
  className?: string
  message?: string
  status?: string
  statusIcon?: ReactNode
  statusColor?: string
}

const SupportStatusIndicator = ({
  body,
  message,
  status,
  statusIcon,
  statusColor = "#fffff",
}: SupportStatusIndicatorProps) => {
  const [isBodyVisible, setIsBodyVisible] = useState(false)

  return (
    <SupportStatusIndicatorStyled statusColor={statusColor}>
      <div className="support-status-indicator__top">
        <AppLayoutWidth
          constraint="readable"
          className="support-status-indicator__top__layout"
        >
          <div className="support-status-indicator__icon">{statusIcon}</div>
          <div className="support-status-indicator__message__container">
            {message && (
              <BodyText className="support-status-indicator__message">
                {message}
              </BodyText>
            )}
            <button
              onClick={() => setIsBodyVisible(!isBodyVisible)}
              type="button"
              className="support-status-indicator__detail"
            >
              <IconChevronDown />
              <span className="u-hidden-visually">More Detail</span>
            </button>
          </div>
          <div className="support-status-indicator__link">
            <a
              href="https://status.smartrent.com/"
              rel="noopener noreferrer"
              className="support-status-indicator__status__link"
              target="_blank"
            >
              System Status
            </a>
          </div>
        </AppLayoutWidth>
      </div>
      <div
        className="support-status-indicator__bottom"
        style={{ display: isBodyVisible ? "block" : "none" }}
      >
        <AppLayoutWidth
          constraint="readable"
          className="support-status-indicator__bottom__layout"
        >
          <div>
            <p className="support-status-indicator__status">
              Incident Status:&nbsp;
              <span className="support-status-indicator__status__name">
                {status}
              </span>
            </p>
            <p className="support-status-indicator__body">{body}</p>
            <a
              href="https://status.smartrent.com/"
              rel="noopener noreferrer"
              className="support-status-indicator__status__link__mobile"
              target="_blank"
            >
              System Status
            </a>
          </div>
        </AppLayoutWidth>
      </div>
    </SupportStatusIndicatorStyled>
  )
}

export default SupportStatusIndicator

interface SupportStatusIndicatorSC {
  statusColor: string
}

const SupportStatusIndicatorStyled = styled.span<SupportStatusIndicatorSC>`
  --statusColor: ${({ statusColor }) => statusColor};

  display: block;
  width: 100%;
  text-align: left;
  background-color: var(--color-gray950);
  fill: var(--statusColor);

  .support-status-indicator__top__layout {
    display: flex;
    align-items: center;
    margin: auto;
    padding: ${rem(16)} ${rem(32)};
  }

  .support-status-indicator__icon {
    color: var(--color-success);
    padding-right: ${rem(16)};
  }

  svg {
    vertical-align: middle;
    fill: var(--statusColor);
  }

  .support-status-indicator__message__container {
    flex: 0 0 auto;
    display: flex;
    max-width: calc(85% - ${rem(16)});

    @media ${media.stUp} {
      max-width: calc(86% - ${rem(100)});
    }
  }

  .support-status-indicator__message {
    flex: 1 0 auto;
    width: 100%;
    color: var(--color-white);
    font-size: ${rem(18)};
  }

  .support-status-indicator__detail svg {
    width: ${rem(32)};
    height: ${rem(32)};
    fill: var(--color-white);
    font-size: ${rem(14)};
  }

  .support-status-indicator__link {
    display: inline-block;
    margin-left: auto;
    text-align: center;
  }

  .support-status-indicator__bottom {
    border-top: 1px solid var(--color-gray800);
  }

  .support-status-indicator__bottom__layout {
    display: flex;
    align-items: left;
    justify-content: left;
    margin: auto;
    padding: ${rem(28)} ${rem(32)} ${rem(16)};

    @media ${media.stUp} {
      padding: ${rem(28)} ${rem(32)};
    }
  }

  .support-status-indicator__status__link {
    display: none;

    @media ${media.stUp} {
      display: block;
      color: var(--color-primary);
      font-size: ${rem(14)};
    }
  }

  .support-status-indicator__status__link__mobile {
    display: inline-block;
    padding: ${rem(12)} 0;

    @media ${media.stUp} {
      display: none;
    }
  }

  .support-status-indicator__status {
    padding-bottom: ${rem(16)};
    color: #ffffff;
    font-size: ${rem(18)};
    font-weight: 600;

    @media ${media.stUp} {
      padding-left: ${rem(48)};
    }
  }

  .support-status-indicator__status__name {
    text-transform: capitalize;
    color: var(--statusColor);
  }

  .support-status-indicator__body {
    color: #ffffff;
    font-size: ${rem(14)};

    @media ${media.stUp} {
      padding-left: ${rem(48)};
    }
  }
`
