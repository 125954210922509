import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdTitle6Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-bold);
  font-style: normal;
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  font-weight: 700;

  @media ${media.stUp} {
    font-size: ${rem(20)};
    line-height: ${rem(24)};
  }
`

const BdTitle6 = styled.h6<TypographyTypeProps>`
  ${BdTitle6Style}
`
BdTitle6.displayName = "BdTitle6"

export default BdTitle6
