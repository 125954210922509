import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdCapsLgStyle = css<TypographyTypeProps>`
  font-size: ${rem(24)};
  line-height: ${rem(40)};
  font-weight: 600;
  text-transform: uppercase;
`

const SemiBdCapsLg = styled.p<TypographyTypeProps>`
  ${SemiBdCapsLgStyle}
`
SemiBdCapsLg.displayName = "SemiBdCapsLg"

export default SemiBdCapsLg