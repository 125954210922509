export const layoutZIndexSub = -1
export const layoutZIndexBase = 0
export const layoutZIndexMid = 1
export const layoutZIndexTop = 2

export const layoutZIndexes = {
  sub: layoutZIndexSub,
  base: layoutZIndexBase,
  mid: layoutZIndexMid,
  top: layoutZIndexTop,
}
