import React from "react"
import { IconProps } from "./iconTypes"

const IconEmail = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M21.494 3.998H2.502c-.831 0-1.504.674-1.504 1.505v12.98c0 .831.673 1.505 1.504 1.505h18.992c.83 0 1.503-.674 1.503-1.504V5.503c0-.83-.673-1.504-1.503-1.505zM11.997 14.76L2.695 5.03h18.556l-9.254 9.73zm-9.968 3.724V5.827l9.458 9.892v3.238H2.502a.474.474 0 01-.473-.473zm19.465.473h-8.976v-3.249l9.448-9.934v12.71c0 .26-.211.472-.472.473z" />
  </svg>
)

export default IconEmail
