import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import { IconArrowRight, RegBody, SemiBdTitle6 } from "@sr/gatsby-theme-ui"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "gatsby"

function FeaturedContainer({ featured, mainTab }: any) {
  const { contentPrimary, contentSecondary } = featured

  return (
    <FeaturedContainerStyled>
      <SemiBdTitle6 className="featured__dropdown-header">
        Featured
      </SemiBdTitle6>
      {contentPrimary && (
        <div className="featured__content-container">
          {contentPrimary.linkType === "internal" ? (
            <Link
              to={contentPrimary?.internalLink?.reference?.path?.current}
              className="featured__link"
              id={`${(mainTab + 1) * 100 - 3}`}
            />
          ) : (
            <a
              href={contentPrimary?.externalLink}
              target="_blank"
              rel="noreferrer nonopener"
              className="featured__link"
              id={`${(mainTab + 1) * 100 - 3}`}
            />
          )}
          {contentPrimary.image && contentPrimary.image.asset && (
            <SanityImage
              alt={contentPrimary?.alt || ""}
              asset={contentPrimary?.image?.asset}
              className="article-author__img"
              crop={contentPrimary?.image?.crop}
              hotspot={contentPrimary?.image?.hotspot}
              width={163}
              height={144}
            />
          )}
          <div className="featured__content">
            <SemiBdTitle6>{contentPrimary.title}</SemiBdTitle6>
            <RegBody>{contentPrimary.snippet}</RegBody>
            <RegBody className="featured__read-more">Read More</RegBody>
          </div>
        </div>
      )}
      {contentSecondary && (
        <div className="featured__content-container">
          {contentSecondary.linkType === "internal" ? (
            <Link
              to={contentSecondary?.internalLink?.reference?.path?.current}
              className="featured__link"
              id={`${(mainTab + 1) * 100 - 2}`}
            />
          ) : (
            <a
              href={contentSecondary?.externalLink}
              target="_blank"
              rel="noreferrer nonopener"
              className="featured__link"
              id={`${(mainTab + 1) * 100 - 2}`}
            />
          )}
          {contentSecondary.image && (
            <SanityImage
              alt={contentSecondary?.image?.alt || ""}
              asset={contentSecondary.image.asset}
              className="article-featured__img"
              crop={contentSecondary?.image?.crop}
              hotspot={contentSecondary?.image?.hotspot}
              width={163}
              height={144}
            />
          )}
          <div className="featured__content">
            <SemiBdTitle6>{contentSecondary.title}</SemiBdTitle6>
            <RegBody>{contentSecondary.snippet}</RegBody>
            <RegBody className="featured__read-more">Read More</RegBody>
          </div>
        </div>
      )}
      <Link
        className="featured__all-posts"
        to="/news/"
        id={`${(mainTab + 1) * 100 - 1}`}
      >
        <RegBody>View All Blog Posts</RegBody>
        <IconArrowRight />
      </Link>
    </FeaturedContainerStyled>
  )
}

export default FeaturedContainer

const FeaturedContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};

  h6 {
    color: var(--color-primary);
  }
  .featured__content-container {
    display: flex;
    gap: ${rem(24)};
    border-radius: ${rem(8)};
    box-shadow: 0px 4px 4px 0px rgba(45, 50, 57, 0.08);
    position: relative;
    background-color: var(--color-gray050);
    max-width: ${rem(520)};
    overflow: hidden;

    img {
      min-height: ${rem(144)};
      height: 100%;
      width: ${rem(162)};
      object-fit: cover;
    }

    .featured__link {
      position: absolute;
      height: 100%;
      width: 100%;

      &:focus {
        border: ${rem(1)} solid var(--color-primary);
        border-radius: ${rem(8)};
      }
    }
  }

  .featured__content {
    padding: ${rem(16)} ${rem(32)} ${rem(16)} 0;
    border-radius: 0 ${rem(8)} ${rem(8)} 0;
    display: flex;
    flex-direction: column;
    gap: ${rem(8)};
  }

  a.featured__all-posts {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    p {
      color: var(--color-primary);
      &:hover {
        text-decoration: underline;
      }
    }
    svg {
      height: ${rem(16)};
      width: ${rem(16)};
      fill: var(--color-primary);
      margin-left: ${rem(8)};
    }
  }

  .featured__read-more {
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
  }
`
