import React from "react"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
const merge = require("deepmerge")

import { useSanity } from "@sr/common"

import SectionImgGrid from "../Section/ImgGrid"
import type { SectionImgGridImg } from "../Section/ImgGrid"
import { AlignXType, WidthConstraintType } from "../../types/index"
import MediaBlockImg from "../Media/BlockImg"
import { ButtonGroup } from "../Button/index"

import {
  Blockquote,
  Callout,
  Caption,
  RegBody,
  SemiBdTitle1,
  SemiBdTitle2,
  SemiBdTitle3,
  SemiBdTitle4,
  SemiBdTitle5,
  SemiBdTitle6,
} from "../Typography/index"

export interface BlockContentSerializerShape {
  listItem?: any
  types?: any
  marks?: any
}

export type BlockContentSerializerType = BlockContentSerializerShape

const { imgBuilder } = useSanity()

const InlineBlockRenderer = (props: any) => {
  const { node, children } = props
  const { style } = node
  switch (style) {
    case "normal":
      return <RegBody>{children}</RegBody>
    default:
      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props)
  }
}

const BlockBlockRenderer = (props: any) => {
  const { node, children } = props
  let { style, id } = node
  if (!id) id = ""

  switch (style) {
    case "blockquote":
      return (
        <Blockquote>
          <p>{children}</p>
        </Blockquote>
      )
    case "h1":
      return (
        <SemiBdTitle1 id={id} className="title title1">
          {children}
        </SemiBdTitle1>
      )
    case "h2":
      return (
        <SemiBdTitle2 id={id} className="title title2">
          {children}
        </SemiBdTitle2>
      )
    case "h3":
      return (
        <SemiBdTitle3 id={id} className="title title3">
          {children}
        </SemiBdTitle3>
      )
    case "h4":
      return (
        <SemiBdTitle4 id={id} className="title title4">
          {children}
        </SemiBdTitle4>
      )
    case "h5":
      return (
        <SemiBdTitle5 id={id} className="title title5">
          {children}
        </SemiBdTitle5>
      )
    case "h6":
      return (
        <SemiBdTitle6 id={id} className="title title6">
          {children}
        </SemiBdTitle6>
      )
    case "caption":
      return (
        <Caption id={id} className="caption">
          {children}
        </Caption>
      )
    case "callout":
      return (
        <Callout id={id} className="callout">
          {children}
        </Callout>
      )
    case "normal":
      return (
        <RegBody id={id} className="body-text">
          {children}
        </RegBody>
      )
    default:
      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props)
  }
}

const ButtonRenderer = (props: any) => {
  const { node } = props
  return <ButtonGroup buttons={[node]} />
}

const ImgGridRenderer = (props: any) => {
  const { node } = props
  const bgColor: string | undefined = node.bgColor
  const columnsSt: number | undefined = node.columns
  const contentAlign: AlignXType | undefined = node.contentAlign
  const contentWidth: WidthConstraintType | undefined = node.contentWidth
  const imgs: SectionImgGridImg[] | undefined = node.imgs
  const onHoverSaturate: boolean | undefined = node.onHoverSaturate
  const paddingBottomM: string | undefined = node.paddingBottomMobile
  const paddingBottomSt: string | undefined = node.paddingBottomStandard
  const paddingTopM: string | undefined = node.paddingTopMobile
  const paddingTopSt: string | undefined = node.paddingTopStandard

  return (
    <SectionImgGrid
      bgColor={bgColor}
      columnsSt={columnsSt}
      contentAlign={contentAlign}
      contentWidth={contentWidth}
      imgs={imgs}
      onHoverSaturate={onHoverSaturate}
      paddingBottomM={paddingBottomM}
      paddingBottomSt={paddingBottomSt}
      paddingTopM={paddingTopM}
      paddingTopSt={paddingTopSt}
    />
  )
}

const ImgRenderer = (props: any) => {
  const { node } = props
  const { float } = node
  if (!node.img) return null
  const src = imgBuilder(node.img.asset)
    .maxWidth(720)
    .maxHeight(720)
    .auto("format")
    .crop("focalpoint")
    .url()
  return (
    <MediaBlockImg
      alt={node.img.asset.alt}
      className="img"
      src={src || ""}
      float={float}
      width={720}
    />
  )
}

const inlineSerializers: BlockContentSerializerShape = {
  listItem: ({ children }: any) => {
    return (
      <li>
        <RegBody as="span">{children}</RegBody>
      </li>
    )
  },
  types: {
    block: InlineBlockRenderer,
  },
  marks: {
    em: ({ children }: any) => {
      return <em className="italic">{children}</em>
    },
    externalLink: ({ mark, children }: any) => {
      const { blank, url } = mark
      return blank ? (
        <a
          className="a a--external"
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      ) : (
        <a href={url}>{children}</a>
      )
    },
    internalLink: ({ mark, children }: any) => {
      const path =
        mark &&
        mark.reference &&
        mark.reference.path &&
        mark.reference.path.current
          ? mark.reference.path.current
          : ""
      return (
        <Link className="a" to={path}>
          {children}
        </Link>
      )
    },
    strong: ({ children }: any) => {
      return <strong className="strong">{children}</strong>
    },
    underline: ({ children }: any) => {
      return <span className="underline">{children}</span>
    },
  },
}

const block: BlockContentSerializerShape = {
  types: {
    block: BlockBlockRenderer,
    button: ButtonRenderer,
    img: ImgRenderer,
    imgGrid: ImgGridRenderer,
    undefined: (props: any) => {
      console.log("Undefined serializer for block type: ", props.node)
      return <></>
    },
  },
}

const blockSerializers = merge(inlineSerializers, block)

export { inlineSerializers, blockSerializers }
