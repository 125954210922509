import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface DeviceDeviceLaptopProps {
  bgColor?: string
  children?: ReactNode
  className?: string
  frameColor?: "light" | "dark"
  shadow?: string
}

const DeviceLaptop = ({
  bgColor = "var(--color-content-bg)",
  children,
  className,
  frameColor = "dark",
  shadow = "var(--shadow)",
}: DeviceDeviceLaptopProps) => {
  return (
    <DeviceLaptopStyled
      className={className}
      bgColor={bgColor}
      frameColor={frameColor}
      shadow={shadow}
    >
      <div className="top">
        <div className="screen">{children}</div>
      </div>
    </DeviceLaptopStyled>
  )
}

export default DeviceLaptop

interface LaptopSC {
  bgColor: string
  frameColor: string
  shadow: string
}

const DeviceLaptopStyled = styled.div<LaptopSC>`
  --frame-color: ${({ frameColor }) =>
    frameColor === "dark" ? `var(--color-gray900)` : `var(--color-gray100)`};

  position: relative;
  width: 100%;
  padding: 0 10%;

  /** Bottom part of laptop (keyboard, mouse, etc.) */
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
    width: 100%;
    transform: translateX(-50%);
    margin-top: -1.5%;
    padding-top: 2.25%;
    background: var(--frame-color);
    border-radius: ${rem(8)};
    border-bottom-right-radius: ${rem(40)};
    border-bottom-left-radius: ${rem(40)};
    box-shadow: ${({ shadow }) => shadow};
  }

  .top {
    position: relative;
    width: 100%;
    padding-top: 71.11%;
    overflow: hidden;
    border: ${rem(6)} solid var(--frame-color);
    border-radius: ${rem(4)};
    border-top-right-radius: ${rem(16)};
    border-top-left-radius: ${rem(16)};
    box-shadow: ${({ shadow }) => shadow};

    @media ${media.stUp} {
      border-top-right-radius: ${rem(32)};
      border-top-left-radius: ${rem(32)};
    }
  }

  .screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
