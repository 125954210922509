import React from "react"
import { motion } from "framer-motion"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { SectionHotspotSliderSlideType } from "./index"
import SectionHotspotSliderPin from "./Pin"
import { SanityImg } from "../../../types"
export interface SectionHotspotSliderIllustrationProps {
  className?: string
  currentSlide: number
  illustration: SanityImg
  onSlideSelect: (id: number) => void
  slides?: SectionHotspotSliderSlideType[]
}

const SectionHotspotSliderIllustration = ({
  className,
  currentSlide,
  illustration,
  onSlideSelect,
  slides,
}: SectionHotspotSliderIllustrationProps) => {
  return (
    <SectionHotspotSliderIllustrationStyled className={className}>
      {slides && slides.length !== 0
        ? slides.map((slide, index) => (
            <motion.button
              className="pin"
              key={index}
              onClick={() => onSlideSelect(index)}
              type="button"
              initial={{ scale: 0, opacity: 0 }}
              animate={
                currentSlide === index
                  ? { scale: 1, opacity: 1 }
                  : { scale: 0.85, opacity: 0.65 }
              }
              exit={{ scale: 0, opacity: 0 }}
              style={{
                top: slide.pin && slide.pin.y ? slide.pin.y : "0%",
                left: slide.pin && slide.pin.x ? slide.pin.x : "0%",
                zIndex: 1,
              }}
            >
              <SectionHotspotSliderPin
                fillColor={
                  currentSlide === index
                    ? "var(--color-primary)"
                    : "var(--color-white)"
                }
                strokeColor="var(--color-primary)"
              />
            </motion.button>
          ))
        : null}
      {illustration && illustration?.asset ? (
        <SanityImage
          alt={illustration.alt || ""}
          asset={illustration.asset}
          className="img"
          crop={illustration.crop}
          hotspot={illustration.hotspot}
          width={500}
          height={300}
        />
      ) : null}
    </SectionHotspotSliderIllustrationStyled>
  )
}

export default SectionHotspotSliderIllustration

const SectionHotspotSliderIllustrationStyled = styled.aside`
  position: relative;
  overflow: hidden;

  .pin {
    position: absolute;
    display: block;
    width: ${rem(24)};
    height: ${rem(24)};
    transform: translate(-50%, -50%);

    @media ${media.stUp} {
      width: ${rem(48)};
      height: ${rem(48)};
    }

    &:focus {
      outline: none;
    }
  }
`
