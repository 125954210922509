import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { rem } from "polished"
import { motion } from "framer-motion"
import { IconArrowRight, SemiBdTitle6 } from "@sr/gatsby-theme-ui"
import FeaturedContainer from "./FeaturedContainer"
import { type NavigationSubSectionContent } from "../navData"

const NavItemDesktop = ({ navData, featured, mainTab }: any) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleMouseEnter = () => {
    setShowDropdown(true)
  }

  const handleMouseLeave = () => {
    setShowDropdown(false)
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }
  useEffect(() => {
    const handleTabPress = (event: any) => {
      if (event.key === "Tab") {
        const currentlyFocusedElement = document.activeElement
        if (
          currentlyFocusedElement &&
          parseInt(currentlyFocusedElement?.id) < (mainTab + 1) * 100 &&
          parseInt(currentlyFocusedElement?.id) >= mainTab * 100
        ) {
          setShowDropdown(true)
        } else {
          setShowDropdown(false)
        }
      }
    }

    document.addEventListener("keyup", handleTabPress)

    return () => {
      document.removeEventListener("keyup", handleTabPress)
    }
  }, [])

  return (
    //Main Navigation Item
    <NavItemDesktopStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => toggleDropdown()}
    >
      <motion.li
        className="nav-item__main-nav"
        whileHover={{ color: "var(--color-primary)" }}
      >
        <h4 style={{ color: showDropdown ? "var(--color-primary)" : "" }}>
          {navData.nav}
        </h4>
        <motion.div
          className="animate-border-bottom"
          initial={{ right: "100%" }}
          animate={{ right: showDropdown ? 0 : "100%" }}
        />
      </motion.li>
      {/*Container for Dropdown*/}
      <motion.div
        className={`show-hide ${navData.content[0].simple && "simple"}`}
        initial={{ height: 0 }}
        animate={{ height: showDropdown ? "auto" : 0 }}
      >
        <div className="nav-item__dropdown-container">
          {navData.content.map(
            (
              dropdownContent: NavigationSubSectionContent,
              outerIndex: number
            ) => {

              return (
                <div className="nav-item__navigtion-columns" key={outerIndex}>
                  <SemiBdTitle6 className="nav-item__dropdown-header">
                    {dropdownContent.header}
                  </SemiBdTitle6>
                  {dropdownContent.navigationItems?.map(
                    (navItem, index: number) => {
                      return (
                        <li className="nav-item__container" key={index}>
                          {navItem.icon && navItem.icon()}
                          {navItem?.internal ? (
                            <Link
                              className="header__link"
                              to={navItem.link}
                              id={`${mainTab * 100 + outerIndex * 20 + index}`}
                            >
                              {navItem.nav}
                            </Link>
                          ) : (
                            <a
                              href={navItem.link}
                              id={`${mainTab * 100 + outerIndex * 20 + index}`}
                              target="_blank"
                              rel="noopener nonreferer"
                              className="nav-item__link"
                            >
                              {navItem.nav}
                            </a>
                          )}
                        </li>
                      )
                    }
                  )}
                  {navData.content[outerIndex].nav && (
                    <>
                      <div
                        className={`nav-item__view-all ${
                          navData.content[outerIndex].iconAbove
                            ? "icon-above"
                            : ""
                        }`}
                      >
                        {navData.content[outerIndex]?.internal ? (
                          <Link
                            to={navData.content[outerIndex].link}
                            id={`${mainTab * 100}`}
                          >
                            {navData.content[outerIndex].nav}
                          </Link>
                        ) : (
                          <a
                            href={navData.content[outerIndex].link}
                            id={`${mainTab * 100}`}
                            target="_blank"
                            rel="noopener nonreferer"
                            className="nav-item__link"
                          >
                            {navData.content[outerIndex].nav}
                          </a>
                        )}
                        <IconArrowRight />
                      </div>
                      {navData.content[outerIndex].linkSecondary &&
                        navData.content[outerIndex].navSecondary && (
                          <div
                            className={`nav-item__view-all ${
                              navData.content[outerIndex].iconAbove
                                ? "icon-above"
                                : ""
                            }`}
                          >
                            <Link
                              to={navData.content[outerIndex].linkSecondary}
                              id={`${mainTab * 100}`}
                            >
                              {navData.content[outerIndex].navSecondary}
                            </Link>
                            <IconArrowRight />
                          </div>
                        )}
                    </>
                  )}
                </div>
              )
            }
          )}
          {featured && (
            <FeaturedContainer mainTab={mainTab} featured={featured} />
          )}
        </div>
      </motion.div>
    </NavItemDesktopStyled>
  )
}

export default NavItemDesktop

const NavItemDesktopStyled = styled.nav`
  height: 100%;
  padding: 0 ${rem(20)};
  font-size: ${rem(16)};
  line-height: ${rem(16)};

  @media (max-width: 1200px) {
    padding: 0 ${rem(14)};
  }

  .nav-item__main-nav {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;

    font-size: ${rem(12)};
    font-weight: 700;
    line-height: ${rem(16)};
    letter-spacing: 0px;
    text-align: left;

    @media (max-width: 1200px) {
      font-size: ${rem(10)};
    }
  }

  .show-hide {
    height: 0;
    width: 100vw;
    top: 100%; /* Position it below the parent */
    left: 0; /* Start at the left-most part of the screen */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    background: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(45, 50, 57, 0.08);
    border-radius: 0 0 ${rem(8)} ${rem(8)};
  }

  .show-hide.simple {
    top: auto;
    left: auto;
    width: auto;
  }

  .nav-item__dropdown-container {
    display: flex;
    justify-content: center;
    gap: ${rem(56)};
    padding: ${rem(32)};
  }

  .nav-item__dropdown-header {
    color: var(--color-primary);
  }

  .nav-item__container {
    display: flex;
    align-items: center;
    gap: ${rem(24)};

    svg {
      fill: var(--color-primary);
    }

    a {
      text-decoration: none;
      color: var(--color-gray900);
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .nav-item__view-all {
    display: flex;
    align-items: center;
    gap: ${rem(8)};
    color: var(--color-primary);

    

    a {
      color: var(--color-primary);
      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      fill: var(--color-primary);
      height: ${rem(16)};
      width: ${rem(16)};
    }
  }

  .nav-item__view-all.icon-above {
    margin-left: ${rem(48)};
  }

  .nav-item__navigtion-columns {
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
  }

  .simple {
    .nav-item__dropdown-container {
      padding-top: ${rem(16)};
    }
  }

  .animate-border-bottom {
    position: absolute;
    height: 0;
    border-bottom: ${rem(2)} solid var(--color-primary);
    width: 100%;
    bottom: 24px;
  }
`
