export const layoutMobilePadding = 16
export const layoutStandardPadding = 48

export const layoutPadding = {
  mobile: layoutMobilePadding,
  standard: layoutStandardPadding,
}

export const layoutMaxWidth = 2000
export const layoutXLWidth = 1700
export const layoutLargeWidth = 1440
export const layoutMediumWidth = 1280
export const layoutReadableLargeContentWidth = 860
export const layoutReadableContentWidth = 720

export const layoutWidths = {
  max: layoutMaxWidth,
  xl: layoutXLWidth,
  lg: layoutLargeWidth,
  md: layoutMediumWidth,
  readableLg: layoutReadableLargeContentWidth,
  readable: layoutReadableContentWidth,
}

export const layoutHeaderHeightMobile = 64
export const layoutHeaderMainHeight = 64
export const layoutHeaderSuppHeight = 64
export const layoutHeaderHeight = 64
