import React from "react"
import { IconProps } from "./iconTypes"

const IconCloudSync = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.007 10.793l-.436.41a.474.474 0 00-.152.346c0 .13.055.254.152.346.097.091.23.143.367.143.138 0 .27-.052.367-.143l1.317-1.24a.412.412 0 00.048-.052l.005-.006.016-.02a.366.366 0 00.017-.027l.008-.012a.36.36 0 00.02-.038l.001-.004a.47.47 0 00-.11-.54L12.305 8.71a.536.536 0 00-.367-.143.536.536 0 00-.367.143.474.474 0 00-.152.346c0 .13.055.254.152.345l.445.42h-.405a3.336 3.336 0 00-2.278.888 2.953 2.953 0 00-.948 2.141v.737c0 .13.054.254.152.345a.535.535 0 00.366.143c.137 0 .269-.052.366-.143a.474.474 0 00.152-.345v-.736a2.005 2.005 0 01.644-1.454c.41-.386.966-.602 1.546-.603l.396-.002zM15.132 11.577a.535.535 0 00-.366.143.474.474 0 00-.152.345v.737a2.005 2.005 0 01-.644 1.453c-.41.386-.966.603-1.546.603h-.396l.436-.41a.488.488 0 00.152-.346.463.463 0 00-.152-.345.52.52 0 00-.367-.143.546.546 0 00-.367.143l-1.316 1.24a.471.471 0 00-.04.04l-.007.008-.006.007a1.306 1.306 0 00-.029.04l-.004.007-.005.007a.47.47 0 00.085.59l1.32 1.245a.52.52 0 00.367.143.546.546 0 00.367-.143.488.488 0 00.152-.346.464.464 0 00-.152-.345l-.444-.418h.405a3.337 3.337 0 002.276-.889 2.954 2.954 0 00.949-2.14v-.738a.473.473 0 00-.151-.344.535.535 0 00-.365-.144z" />
    <path d="M19.382 10.393c.457.227.877.516 1.246.856.73.676 1.553 1.863 1.337 3.682-.35 2.939-2.796 3.805-3.536 4a2 2 0 01-.512.069H5.411c-.306 0-.607-.07-.878-.203-.747-.367-2.489-1.468-2.532-3.748-.027-1.377.59-2.525 1.782-3.319a5.686 5.686 0 011.391-.663c.037-.79.285-2.578 1.725-3.482 1.611-1.01 2.977-.858 3.63-.685a6.447 6.447 0 011.582-1.26c1.082-.602 2.796-1.086 4.818.03 1.722.951 2.277 2.296 2.439 3.256.08.486.086.98.014 1.467zm.288 6.829c.72-.59 1.146-1.397 1.265-2.397v-.002c.323-2.7-2.18-3.64-2.316-3.692h-.002a.509.509 0 01-.281-.237.462.462 0 01-.037-.353c.03-.104.709-2.594-1.895-4.032-1.274-.703-2.541-.714-3.767-.032a5.284 5.284 0 00-1.51 1.275.509.509 0 01-.298.196.537.537 0 01-.36-.04c-.042-.019-.385-.163-.931-.163-.528 0-1.243.136-2.064.65-1.333.836-1.271 2.905-1.268 3.01a.468.468 0 01-.107.325.522.522 0 01-.302.18c-.113.024-2.809.612-2.76 3.121.015.758.272 1.426.763 1.985a3.95 3.95 0 001.213.916c.123.06.26.092.398.092h12.506c.078 0 .156-.01.232-.03.56-.15 1.08-.414 1.52-.772z" />
  </svg>
)

export default IconCloudSync
