import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import {
  AlignXType,
  WidthConstraintType,
  SanityImg,
} from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import MediaBgImg from "../../Media/BgImg"
import { BdTitle1 } from "../../Typography/index"
import BlockContentBlock from "../../BlockContent/Block"
import ButtonGroup, { ButtonGroupInstance } from "../../Button/Group"

export interface SectionColorBlockProps {
  /** Sets a blur effect over background media
   * Converts number to pixel value
   * @default 0
   */
  bgBlur?: number
  /** Background color for component  */
  bgColor?: string
  /** Image object to be used as background */
  bgImg?: SanityImg
  /** Block content JSON */
  blockContent?: any[]
  buttonAlign?: string
  /** Array of buttons to be rendered as CTAs */
  buttons?: ButtonGroupInstance[]
  children?: ReactNode
  className?: string
  /**
   * Controls the horizontal alignment of content
   * @default "center"
   */
  contentAlign?: AlignXType
  /** Constrain the width of the content section
   * @default "readableLg"
   */
  contentWidth?: WidthConstraintType
  header?: string | ReactNode
  /** Pass the min height in vh to be applied on mobile
   * @default 50
   */
  heightMobile?: number
  /** Pass the min height in vh to be applied on desktop
   * @default 30
   */
  heightStandard?: number
  /** Sets text color based on background contrast
   * @default true
   */
  onDark?: boolean
  /** Color to be overlayed on top of image/media
   * @default "transparent"
   */
  overlayColor?: string
  /** Opacity of color overlay on top of image/media
   * @default 0
   */
  overlayOpacity?: number
  textAlign?: string
  /** Sets text color explicitly. Will override onDark setting */
  textColor?: string
}

/**
 * Color block section component
 * - Renders content in a full width strip
 * - Background color or media can be set
 */
const SectionColorBlock = ({
  bgBlur = 0,
  bgColor = `var(--color-primary)`,
  bgImg,
  blockContent,
  buttonAlign,
  buttons,
  children,
  className,
  contentAlign = "center",
  contentWidth = "readableLg",
  header,
  heightMobile = 50,
  heightStandard = 30,
  onDark = true,
  overlayColor = "transparent",
  overlayOpacity = 0,
  textAlign = "left",
  textColor,
}: SectionColorBlockProps) => {
  return (
    <SectionColorBlockStyled
      alignContent={contentAlign}
      bgColor={bgColor}
      className={`${className} color-block`}
      heightM={heightMobile}
      heightSt={heightStandard}
      isOnDark={onDark}
      textAlign={textAlign}
      textColor={textColor}
    >
      <AppLayoutWidth className="color-block__content-wrapper" constraint="max">
        <AppLayoutWidth
          center={contentAlign === "center"}
          className="color-block__content"
          constraint={contentWidth}
          paddings={[0, 0]}
        >
          {header && (
            <BdTitle1 as="h2" className="color-block__header">
              {header}
            </BdTitle1>
          )}
          {blockContent && blockContent.length > 0 && (
            <BlockContentBlock
              blocks={blockContent}
              className="color-block__block-content"
            />
          )}
          {children}
          {buttons && buttons.length > 0 && (
            <ButtonGroup
              align={buttonAlign}
              buttons={buttons}
              className="color-block__buttons"
            />
          )}
        </AppLayoutWidth>
      </AppLayoutWidth>
      {bgImg && bgImg.asset && (
        <MediaBgImg
          bgBlur={bgBlur}
          className="color-block__bg-img"
          bgImg={bgImg}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
        />
      )}
    </SectionColorBlockStyled>
  )
}

export default SectionColorBlock

interface ColorBlockSC {
  alignContent: AlignXType
  bgColor: string
  heightM: number
  heightSt: number
  isOnDark: boolean
  textAlign: string
  textColor?: string
}

const SectionColorBlockStyled = styled.section<ColorBlockSC>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ heightM }) => `${heightM}vh`};
  padding: ${rem(32)} 0;
  text-align: ${({ textAlign }) => textAlign};
  background-color: ${({ bgColor }) => bgColor};
  transition: min-height 500ms ease-in-out;

  @media ${media.stUp} {
    ${({ heightSt }) => (heightSt ? `min-height: ${heightSt}vh` : null)};
    padding: ${rem(48)} 0;
  }

  .color-block__content-wrapper {
    @media ${media.stUp} {
      display: flex;
      flex-direction: column;
      align-items: ${({ alignContent }) => {
        switch (alignContent) {
          case "start":
            return "flex-start"
          case "center":
            return "center"
          case "end":
            return "flex-end"
          default:
            return "normal"
        }
      }};
      justify-content: center;
    }
  }

  .color-block__bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .color-block__header {
    margin-bottom: ${rem(24)};

    @media ${media.stUp} {
      margin-bottom: ${rem(32)};
    }
  }

  .color-block__header,
  .color-block__block-content * {
    color: ${({ isOnDark, textColor }) => {
      if (textColor) {
        return textColor
      }
      return isOnDark ? `var(--color-text-subtract)` : `var(--color-text)`
    }};

    a {
      ${({ bgColor }) => {
        if (bgColor === "#fff") {
          return `color: var(--color-primary)`
        }
      }};
    }
  }

  .color-block__buttons {
    margin-top: ${rem(16)};
  }
`
