import React from "react"
import styled from "styled-components"
import { rem } from "polished"

const dimension = 48

export interface AppHeaderNaviconProps {
  accessibleHint?: string
  className?: string
  isOpen?: boolean
  onTap: (event: React.MouseEvent<HTMLElement>) => void
}

const AppHeaderNavicon = ({
  accessibleHint = "Toggle mobile navigation",
  className,
  isOpen = false,
  onTap,
}: AppHeaderNaviconProps) => {
  return (
    <AppHeaderNaviconStyled
      className={className}
      type="button"
      onClick={onTap}
      dimensions={[dimension, dimension]}
      open={isOpen}
    >
      <i className="icon" />
      <span className="u-hidden-visually">{accessibleHint}</span>
    </AppHeaderNaviconStyled>
  )
}

export default AppHeaderNavicon

const AppHeaderNaviconStyled = styled.button<{
  dimensions: [number, number]
  open: boolean
}>`
  position: relative;
  display: block;
  overflow: hidden;
  width: ${({ dimensions }) => rem(dimensions[0])};
  height: ${({ dimensions }) => rem(dimensions[1])};

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: ${({ dimensions }) => rem(dimensions[0] * 0.6)};
    height: 2px;
    background: ${({ open }) =>
      open ? "transparent" : "var(--color-gray700)"};
    border-radius: 2px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: var(--color-gray700);
      border-radius: 2px;
      transition: transform 200ms ease;
    }

    &::before {
      transform: ${({ open }) =>
        open
          ? `translateY(${rem(0)}) rotate(45deg)`
          : `translateY(${rem(-8)})`};
    }

    &::after {
      transform: ${({ open }) =>
        open
          ? `translateY(${rem(0)}) rotate(-45deg)`
          : `translateY(${rem(8)})`};
    }
  }
`
