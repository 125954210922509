import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { timingFunctions } from "@sr/common"

import { IconChevronLeft, IconChevronRight } from "../Icon/index"
import BodyText from "../Typography/BodyText"
import { useI18n } from "../GlobalState/LanguageProvider"

interface NavigationPaginationItemShape {
  label?: string
  path: string
}

interface NavigationPaginationLazyItemShape {
  end: boolean
  error?: boolean
  label?: string
  loading: boolean
  onClick: () => void
}

export interface NavPaginationProps {
  className?: string
  lazy?: NavigationPaginationLazyItemShape
  next?: NavigationPaginationItemShape
  previous?: NavigationPaginationItemShape
}

/**
 * Component used for navigating paginated items
 */
const NavPagination = ({
  className,
  lazy,
  next,
  previous,
}: NavPaginationProps) => {
  const { t } = useI18n()
  const prevLabel =
    previous && previous.label
      ? previous.label
      : t("components.nav.pagination.previousLabel")
  const nextLabel =
    next && next.label ? next.label : t("components.nav.pagination.nextLabel")
  const lazyLabel = lazy && lazy.label ? lazy.label : "Load More Items"
  const lazyLoading = lazy && lazy.loading ? lazy.loading : false
  const lazyError = lazy && lazy.error ? lazy.error : false

  let justify = "space-between"
  if (!previous) {
    justify = "flex-end"
  }
  if (lazy) {
    justify = "center"
  }

  return (
    <NavPaginationStyled
      className={`${className} pagination`}
      justify={justify}
    >
      {previous && !lazy && (
        <Link
          className="u-text-decoration-none pagination__link"
          to={previous.path}
        >
          <IconChevronLeft />
          <BodyText as="span">{prevLabel}</BodyText>
        </Link>
      )}
      {next && !lazy && (
        <Link
          className="u-text-decoration-none pagination__link"
          to={next.path}
        >
          <BodyText as="span">{nextLabel}</BodyText>
          <IconChevronRight />
        </Link>
      )}
      {lazy && (
        <button
          className="u-text-decoration-none pagination__link"
          disabled={lazy.end}
          onClick={lazy.onClick}
        >
          <NavPaginationLazyStyled
            className="lazy"
            isEnd={lazy.end}
            isError={lazyError}
            isLoading={lazyLoading}
          >
            <span className="lazy__container">
              <span className="lazy__label">
                {lazyLabel} <IconChevronRight />
              </span>
              <span className="lazy__label lazy__label--loading">
                Loading...
              </span>
              <span className="lazy__label lazy__label--error">
                There was an error. Try again.
              </span>
            </span>
          </NavPaginationLazyStyled>
        </button>
      )}
    </NavPaginationStyled>
  )
}

export default NavPagination

interface PaginationSC {
  justify: string
}

const NavPaginationStyled = styled.nav<PaginationSC>`
  padding: ${rem(8)} 0;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  border-top: 1px solid var(--color-border-light);

  .pagination__link {
    display: flex;
    align-items: center;
    min-height: ${rem(48)};
    color: var(--color-text);

    &:hover,
    &:focus {
      color: var(--color-link);
    }

    > * {
      margin: 0;
      font-weight: 600;
    }

    .icon {
      margin-right: ${rem(8)};
      fill: var(--color-link);
    }

    &:last-of-type {
      margin-left: 0;

      .icon {
        margin-right: 0;
        margin-left: ${rem(8)};
      }
    }
  }
`

interface LazySC {
  isEnd: boolean
  isError: boolean
  isLoading: boolean
}
const NavPaginationLazyStyled = styled.span<LazySC>`
  position: relative;
  height: ${rem(48)};
  overflow: hidden;

  .lazy__container {
    display: block;
    transition: transform 250ms ${timingFunctions.easeInOutQuad};
    transform: ${({ isEnd, isError, isLoading }) => {
      if (isEnd) {
        return `translateY(${rem(48)})`
      }
      if (isLoading) {
        return `translateY(${rem(-48)})`
      }
      if (isError) {
        return `translateY(${rem(-48 * 2)})`
      }
      return `translateY(0)`
    }};
  }

  .lazy__label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${rem(48)};
    text-align: center;
  }

  .lazy__label--loading {
    color: var(--color-text-placeholder);
  }

  .lazy__label--error {
    color: var(--color-danger);
  }
`
