import React from "react"
import styled from "styled-components"
import { rem } from "polished"

export interface NavDotProps {
  active?: boolean
  className?: string
  index?: number
  onClick: () => void
}

const NavDot = ({ active = false, className, index, onClick }: NavDotProps) => {
  return (
    <NavDotStyled
      active={active}
      className={className}
      onClick={() => onClick()}
    >
      <span className="dot" />
      <span className="u-hidden-visually">View slide {index}</span>
    </NavDotStyled>
  )
}

export default NavDot

interface DotSC {
  active: boolean
}

const NavDotStyled = styled.button<DotSC>`
  padding: ${rem(16)};

  .dot {
    display: block;
    width: ${rem(8)};
    height: ${rem(8)};
    margin: auto;
    border-radius: 50%;
    background: ${({ active }) =>
      active ? "var(--color-gray500)" : "var(--color-gray200)"};
  }
`
