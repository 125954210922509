import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { graphql, useStaticQuery } from "gatsby"

import { media } from "@sr/common"

export interface AppFooterProductsProps {
  className?: string
}

const AppFooterProducts = ({ className }: AppFooterProductsProps) => {
  const { allSanityProductEntry } = useStaticQuery(graphql`
    {
      allSanityProductEntry(sort: {title: ASC}) {
        nodes {
          title
          path {
            current
          }
        }
      }
    }
  `)

  const productEntrySubNavItems: { title: string; path: string }[] =
    allSanityProductEntry?.nodes?.map((navItem: any) => {
      const {
        title,
        path: { current },
      } = navItem
      return { title, path: current }
    })

  return (
    <AppFooterProductsStyled className={`${className} footer-products`}>
      <Link to="/products/" className="footer-products__title">
        Solutions
      </Link>
      <div>
        {productEntrySubNavItems ? (
          productEntrySubNavItems.map((navItem, i) => {
            const { title, path } = navItem
            return (
              <Link className="footer-products__link" to={path} key={i}>
                {title}
              </Link>
            )
          })
        ) : (
          <Link className="footer-products__link" to="/products/">
            Products
          </Link>
        )}
      </div>
    </AppFooterProductsStyled>
  )
}

export default AppFooterProducts

const AppFooterProductsStyled = styled.div`
  padding: ${rem(16)} 0 0;

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    width: var(--st-column-default);
  }

  .footer-products__title {
    font-weight: bold;
    color: var(--color-gray200);
    padding: ${rem(8)} 0;
  }

  .footer-products__link {
    padding: ${rem(8)} 0;
    line-height: 150%;
    color: var(--color-gray200);
  }
`
