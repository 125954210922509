import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"

import { ButtonStyleType } from "../../types/index"

export type ButtonStyle = ButtonStyleType

export interface ButtonProps {
  as?: "button" | "span" | "div"
  btnColor?: string
  btnStyle?: ButtonStyle
  btnTextColor?: string
  children?: ReactNode
  className?: string
  expand?: boolean
  onClick?: () => void
  shadow?: boolean
}

const Button = ({
  as = "button",
  btnColor = "var(--button-bg)",
  btnStyle = "default",
  btnTextColor,
  children,
  className,
  expand = false,
  onClick,
  shadow = false,
}: ButtonProps) => {
  return (
    <ButtonStyled
      as={as}
      className={className}
      expand={expand}
      onClick={onClick}
    >
      <ButtonInnerStyled
        btnColor={btnColor}
        btnTextColor={btnTextColor}
        expand={expand}
        btnStyle={btnStyle}
        shadow={shadow}
      >
        <span className="children">{children}</span>
      </ButtonInnerStyled>
    </ButtonStyled>
  )
}

export default Button

interface ButtonSC {
  expand: boolean
}

const ButtonStyled = styled.button<ButtonSC>`
  width: ${({ expand }) => (expand ? "100%" : "auto")};
  margin: 0;
  padding: 0;
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 600;
`

interface ButtonInnerSC {
  expand: boolean
  btnColor: string
  btnStyle: ButtonStyle
  btnTextColor?: string
  shadow: boolean
}

const ButtonDefaultStyle = css<ButtonInnerSC>`
  color: ${({ btnTextColor }) =>
    btnTextColor ? btnTextColor : "var(--color-text-subtract)"};
  background: ${({ btnColor }) => btnColor};
  border-color: ${({ btnColor }) => btnColor};
  box-shadow: ${({ shadow }) => (shadow ? "var(--shadow)" : "none")};
`

const ButtonOutlineStyle = css<ButtonInnerSC>`
  color: ${({ btnColor, btnTextColor }) =>
    btnTextColor ? btnTextColor : btnColor};
  background: transparent;
  border-color: ${({ btnColor }) => btnColor};
  box-shadow: ${({ shadow }) => (shadow ? "var(--shadow)" : "none")};
`

const ButtonPlainStyle = css<ButtonInnerSC>`
  color: ${({ btnColor }) => btnColor};
  background: transparent;
  border-color: transparent;
  box-shadow: none;
`

const ButtonInnerStyled = styled.span<ButtonInnerSC>`
  display: ${(props) => (props.expand ? "flex" : "inline-flex")};
  align-items: center;
  justify-content: center;
  height: ${rem(40)};
  padding: 0 ${rem(32)};
  text-decoration: none !important;
  border: 1px solid;
  border-radius: var(--rounded);

  ${(props) => {
    if (props.btnStyle === "outline") {
      return ButtonOutlineStyle
    } else if (props.btnStyle === "plain") {
      return ButtonPlainStyle
    } else {
      return ButtonDefaultStyle
    }
  }}

  .children {
    white-space: nowrap;
    text-decoration: none !important;
  }
`
