import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdCapsSmStyle = css<TypographyTypeProps>`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  font-weight: 600;
  text-transform: uppercase;
`

const SemiBdCapsSm = styled.p<TypographyTypeProps>`
  ${SemiBdCapsSmStyle}
`
SemiBdCapsSm.displayName = "SemiBdCapsSm"

export default SemiBdCapsSm