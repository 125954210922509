import React from "react"
import { IconProps } from "./iconTypes"

const IconTwitter = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M21.6 5.81786C20.886 6.1431 20.1252 6.35869 19.332 6.46336C20.148 5.95742 20.7708 5.16238 21.0636 4.20409C20.3028 4.67513 19.4628 5.00786 18.5676 5.19353C17.8452 4.39475 16.8156 3.90002 15.6924 3.90002C13.5132 3.90002 11.7588 5.73686 11.7588 7.98865C11.7588 8.31265 11.7852 8.62419 11.85 8.92078C8.57759 8.75504 5.68199 7.12632 3.73679 4.64522C3.39719 5.25709 3.19799 5.95742 3.19799 6.71135C3.19799 8.12698 3.89999 9.38185 4.94639 10.1084C4.31399 10.0959 3.69359 9.90524 3.16799 9.60492C3.16799 9.61738 3.16799 9.63358 3.16799 9.64978C3.16799 11.6361 4.53239 13.2861 6.32159 13.6661C6.00119 13.7571 5.65199 13.8007 5.28959 13.8007C5.03759 13.8007 4.78319 13.7858 4.54439 13.7309C5.05439 15.3497 6.50159 16.5398 8.22239 16.5784C6.88319 17.6663 5.18279 18.3218 3.34199 18.3218C3.01919 18.3218 2.70959 18.3068 2.39999 18.2657C4.14359 19.4333 6.20999 20.1 8.43839 20.1C15.6816 20.1 19.6416 13.8693 19.6416 8.46842C19.6416 8.28773 19.6356 8.11327 19.6272 7.94005C20.4084 7.36433 21.0648 6.6453 21.6 5.81786Z" />
  </svg>
)

export default IconTwitter
