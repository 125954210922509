import React from "react"
import { IconProps } from "./iconTypes"

const IconMobilePhone = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M8.81702 2C7.81231 2 7 2.80804 7 3.81702V20.183C7 21.192 7.81231 22 8.81702 22H16.0936C17.1026 22 17.9106 21.1877 17.9106 20.183V3.81702C17.9106 2.80804 17.0983 2 16.0936 2H8.81702ZM8.13724 4.2702H16.7777V18.8192H8.13724V4.2702ZM12.4553 19.5032C12.9555 19.5032 13.366 19.9094 13.366 20.4139C13.366 20.9141 12.9598 21.3245 12.4553 21.3245C11.9551 21.3245 11.5447 20.9183 11.5447 20.4139C11.5447 19.9094 11.9551 19.5032 12.4553 19.5032Z" />
  </svg>
)

export default IconMobilePhone
