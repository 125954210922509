import React, { useState, useEffect, useContext } from "react"
import { useLocation } from "@reach/router"
import { useCookies } from "react-cookie"
import styled from "styled-components"
import { rem } from "polished"

import { media, timingFunctions } from "@sr/common"

//temporarily remove imports which were causing unused import errors during yarn tsc
// import { Link } from "gatsby"
// import SanityImage from "gatsby-plugin-sanity-image"
// import { Button } from "../../Button/index"
// import { IconClose, IconChevronUp } from "../../Icon/index"

import { PopupContext } from "../../GlobalState/index"
import type { PopupProviderContentProps } from "../../GlobalState/index"

const AppPopup = () => {
  const { pathname } = useLocation()
  const [cookies, setCookie] = useCookies()
  const [shouldShowPopup, setShouldShowPopup] = useState(false)
  //temporarily ignore unused var error during yarn tsc
  // @ts-ignore: Unreachable code error
  const [isMobileCollapsed, setIsMobileCollapsed] = useState(true)
  const [isMounted, setIsMounted] = useState(false)

  // 1. Get context for popup, if set
  const {
    popupContent,
  }: { popupContent: PopupProviderContentProps; setPopupContent: any } =
    useContext(PopupContext)

  // 2. Check if cookie was set for context id
  useEffect(() => {
    if (!popupContent) return

    // if set, disable popup
    if (cookies.popupID && cookies.popupID === popupContent.id) {
      setShouldShowPopup(false)
    }

    // if unset, show popup
    if (!cookies.popupID || cookies.popupID !== popupContent.id) {
      setShouldShowPopup(true)
    }
  }, [popupContent, cookies])

  // 3. Add handler to set cookie to context id
  const handleSetCookie = () => setCookie("popupID", popupContent.id)

  // 4. Set mounting after render
  useEffect(() => {
    const timer = setTimeout(() => setIsMounted(shouldShowPopup), 1000)
    return () => clearTimeout(timer)
  }, [shouldShowPopup])

  // 5. Check if current pathname === popupContent.path
  // set cookie if match
  useEffect(() => {
    if (popupContent && pathname === popupContent.path) {
      handleSetCookie()
    }
  }, [pathname, popupContent])

  return (
    <AppPopupStyled
      isMobileCollapsed={isMobileCollapsed}
      isMounted={isMounted}
      shouldShowPopup={shouldShowPopup}
    >
      {/* temporarily disable recommended article popup */}
      {null}
      {/* <button
        type="button"
        className="app-popup__recommended__banner"
        onClick={() => setIsMobileCollapsed(!isMobileCollapsed)}
      >
        <span className="app-popup__recommended__banner__layout">
          <IconChevronUp className="app-popup__recommended__banner__layout__icon app-popup__recommended__banner__layout__icon--chevron" />
          <span>Recommended For You</span>
          <IconClose className="app-popup__recommended__banner__layout__icon app-popup__recommended__banner__layout__icon--close" />
        </span>
      </button>
      {popupContent && popupContent.path && (
        <Link
          className="app-popup__link u-text-decoration-none"
          to={popupContent.path}
        >
          {popupContent && popupContent.img && (
            <SanityImage
              alt={popupContent.img.alt || ""}
              asset={popupContent.img}
              className="app-popup__image"
              crop={popupContent.img.crop}
              hotspot={popupContent.img.hotspot}
              width={455 * 2}
              height={201 * 2}
            />
          )}
          {popupContent && popupContent.title && (
            <h3 className="app-popup__title">{popupContent.title}</h3>
          )}
          <Button className="app-popup__read__button" onClick={handleSetCookie}>
            Read More
          </Button>
        </Link>
      )}
      <button
        type="button"
        onClick={handleSetCookie}
        className="app-popup__close__button"
      >
        <span className="app-popup__close__button__layout">
          <IconClose className="app-popup__close__icon" />
          Close
        </span>
      </button> */}
    </AppPopupStyled>
  )
}

export default AppPopup
interface PopupSC {
  isMobileCollapsed: boolean
  isMounted: boolean
  shouldShowPopup: boolean
}

const AppPopupStyled = styled.article<PopupSC>`
  --mobile-recommended-banner-height: ${rem(48)};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${rem(24)};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  transform: ${({ isMobileCollapsed, isMounted, shouldShowPopup }) => {
    if (!shouldShowPopup) {
      return "translateY(100%)"
    }

    if (isMounted && isMobileCollapsed) {
      return "translateY(calc(100% - var(--mobile-recommended-banner-height)))"
    }

    if (isMounted && !isMobileCollapsed) {
      return "translateY(0)"
    }

    return "translateY(100%)"
  }};
  transition: transform 250ms ${timingFunctions.easeInOutQuad};
  background-color: white;
  box-shadow: var(--shadow);

  @media ${media.stUp} {
    margin: 0 0 ${rem(48)} ${rem(48)};
    max-width: ${rem(455)};
    ${({ isMounted, shouldShowPopup }) => {
      if (!isMounted) {
        return "transform: translateY(5%); opacity: 0; visibility: hidden;"
      }

      if (!shouldShowPopup) {
        return "transform: translateY(5%); opacity: 0; visibility: hidden;"
      }

      if (shouldShowPopup && isMounted) {
        return "transform: translateY(0); opacity: 1; visibility: visibile;"
      }
    }}
    transition: transform 250ms ${timingFunctions.easeInOutQuad}, opacity 250ms ease, visibility 250ms ease;
  }

  .app-popup__recommended__banner {
    display: block;
    width: 100%;
    height: var(--mobile-recommended-banner-height);
    background: var(--color-link);
    color: var(--color-text-subtract);

    @media ${media.stUp} {
      display: none;
    }
  }

  .app-popup__recommended__banner__layout {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .app-popup__recommended__banner__layout__icon {
    fill: var(--color-text-subtract);
  }

  .app-popup__recommended__banner__layout__icon--close {
    position: absolute;
    top: 50%;
    right: ${rem(16)};
    transform: translateY(-50%);
    display: ${({ isMobileCollapsed }) =>
      isMobileCollapsed ? "none" : "block"};
  }

  .app-popup__recommended__banner__layout__icon--chevron {
    opacity: ${({ isMobileCollapsed }) => (!isMobileCollapsed ? "0" : "1")};
  }

  .app-popup__link {
    display: block;
  }

  .app-popup__image {
    display: none;

    @media ${media.stUp} {
      display: block;
    }
  }

  .app-popup__title {
    margin-bottom: ${rem(16)};
    padding: 0 ${rem(16)};
    font-weight: 600;
    color: var(--color-text);

    @media ${media.stUp} {
      margin-top: ${rem(24)};
      margin-bottom: ${rem(24)};
      font-size: ${rem(24)};
      line-height: ${rem(32.68)};
    }
  }

  .app-popup__close__button {
    display: block;
    width: 100%;
    margin-bottom: ${rem(16)};

    @media ${media.stUp} {
      margin-bottom: ${rem(24)};
    }
  }

  .app-popup__close__button__layout {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${rem(8)};
    min-height: ${rem(48)};
    padding: 0 ${rem(16)};
    color: var(--color-text);
    fill: var(--color-text);
  }

  .app-popup__close__icon {
    width: ${rem(16)};
    height: ${rem(16)};
  }
`
