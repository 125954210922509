import useFetch from "use-http"

export type UseCloudFlareI18nWorkerResponse = {
  // @todo ask Matt Lorey what these options can be
  continent: string
  email: string
  cmwLoginLink: string
  phone: string
  unformattedPhone: string
}

export type UseCloudFlareI18nWorkerPayload = {
  data: UseCloudFlareI18nWorkerResponse | null
  loading: boolean
  error?: Error
}

const useCloudFlareI18nWorker = (
  deps: any[] = []
): UseCloudFlareI18nWorkerPayload => {
  const config = {
    cacheLife: 86400000, // 24 hour memory caching
  }
  const { loading, error, data } = useFetch(
    process.env.GATSBY_CLOUDFLARE_I18N_ENDPOINT,
    config,
    deps
  )


  return {
    data:
      loading || !data
        ? null
        : {
            continent: data.continent,
            email: data.email,
            cmwLoginLink: data.login_link,
            phone: data.phone,
            unformattedPhone: data.phone.replace(/\D/g, ""),
          },
    loading,
    error,
  }
}

export default useCloudFlareI18nWorker
