import React from "react"
import { IconProps } from "./iconTypes"

const IconCheckCircleSolid = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M10.0019 15.3031L17.5229 7.78214C17.7065 7.59862 18.0039 7.59862 18.1875 7.78214C18.371 7.96567 18.371 8.26321 18.1875 8.44673L10.3342 16.3C10.2425 16.3917 10.1222 16.4376 10.0019 16.4376C9.88167 16.4376 9.76141 16.3917 9.66965 16.3L5.80685 12.4372C5.62337 12.2537 5.62337 11.9561 5.80685 11.7726C5.99038 11.5891 6.28792 11.5891 6.47144 11.7726L10.0019 15.3031ZM12 2C9.32891 2 6.81767 3.04018 4.92895 4.92895C3.04018 6.81767 2 9.32891 2 12C2 14.6711 3.04018 17.1823 4.92895 19.0711C6.81772 20.9598 9.32891 22 12 22C14.6711 22 17.1823 20.9598 19.0711 19.0711C20.9598 17.1823 22 14.6711 22 12C22 9.32891 20.9598 6.81772 19.0711 4.92895C17.1823 3.04018 14.6711 2 12 2Z" />
  </svg>
)

export default IconCheckCircleSolid
