import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdTitle1Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-bold);
  font-style: normal;
  font-size: ${rem(40)};
  line-height: ${rem(48)};
  font-weight: 700;

  @media ${media.stUp} {
    font-size: ${rem(72)};
    line-height: ${rem(88)};
  }
`

const BdTitle1 = styled.h1<TypographyTypeProps>`
  ${BdTitle1Style}
`
BdTitle1.displayName = "BdTitle1"

export default BdTitle1
