import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media } from "@sr/common"
import { MediaVimeo, MediaYouTube } from "../../Media"
import { SemiBdTitle4 } from "../../Typography/index"

export interface IntroductionVideoPropsShape {
  videoTitle?: string
  videoUrl: string
  introText?: string
  description?: string
  className?: string
  id?: string
}

const IntroductionVideo = ({
  videoTitle,
  videoUrl,
  introText,
  description,
  className,
  id,
}: IntroductionVideoPropsShape) => {
  let isVimeo = false
  let isYoutube = false

  if (videoUrl) {
    isVimeo = videoUrl.includes("vimeo")
    isYoutube = videoUrl.includes("youtube")
  }
  return (
    <IntroductionVideoStyled className={className}>
      {id && <a className="introduction-video__anchor" id={id} />}
      {introText ? <SemiBdTitle4>{introText}</SemiBdTitle4> : null}
      {videoTitle ? <SemiBdTitle4>{videoTitle}</SemiBdTitle4> : null}
      {description ? <SemiBdTitle4>{description}</SemiBdTitle4> : null}
      {videoUrl && (
        <div className="introduction-video__container">
          {isYoutube ? <MediaYouTube url={videoUrl} /> : null}
          {isVimeo ? (
            <MediaVimeo
              className="introduction-video__video-player"
              controls={true}
              url={videoUrl}
              volume={0.5}
            />
          ) : null}
        </div>
      )}
    </IntroductionVideoStyled>
  )
}

export default IntroductionVideo

const IntroductionVideoStyled = styled.section`
  width: 100%;
  /* height: ${rem(514)}; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${rem(40)} 0;

  @media ${media.stUp} {
    margin: ${rem(80)} 0;
    /* height: ${rem(514)}; */
  }

  .introduction-video__anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;

    @media ${media.stMidUp} {
      /* top: -100px; */
    }
  }

  .introduction-video__anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }

  .introduction-video__container {
    width: 100%;
    margin-top: ${rem(32)};

    @media ${media.stUp} {
      width: ${rem(914)};
    }

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
`
