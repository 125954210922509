import React from "react"
import { IconProps } from "./iconTypes"

const IconSmrtCommunities = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <g clip-path="url(#clip0_2403_2875)">
      <path d="M24 0H0V24H24V0Z" fill="#231F20" />
      <path
        d="M4 16.776L6.82545 15.0694V13.6432L4 15.3031V16.776Z"
        fill="white"
      />
      <path
        d="M15.9334 9.58378L18.7588 7.86929L17.7253 7.24585L14.8552 8.92916L15.9334 9.58378Z"
        fill="white"
      />
      <path
        d="M6.82547 10.328L11.3759 7.57703C11.7626 7.34324 12.231 7.34324 12.6176 7.57703L14.3873 8.64469L17.2573 6.96138L12.6251 4.15586C12.4541 4.05455 12.231 4 12.0079 4C11.7849 4 11.5618 4.05455 11.3908 4.15586L4.63203 8.24724C4.43127 8.37193 4.24539 8.60573 4.13386 8.85511C4.0595 9.02656 4.01489 9.2058 4.01489 9.36945V14.7467L6.84034 13.0868V10.328H6.82547Z"
        fill="white"
      />
      <path
        d="M19.9255 8.97277C19.8958 8.88704 19.8586 8.80132 19.814 8.72339L17.167 10.321V16.5788L12.6165 13.8279C12.4455 13.7266 12.2224 13.672 11.9994 13.672C11.7763 13.672 11.5533 13.7266 11.3823 13.8279L6.83179 16.5788L4.00635 18.2855L6.83179 20L11.3823 17.2491C11.7689 17.0153 12.2373 17.0153 12.624 17.2491L17.1744 20L19.9999 18.2933V9.36242C19.9999 9.23773 19.9776 9.10525 19.9255 8.97277Z"
        fill="white"
      />
      <path
        d="M6.82545 13.6385V13.0774L4 14.7373V15.2984L6.82545 13.6385Z"
        fill="white"
      />
      <path
        d="M14.8551 8.9267L17.7252 7.24339L17.2493 6.95505L14.3867 8.63836L14.8551 8.9267Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2403_2875">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconSmrtCommunities
