/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Global context provider
import React from "react"
import { GlobalStateContextProvider } from "@sr/gatsby-theme-ui"

import PopupContextSetter from "./src/components/PopupContextSetter"

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalStateContextProvider>
      <PopupContextSetter>{element}</PopupContextSetter>
    </GlobalStateContextProvider>
  )
}

export const onRouteUpdate = () => {
  if (process.env === "production" && window.drift) {
    window.drift.page() // generic page event
  }
}

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const integrationsFilterRoutes = [
    "/integrations-partnerships/",
    "/integrations-partnerships/pms/",
    "/integrations-partnerships/crm/",
    "/integrations-partnerships/software/",
  ]

  // disables scroll to top behavior when navigating to integrations filter routes
  if (integrationsFilterRoutes.includes(routerProps?.location?.pathname)) {
    if (
      prevRouterProps?.location?.pathname &&
      !integrationsFilterRoutes.includes(prevRouterProps?.location?.pathname)
    ) {
      // if prev location is not in array allow default behavior
      return true
    } else {
      // prevent scroll to top
      return false
    }
  }
}
