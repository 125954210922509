import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const RegBodyLgStyle = css<TypographyTypeProps>`
  font-size: ${rem(24)};
  line-height: ${rem(40)};
  font-weight: 400;
`

const RegBodyLg = styled.p<TypographyTypeProps>`
  ${RegBodyLgStyle}
`
RegBodyLg.displayName = "RegBodyLg"

export default RegBodyLg
