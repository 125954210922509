import React, { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { Button } from "../../Button/index"

export interface SectionFeatureTableGroupFooterProps {
  className?: string
  cta?: string | ReactNode
  ctaPath?: string
}

const SectionFeatureTableGroupFooter = ({
  className,
  cta = "Learn More",
  ctaPath,
}: SectionFeatureTableGroupFooterProps) => {
  return (
    <SectionFeatureTableGroupFooterStyled
      className={`feature-table-footer ${className || ""}`}
    >
      {ctaPath && (
        <Link className="feature-table-footer__cta" to={ctaPath}>
          <Button
            btnColor="var(--color-primary)"
            btnStyle="outline"
            expand={true}
            shadow={false}
          >
            {cta}
          </Button>
        </Link>
      )}
    </SectionFeatureTableGroupFooterStyled>
  )
}

export default SectionFeatureTableGroupFooter

const SectionFeatureTableGroupFooterStyled = styled.footer`
  padding: ${rem(24)} ${rem(16)};
  text-align: center;
  background: var(--color-content-bg);

  @media ${media.stUp} {
    display: none;
  }
`
