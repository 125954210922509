import React, { useState } from "react"
import styled from "styled-components"
import { rem } from "polished"
import { motion } from "framer-motion"

import { BdTitle5, IconChevronRight } from "@sr/gatsby-theme-ui"
import { type NavigationSubSectionContentNavigationItems } from "../navData"

import AppHeaderMobileAccordionGroup from "./NavMobileAccordionGroup"

export interface AppHeaderMobileAccordionContainerProps {
  accordionItems: {
    content: {
      header: string
      navigationItems: NavigationSubSectionContentNavigationItems[]
    }[]
    link: string
    nav: string
  }[]
  defaultActiveItem?: string | null
}

const AppHeaderMobileAccordionContainer = ({
  accordionItems,
  defaultActiveItem = null,
}: AppHeaderMobileAccordionContainerProps) => {
  const [activeAccordionTab, setActiveAccordionTab] = useState<string | null>(
    defaultActiveItem
  )

  const handleClick = (id: string) => {
    if (activeAccordionTab === id) {
      setActiveAccordionTab(null)
    } else {
      setActiveAccordionTab(id)
    }
  }

  return (
    <AppHeaderMobileAccordionContainerStyled>
      {accordionItems.map((accordionItem: any, i: number) => {
        let active = activeAccordionTab === accordionItem.content.nav
        return (
          <div className="header__mobile-accordion__group-container" key={i}>
            <div
              className="header__mobile-accordion__item-container"
              onClick={() => handleClick(accordionItem.content.nav)}
            >
              <BdTitle5
                className={`header__mobile-accordion__item-title ${
                  active ? "active" : ""
                }`}
              >
                {accordionItem.content.nav}
              </BdTitle5>
              <motion.span
                initial={{
                  rotate: active ? 90 : 0,
                }}
                animate={{
                  rotate: active ? 90 : 0,
                }}
                style={{ lineHeight: "0" }}
              >
                <IconChevronRight
                  color={active ? "var(--color-primary)" : undefined}
                />
              </motion.span>
            </div>
            {accordionItem.content.content.map(
              (accordionGroup: any, i: number) => {
                return (
                  <AppHeaderMobileAccordionGroup
                    header={accordionGroup.header}
                    iconAbove={accordionGroup.iconAbove}
                    link={accordionGroup.link}
                    nav={accordionGroup.nav}
                    navigationItems={accordionGroup.navigationItems}
                    key={i}
                    active={active}
                  />
                )
              }
            )}
          </div>
        )
      })}
    </AppHeaderMobileAccordionContainerStyled>
  )
}

export default AppHeaderMobileAccordionContainer

const AppHeaderMobileAccordionContainerStyled = styled.div`
  width: 100%;

  .header__mobile-accordion__item-container {
    display: flex;
    justify-content: space-between;
    padding: ${rem(16)} ${rem(48)};
    border-bottom: 1px solid var(--color-gray100);
    cursor: pointer;
  }

  .header__mobile-accordion__item-title {
    text-transform: uppercase;

  }

  .header__mobile-accordion__item-title.active {
    color: var(--color-primary);
  }
`
