import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import CardLift from "./Lift"

export type sizeMobile = number | string
export type sizeStandard = number | string

export interface CardProps {
  /**
   * background color of card
   * @default `var(--color-content-bg)`
   */
  bgColor?: string
  children?: ReactNode
  className?: string
  /** card border radius */
  radius?: number
  /**
   * set padding of card for mobile and standard breakpoints
   * pixel value is converted to rem
   * @default [16, 16]
   */
  paddings?: [sizeMobile, sizeStandard]
  /**
   * box shadow applied to card
   * @default `var(--shadow)`
   */
  shadow?: string
  textColor?: string
}

/**
 * A Card component that composes its children within
 * a card block. Can be styled accordingly
 */
const Card = ({
  bgColor = `var(--color-content-bg)`,
  children,
  className,
  radius = 0,
  paddings = [16, 16],
  shadow = `var(--shadow)`,
  textColor,
}: CardProps) => {
  return (
    <CardStyled
      bgColor={bgColor}
      className={className}
      paddingM={paddings[0]}
      paddingSt={paddings[1]}
      shadow={shadow}
      style={{ borderRadius: `${rem(radius)}` }}
      textColor={textColor}
    >
      {children}
    </CardStyled>
  )
}

interface CardSC {
  bgColor: string
  paddingM: number | string
  paddingSt: number | string
  shadow: string
  textColor?: string
}

const CardStyled = styled.div<CardSC>`
  padding: ${({ paddingM }) =>
    typeof paddingM === "number" ? rem(paddingM) : paddingM};
  color: ${({ textColor }) => (textColor ? textColor : "inherit")};
  background: ${({ bgColor }) => bgColor};
  box-shadow: ${({ shadow }) => shadow};

  @media ${media.stUp} {
    padding: ${({ paddingSt }) =>
      typeof paddingSt === "number" ? rem(paddingSt) : paddingSt};
  }
`

export { Card as default, CardLift }
