import React, { ReactNode } from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { getFlexAlign, media } from "@sr/common"

import { AlignXType, SanityImg, WidthConstraintType } from "../../types/index"
import AppLayoutWidth from "../App/Layout/Width"

interface SectionImgGridSanityImg extends SanityImg {
  alt?: string
  path?: string
  url?: string
}

export type SectionImgGridImg = SectionImgGridSanityImg

export interface SectionImgGridProps {
  bgColor?: string
  columnsM?: number
  columnsSt?: number
  children?: ReactNode
  className?: string
  contentAlign?: AlignXType
  contentWidth?: WidthConstraintType
  imgs?: SectionImgGridSanityImg[]
  itemPaddingM?: string
  itemPaddingSt?: string
  paddingBottomM?: string
  paddingBottomSt?: string
  paddingTopM?: string
  paddingTopSt?: string
  onHoverSaturate?: boolean
}

/** Component for rendering images in grid format */
const SectionImgGrid = ({
  bgColor = "transparent",
  columnsM = 2,
  columnsSt = 4,
  children,
  className,
  contentAlign = "center",
  contentWidth = "lg",
  imgs = [],
  itemPaddingM = rem(16),
  itemPaddingSt = rem(24),
  paddingBottomM = rem(48),
  paddingBottomSt = rem(72),
  paddingTopM = rem(48),
  paddingTopSt = rem(72),
  onHoverSaturate = false,
}: SectionImgGridProps) => {
  const renderSanityImgAsset = (img: SectionImgGridSanityImg) => {
    if (img?.asset) {
      return (
        <SanityImage
          alt={img.alt || ""}
          asset={img.asset}
          className="img"
          crop={img.crop}
          hotspot={img.hotspot}
          width={300}
          hieght={300}
        />
      )
    }
    return null
  }

  return (
    <SectionImgGridStyled
      className={className}
      flexAlign={getFlexAlign(contentAlign)}
      flexBasisM={`${100 / columnsM}%`}
      flexBasisSt={`${100 / columnsSt}%`}
      hoverSaturate={onHoverSaturate}
      itemPaddingM={itemPaddingM}
      itemPaddingSt={itemPaddingSt}
      paddingBottomM={paddingBottomM}
      paddingBottomSt={paddingBottomSt}
      paddingTopM={paddingTopM}
      paddingTopSt={paddingTopSt}
      style={{ backgroundColor: bgColor }}
    >
      <AppLayoutWidth constraint={contentWidth}>
        {imgs && imgs.length ? (
          <ul className="list">
            {imgs.map((img, index) => (
              <li className="item" key={`img-item-${index}`}>
                {img.path && (
                  <Link className="link" to={img.path}>
                    {renderSanityImgAsset(img)}
                  </Link>
                )}
                {img.url && (
                  <a
                    className="link"
                    href={img.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {renderSanityImgAsset(img)}
                  </a>
                )}
                {!img.path && !img.url && (
                  <span className="link">{renderSanityImgAsset(img)}</span>
                )}
              </li>
            ))}
          </ul>
        ) : null}
        {children}
      </AppLayoutWidth>
    </SectionImgGridStyled>
  )
}

export default SectionImgGrid

interface ImgGridSC {
  flexAlign: string
  flexBasisM: string
  flexBasisSt: string
  hoverSaturate: boolean
  itemPaddingM: string
  itemPaddingSt: string
  paddingBottomM: string
  paddingBottomSt: string
  paddingTopM: string
  paddingTopSt: string
}

const SectionImgGridStyled = styled.div<ImgGridSC>`
  padding: ${({ paddingBottomM, paddingTopM }) =>
    `${paddingTopM} 0 ${paddingBottomM}`};

  @media ${media.stUp} {
    padding: ${({ paddingBottomSt, paddingTopSt }) =>
      `${paddingTopSt} 0 ${paddingBottomSt}`};
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: ${({ flexAlign }) => flexAlign};
    list-style-type: none;
  }

  .item {
    flex: 0 0 ${({ flexBasisM }) => flexBasisM};
    align-items: stretch;
    justify-content: space-between;

    @media ${media.stUp} {
      flex: 0 0 ${({ flexBasisSt }) => flexBasisSt};
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ${({ itemPaddingM }) => itemPaddingM};

    ${({ hoverSaturate }) =>
      hoverSaturate
        ? `
      filter: grayscale(100%);
      transition: filter 200ms ease-in-out;
    `
        : ``}

    @media ${media.stUp} {
      padding: ${({ itemPaddingSt }) => itemPaddingSt};
    }

    &:hover,
    &:focus {
      ${({ hoverSaturate }) =>
        hoverSaturate
          ? `
        filter: grayscale(0%);
      `
          : ``}
    }
  }
`
