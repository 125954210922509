import React, { ReactNode, createContext, useState } from "react"

import type { SanityImg } from "../../types/index"

interface PopupProviderProps {
  children?: ReactNode
}

const PopupContext = createContext<any | null>(null)

export type PopupProviderContentProps = {
  id?: string | null
  img?: SanityImg | null
  title?: string | null
  path?: string | null
}

const PopupProvider = ({ children }: PopupProviderProps) => {
  const [popupContent, setPopupContent] =
    useState<PopupProviderContentProps | null>(null)

  return (
    <PopupContext.Provider
      value={{
        popupContent,
        setPopupContent,
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}

export { PopupProvider as default, PopupContext }
