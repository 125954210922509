import React from "react"
import { IconProps } from "./iconTypes"

const IconGlobeSimple = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM10.919 3.016a15.048 15.048 0 00-3.188 8.508H2.965a9.05 9.05 0 017.954-8.508zm2.162 0a9.05 9.05 0 017.954 8.508H16.27a15.048 15.048 0 00-3.188-8.508zm2.234 8.508h-6.63A14.095 14.095 0 0112 3.197a14.095 14.095 0 013.315 8.327zm-6.63.952h6.63A14.095 14.095 0 0112 20.803a14.095 14.095 0 01-3.315-8.327zm-.954 0a15.048 15.048 0 003.188 8.508 9.05 9.05 0 01-7.954-8.508H7.73zm5.35 8.508a15.048 15.048 0 003.188-8.508h4.766a9.05 9.05 0 01-7.954 8.508z" />
  </svg>
)

export default IconGlobeSimple
