import React from "react"
import styled from "styled-components"

import { BodyText } from "../Typography/index"

export interface StatusIndicatorProps {
  statusColor?: string
  message?: string
  className?: string
}

const StatusIndicator = ({
  statusColor = "#fffff",
  message,
}: StatusIndicatorProps) => {
  return (
    <StatusIndicatorStyled statusColor={statusColor}>
      <div className="status-indicator__icon">
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8172 3.52025L7.18272 0.128982C7.04091 0.0440425 6.85376 0 6.66665 0C6.47954 0 6.29243 0.0440425 6.15058 0.128982L0.516069 3.52025C0.232382 3.69013 0 4.11168 0 4.45458V7.96539V8.0346V11.5454C0 11.8883 0.232382 12.3067 0.516069 12.4797L6.15058 15.871C6.29243 15.956 6.47954 16 6.66665 16C6.85376 16 7.04091 15.956 7.18272 15.871L12.8172 12.4735C13.1009 12.3036 13.3333 11.882 13.3333 11.5391V8.03146V7.96225V4.45144C13.3333 4.10853 13.1009 3.69013 12.8172 3.52025Z"
          />
        </svg>
      </div>
      <div>
        {message && (
          <BodyText className="status-indicator__message">{message}</BodyText>
        )}
      </div>
    </StatusIndicatorStyled>
  )
}

export default StatusIndicator

interface StatusIndicatorSC {
  statusColor: string
}

const StatusIndicatorStyled = styled.span<StatusIndicatorSC>`
  --statusColor: ${({ statusColor }) => statusColor};

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem;
  text-align: left;
  background-color: var(--color-gray950);
  fill: var(--statusColor);

  .status-indicator__icon {
    color: var(--color-success);
    padding-right: 1rem;
  }

  svg {
    vertical-align: middle;
    fill: var(--statusColor);
  }

  .status-indicator__message {
    color: var(--color-white);
  }
`
