import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

export const Title4Style = css<TypographyTypeProps>`
  font-size: ${rem(24)};
  line-height: ${rem(36.5)};
  ${SharedDynamicPropsStyled}

  @media ${media.stUp} {
    font-size: ${rem(27)};
    line-height: ${rem(43.5)};
  }
`

const Title4 = styled.h4<TypographyTypeProps>`
  ${Title4Style}
`
Title4.displayName = "Title4"

export default Title4
