import React from "react"
import styled from "styled-components"
import { media } from "@sr/common"

export interface SeparatorProps {
  className?: string
  showOnMobile?: boolean
  /** Array of widths for different screen sizes [mobile, desktop]
   * A single value passed will be applied to all screen sizes
   */
  width: [number, number] | [number]
  color?: string
  /** pixel value of border thickness */
  thickness?: number
  /** Array of margin-top pixel values for different screensizes [mobile px, desktop px] */
  marginTop?: [number, number]
  /** Array of margin-bottom pixel values for different screensizes [mobile px, desktop px] */
  marginBottom?: [number, number]
}

const Separator = ({
  className,
  showOnMobile = true,
  width,
  color = "var(--color-gray300)",
  thickness = 1,
  marginTop = [24, 64],
  marginBottom = [0, 0],
}: SeparatorProps) => {
  return (
    <SeparatorStyled
    className={className}
      showOnMobile={showOnMobile}
      width={width}
      color={color}
      thickness={thickness}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="separator" />
    </SeparatorStyled>
  )
}

export default Separator

interface SeparatorSC {
  showOnMobile: boolean
  width: [number, number] | [number]
  color: string
  thickness: number
  marginTop: [number, number]
  marginBottom: [number, number]
}

const SeparatorStyled = styled.div<SeparatorSC>`
  display: ${({ showOnMobile }) => (showOnMobile ? "flex" : "none")};
  justify-content: center;
  margin-top: ${({ marginTop }) => `${marginTop[0]}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom[0]}px`};
  .separator {
    width: ${({ width }) => `${width[0]}vw`};
    height: ${({ thickness }) => `${thickness}px`};
    border-bottom: ${({ color, thickness }) => `${thickness}px solid ${color}`};
  }

  @media ${media.stUp} {
    display: flex;
    margin-top: ${({ marginTop }) => `${marginTop[1]}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom[1]}px`};
    .separator {
      ${({ width }) =>
        width.length > 1 ? `width: ${width[1]}vw` : `width: ${width[0]}vw`};
    }
  }
`
