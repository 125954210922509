import React, { ReactNode, useState } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import { SanityImg } from "../../../types/index"

import { AppLayoutWidth, appHeaderHeightMobile } from "../../App/Layout/index"
import SectionHotspotSliderIllustration from "./Illustration"

import SectionHotspotSliderSlides from "./Slides"

export type SectionHotspotSliderSlideType = {
  content?: any
  header?: string | ReactNode
  img?: SanityImg
  pin?: {
    x?: string
    y?: string
  }
}

export interface SectionHotspotSliderProps {
  illustration: SanityImg
  slides?: SectionHotspotSliderSlideType[]
}

const SectionHotspotSlider = ({
  illustration,
  slides,
}: SectionHotspotSliderProps) => {
  if (!slides || slides.length === 0) return null
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const handleSlideDrag = (direction: "left" | "right") => {
    let nextSlide = direction === "right" ? currentSlide + 1 : currentSlide - 1

    if (nextSlide < 0) nextSlide = slides.length - 1
    if (nextSlide > slides.length - 1) nextSlide = 0

    setCurrentSlide(nextSlide)
  }

  const handleSlideSelect = (id: number) => setCurrentSlide(id)
  return (
    <SectionHotspotSliderStyled>
      <AppLayoutWidth className="layout" constraint="full" paddings={[0, 0]}>
        <SectionHotspotSliderSlides
          className="slides"
          currentSlide={currentSlide}
          onSlideDrag={handleSlideDrag}
          onSlideSelect={handleSlideSelect}
          slides={slides}
        />
        <SectionHotspotSliderIllustration
          className="illustration"
          currentSlide={currentSlide}
          illustration={illustration}
          onSlideSelect={handleSlideSelect}
          slides={slides}
        />
      </AppLayoutWidth>
    </SectionHotspotSliderStyled>
  )
}

export default SectionHotspotSlider

const SectionHotspotSliderStyled = styled.section`
  border-top: 1px solid var(--color-border-light);
  border-bottom: 1px solid var(--color-border-light);

  .layout {
    display: flex;
    flex-direction: column-reverse;
    min-height: calc(100vh - ${rem(appHeaderHeightMobile)});

    @media ${media.stUp} {
      flex-direction: row-reverse;
      align-items: stretch;
      min-height: auto;
    }
  }

  .slides {
    flex: 1 0 100%;

    @media ${media.stUp} {
      flex: 1 0 33.333%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .slideshow {
    width: 100%;
  }

  .illustration {
    @media ${media.stUp} {
      flex: 1 0 66.666%;
    }
  }
`
