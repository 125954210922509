import React from "react"
import { IconProps } from "./iconTypes"

const IconArrowRight = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M22.8459375,11.625 L12.354375,1.15171875 C12.2240847,1.02142846 12.0341825,0.970544303 11.8562026,1.01823386 C11.6782228,1.06592342 11.5392047,1.20494151 11.4915151,1.38292136 C11.4438256,1.56090121 11.4947097,1.75080346 11.625,1.88109375 L21.245625,11.484375 L1.48265625,11.484375 C1.19788443,11.484375 0.96703125,11.7152282 0.96703125,12 C0.96703125,12.2847718 1.19788443,12.515625 1.48265625,12.515625 L21.234375,12.515625 L11.6559375,22.1535938 C11.4595661,22.356247 11.4625744,22.6791075 11.6626879,22.8780665 C11.8628013,23.0770254 12.1856738,23.0781656 12.3871875,22.880625 L22.8473437,12.3534375 C23.0478094,12.1517739 23.0471803,11.8258881 22.8459375,11.625 Z" />
  </svg>
)

export default IconArrowRight
