import React from "react"
import { IconProps } from "./iconTypes"

const IconShieldPerson = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M16.217 13.188a.502.502 0 00-.036-.188c-.031-.079-.8-1.927-4.037-1.927h-.036c-3.227.013-4.2 1.836-4.24 1.913a.512.512 0 00-.057.237v.042l.007 1.68a.511.511 0 00.506.509h.002l7.395-.032a.502.502 0 00.465-.316.515.515 0 00.038-.195v-.043l-.007-1.68zm-7.39 1.198l-.004-.976c.22-.31 1.082-1.266 3.29-1.276h.032c2.184 0 2.899.944 3.062 1.221l.004 1.006-6.384.025zM13.709 9.928a2.386 2.386 0 01-1.691.707 2.386 2.386 0 01-1.692-.707 2.424 2.424 0 01-.701-1.704V8.18a2.427 2.427 0 01.7-1.705 2.39 2.39 0 011.693-.706 2.377 2.377 0 011.691.706 2.413 2.413 0 01.701 1.705v.043c0 .639-.253 1.252-.701 1.704zm-.941-2.856a1.353 1.353 0 00-.75-.24c-.363 0-.71.144-.969.4a1.398 1.398 0 00-.412.97 1.378 1.378 0 00.835 1.265 1.35 1.35 0 001.48-.288 1.374 1.374 0 00.308-1.487c-.1-.251-.271-.466-.492-.62z" />
    <path d="M19.98 6.639v-.072c-.02-.382-.02-.762-.02-1.13a.513.513 0 00-.321-.475l-7.453-2.927a.502.502 0 00-.368 0l-7.451 2.93a.506.506 0 00-.321.446c-.021.389-.021.79-.021 1.18V6.6c-.054 2.9-.109 5.899.84 8.64 1.1 3.178 3.375 5.378 6.957 6.728a.495.495 0 00.354 0c3.572-1.347 5.844-3.541 6.947-6.708.951-2.735.903-5.728.857-8.621zm-1.812 8.281c-.474 1.362-1.166 2.494-2.114 3.461-1.031 1.052-2.395 1.913-4.056 2.562-1.666-.65-3.033-1.514-4.066-2.57-.949-.969-1.64-2.105-2.113-3.47-.889-2.57-.836-5.478-.784-8.286v-.014-.01c0-.277 0-.543.007-.803l6.96-2.734 6.946 2.729c0 .27.006.544.02.825v.045c.046 2.804.092 5.704-.8 8.265z" />
  </svg>
)

export default IconShieldPerson
