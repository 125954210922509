import React, { ReactNode } from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { SanityImg } from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import BlockContentInline from "../../BlockContent/Inline"
import Card from "../../Card/index"
import SectionCardSliderDot from "./Dot"
import { Caption, BdTitle3 } from "../../Typography/index"

const itemWidth = 320
const landscapeImgDimensions = [248, 140]
const squareImgDimension = 272

type SectionCardSliderItem = {
  url?: string
  img?: SanityImg
  path?: string
  title?: string
}

export interface SectionCardSliderProps {
  autoPlaySpeed?: number
  bgColor?: string
  className?: string
  content?: any[]
  header?: string | ReactNode
  imgCrop?: "square" | "landscape"
  list?: SectionCardSliderItem[]
  visibleItemsStandard?: number
}

const SectionCardSlider = ({
  autoPlaySpeed,
  bgColor = "var(--color-interactive-bg)",
  className,
  content,
  header,
  imgCrop = "square",
  list,
  visibleItemsStandard = 3,
}: SectionCardSliderProps) => {
  return (
    <SectionCardSliderStyled
      className={className}
      style={{ backgroundColor: bgColor }}
    >
      <AppLayoutWidth className="intro" constraint="readableLg">
        {header && <BdTitle3 className="intro__header">{header}</BdTitle3>}
        {content && <BlockContentInline blocks={content} />}
      </AppLayoutWidth>
      {list && list.length > 0 ? (
        <div className="carousel">
          <Carousel
            additionalTransfrom={0}
            arrows={true}
            autoPlay={!!autoPlaySpeed}
            autoPlaySpeed={autoPlaySpeed}
            centerMode={true}
            className=""
            containerClass="carousel__container"
            customDot={<SectionCardSliderDot className="carousel__dot" />}
            dotListClass="carousel__dot-nav"
            draggable={true}
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: visibleItemsStandard,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 10,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {list.map((card, index) => {
              const cardContent = (
                <Card className="carousel__item__card" radius={4}>
                  {card.img && card?.img?.asset && (
                    <SanityImage
                      alt={card.img.alt || ""}
                      asset={card.img.asset}
                      crop={card.img.crop}
                      hotspot={card.img.hotspot}
                      width={
                        imgCrop === "square"
                          ? squareImgDimension
                          : landscapeImgDimensions[0]
                      }
                      height={
                        imgCrop === "square"
                          ? squareImgDimension
                          : landscapeImgDimensions[1]
                      }
                    />
                  )}
                  {card.title && (
                    <Caption className="carousel__item__title">
                      {card.title}
                    </Caption>
                  )}
                </Card>
              )
              return (
                <div className="carousel__item" key={index}>
                  {card.path && (
                    <Link className="carousel__item__link" to={card.path}>
                      {cardContent}
                    </Link>
                  )}
                  {card.url && !card.path && (
                    <a
                      className="carousel__item__link"
                      href={card.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {cardContent}
                    </a>
                  )}
                  {!card.url && !card.path && cardContent}
                </div>
              )
            })}
          </Carousel>
        </div>
      ) : null}
    </SectionCardSliderStyled>
  )
}

export default SectionCardSlider

const SectionCardSliderStyled = styled.section`
  --item-width: ${rem(itemWidth)};
  --item-gap: ${rem(16)};

  position: relative;
  z-index: 1;
  padding: ${rem(72)} 0;

  @media ${media.stUp} {
    padding: 10vh 0;
  }

  .intro {
    @media ${media.stUp} {
      text-align: center;
    }
  }

  .intro__header {
    margin-bottom: ${rem(24)};
  }

  .carousel {
    [aria-hidden] {
      transition: opacity 400ms ease-in-out;
    }

    [aria-hidden="true"] {
      opacity: 0.25;
    }
  }

  .carousel__container {
    padding-top: ${rem(48)};
    padding-bottom: ${rem(48)};
  }

  .carousel__item {
    height: 100%;
    padding: 0 ${rem(8)};

    @media ${media.stUp} {
      padding: 0 var(--item-gap);
    }
  }

  .carousel__item__title {
    display: block;
    margin-top: ${rem(16)};
    padding: ${rem(16)} 0;
    text-align: center;
  }

  .carousel__item__link {
    display: block;
    height: 100%;
    color: var(--color-text);
  }

  .carousel__item__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`
