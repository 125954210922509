import React from "react"
import { IconProps } from "./iconTypes"

const IconPaperPlane = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M18.75 21.45L12.7195 18.0041L10.5315 20.1L9.30001 16.05L18.75 6.59999L7.16689 14.322L1.20001 10.65L22.8 2.54999L18.75 21.45Z" />
  </svg>
)

export default IconPaperPlane
