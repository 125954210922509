import React, { useState, useEffect, ReactNode } from "react"
import { navigate } from "gatsby"

declare global {
  interface Window {
    hbspt: any
    HubSpotForms: any
  }
}

export interface FormHubspotProps {
  actionType?: "navigate" | "changeState"
  id: string
  navigatePath?: string
  changeStateNode?: ReactNode
}

const FormHubspot = ({
  actionType = "navigate",
  id,
  navigatePath = "/",
  changeStateNode,
}: FormHubspotProps) => {
  const [changeFormState, setChangeFormState] = useState(false)
  useEffect(() => {
    // hubspot forms script
    const script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    const jquery = document.createElement("script")
    jquery.src = "https://code.jquery.com/jquery-3.6.0.slim.min.js"
    document.body.appendChild(jquery)

    const scriptInit = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "20434445",
          formId: id,
          target: "#hubspotForm",
          onFormSubmitted: function () {
            if (actionType === "navigate") {
              navigate(navigatePath)
            }
            if (actionType === "changeState") {
              setChangeFormState(true)
              window.HubSpotForms.removeAll()
            }
          },
        })
      }
    }

    script.addEventListener("load", scriptInit)

    return () => {
      script.removeEventListener("load", scriptInit)
    }
  }, [])

  return changeFormState ? (
    <div className="hubspot-form">{changeStateNode}</div>
  ) : (
    <div className="hubspot-form" id="hubspotForm" />
  )
}

export default FormHubspot
