import React from "react"
import styled from "styled-components"
import { media } from "@sr/common"
import { IconChevronCircleRight } from "./Icon"
import { rem } from "polished"

export interface AppCtaBannerProps {
  showComponent: boolean
  bannerContent: string
  bannerTag: boolean
  bannerTagText?: string
  learnMoreUrl: string
}

const AppCtaBanner = ({
  showComponent,
  bannerContent,
  bannerTag,
  bannerTagText,
  learnMoreUrl,
}: AppCtaBannerProps) => {
  if (showComponent == null || !showComponent) return null

  return (
    <CtaBannerStyled>
      {bannerTag && bannerTagText && (
        <div className="banner__banner-tag-container">
          <div className="banner__banner-tag-text">
            <h6>{bannerTagText}</h6>
          </div>
        </div>
      )}

      <div className="banner__banner-content-container">
        <h6 className="banner__banner-content-content">{bannerContent}</h6>
      </div>
      <div className="banner__banner-learn-more-container">
        <a className="banner__banner-learn-more-link" href={learnMoreUrl}>
          <h6>Learn More</h6>
          <IconChevronCircleRight color="var(--color-gray300)" />
        </a>
      </div>
    </CtaBannerStyled>
  )
}

export const CtaBannerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background: var(--color-gray900);
  color: white;
  gap: ${rem(28)};
  padding: ${rem(24)};
  text-align: center;

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  @media ${media.stUp} {
    flex-direction: row;
    gap: ${rem(88)};
    padding: ${rem(28)};
  }

  .banner__banner-tag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${rem(9)};
    border-radius: ${rem(4)};
    border: ${rem(1)} solid var(--color-primary-on-dark);
    padding: ${rem(4)} ${rem(16)};
  }

  .banner__banner-learn-more-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-gray300);
    gap: ${rem(8)};
  }

  .banner__banner-tag-text {
    color: var(--color-primary);
  }

  @media ${media.stUp} {
    height: ${rem(48)};
    gap: ${rem(48)};
  }
`

export default AppCtaBanner
