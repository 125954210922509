import React, { ReactNode } from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { BodyText, Caption } from "../Typography/index"
import type { SanityImg } from "../../types/sanityImg"

export interface MediaDocThumbProps {
  className?: string
  subTitle?: string | ReactNode
  maxImgWidth?: number
  thumbImg?: SanityImg
  thumbComponent?: ReactNode
  title?: string | ReactNode
}

/**
 * Component to render a document thumbnail preview
 */
const MediaDocThumb = ({
  className,
  subTitle,
  maxImgWidth = 150,
  thumbImg,
  thumbComponent,
  title,
}: MediaDocThumbProps) => {
  let thumbContent = null

  if (thumbComponent) {
    thumbContent = thumbComponent
  }

  if (thumbImg) {
    thumbContent = (
      <SanityImage
        alt={thumbImg.alt || ""}
        asset={thumbImg.asset}
        crop={thumbImg.crop}
        hotspot={thumbImg.hotspot}
        width={300}
        height={300}
      />
    )
  }

  if (!thumbContent) return null

  return (
    <MediaDocThumbStyled
      className={`${className} doc-thumb`}
      maxImgWidth={maxImgWidth}
    >
      <div className="doc-thumb__thumb-content">{thumbContent}</div>
      {title && <BodyText>{title}</BodyText>}
      {subTitle && <Caption>{subTitle}</Caption>}
    </MediaDocThumbStyled>
  )
}

export default MediaDocThumb

interface ThumbSC {
  maxImgWidth: number
}

const MediaDocThumbStyled = styled.div<ThumbSC>`
  text-align: center;
  color: var(--color-text);

  &:hover,
  &:focus {
    color: var(--color-link-hover);

    .doc-thumb__thumb-content {
      border-color: var(--color-content-bg);
      box-shadow: var(--shadow);
    }
  }

  .doc-thumb__thumb-content {
    max-width: ${({ maxImgWidth }) => rem(maxImgWidth)};
    margin: 0 auto ${rem(16)};
    border: 8px solid transparent;
    transition: border-color 250ms ease, box-shadow 250ms ease;
  }
`
