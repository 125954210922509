import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

export const BlockquoteStyle = css<TypographyTypeProps>`
  margin-top: ${rem(24)};
  margin-bottom: ${props =>
    props.marginBottom ? `${rem(props.marginBottom)}` : rem(24)};
  padding-top: ${rem(16)};
  font-size: ${rem(16)};
  line-height: ${rem(27.8)};
  ${SharedDynamicPropsStyled}
  font-weight: 400;
  letter-spacing: -0.77px;
  border-top: ${rem(4)} solid var(--color-primary);

  @media ${media.stUp} {
    margin-bottom: ${props =>
      props.marginBottom ? `${rem(props.marginBottom)}` : rem(24)};
    padding-top: ${rem(16)};
    padding-bottom: ${rem(24)};
    padding-left: ${rem(24)};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    border-top: none;
    border-left: ${rem(4)} solid var(--color-primary);
  }

  &::before {
    content: "\\201C";
    display: block;
    margin-top: ${rem(24)};
    font-size: ${rem(72)};
    line-height: ${rem(32)};
    font-weight: 700;
    color: var(--color-primary);
  }

  p {
    margin-bottom: ${rem(16)};

    @media ${media.stUp} {
      margin-bottom: ${rem(24)};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Blockquote = styled.blockquote<TypographyTypeProps>`
  ${BlockquoteStyle}
`
Blockquote.displayName = "Blockquote"

export default Blockquote
