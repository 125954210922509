import React from "react"
import { IconProps } from "./iconTypes"

const IconCheckCircle = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 56 56"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M15.1001 27.4695L23.3379 35.7074L40.8869 18.1584C41.3152 17.7302 42.0092 17.7302 42.4376 18.1584C42.8658 18.5866 42.8658 19.2809 42.4376 19.7091L24.1133 38.0334C23.8992 38.2474 23.6185 38.3544 23.3379 38.3544C23.0573 38.3544 22.7767 38.2474 22.5626 38.0334L13.5494 29.0202C13.1213 28.5919 13.1213 27.8977 13.5494 27.4695C13.9776 27.0412 14.6719 27.0412 15.1001 27.4695Z"/>
    <path d="M11.501 11.501C15.908 7.09384 21.7675 4.66675 28.0001 4.66675C34.2326 4.66675 40.0922 7.09384 44.4993 11.501C48.9063 15.9081 51.3334 21.7675 51.3334 28.0001C51.3334 34.2326 48.9063 40.0922 44.4993 44.4993C40.0922 48.9063 34.2326 51.3334 28.0001 51.3334C21.7675 51.3334 15.9081 48.9063 11.501 44.4993C7.09384 40.0922 4.66675 34.2326 4.66675 28.0001C4.66675 21.7675 7.09384 15.908 11.501 11.501ZM12.9106 43.0895C16.9412 47.1202 22.3 49.3398 28.0001 49.3398C33.7001 49.3398 39.059 47.1202 43.0895 43.0895C47.1202 39.059 49.3398 33.7001 49.3398 28.0001C49.3398 22.3 47.1202 16.9412 43.0895 12.9106C39.059 8.88009 33.7001 6.66033 28.0001 6.66033C22.3 6.66033 16.9412 8.88009 12.9106 12.9106C8.88009 16.9412 6.66033 22.3 6.66033 28.0001C6.66033 33.7001 8.88009 39.059 12.9106 43.0895Z"/>
  </svg>
)

export default IconCheckCircle
