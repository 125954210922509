import React from "react"
import { IconProps } from "./iconTypes"

const IconOptions = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.25 3.75C10.281 3.75 9.45429 4.38319 9.14063 5.25H3V6.75H9.14063C9.45429 7.61681 10.281 8.25 11.25 8.25C12.219 8.25 13.0457 7.61681 13.3594 6.75H21V5.25H13.3594C13.0457 4.38319 12.219 3.75 11.25 3.75ZM11.25 5.25C11.6731 5.25 12 5.5769 12 6C12 6.4231 11.6731 6.75 11.25 6.75C10.8269 6.75 10.5 6.4231 10.5 6C10.5 5.5769 10.8269 5.25 11.25 5.25ZM16.5 9.75C15.531 9.75 14.7043 10.3832 14.3906 11.25H3V12.75H14.3906C14.7043 13.6168 15.531 14.25 16.5 14.25C17.469 14.25 18.2957 13.6168 18.6094 12.75H21V11.25H18.6094C18.2957 10.3832 17.469 9.75 16.5 9.75ZM16.5 11.25C16.9231 11.25 17.25 11.5769 17.25 12C17.25 12.4231 16.9231 12.75 16.5 12.75C16.0769 12.75 15.75 12.4231 15.75 12C15.75 11.5769 16.0769 11.25 16.5 11.25ZM8.25 15.75C7.28099 15.75 6.45429 16.3832 6.14063 17.25H3V18.75H6.14063C6.45429 19.6168 7.28099 20.25 8.25 20.25C9.21901 20.25 10.0457 19.6168 10.3594 18.75H21V17.25H10.3594C10.0457 16.3832 9.21901 15.75 8.25 15.75ZM8.25 17.25C8.6731 17.25 9 17.5769 9 18C9 18.4231 8.6731 18.75 8.25 18.75C7.8269 18.75 7.5 18.4231 7.5 18C7.5 17.5769 7.8269 17.25 8.25 17.25Z" />
  </svg>
)

export default IconOptions
