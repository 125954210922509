/*////////////////////////////////////////////
// GLOBAL COLOR PALETTE
////////////////////////////////////////////*/

/*////////////////////////////////////////////
// Non-semantic colors
// DON'T USE DIRECTLY !!!
////////////////////////////////////////////*/

const white = `#fff`
const black = `#1a1f27`
const smartRentPrimary = `#127D89`
const smartRentPrimaryLight = `#127D89`
const smartRentPrimaryOnLight = `#127D89`
const smartRentPrimaryOnDark = `#32BDCD`
const smartRentSecondary = `#ed7e66`
const green = `#4caf50`
const orange = `#fd7037`
const red = `#f55948`
const blue = `#5EA3EF`
const nuetral050 = `#f4f3f3`
const nuetral100 = `#f2eae7`
const nuetral200 = `#dbd1cd`

/*////////////////////////////////////////////
// Semantic colors
////////////////////////////////////////////*/

const gray = {
  white: white,
  gray050: `#f3f5f8`,
  gray075: `#f5f7fa`,
  gray100: `#e6e7eb`,
  gray200: `#cbd0db`,
  gray300: `#bec2cd`,
  gray400: `#9a9eaa`,
  gray500: `#757a87`,
  gray600: `#6a6e79`,
  gray700: `#3e4650`,
  gray800: `#2d3238`,
  gray900: `#1a1f27`,
  gray950: `#11151A`,
  black: black,
}

const brand = {
  primary: smartRentPrimary,
  primaryLight: smartRentPrimaryLight,
  primaryOnLight: smartRentPrimaryOnLight,
  primaryOnDark: smartRentPrimaryOnDark,
  secondary: smartRentSecondary,
  nuetral050: nuetral050,
  nuetral100: nuetral100,
  nuetral200: nuetral200,
}

const symbolic = {
  success: green,
  warning: orange,
  danger: red,
  knowledge: blue,
}

const colors = {
  gray,
  brand,
  symbolic,
}

export { colors as default }
