import React, { useState } from "react"
import styled from "styled-components"
import { rem } from "polished"

type NavPillItemId = string | number
type NavPillItemOnClick = (id: NavPillItemId) => void

interface NavPillProps {
  activeItemId: NavPillItemId
  className?: string
  onClick?: NavPillItemOnClick
  items: {
    id: NavPillItemId
    label: string
    onClick?: NavPillItemOnClick
  }[]
}

const NavPill = ({ items, activeItemId, className, onClick }: NavPillProps) => {
  const [activeItem, setActiveItem] = useState<NavPillItemId>(activeItemId)

  const handleGlobalOnClick = (
    id: NavPillItemId,
    itemOnClick?: NavPillItemOnClick
  ) => {
    setActiveItem(id)
    if (itemOnClick) {
      itemOnClick(id)
    } else if (onClick) {
      onClick(id)
    }
  }

  return (
    <NavPillStyled className={`${className || ""}`}>
      {items.map((item) => (
        <NavPillItemStyled active={activeItem === item.id} key={item.id}>
          <button
            className="nav-pill-item__button"
            type="button"
            onClick={() => handleGlobalOnClick(item.id, item.onClick)}
            role="link"
          >
            <span className="nav-pill-item__button__text">
              <span
                className="nav-pill-item__button__text__default"
                aria-hidden={activeItem === item.id}
              >
                {item.label}
              </span>
              <span
                className="nav-pill-item__button__text__active"
                aria-hidden={activeItem !== item.id}
              >
                {item.label}
              </span>
            </span>
          </button>
        </NavPillItemStyled>
      ))}
    </NavPillStyled>
  )
}

export default NavPill

const NavPillStyled = styled.ul`
  display: inline-flex;
  margin: 30px;
  align-items: center;
  border-radius: 12px;
  background: var(--color-gray100);
  
`

interface ItemSC {
  active: boolean
}

const NavPillItemStyled = styled.li<ItemSC>`
  position: relative;
  z-index: ${({ active }) => (active ? 1 : 0)};
  background: ${({ active }) =>
    active ? "var(--color-primary)" : "var(--color-gray100)"};
  border-radius: ${rem(12)};
  box-shadow: ${({ active }) => (active ? "var(--shadow)" : "none")};
  

  .nav-pill-item__button {
    min-width: ${rem(52)};
    height: ${rem(24)};
    line-height: ${rem(14)};
    font-size: ${rem(14)};
  }

  .nav-pill-item__button__text {
    position: relative;
    top: ${rem(-0.5)};
    display: inline-block;
    padding: ${rem(4)} ${rem(12)};
    color: ${({ active }) =>
      active ? "var(--color-text-subtract)" : "var(--color-gray300)"};
  }

  .nav-pill-item__button__text__default {
    opacity: ${({ active }) => (active ? 0 : 1)};
  }

  .nav-pill-item__button__text__active {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    width: 100%;
    font-weight: 600;
    opacity: ${({ active }) => (active ? 1 : 0)};
    transform: translateY(-50%);
  }
`
