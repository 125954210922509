const enUS = {
  app: {
    footer: {
      text: "Footer Text",
      contact: {
        title: "Get in Touch",
      },
      legal: {
        title: "Legal Stuff",
      },
      location: {
        title: "Where We Are",
      },
    },
    header: {
      brandMarkAlt: "SmartRent.com, LLC",
      toggle: "Toggle Header",
      navMain: {
        item1: "Item 1",
        item2: "Item 2",
        custom: {
          mobileSubMenuExit: "Go Back",
        },
      },
      navSub: {
        login: "Login",
        support: "Support",
      },
    },
  },
  components: {
    nav: {
      pagination: {
        nextLabel: "Next",
        previousLabel: "Previous",
      },
    },
    section: {
      newsletterCallout: {
        titleDefault:
          "Subscribe to our newsletter for the latest smart home innovations, data-driven analysis and expert guidance.",
        btnTextDefault: "Subscribe to newsletter",
        placeholderDefault: "Enter your email here",
      },
    },
  },
  pages: {
    index: {
      h1: "Style Guide Homepage",
      button: "Button",
    },
  },
}

export default enUS
