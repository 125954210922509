import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface AppFooterHardwareProps {
  className?: string
}

const AppFooterHardware = ({ className }: AppFooterHardwareProps) => {
  return (
    <AppFooterHardwareStyled className={`${className} footer-hardware`}>
      <Link className="footer-hardware__title" to="/hardware/">
        Hardware
      </Link>
      <div>
        <Link className="footer-hardware__link" to="/hardware/hubs/">
          Hubs
        </Link>
        <Link
          className="footer-hardware__link"
          to="/hardware/locks-lock-boxes/"
        >
          Locks & Lockboxes
        </Link>

        <Link className="footer-hardware__link" to="/hardware/sensors/">
          Sensors
        </Link>
        <Link className="footer-hardware__link" to="/hardware/thermostats/">
          Thermostats
        </Link>

        <Link className="footer-hardware__link" to="/hardware/intercoms/">
          Intercoms
        </Link>
        <Link className="footer-hardware__link" to="/hardware/smart-lighting/">
          Lighting
        </Link>
        <Link
          className="footer-hardware__link"
          to="/hardware/readers-panels-boards/"
        >
          Readers
        </Link>
      </div>
    </AppFooterHardwareStyled>
  )
}

export default AppFooterHardware

const AppFooterHardwareStyled = styled.div`
  padding: ${rem(16)} 0 0;

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    width: var(--st-column-default);
  }

  .footer-hardware__title {
    padding: ${rem(8)} 0;
    font-weight: bold;
    color: var(--color-gray200);
  }

  .footer-hardware__link {
    padding: ${rem(8)} 0;
    line-height: 150%;
    color: var(--color-gray200);
  }
`
