// This component shadows the AppHeader component in @sr/gatsby-theme-ui
// packages/gatsby-theme-ui/sr/components/App/Header/index.tsx
// See: https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/shadowing/
import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media } from "@sr/common"
import { graphql, useStaticQuery } from "gatsby"
import {
  Solutions,
  Hardware,
  WhySmartRent,
  Resources,
  Contact,
} from "./navData"

import AppHeaderDesktopContainer from "./Desktop/NavDesktop"
import AppHeaderMobileContainer from "./Mobile/NavMobile"

export const headerLargeBreakpoint = media.stMidUp
export const headerHeight = 64

const AppHeader = () => {
  const { sanityNavigationFeatured } = useStaticQuery(graphql`
    query NavigationFeaturedQuery {
      sanityNavigationFeatured {
        navigationSolutions {
          contentPrimary {
            title
            snippet
            linkType
            internalLink {
              ...SanityBuilderInternalLink
            }
            externalLink
            image {
              ...SanityImageWithPreview
            }
          }
          contentSecondary {
            title
            snippet
            linkType
            internalLink {
              ...SanityBuilderInternalLink
            }
            externalLink
            image {
              ...SanityImageWithPreview
            }
          }
          navCta {
            text
            internalLink {
              ...SanityBuilderInternalLink
            }
          }
        }
        navigationHardware {
          contentPrimary {
            title
            snippet
            linkType
            internalLink {
              ...SanityBuilderInternalLink
            }
            externalLink
            image {
              ...SanityImageWithPreview
            }
          }
          contentSecondary {
            title
            snippet
            linkType
            internalLink {
              ...SanityBuilderInternalLink
            }
            externalLink
            image {
              ...SanityImageWithPreview
            }
          }
          navCta {
            text
            internalLink {
              ...SanityBuilderInternalLink
            }
          }
        }
        navigationWhySmartrent {
          contentPrimary {
            title
            snippet
            linkType
            internalLink {
              ...SanityBuilderInternalLink
            }
            externalLink
            image {
              ...SanityImageWithPreview
            }
          }
          contentSecondary {
            title
            snippet
            linkType
            internalLink {
              ...SanityBuilderInternalLink
            }
            externalLink
            image {
              ...SanityImageWithPreview
            }
          }
          navCta {
            text
            internalLink {
              ...SanityBuilderInternalLink
            }
          }
        }
      }
    }
  `)
  const navigation = [
    {
      content: Solutions,
      featured: sanityNavigationFeatured?.navigationSolutions,
    },
    {
      content: Hardware,
      featured: sanityNavigationFeatured?.navigationHardware,
    },
    {
      content: WhySmartRent,
      featured: sanityNavigationFeatured?.navigationWhySmartrent,
    },
    { content: Resources, featured: false },
    { content: Contact, featured: false },
  ]

  return (
    <AppHeaderStyled role="banner" headerHeight={headerHeight}>
      <AppHeaderMobileContainer
        navigation={navigation}
        className="mobile"
        as="nav"
      />
      <AppHeaderDesktopContainer
        navigation={navigation}
        className="desktop"
        as="nav"
      />
    </AppHeaderStyled>
  )
}

export default AppHeader

interface HeaderSC {
  headerHeight: number
}

const AppHeaderStyled = styled.header<HeaderSC>`
  position: sticky;
  height: ${({ headerHeight }) => rem(headerHeight)};
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--color-content-bg);
  box-shadow: 0px 4px 8px 0px rgba(45, 50, 57, 0.2);

  @media ${media.stUp} {
    justify-content: flex-start;
  }

  .desktop {
    display: none;

    @media ${media.stUp} {
      display: flex;
    }
  }

  .mobile {
    display: flex;

    @media ${media.stUp} {
      display: none;
    }
  }
`
