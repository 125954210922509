import React from "react"
import { IconProps } from "./iconTypes"

const IconClipboardTask = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M13.3056 3.77102C13.3056 4.28853 12.8861 4.70805 12.3686 4.70805C11.851 4.70805 11.4315 4.28853 11.4315 3.77102C11.4315 3.25351 11.851 2.83398 12.3686 2.83398C12.8861 2.83398 13.3056 3.25351 13.3056 3.77102Z" />
    <path d="M9.98773 14.2583H7.12836C6.92944 14.2583 6.73868 14.3373 6.59803 14.478C6.45737 14.6186 6.37836 14.8094 6.37836 15.0083V17.8677C6.37836 18.0666 6.45737 18.2574 6.59803 18.398C6.73868 18.5387 6.92944 18.6177 7.12836 18.6177H9.98773C10.1866 18.6177 10.3774 18.5387 10.5181 18.398C10.6587 18.2574 10.7377 18.0666 10.7377 17.8677V15.0083C10.7377 14.8094 10.6587 14.6186 10.5181 14.478C10.3774 14.3373 10.1866 14.2583 9.98773 14.2583ZM9.61273 17.4927H7.50336V15.3833H9.61273V17.4927Z" />
    <path d="M12.3466 10.397H17.7841C17.9333 10.397 18.0763 10.4562 18.1818 10.5617C18.2873 10.6672 18.3466 10.8103 18.3466 10.9595C18.3466 11.1087 18.2873 11.2517 18.1818 11.3572C18.0763 11.4627 17.9333 11.522 17.7841 11.522H12.3466C12.1974 11.522 12.0543 11.4627 11.9488 11.3572C11.8434 11.2517 11.7841 11.1087 11.7841 10.9595C11.7841 10.8103 11.8434 10.6672 11.9488 10.5617C12.0543 10.4562 12.1974 10.397 12.3466 10.397Z" />
    <path d="M17.7841 15.8755H12.3466C12.1974 15.8755 12.0543 15.9348 11.9488 16.0402C11.8434 16.1457 11.7841 16.2888 11.7841 16.438C11.7841 16.5872 11.8434 16.7302 11.9488 16.8357C12.0543 16.9412 12.1974 17.0005 12.3466 17.0005H17.7841C17.9333 17.0005 18.0763 16.9412 18.1818 16.8357C18.2873 16.7302 18.3466 16.5872 18.3466 16.438C18.3466 16.2888 18.2873 16.1457 18.1818 16.0402C18.0763 15.9348 17.9333 15.8755 17.7841 15.8755Z" />
    <path d="M9.95786 12.9578H7.11582C6.70426 12.9578 6.36863 12.6353 6.36957 12.2369V9.50031C6.36957 9.10375 6.70426 8.78125 7.11582 8.78125H9.95786C10.3694 8.78125 10.7041 9.10375 10.7041 9.50031V12.2387C10.7041 12.6353 10.3694 12.9578 9.95786 12.9578ZM7.51999 10.5946C7.41512 10.5781 7.30802 10.604 7.22223 10.6666C7.13653 10.7292 7.07921 10.8232 7.06286 10.9281C7.04651 11.033 7.07247 11.14 7.13504 11.2258L7.65348 11.9364C7.68639 11.9817 7.72842 12.0195 7.77686 12.0475C7.82531 12.0755 7.87909 12.0931 7.93473 12.099C7.94879 12.1014 7.96333 12.1014 7.97739 12.1014C8.0756 12.1013 8.17035 12.0651 8.24364 11.9997L9.90489 10.518C9.98024 10.4462 10.0247 10.3481 10.029 10.2441C10.0333 10.1402 9.997 10.0387 9.9278 9.96103C9.85861 9.88337 9.76193 9.83566 9.6582 9.82797C9.55447 9.82029 9.45181 9.85323 9.37192 9.91984L8.03926 11.1081L7.78145 10.7542C7.71891 10.6684 7.62486 10.611 7.51999 10.5946Z" />
    <path d="M15.025 2.86938H19.0028C19.4625 2.86937 19.9033 3.05185 20.2285 3.3767C20.5537 3.70155 20.7367 4.14222 20.7372 4.60188V20.185C20.7372 20.645 20.5545 21.0861 20.2292 21.4114C19.9039 21.7367 19.4628 21.9194 19.0028 21.9194H5.73437C5.27439 21.9194 4.83324 21.7367 4.50799 21.4114C4.18273 21.0861 4 20.645 4 20.185V4.60375C4 4.14377 4.18273 3.70262 4.50799 3.37736C4.83324 3.0521 5.27439 2.86938 5.73437 2.86938H9.74219C9.86687 2.56563 10.5953 1 12.3634 1H12.3831C14.3612 1.01266 14.9912 2.77094 15.0175 2.84594L15.025 2.86938ZM10.4046 3.93723C10.3135 3.99385 10.2085 4.02402 10.1012 4.02438H7.92953V6.40234H16.8077V4.02438H14.5858C14.4598 4.02433 14.3372 3.98308 14.2369 3.90691C14.1365 3.83075 14.0638 3.72384 14.0298 3.6025L13.9206 3.21344C13.8737 3.09672 13.472 2.16203 12.3737 2.155C11.2441 2.14703 10.8105 3.3025 10.8062 3.31422C10.7987 3.33438 10.7903 3.35359 10.7809 3.37281L10.6169 3.70375C10.5691 3.79976 10.4956 3.8806 10.4046 3.93723ZM19.411 20.5913C19.5193 20.4831 19.5802 20.3363 19.5803 20.1831L19.5798 4.60188C19.5797 4.44875 19.5188 4.30193 19.4106 4.19366C19.3023 4.08538 19.1555 4.0245 19.0023 4.02438H17.9322V6.77641C17.9322 6.97532 17.8532 7.16609 17.7125 7.30674C17.5719 7.44739 17.3811 7.52641 17.1822 7.52641H7.55453C7.35562 7.52641 7.16485 7.44739 7.0242 7.30674C6.88355 7.16609 6.80453 6.97532 6.80453 6.77641V4.02438H5.73437C5.58129 4.02462 5.43454 4.08555 5.32629 4.1938C5.21805 4.30204 5.15712 4.44879 5.15687 4.60188V20.1831C5.157 20.3363 5.21788 20.4831 5.32616 20.5913C5.43443 20.6996 5.58125 20.7605 5.73437 20.7606H19.0028C19.1559 20.7605 19.3027 20.6996 19.411 20.5913Z" />
  </svg>
)

export default IconClipboardTask
