import React from "react"
import { IconProps } from "./iconTypes"

const IconSmrtInspection = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <g clip-path="url(#clip0_2403_2869)">
      <path d="M24 0H0V24H24V0Z" fill="#231F20" />
      <path
        d="M19.106 9.94569C19.106 13.018 16.6154 15.5086 13.5431 15.5086C10.4708 15.5086 7.98022 13.018 7.98022 9.94569C7.98022 6.87339 10.4708 4.38281 13.5431 4.38281C16.6154 4.38281 19.106 6.87339 19.106 9.94569ZM9.9593 9.94569C9.9593 11.925 11.5638 13.5295 13.5431 13.5295C15.5224 13.5295 17.1269 11.925 17.1269 9.94569C17.1269 7.96641 15.5224 6.36189 13.5431 6.36189C11.5638 6.36189 9.9593 7.96641 9.9593 9.94569Z"
        fill="white"
      />
      <path
        d="M5.2221 19.3018C4.78872 18.9077 4.75682 18.2369 5.1509 17.8035L9.3192 13.2192C9.71326 12.7858 10.3841 12.7539 10.8175 13.148L10.8609 13.1875C11.2943 13.5816 11.3262 14.2524 10.9321 14.6858L6.76382 19.2701C6.36976 19.7035 5.69896 19.7354 5.26556 19.3413L5.2221 19.3018Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2403_2869">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconSmrtInspection
