import React, { ReactNode, useState, useRef, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { media } from "@sr/common"
import { rem } from "polished"
import { IconArrowLeft, IconArrowRight } from "../Icon"

export interface CarouselProps {
  autoRotate?: boolean
  buttons?: boolean
  children: ReactNode[]
  getActiveIndex: (num: number) => any
  height: [number, number] | [number]
  marginBottom: [number, number] | [number]
  onNavigate?: Function
  isMobile: boolean
  // allows the parent component to set to carousel if the user has interacted with an item inside the carousel
  userHasInteractedParent: boolean
  /** 
  * const animations = [
    * { left: "-50vw", scale: 0.8, zIndex: 2 },
    * { left: "0", scale: 1, zIndex: 4 },
    * { left: "50vw", scale: 0.8, zIndex: 3 },
  ]
 */
  animations: Array<any>
  /**
   * const getActiveIndex = (currentActive: number) => {
   *  setActiveIndex(currentActive)}
   */
  getActiveCarouselPosition: (currentCarousel: Array<any>) => Array<any>
}

const Carousel = ({
  animations,
  autoRotate = true,
  buttons = false,
  children,
  getActiveIndex,
  height,
  marginBottom,
  onNavigate,
  isMobile,
  userHasInteractedParent,
}: CarouselProps) => {
  //Shows whether or not the Carousel has been in view yet, to allow for the main element to stay in active until it has been viewed
  const [hasBeenInView, setHasBeenInView] = useState(false)
  const [timeoutId, setTimeoutId] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState(
    children && Math.floor(children.length / 2)
  )
  const [navigatedAnimations, setNavigatedAnimations] = useState(animations)
  //Sets whether the user has interacted with the carousel yet to allow for auto play until the user interacts.
  const [userHasInteracted, setUserHasInteracted] = useState(
    userHasInteractedParent
  )
  const carouselRef = useRef(null)
  // Sets minimum swipe amount to make sure that the user is trying to actually swipe.
  const swipeConfidenceThreshold = 2000
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
  }

  //This useEffect is for the viewport to set when the carousel has been in view.
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasBeenInView(true)
        }
      },
      { threshold: 0.2 }
    )

    if (carouselRef.current) {
      // Add a guard clause to check if carouselRef.current is truthy
      observer.observe(carouselRef.current)
    }

    return () => observer.disconnect()
  }, [carouselRef])

  useEffect(() => {
    setNavigatedAnimations(animations)
  }, [isMobile])

  useEffect(() => {
    const delay = 8000

    const timeoutId = setTimeout(() => {
      if (hasBeenInView && !userHasInteracted && autoRotate) {
        Navigate(1, false)
      }
    }, delay)

    setTimeoutId(timeoutId)

    if (userHasInteracted) {
      clearTimeout(timeoutId)
    }

    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId)
    }
  }, [navigatedAnimations, hasBeenInView])

  const moveCarouselRight = (arr: Array<any>) => {
    const tempAnimationsArray = [...arr]
    const lastElement = tempAnimationsArray.pop()
    tempAnimationsArray.unshift(lastElement)
    return tempAnimationsArray
  }

  const moveCarouselLeft = (arr: Array<any>) => {
    const tempAnimationsArray = [...arr]
    const firstElement = tempAnimationsArray.shift()
    tempAnimationsArray.push(firstElement)
    return tempAnimationsArray
  }

  const moveActiveIndexLeft = (num: number) => {
    if (num + 1 > children?.length - 1) {
      return 0
    } else return num + 1
  }
  const moveActiveIndexRight = (num: number) => {
    if (num - 1 < 0) {
      return children?.length - 1
    } else return num - 1
  }

  const Navigate = (newDirection: number, userInteracted: boolean) => {
    if (userInteracted) {
      setUserHasInteracted(true)
    }
    const AnimationsMovedLeft = moveCarouselLeft(navigatedAnimations)
    const AnimationsMovedRight = moveCarouselRight(navigatedAnimations)
    const activeIndexMovedLeft = moveActiveIndexRight(activeIndex)
    const activeIndexMovedRight = moveActiveIndexLeft(activeIndex)
    if (newDirection < 0) {
      setNavigatedAnimations(AnimationsMovedLeft)
      setActiveIndex(activeIndexMovedLeft)
      getActiveIndex(activeIndexMovedLeft)
      onNavigate && onNavigate()
    } else if (newDirection > 0) {
      setNavigatedAnimations(AnimationsMovedRight)
      setActiveIndex(activeIndexMovedRight)
      getActiveIndex(activeIndexMovedRight)
      onNavigate && onNavigate()
    }
  }

  return (
    <CarouselStyled
      ref={carouselRef}
      height={height}
      marginBottom={marginBottom}
    >
      <AnimatePresence initial={false}>
        {React.Children.map(children, (child, index) => {
          return (
            <motion.div
              className="carousel-child"
              key={index}
              initial={animations[index]}
              animate={navigatedAnimations[index]}
              transition={{
                x: { type: "spring", stiffness: 500, damping: 50 },
                duration: 0.3,
              }}
              // disable drag functionality if not on mobile
              drag={"x"}
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(_e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  Navigate(1, true)
                } else if (swipe > swipeConfidenceThreshold) {
                  Navigate(-1, true)
                }
              }}
              onClick={() => {
                setUserHasInteracted(true)
                clearTimeout(timeoutId)
              }}
            >
              {child}
            </motion.div>
          )
        })}
      </AnimatePresence>
      {buttons && (
        <div className="carousel__navigation-button__container">
          <div
            className="carousel__navigation-button"
            onClick={() => Navigate(-1, true)}
          >
            <IconArrowLeft />
          </div>
          <div
            className="carousel__navigation-button"
            onClick={() => Navigate(1, true)}
          >
            <IconArrowRight />
          </div>
        </div>
      )}
    </CarouselStyled>
  )
}

export default Carousel

interface CarouselSC {
  height: [number, number] | [number]
  marginBottom: [number, number] | [number]
}

const CarouselStyled = styled.div<CarouselSC>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ height }) => `${height[0]}px`};
  width: 100%;
  margin-bottom: ${({ marginBottom }) => `${marginBottom[0]}px`};

  .carousel-child {
    position: absolute;
  }

  .carousel__navigation-button__container {
    display: flex;
    justify-content: center;
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
    gap: ${rem(32)};
  }

  .carousel__navigation-button {
    height: ${rem(40)};
    width: ${rem(40)};
    border-radius: 50%;
    background: white;
    box-shadow: 0px 4px 8px rgba(45, 50, 57, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: ${rem(26)};
      width: ${rem(26)};
    }
  }

  @media ${media.stUp} {
    height: ${({ height }) => `${height[1]}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom[1]}px`};
  }
`
