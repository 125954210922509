import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

export interface CaptionTypographyTypeProps extends TypographyTypeProps {
  size?: "large"
  upper?: Boolean
}

export const CaptionDefaultTypeStyle = css<CaptionTypographyTypeProps>`
  font-size: ${rem(12)};
  line-height: ${rem(20)};
`

export const CaptionLargeTypeStyle = css<CaptionTypographyTypeProps>`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
`

export const CaptionStyle = css<CaptionTypographyTypeProps>`
  ${props =>
    props.size === "large" ? CaptionLargeTypeStyle : CaptionDefaultTypeStyle};
  text-transform: ${props => (props.upper ? "uppercase" : "none")};
  ${SharedDynamicPropsStyled}
`

const Caption = styled.strong`
  ${CaptionStyle}
`
Caption.displayName = "Caption"

export default Caption
