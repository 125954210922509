import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
import { rem } from "polished"
const merge = require("deepmerge")

import { media } from "@sr/common"

import {
  BlockContentSerializerType,
  blockSerializers,
} from "./DefaultSerializers"

export interface BlockContentSerializerShape {
  types?: any
  marks?: any
}

export interface BlockContentInlineProps {
  blocks?: any[]
  className?: string
  serializers?: BlockContentSerializerType
}

/**
 * Component for rendering blocks Block Content with support for
 * - Major block level elements
 * - Annotations and marks
 * - Links
 * - Provides basic styling for child typography components
 */
const BlockContentBlock = ({
  blocks,
  className,
  serializers = {},
}: BlockContentInlineProps) => {
  if (!blocks) return null

  const mergedSerializers = merge(blockSerializers, serializers)

  return (
    <BlockContentBlockStyled className={`${className} block-content`}>
      <BlockContent blocks={blocks} serializers={mergedSerializers} />
    </BlockContentBlockStyled>
  )
}

export default BlockContentBlock

const BlockContentBlockStyled = styled.div`
  .title,
  .callout,
  .body-text {
    margin-bottom: ${rem(24)};
  }

  ol,
  ul {
    margin: ${rem(24)} 0;
    padding-left: ${rem(24)};

    p {
      padding-left: ${rem(8)};

      @media ${media.stUp} {
        padding-left: ${rem(24)};
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: ${rem(16)};
  }

  strong,
  b {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }
`
