import React from "react"
import { IconProps } from "./iconTypes"

const IconOnline = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M1.03226 5.58325C1.10733 5.78545 1.30032 5.91956 1.516 5.91942C1.6394 5.91926 1.75865 5.87485 1.8521 5.79426C7.65423 0.802481 16.231 0.791929 22.0454 5.76942C22.2617 5.95465 22.5872 5.92946 22.7724 5.71317C22.9576 5.49687 22.9325 5.17137 22.7162 4.98614C16.515 -0.322709 7.36743 -0.31136 1.17944 5.01285C1.01591 5.15349 0.957194 5.38105 1.03226 5.58325Z" />
    <path d="M2.49672 8.02988C2.57191 8.23212 2.76507 8.36617 2.98085 8.36582C3.10483 8.36625 3.22481 8.32198 3.31881 8.24114C8.27889 3.97298 15.6117 3.96382 20.5824 8.21957C20.7223 8.3394 20.9155 8.37547 21.0893 8.31421C21.263 8.25295 21.3909 8.10366 21.4247 7.92257C21.4585 7.74148 21.3931 7.55612 21.2532 7.43629C15.8951 2.8485 7.99055 2.85825 2.64381 7.45926C2.48022 7.59995 2.42153 7.82763 2.49672 8.02988Z" />
    <path d="M4.01914 10.455C4.0942 10.6572 4.28719 10.7913 4.50288 10.7911C4.62605 10.791 4.74511 10.7468 4.8385 10.6664C8.92376 7.15081 14.9635 7.14324 19.0576 10.6486C19.2739 10.834 19.5995 10.8089 19.7848 10.5926C19.9702 10.3763 19.9451 10.0507 19.7288 9.86535C15.2479 6.02907 8.63777 6.03723 4.16631 9.88457C4.00279 10.0252 3.94407 10.2528 4.01914 10.455Z" />
    <path d="M18 17C18 20.3137 15.3137 23 12 23C8.68629 23 6 20.3137 6 17C6 13.6863 8.68629 11 12 11C15.3137 11 18 13.6863 18 17ZM16.8 17C16.8 14.349 14.651 12.2 12 12.2C9.34903 12.2 7.2 14.349 7.2 17C7.2 19.651 9.34903 21.8 12 21.8C14.651 21.8 16.8 19.651 16.8 17Z" />
  </svg>
)

export default IconOnline
