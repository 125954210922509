import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import BlockContentBlock from "../../BlockContent/Block"
import ButtonGroup, { ButtonGroupInstance } from "../../Button/Group"
import AppLayoutWidth from "../../App/Layout/Width"
import { SanityImg, WidthConstraintType } from "../../../types/index"
import { SemiBdTitle2 } from "../../Typography/index"
import MediaVideoPlayer from "../../Media/VideoPlayer"
export interface SectionSplitContentVideoProps {
  /** Sets BG color for selected Color Theme */
  bgColor?: string
  /** Array of buttons to be rendered as CTAs */
  buttons?: ButtonGroupInstance[]
  children?: ReactNode
  className?: string
  /** Sanity block content to be rendered through serialization */
  blockContent?: any[]
  /** Select color theme for section */
  colorTheme?: string
  /** Constrain width of section content columns
   * @default "max"
   */
  contentWidth?: WidthConstraintType
  /** Content to be rendered as top-level heading */
  header?: string | ReactNode
  /** Provide a video url to render in media container */
  mediaVideo?: string
  mediaVideoSource?: "youtube" | "cloudinary"
  /** Fluid Sanity Image object with 16:9 aspect ratio */
  mediaVideoPoster?: SanityImg
  /** Switch section on */
  on?: boolean
}

/**
 * Split content video section component
 * - Renders split sections for video and content
 */
const SectionSplitContentVideo = ({
  buttons,
  blockContent,
  colorTheme = "white",
  contentWidth,
  header,
  mediaVideo,
  mediaVideoPoster,
  mediaVideoSource,
}: SectionSplitContentVideoProps) => {
  const headerColorTheme =
    colorTheme === "primary" || colorTheme === "gray900"
      ? "var(--color-white)"
      : "var(--color-gray900)"

  const calloutColorTheme =
    colorTheme === "primary" ? "var(--color-white)" : "var(--color-primary)"

  const bodyColorTheme =
    colorTheme === "primary" || colorTheme === "gray900"
      ? "var(--color-white)"
      : "var(--color-gray900)"

  const getColorTheme = (theme: string) => {
    switch (theme) {
      case "gray050":
        return "--color-gray050"
      case "gray900":
        return "--color-gray900"
      case "primary":
        return "--color-primary"
      default:
        return "--color-white"
    }
  }
  return (
    <SectionSplitContentVideoStyled
      headerColorTheme={headerColorTheme}
      calloutColorTheme={calloutColorTheme}
      bodyColorTheme={bodyColorTheme}
      style={{
        backgroundColor: `var(${getColorTheme(colorTheme)})`,
      }}
    >
      <AppLayoutWidth
        className="split-content-video__inner"
        constraint={contentWidth}
      >
        <div className="split-content-video__columns">
          <div className="split-content-video__content">
            {header && (
              <SemiBdTitle2 className="split-content-video__header">
                {header}
              </SemiBdTitle2>
            )}
            {blockContent && (
              <BlockContentBlock
                className="split-content-video__body"
                blocks={blockContent}
              />
            )}
            {buttons && buttons.length > 0 && (
              <ButtonGroup
                buttons={buttons}
                className="split-content-video__buttons"
              />
            )}
          </div>
          <div className="split-content-video__video">
            {mediaVideo && mediaVideoSource === "youtube" && (
              <MediaVideoPlayer
                url={mediaVideo}
                type={mediaVideoSource}
                placeholder={mediaVideoPoster}
              />
            )}
          </div>
        </div>
      </AppLayoutWidth>
    </SectionSplitContentVideoStyled>
  )
}
export default SectionSplitContentVideo

interface SectionSC {
  headerColorTheme: string
  calloutColorTheme: string
  bodyColorTheme: string
}

const SectionSplitContentVideoStyled = styled.section<SectionSC>`
  position: relative;
  z-index: 1;
  display: flex;
  text-align: left;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  padding: ${rem(80)} 0;

  .split-content-video__header {
    color: ${({ headerColorTheme }) => headerColorTheme};
  }

  .callout {
    color: ${({ calloutColorTheme }) => calloutColorTheme};
  }

  .split-content-video__body {
    color: ${({ bodyColorTheme }) => bodyColorTheme};
  }

  .split-content-video__columns {
    @media ${media.stUp} {
      gap: 8%;
      display: flex;
      align-items: center;
    }
  }

  .split-content-video__content {
    padding: 0 0 ${rem(80)} 0;
    @media ${media.stUp} {
      width: 50%;
      padding: 0;
    }
  }

  .split-content-video__video {
    @media ${media.stUp} {
      width: 50%;
    }
  }
`
