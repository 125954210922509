import React from "react"
import { IconProps } from "./iconTypes"

const IconShield = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.9999 22.988C11.9383 22.988 11.8772 22.977 11.8194 22.9556C7.84676 21.4687 5.32442 19.0505 4.10567 15.5559C3.05286 12.5372 3.11426 9.23061 3.1738 6.0328V6.02249C3.1738 5.59358 3.1738 5.15014 3.19676 4.72264C3.20769 4.52035 3.33606 4.3432 3.52489 4.26983L11.8147 1.03827C11.9352 0.990784 12.0692 0.990784 12.1897 1.03827L20.4796 4.26983C20.6775 4.34709 20.8078 4.53784 20.8077 4.7503C20.8077 5.15811 20.8077 5.57952 20.8293 6.00045V6.07827C20.8808 9.26905 20.9343 12.5681 19.8782 15.5775C18.6561 19.0608 16.139 21.4739 12.179 22.9551C12.1217 22.9767 12.0611 22.9878 11.9999 22.988ZM4.21348 5.10936C4.20551 5.40608 4.20551 5.71077 4.20551 6.02811C4.20575 6.03123 4.20575 6.03437 4.20551 6.03749V6.05295C4.14786 9.15983 4.08786 12.3726 5.07973 15.217C5.60848 16.7325 6.38098 17.992 7.44223 19.0678C8.59958 20.2397 10.1319 21.2001 11.999 21.9215C13.8604 21.202 15.389 20.2453 16.5458 19.0758C17.6057 18.0033 18.3786 16.7475 18.9083 15.2372C19.9035 12.4012 19.8515 9.19592 19.799 6.09655V6.04592C19.783 5.7253 19.7783 5.41124 19.7769 5.1042L12.0022 2.07186L4.21348 5.10936ZM10.1479 15.3511C10.0113 15.351 9.88026 15.2967 9.78364 15.2001L7.14973 12.5662C6.95295 12.3641 6.95518 12.0413 7.15474 11.8419C7.35429 11.6424 7.67707 11.6404 7.87911 11.8373L10.1479 14.1065L15.2502 9.0042C15.3805 8.87391 15.5704 8.82303 15.7484 8.87072C15.9264 8.91841 16.0654 9.05743 16.1131 9.23541C16.1608 9.41339 16.1099 9.60329 15.9796 9.73358L10.5125 15.2001C10.4158 15.2968 10.2846 15.3511 10.1479 15.3511Z" />
  </svg>
)

export default IconShield
