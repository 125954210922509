import React from "react"
import { IconProps } from "./iconTypes"

const IconCheck = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M8.754 19a.468.468 0 01-.334-.141l-6.277-6.406a.488.488 0 01-.127-.468.477.477 0 01.336-.343.466.466 0 01.459.13l5.943 6.065L21.181 5.154a.466.466 0 01.463-.14.477.477 0 01.341.35.489.489 0 01-.136.472L9.087 18.859a.464.464 0 01-.333.141z" />
  </svg>
)

export default IconCheck
