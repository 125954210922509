import React, { ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import Card from "../../Card"
import AppLayoutWidth from "../../App/Layout/Width"
import BlockContentInline from "../../BlockContent/Inline"
import { RegBody, SemiBdTitle3 } from "../../Typography/index"

declare global {
  interface Window {
    hbspt: any
  }
}

export interface SectionHubspotFormProps {
  bgColor?: string
  className?: string
  content?: any[]
  header?: string | ReactNode
  textColor?: string
  hubspotFormId?: string
}

const SectionHubspotForm = ({
  bgColor = "var(--color-interactive-bg)",
  className,
  content,
  header,
  textColor,
  hubspotFormId,
}: SectionHubspotFormProps) => {
  const [isFormVisible, setIsFormVisible] = useState(true)

  useEffect(() => {
    // hubspot forms script
    const script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    const jquery = document.createElement("script")
    jquery.src = "https://code.jquery.com/jquery-3.6.0.slim.min.js"
    document.body.appendChild(jquery)

    const scriptInit = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "20434445",
          formId: hubspotFormId,
          target: "#hubspotForm__form",
          onFormSubmitted: function () {
            setIsFormVisible(false)
            let boundingClientRect = document
              .querySelector(".hubspot-form")
              ?.getBoundingClientRect()
            const y =
              boundingClientRect?.top && boundingClientRect.top + window.scrollY
            window.scroll({
              top: y,
              behavior: "smooth",
            })
          },
        })
      }
    }

    script.addEventListener("load", scriptInit)

    return () => {
      script.removeEventListener("load", scriptInit)
    }
  }, [])

  return (
    <SectionHubspotFormStyled
      className={`hubspot-form ${className || ""}`}
      style={{ color: textColor, backgroundColor: bgColor }}
    >
      <AppLayoutWidth className="hubspot-form__intro" constraint="readable">
        {header && (
          <SemiBdTitle3 className="hubspot-form__intro__header">
            {header}
          </SemiBdTitle3>
        )}
        {content && <BlockContentInline blocks={content} />}
      </AppLayoutWidth>
      <AppLayoutWidth className="hubspot-form__content" constraint="max">
        <Card radius={16} paddings={[12, 48]}>
          {isFormVisible ? (
            <div className="hubspot-form__form" id="hubspotForm__form" />
          ) : (
            <div className="hubspot-form__thank-you-container">
              <SemiBdTitle3 className="hubspot-form__thank-you-title">
                Thank you for submitting the form.
              </SemiBdTitle3>
              <RegBody>Someone will reach out shortly.</RegBody>
            </div>
          )}
        </Card>
      </AppLayoutWidth>
    </SectionHubspotFormStyled>
  )
}

export default SectionHubspotForm

const SectionHubspotFormStyled = styled.section`
  padding: ${rem(72)} 0;

  @media ${media.stUp} {
    padding: 10vh 0;
  }

  .hubspot-form__intro {
    margin-bottom: ${rem(48)};
    text-align: center;
  }

  .hubspot-form__intro__header {
    margin-bottom: ${rem(24)};
  }

  .hubspot-form__content {
    width: 90%;
    @media ${media.stUp} {
      width: 60%;
    }

    .hubspot-form__thank-you-container {
      color: var(--color-gray800);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: ${rem(16)};

      .hs-form-iframe {
        display: none !important;
      }
      .hubspot-form__thank-you-title {
        padding-bottom: ${rem(16)};
      }
    }
  }
`
