import React, { ReactNode, useState } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import AppLayoutWidth from "../../App/Layout/Width"
import BlockContentBlock from "../../BlockContent/Block"
import { IconChevronDown, iconMapper } from "../../Icon/index"
import { IconName } from "../../Icon/iconTypes"
import { BdTitle3 } from "../../Typography/index"

export interface SectionExpanderProps {
  className?: string
  content?: any[]
  header?: string | ReactNode
  icon?: string
  intro?: any[]
}

const SectionExpander = ({
  className,
  content,
  header,
  icon,
  intro,
}: SectionExpanderProps) => {
  const isExpandable = typeof content !== "undefined"
  const [active, setActive] = useState<boolean>(false)

  return (
    <SectionExpanderStyled className={className}>
      <header
        className="expander__header"
        role="button"
        aria-pressed={false}
        tabIndex={0}
        onClick={() => setActive(!active)}
      >
        <AppLayoutWidth className="header-content" constraint="md">
          <div className="feature-icon">
            {icon &&
              iconMapper(icon as IconName, { className: "feature-icon__icon" })}
          </div>
          <AppLayoutWidth
            className="header-content__content"
            center={false}
            constraint="readableLg"
            paddings={[0, 0]}
          >
            <BdTitle3 as="h2" className="title">
              {header}
            </BdTitle3>
            {intro && (
              <div className="intro">
                <BlockContentBlock blocks={intro} />
              </div>
            )}
          </AppLayoutWidth>
          {isExpandable && (
            <motion.div
              className="expandable"
              initial={{ rotate: active ? -180 : 0 }}
              animate={{ rotate: active ? -180 : 0 }}
            >
              <IconChevronDown />
            </motion.div>
          )}
        </AppLayoutWidth>
      </header>
      {isExpandable && (
        <motion.div
          className="content"
          initial={{ height: active ? "auto" : 0 }}
          animate={{ height: active ? "auto" : 0 }}
          transition={{ duration: 0.4 }}
        >
          <AppLayoutWidth className="content__layout" constraint="md">
            <BlockContentBlock blocks={content} />
          </AppLayoutWidth>
        </motion.div>
      )}
    </SectionExpanderStyled>
  )
}

export default SectionExpander

const SectionExpanderStyled = styled.section`
  border-bottom: 1px solid var(--color-border-light);

  .expander__header {
    padding: ${rem(56)} 0;
    transition: background-color 400ms ease;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--color-interactive-bg);
      outline: none;
    }
  }

  .header-content {
    position: relative;

    @media ${media.stUp} {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .title {
    margin-bottom: ${rem(16)};
  }

  .feature-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${rem(136)};
    height: ${rem(136)};
    margin-bottom: ${rem(24)};
    background: var(--color-primary);
    border-radius: ${rem(16)};

    @media ${media.stUp} {
      flex: 0 0 ${rem(136)};
      margin-right: ${rem(48)};
      margin-bottom: 0;
    }
  }

  .feature-icon__icon {
    width: ${rem(72)};
    height: ${rem(72)};
    fill: var(--color-white);
  }

  .header-content__content {
    flex: 1 0 100%;
    width: 100%;

    @media ${media.stUp} {
      margin-right: ${rem(32)};
    }
  }

  .expandable {
    position: absolute;
    top: 0;
    right: ${rem(16)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${rem(72)};
    height: ${rem(72)};
    border: 1px solid var(--color-primary);
    border-radius: 50%;

    @media ${media.stUp} {
      position: static;
      top: auto;
      right: auto;
      flex: 0 0 ${rem(72)};
      margin-left: auto;
    }

    .icon {
      width: ${rem(32)};
      height: ${rem(32)};
      fill: var(--color-primary);
    }
  }

  .content {
    overflow: hidden;
  }

  .content__layout {
    padding-top: ${rem(48)};
    padding-bottom: ${rem(48)};
  }
`
