import React from "react"
import { IconProps } from "./iconTypes"

const WireConnected = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path
      d="M18.476 4.456a.524.524 0 00-.523.524v13.777c0 .196-.037.392-.109.575-.15.38-.81 1.621-3.022 1.621-1.605 0-2.437-1.038-2.675-1.652a1.556 1.556 0 01-.106-.567v-2.395a2.01 2.01 0 001.753-1.991V9.546a2.011 2.011 0 00-1.753-1.99v-2.29a2.601 2.601 0 00-.174-.946C11.449 3.25 10.258 2 8.271 2c-1.132 0-2.097.278-2.868.825-.792.562-1.118 1.234-1.221 1.497-.121.306-.183.631-.182.96v13.59a.524.524 0 101.047 0V5.28c0-.196.037-.391.109-.575.054-.137.275-.615.853-1.025.59-.42 1.352-.632 2.262-.632.721 0 1.34.205 1.838.61.343.277.611.635.78 1.042.07.18.106.373.105.567v2.288a2.01 2.01 0 00-1.73 1.989v4.801a2.011 2.011 0 001.73 1.989v2.4c0 .324.06.645.177.946.335.862 1.48 2.32 3.651 2.32 2.874 0 3.786-1.75 3.997-2.286.12-.305.182-.631.181-.96V4.98a.524.524 0 00-.524-.524zm-8.175 9.892V9.546a.973.973 0 01.972-.971h.514a.972.972 0 01.971.971v4.802a.973.973 0 01-.97.972h-.515a.973.973 0 01-.972-.972z"
      fill={color}
    />
  </svg>
)

export default WireConnected
