/**
 * Utility to convert alignment strings to flexbox alignment property
 * @param {string} - "start" | "center" | "end" | string
 * @return {string}
 */

const getFlexBoxAlignmentString = (string: string): string => {
  let property = "center"

  if (string === "start") {
    return "flex-start"
  }

  if (string === "end") {
    return "flex-end"
  }

  return property
}

export default getFlexBoxAlignmentString
