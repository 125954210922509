import React, { ReactNode } from "react"
import styled from "styled-components"

export interface PageProps {
  /** Render the wrapping element */
  as?: "article" | "section" | "div"
  className?: string
  children?: ReactNode
}

/**
 * Wraps page sections and components and allows
 * for top level control of page state:
 * - Setting header overlay on or off
 */
const Page = ({ as = "article", className, children }: PageProps) => {
  return (
    <PageStyled as={as} className={`${className} page`}>
      {children}
    </PageStyled>
  )
}

export default Page

const PageStyled = styled.article``
