import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

export const Title1Style = css<TypographyTypeProps>`
  margin-bottom: ${props =>
    props.marginBottom ? `${rem(props.marginBottom)}` : `auto`};
  font-size: ${rem(38)};
  line-height: ${rem(51.5)};
  ${SharedDynamicPropsStyled}
  font-weight: 700;
  letter-spacing: -0.77px;

  @media ${media.stUp} {
    font-size: ${rem(72)};
    line-height: ${rem(88)};
  }
`

const Title1 = styled.h1<TypographyTypeProps>`
  ${Title1Style}
`
Title1.displayName = "Title1"

export default Title1
