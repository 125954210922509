import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

export const Title3Style = css<TypographyTypeProps>`
  font-size: ${rem(24)};
  line-height: ${rem(36.5)};
  ${SharedDynamicPropsStyled}

  @media ${media.stUp} {
    font-size: ${rem(36)};
    line-height: ${rem(52)};
  }
`

const Title3 = styled.h3<TypographyTypeProps>`
  ${Title3Style}
`
Title3.displayName = "Title3"

export default Title3
