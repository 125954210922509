import React from "react"
import { IconProps } from "./iconTypes"

const IconWifi = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M1.923 9.349a.591.591 0 01-.41-1.019A15.25 15.25 0 0112.19 4c3.984 0 7.756 1.523 10.628 4.28a.594.594 0 11-.825.856 14.086 14.086 0 00-9.808-3.95 14.079 14.079 0 00-9.852 3.995.56.56 0 01-.41.168z" />
    <path d="M4.395 12.325a.591.591 0 01-.41-1.019A11.723 11.723 0 0112.19 7.98c3.06 0 5.962 1.167 8.167 3.293a.594.594 0 11-.826.855 10.541 10.541 0 00-7.346-2.956 10.552 10.552 0 00-7.38 2.99.592.592 0 01-.41.164z" />
    <path d="M7.954 15.083a.591.591 0 01-.41-1.018 6.62 6.62 0 014.631-1.879c1.73 0 3.367.658 4.613 1.859a.594.594 0 11-.826.855 5.443 5.443 0 00-3.791-1.527 5.44 5.44 0 00-3.807 1.542.568.568 0 01-.41.168zM12.165 20.283a2.249 2.249 0 01-2.244-2.244 2.249 2.249 0 012.244-2.244 2.249 2.249 0 012.245 2.244 2.249 2.249 0 01-2.245 2.244zm0-3.302a1.059 1.059 0 101.058 1.058 1.06 1.06 0 00-1.058-1.058z" />
  </svg>
)

export default IconWifi
