import styled, { css } from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

const CalloutStyle = css<TypographyTypeProps>`
  font-size: ${rem(21)};
  line-height: ${rem(32)};
  font-weight: 600;
  ${SharedDynamicPropsStyled}
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.brand.primaryOnLight};

  @media ${media.stUp} {
    font-size: ${rem(26)};
    line-height: ${rem(44.3)};
  }
`

const Callout = styled.p`
  ${CalloutStyle}
`

export { Callout as default, CalloutStyle }
