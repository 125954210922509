import React from "react"

import img from "../../images/brand/brandmark-primary.svg"

export interface BrandMarkProps {
  className?: string
  width?: number
  height?: number
  alt?: string
  loading?: string
}

const BrandMark = ({
  alt = "",
  className,
  width = 66,
  height = 54,
}: BrandMarkProps) => {
  return (
    <img
      alt={alt}
      className={className}
      src={img}
      width={width}
      height={height}
      loading="lazy"
    />
  )
}

export default BrandMark
