import React from "react"
import useFetch from "use-http"

import { StatusIndicator } from "@sr/gatsby-theme-ui"

const statusEndpoint = "https://m4mnt21f0t6m.statuspage.io/api/v2/status.json"

const AppFooterStatusIndicator = () => {
  // 1. On page load: Make a request to: https://m4mnt21f0t6m.statuspage.io/api/v2/status.json
  const { loading, data = [] }: { loading: boolean; data: any } = useFetch(
    statusEndpoint,
    {},
    []
  )

  // 2. Get request payload and set state for statusColor
  const getStatusColor = (indicator: string): string => {
    switch (indicator) {
      case "none":
        return "var(--color-success)"
      case "minor":
        return "var(--color-warning)"
      case "major":
        return "var(--color-danger)"
      case "critical":
        return "var(--color-danger)"
      default:
        return "var(--color-white)"
    }
  }
  const statusColor =
    data && data.status ? getStatusColor(data.status.indicator) : ""

  // 3. Get request payload and set state for message
  const statusMessage = data && data.status ? data.status.description : ""

  // 4. @Todo: On tiemout: Make request again

  return (
    <>
      {loading && <span>Loading...</span>}
      {!loading && (
        <StatusIndicator statusColor={statusColor} message={statusMessage} />
      )}
    </>
  )
}

export default AppFooterStatusIndicator
