import React from "react"
import { IconProps } from "./iconTypes"

const IconTrendUp = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M21.784 5.806a.626.626 0 00-.09-.3.505.505 0 00-.277-.248.456.456 0 00-.237-.022L15.948 5a.478.478 0 00-.367.152.61.61 0 00-.165.4.621.621 0 00.135.414c.091.112.219.18.354.186l4.154.187-6.818 7.663-3.348-3.688a.482.482 0 00-.356-.164.482.482 0 00-.357.164l-7.025 7.698a.582.582 0 00-.113.185.639.639 0 00.102.629.513.513 0 00.164.127.46.46 0 00.391.006.508.508 0 00.167-.122l6.67-7.308 3.352 3.693a.482.482 0 00.36.164.483.483 0 00.357-.168l7.204-8.095.17 4.744c.005.149.061.29.156.392.095.103.222.16.354.16h.021a.489.489 0 00.355-.184.62.62 0 00.135-.413l-.216-6.016z" />{" "}
  </svg>
)

export default IconTrendUp
