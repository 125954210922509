import React, { forwardRef, ReactNode, Ref } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface DeviceDesktopBrowserProps {
  bgColor?: string
  children?: ReactNode
  className?: string
  frameColor?: "light" | "dark"
  shadow?: string
}

const DeviceDesktopBrowser = forwardRef(
  (
    {
      bgColor = "var(--color-content-bg)",
      children,
      className,
      frameColor = "dark",
      shadow = "var(--shadow)",
    }: DeviceDesktopBrowserProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <DeviceDesktopBrowserStyled
        bgColor={bgColor}
        className={`${className || ""} browser`}
        ref={ref}
        frameColor={frameColor}
        shadow={shadow}
      >
        <div className="browser__toolbar">
          <div className="browser__toolbar__controls">
            <div className="browser__toolbar__controls__visibility">
              <span className="browser__toolbar__controls__visibility__button " />
              <span className="browser__toolbar__controls__visibility__button " />
              <span className="browser__toolbar__controls__visibility__button " />
            </div>
          </div>
        </div>
        <div className="browser__screen">{children}</div>
      </DeviceDesktopBrowserStyled>
    )
  }
)

export default DeviceDesktopBrowser

interface BrowserSC {
  bgColor: string
  frameColor: string
  shadow: string
}

const DeviceDesktopBrowserStyled = styled.div<BrowserSC>`
  --frame-color: ${({ frameColor }) =>
    frameColor === "dark" ? `var(--color-gray900)` : `var(--color-gray100)`};

  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 75%;
  overflow: hidden;
  border: ${rem(6)} solid var(--frame-color);
  border-radius: ${rem(4)};
  box-shadow: ${({ shadow }) => shadow};

  .browser__toolbar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6.25% ${rem(32)} 0;
    background-color: var(--frame-color);
  }

  .browser__toolbar__controls {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    width: 100%;
    padding: 0 ${rem(10)};
    transform: translateY(-50%);
    align-items: center;
    line-height: 0;

    @media ${media.stUp} {
      padding: 0 ${rem(32)};
    }
  }

  .browser__toolbar__controls__visibility {
    display: flex;
    align-items: center;
    line-height: 0;
  }

  .browser__toolbar__controls__visibility__button {
    width: ${rem(10)};
    height: ${rem(10)};
    margin-right: ${rem(10)};
    border-radius: 50%;
    background-color: var(--color-danger);

    @media ${media.stUp} {
      width: ${rem(16)};
      height: ${rem(16)};
      margin-right: ${rem(16)};
    }

    &:nth-child(2) {
      background-color: var(--color-warning);
    }

    &:nth-child(3) {
      background-color: var(--color-success);
    }
  }

  .browser__screen {
    position: absolute;
    top: 8.2%;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    background-color: ${({ bgColor }) => bgColor};
  }
`
