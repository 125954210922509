import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdTitle1Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-semibold);
  font-style: normal;
  font-size: ${rem(40)};
  line-height: ${rem(48)};
  font-weight: 600;
  scroll-margin-top: ${rem(
    72
  )}; /*fixes anchor link scrolling too far due to main site nav height */

  @media ${media.stUp} {
    font-size: ${rem(72)};
    line-height: ${rem(88)};
  }
`

const SemiBdTitle1 = styled.h1<TypographyTypeProps>`
  ${SemiBdTitle1Style}
`
SemiBdTitle1.displayName = "SemiBdTitle1"

export default SemiBdTitle1
