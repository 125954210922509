import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import {
  AlignXType,
  AlignYType,
  SanityImg,
  WidthConstraintType,
} from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import MediaBgImg from "../../Media/BgImg"
import MediaBgVideo from "../../Media/BgVideo"
import SectionSplitContentContent from "./Content"
import SectionSplitContentMedia, {
  SectionSplitContentMediaImgFitType,
} from "./Media"
import { ButtonGroupInstance } from "../../Button/Group"

export interface SectionSplitContentProps {
  /** Sets a blur effect over background media
   * Converts number to pixel value
   * @default 0
   */
  bgBlur?: number
  /** Background color for component  */
  bgColor?: string
  /** Image object to be used as background */
  sanityBgImg?: SanityImg
  bgVideo?: string
  /** Sanity block content to be rendered through serialization */
  blockContent?: any[]
  /** Array of buttons to be rendered as CTAs */
  buttons?: ButtonGroupInstance[]
  className?: string
  /** Max width of container wrappping content and media sections
   * @default "max"
   */
  containerWidth?: WidthConstraintType
  /** Constrain width of content container on desktop
   * number converted to percentage
   * @default 50
   */
  contentContainerWidthStandard?: number
  /** Set text on dark background
   * @default false
   */
  contentTextOnDark?: boolean
  /** Constrain width of section content columns
   * @default "max"
   */
  contentWidth?: WidthConstraintType
  /** Content to be rendered as top-level heading */
  header?: string | ReactNode
  /** Pass the min height in vh to be applied on mobile
   * @default 50
   */
  heightMobile?: number
  /** Pass the min height in vh to be applied on desktop
   * @default 30
   */
  heightStandard?: number
  /** Constrain width of media container on desktop
   * number converted to percentage
   * @default 50
   */
  mediaContainerWidthStandard?: number
  sanityMediaImg?: SanityImg
  /** Option to control how image media fits into container
   * - Contain: contain image within container
   * - Cover: image covers container space
   * @default "contain"
   */
  mediaImgFit?: SectionSplitContentMediaImgFitType | string
  /** Hide image section on mobile
   * @default false
   */
  mediaImgMobileHide?: boolean
  /** Hide image section on desktop
   * @default false
   */
  mediaImgStandardHide?: boolean
  /** Control x-axis alignment of media image
   * @default "center"
   */
  mediaImgXAlign?: AlignXType | string
  /** Control y-axis alignment of media image
   * @default "center"
   */
  mediaImgYAlign?: AlignYType | string
  /** Provide a video url to render in media container */
  mediaVideo?: string
  /** Option to control how video media fits into container
   * - Contain: contain video within container
   * - Cover: video covers container space
   * @default "contain"
   */
  mediaVideoFit?: string
  /** Hide video section on mobile
   * @default false
   */
  mediaVideoMobileHide?: boolean
  sanityMediaVideoPoster?: SanityImg
  /** Hide video section on desktop
   * @default false
   */
  mediaVideoStandardHide?: boolean
  /** Control x-axis alignment of media video
   * @default "center"
   */
  mediaVideoXAlign?: AlignXType
  /** Control y-axis alignment of media video
   * @default "center"
   */
  mediaVideoYAlign?: AlignYType
  /** Color to be overlayed on top of image/media
   * @default "transparent"
   */
  overlayColor?: string
  /** Opacity of color overlay on top of image/media
   * @default 0
   */
  overlayOpacity?: number
  /** Padding values for container */
  paddingBottomMobile?: string
  paddingBottomStandard?: string
  paddingTopMobile?: string
  paddingTopStandard?: string
  /** Stack content on top of media on mobile
   * If true, media renders below content
   * @default false
   */
  reverseMobile?: boolean
  /** Set media to left of content on desktop
   * @default false
   */
  reverseStandard?: boolean
}

/**
 * Split content section component
 * - Renders split sections for media and content
 */
const SectionSplitContent = ({
  bgBlur = 0,
  bgColor = `transparent`,
  sanityBgImg,
  bgVideo,
  buttons,
  blockContent,
  className,
  containerWidth = "max",
  contentContainerWidthStandard = 50,
  sanityMediaImg,
  contentTextOnDark = false,
  contentWidth = "readableLg",
  header,
  heightMobile = 50,
  heightStandard = 30,
  mediaContainerWidthStandard = 50,
  mediaImgFit = "contain",
  mediaImgMobileHide = false,
  mediaImgStandardHide = false,
  mediaImgXAlign = "center",
  mediaImgYAlign = "center",
  mediaVideo,
  mediaVideoFit = "contain",
  mediaVideoMobileHide = false,
  sanityMediaVideoPoster,
  mediaVideoStandardHide,
  mediaVideoXAlign = "center",
  mediaVideoYAlign = "center",
  overlayColor = "transparent",
  overlayOpacity = 0,
  paddingBottomMobile = "0",
  paddingBottomStandard = "0",
  paddingTopMobile = "0",
  paddingTopStandard = "0",
  reverseMobile = false,
  reverseStandard = false,
}: SectionSplitContentProps) => {
  // Normalize string to union types
  const flexDirectionM = reverseMobile ? "column" : "column-reverse"
  const flexDirectionSt = reverseStandard ? "row-reverse" : "row"

  const mediaImgFitString =
    mediaImgFit !== "cover" && mediaImgFit !== "contain"
      ? "contain"
      : mediaImgFit
  const mediaImgXAlignString =
    mediaImgXAlign !== "center" &&
    mediaImgXAlign !== "start" &&
    mediaImgXAlign !== "end"
      ? "center"
      : mediaImgXAlign
  const mediaImgYAlignString =
    mediaImgYAlign !== "center" &&
    mediaImgYAlign !== "start" &&
    mediaImgYAlign !== "end"
      ? "center"
      : mediaImgYAlign
  const mediaVideoFitString =
    mediaVideoFit !== "cover" && mediaVideoFit !== "contain"
      ? "contain"
      : mediaVideoFit
  const mediVideoXAlignString =
    mediaVideoXAlign !== "center" &&
    mediaVideoXAlign !== "start" &&
    mediaVideoXAlign !== "end"
      ? "center"
      : mediaVideoXAlign
  const mediaVideoYAlignString =
    mediaVideoYAlign !== "center" &&
    mediaVideoYAlign !== "start" &&
    mediaVideoYAlign !== "end"
      ? "center"
      : mediaVideoYAlign

  // Collapse container padding if full width and media image is set to cover its container
  const collapseContainerPaddingSt =
    containerWidth === "full" && mediaImgFit === "cover"

  // Default content padding to create gap between conent and media
  // while collapsing gap on edges
  let contentPadRightSt = !reverseStandard ? rem(32) : "0"
  let contentPaddingLeftSt = !reverseStandard ? "0" : rem(32)

  // Add edge gap back in if full width is set
  if (containerWidth === "full") {
    contentPadRightSt = !reverseStandard ? rem(32) : rem(48)
    contentPaddingLeftSt = !reverseStandard ? rem(48) : rem(32)
  }

  return (
    <SectionSplitContentStyled
      bgColor={bgColor}
      collapseContainerPaddingSt={collapseContainerPaddingSt}
      contentFlexBasisSt={`${contentContainerWidthStandard}%`}
      className={`${className} split-content`}
      flexDirectionM={flexDirectionM}
      flexDirectionSt={flexDirectionSt}
      heightM={heightMobile}
      heightSt={heightStandard}
      mediaFlexBasisSt={`${mediaContainerWidthStandard}%`}
      padBotM={paddingBottomMobile}
      padBotSt={paddingBottomStandard}
      padTopM={paddingTopMobile}
      padTopSt={paddingTopStandard}
    >
      <AppLayoutWidth
        className="split-content__layout"
        constraint={containerWidth}
      >
        <SectionSplitContentContent
          buttons={buttons}
          blockContent={blockContent}
          className="split-content__content"
          contentTextOnDark={contentTextOnDark}
          contentBgColor={bgColor}
          contentWidth={contentWidth}
          header={header}
          padLeftSt={contentPaddingLeftSt}
          padRightSt={contentPadRightSt}
          reverseStandard={reverseStandard}
        />
        <SectionSplitContentMedia
          className="split-content__media"
          mediaImgMobileHide={mediaImgMobileHide}
          mediaImgStandardHide={mediaImgStandardHide}
          mediaImgFit={mediaImgFitString}
          sanityMediaImg={sanityMediaImg}
          mediaImgXAlignStandard={mediaImgXAlignString}
          mediaImgYAlignStandard={mediaImgYAlignString}
          mediaVideo={mediaVideo}
          mediaVideoFit={mediaVideoFitString}
          mediaVideoMobileHide={mediaVideoMobileHide}
          sanityMediaVideoPoster={sanityMediaVideoPoster}
          mediaVideoStandardHide={mediaVideoStandardHide}
          mediaVideoXAlign={mediVideoXAlignString}
          mediaVideoYAlign={mediaVideoYAlignString}
        />
      </AppLayoutWidth>
      {sanityBgImg && (
        <MediaBgImg
          className="color-block__bg-media"
          bgImg={sanityBgImg}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
        />
      )}
      {bgVideo && (
        <MediaBgVideo
          bgBlur={bgBlur}
          className="color-block__bg-media"
          loop={true}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
          source={bgVideo}
        />
      )}
    </SectionSplitContentStyled>
  )
}

export default SectionSplitContent

interface SplitContentSC {
  bgColor: string
  collapseContainerPaddingSt: boolean
  contentFlexBasisSt: string
  flexDirectionM: "column" | "column-reverse"
  flexDirectionSt: "row" | "row-reverse"
  heightM: number
  heightSt: number
  mediaFlexBasisSt: string
  padBotM: string
  padBotSt: string
  padTopM: string
  padTopSt: string
}

const SectionSplitContentStyled = styled.section<SplitContentSC>`
  position: relative;
  z-index: 1;
  padding-top: ${({ padTopM }) => padTopM};
  padding-bottom: ${({ padBotM }) => padBotM};
  background-color: ${({ bgColor }) => bgColor};

  @media ${media.stUp} {
    padding-top: ${({ padTopSt }) => padTopSt};
    padding-bottom: ${({ padBotSt }) => padBotSt};
  }

  .split-content__layout {
    display: flex;
    flex-direction: ${({ flexDirectionM }) => flexDirectionM};
    align-items: center;
    justify-content: center;
    min-height: ${({ heightM }) => `${heightM}vh`};

    @media ${media.stUp} {
      flex-direction: ${({ flexDirectionSt }) => flexDirectionSt};
      align-items: stretch;
      min-height: ${({ heightSt }) => `${heightSt}vh`};
      ${({ collapseContainerPaddingSt }) => {
        if (collapseContainerPaddingSt) {
          return `
            padding-right: 0 !important;
            padding-left: 0 !important;
          `
        }
        return null
      }}
    }
  }

  .split-content__content {
    width: 100%;

    @media ${media.stUp} {
      flex: 1 0 ${({ contentFlexBasisSt }) => contentFlexBasisSt};
      width: ${({ contentFlexBasisSt }) => contentFlexBasisSt};
    }
  }

  .split-content__media {
    width: 100%;

    @media ${media.stUp} {
      flex: 1 0 ${({ mediaFlexBasisSt }) => mediaFlexBasisSt};
      width: ${({ mediaFlexBasisSt }) => mediaFlexBasisSt};
    }
  }

  .color-block__bg-media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`
