import React from "react"
import { IconProps } from "./iconTypes"

const IconCheckSolid = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M8.356 16.623l-4.741-4.975L2 13.33 8.356 20 22 5.682 20.397 4 8.356 16.623z" />
  </svg>
)

export default IconCheckSolid
