import { useState, useEffect } from "react"

/**
 * Hook for MatchMedia API
 * @param query: media query (`min-width: 600px`)
 * @returns bool if matches
 */
const useMedia = (query: string): boolean => {
  if (typeof window === `undefined`) return false

  const [matches, setMatches] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    // creates MediaQueryList object
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const mediaListener = () => setMatches(media.matches)
    media.addListener(mediaListener)

    return () => media.removeListener(mediaListener)
  }, [query])

  return matches
}

export default useMedia
