import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import type { SanityImg } from "../../types/sanityImg"

import { Caption, Title4 } from "../Typography"
import MediaImgZoom from "../Media/ImgZoom"

type MediaMobileFlexBasis = string
type MediaStandardFlexBasis = string

type StackMobile = boolean
type StackStandard = boolean

export interface ArticlePreviewProps {
  className?: string
  img?: SanityImg
  /** Flex basis for mobile, st breakpoints */
  mediaFlexBasis?: [MediaMobileFlexBasis, MediaStandardFlexBasis]
  /**
   * Zoom media, on hover, focus, etc.
   * @default false
   * */
  mediaZoom?: boolean
  /** Reverse order of figcaption content
   * @default false
   */
  reverseCaption?: boolean
  /** Stack media and contenet for mobile, st breakpoints */
  stack?: [StackMobile, StackStandard]
  /** Human readable time string */
  time?: string
  /** ISO datetime string  */
  timeIso?: string
  title: string
}

const ArticlePreview = ({
  className,
  img,
  mediaFlexBasis = ["33.333%", "50%"],
  mediaZoom = false,
  reverseCaption = false,
  stack = [false, false],
  time,
  timeIso,
  title,
}: ArticlePreviewProps) => {
  return (
    <ArticlePreviewStyled
      className={`${className} preview`}
      mediaMFlexBasis={mediaFlexBasis[0]}
      mediaStFlexBasis={mediaFlexBasis[1]}
      reverseCaption={reverseCaption}
      stackM={stack[0]}
      stackSt={stack[1]}
    >
      <div className="preview__media">
        {img && (
          <MediaImgZoom height={320} img={img} width={320} zoom={mediaZoom} />
        )}
      </div>
      <figcaption className="preview__caption">
        <Title4 className="preview__caption__title">{title}</Title4>
        {time && (
          <Caption
            as="time"
            className="preview__caption__time"
            dateTime={timeIso}
          >
            {time}
          </Caption>
        )}
      </figcaption>
    </ArticlePreviewStyled>
  )
}

export default ArticlePreview

interface PreviewSC {
  mediaMFlexBasis: MediaMobileFlexBasis
  mediaStFlexBasis: MediaStandardFlexBasis
  reverseCaption: boolean
  stackM: StackMobile
  stackSt: StackStandard
}

const ArticlePreviewStyled = styled.figure<PreviewSC>`
  display: flex;
  flex-direction: ${({ stackM }) => (stackM ? "column" : "row")};
  align-items: stretch;

  @media ${media.stUp} {
    flex-direction: ${({ stackSt }) => (stackSt ? "column" : "row")};
  }

  .preview__media {
    flex: 0 0 ${({ mediaMFlexBasis }) => mediaMFlexBasis};
    margin-right: ${({ stackM }) => (stackM ? 0 : rem(16))};
    margin-bottom: ${({ stackM }) => (stackM ? rem(16) : 0)};
    line-height: 0;

    @media ${media.stUp} {
      flex: 0 0 ${({ mediaStFlexBasis }) => mediaStFlexBasis};
      margin-right: ${({ stackSt }) => (stackSt ? 0 : rem(16))};
      margin-bottom: ${({ stackSt }) => (stackSt ? rem(16) : 0)};
    }
  }

  .preview__caption {
    display: flex;
    flex-direction: ${({ reverseCaption }) =>
      reverseCaption ? "column-reverse" : "column"};
    justify-content: space-between;
  }

  .preview__caption__title {
    margin-top: ${({ reverseCaption }) => (reverseCaption ? 0 : rem(-8))};
    margin-bottom: ${({ reverseCaption }) => (reverseCaption ? 0 : rem(8))};
  }

  .preview__caption__time {
    margin-bottom: ${({ reverseCaption }) => (reverseCaption ? rem(8) : 0)};
  }
`
