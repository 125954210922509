import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdCapsStyle = css<TypographyTypeProps>`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 600;
  text-transform: uppercase;
`

const SemiBdCaps = styled.p<TypographyTypeProps>`
  ${SemiBdCapsStyle}
`
SemiBdCaps.displayName = "SemiBdCaps"

export default SemiBdCaps