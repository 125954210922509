import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"

import { timingFunctions } from "@sr/common"

import { SanityImg } from "../../types/index"

export interface MediaImgZoomProps {
  className?: string
  height?: number
  img?: SanityImg
  width?: number
  zoom?: boolean
  rounded?: boolean
}

const MediaImgZoom = ({
  className,
  height = 500,
  img,
  width = 500,
  zoom = false,
  rounded = false,
}: MediaImgZoomProps) => {
  return (
    <MediaImgZoomStyled
      className={`${className || ""} zoom`}
      zoom={zoom}
      rounded={rounded}
    >
      <div className="zoom__media-container">
        {img && img?.asset ? (
          <SanityImage
            alt={img.alt || ""}
            asset={img.asset}
            className="zoom__media__img"
            crop={img.crop}
            hotspot={img.hotspot}
            width={width}
            height={height}
          />
        ) : undefined}
      </div>
    </MediaImgZoomStyled>
  )
}

export default MediaImgZoom

interface ImgZoomSC {
  zoom: boolean
  rounded?: boolean
}

const MediaImgZoomStyled = styled.div<ImgZoomSC>`
  .zoom__media-container {
    overflow: hidden;
    line-height: 0;
    border-radius: ${({ rounded }) => (rounded ? "var(--rounded)" : "0")};
  }

  .zoom__media__img {
    display: block;
    width: 100%;
    transform: ${({ zoom }) => (zoom ? "scale(1.25)" : "scale(1)")};
    transition: transform 400ms ${timingFunctions.easeInOutQuad};
  }
`
