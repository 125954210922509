import React from "react"
import { IconProps } from "./iconTypes"

const IconThermostat = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.988 22.97c-6.065 0-10.981-4.917-10.981-10.982 0-6.065 4.916-10.981 10.981-10.981 6.065 0 10.982 4.916 10.982 10.981 0 6.065-4.917 10.982-10.982 10.982zm0-.937c5.548 0 10.045-4.497 10.045-10.045 0-5.547-4.497-10.045-10.045-10.045-5.547 0-10.045 4.498-10.045 10.045 0 5.548 4.498 10.045 10.045 10.045zm-.011-14.987a1.12 1.12 0 110-2.24 1.12 1.12 0 010 2.24zm3.183 1.16a1.12 1.12 0 111.44-1.717 1.12 1.12 0 01-1.44 1.716zm1.694 2.933a1.12 1.12 0 112.207-.389 1.12 1.12 0 01-2.207.39zm-.588 3.337a1.12 1.12 0 111.94 1.12 1.12 1.12 0 01-1.94-1.12zm-8.579 0a1.12 1.12 0 11-1.94 1.12 1.12 1.12 0 011.94-1.12zM7.1 11.139a1.12 1.12 0 11-2.207-.389 1.12 1.12 0 012.207.39zm1.694-2.934a1.12 1.12 0 11-1.44-1.716 1.12 1.12 0 011.44 1.716zm3.195 6.289a2.505 2.505 0 110-5.011 2.505 2.505 0 010 5.01zm.706-5.178a2.767 2.767 0 00-1.49.022l.777-1.775.713 1.753z" />
  </svg>
)

export default IconThermostat
