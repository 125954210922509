import React, { useState } from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { IconPlayCircle } from "../Icon/index"
import MediaYouTube from "../Media/YouTube"
import { SanityImg } from "../../types"

export interface MediaVideoPlayerProps {
  className?: string
  /**
   * Provide a Sanity Image to
   * render on top of video while it is loading
   */
  placeholder?: SanityImg
  /**
   * Sets type of video source
   * only supports "youtube" for now
   */
  type?: "youtube"
  url?: string
}

const MediaVideoPlayer = ({
  className,
  placeholder,
  type,
  url,
}: MediaVideoPlayerProps) => {
  const [userInitiatedPlay, setUserInitiatedPlay] = useState<boolean>(false)
  const [mediaReady, setMediaReady] = useState<boolean>(false)

  const handleOnVideoReady = () => setMediaReady(true)

  return (
    <MediaVideoPlayerStyled
      className={`${className} feature-video`}
      mediaReady={mediaReady}
      userInitiatedPlay={userInitiatedPlay}
    >
      {url && type === "youtube" && (
        <MediaYouTube
          className="media-video__player"
          /** Fixes youtube player throwing error */
          config={{ onUnstarted: () => null }}
          controls={true}
          onReady={handleOnVideoReady}
          playing={userInitiatedPlay}
          responsive={true}
          url={url}
        />
      )}
      {placeholder && placeholder?.asset && (
        <figcaption className="media-video-player__placeholder">
          <SanityImage
            alt={placeholder.alt || ""}
            asset={placeholder.asset}
            className="img"
            crop={placeholder.crop}
            hotspot={placeholder.hotspot}
            width={500}
            height={281}
          />
          <button
            className="media-video-player__placeholder__button"
            onClick={() => setUserInitiatedPlay(true)}
          >
            <span className="media-video-player__placeholder__button__content">
              <IconPlayCircle />
              <span className="u-hidden-visually">Click to play video</span>
            </span>
          </button>
        </figcaption>
      )}
    </MediaVideoPlayerStyled>
  )
}

export default MediaVideoPlayer

interface VideoFC {
  mediaReady: boolean
  userInitiatedPlay: boolean
}

const MediaVideoPlayerStyled = styled.figure<VideoFC>`
  position: relative;
  z-index: 0;
  overflow: hidden;

  .media-video-player__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ userInitiatedPlay }) => (userInitiatedPlay ? "-1" : "1")};
    opacity: ${({ userInitiatedPlay }) => (userInitiatedPlay ? "0" : "1")};
    visibility: ${({ userInitiatedPlay }) =>
      userInitiatedPlay ? "hidden" : "visible"};
    transition: opacity 300ms ease, visibility 300ms ease, z-index 300ms ease;
  }

  .media-video-player__placeholder__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .media-video-player__placeholder__button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    z-index: ${({ mediaReady }) => (mediaReady ? "1" : "-1")};
    opacity: ${({ mediaReady }) => (mediaReady ? "1" : "0")};
    visibility: ${({ mediaReady }) => (mediaReady ? "visible" : "hidden")};
    transition: opacity 200ms ease, visibility 200ms ease, z-index 200ms ease;
  }

  .media-video-player__placeholder__button__content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background: var(--color-content-bg-invert);
    }

    .icon {
      position: relative;
      z-index: 2;
      width: ${rem(80)};
      height: ${rem(80)};
      fill: var(--color-text-subtract);
    }
  }
`
