// This component shadows the AppFooter component in @sr/gatsby-theme-ui
// packages/gatsby-theme-ui/sr/components/App/Footer/index.tsx
// See: https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/shadowing/
import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import { AppLayoutWidth, AppFooterBrand } from "@sr/gatsby-theme-ui"

import AppFooterProducts from "./Products"
import AppFooterHardware from "./Hardware"
import AppFooterCustomers from "./Customers"
import AppFooterCompany from "./Company"
import AppFooterBottom from "./Bottom"
import AppFooterStatusIndicator from "./StatusIndicator"

export interface AppFooterProps {
  className?: string
}

const AppFooter = ({ className }: AppFooterProps) => {
  return (
    <AppFooterStyled className={className} role="contentinfo">
      <AppLayoutWidth className="footer__layout" constraint="xl">
        <AppFooterBrand className="footer__brand" />
        <AppFooterProducts className="footer__products" />
        <AppFooterHardware className="footer__hardware" />
        <AppFooterCustomers className="footer__customers" />
        <AppFooterCompany className="footer__company" />
        <AppFooterBottom className="footer__bottom" />
      </AppLayoutWidth>
      <a
        className="footer-status-indicator__link"
        href="https://status.smartrent.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppFooterStatusIndicator />
      </a>
    </AppFooterStyled>
  )
}

export default AppFooter

const AppFooterStyled = styled.footer`
  --st-column-large: calc(100% * (4 / 12));
  --st-column-default: calc(100% * (3 / 12));
  --st-column-small: calc(100% * (2 / 12));

  background: var(--color-content-bg-invert);
  border-top: 4px solid var(--color-primary);

  @media ${media.stUp} {
    border-top: 4px solid var(--color-primary);
  }

  .footer__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${rem(24)};
    padding: ${rem(60)} ${rem(20)} 0 ${rem(20)};

    @media ${media.stUp} {
      gap: 0;
    }
  }

  .footer__brand {
    padding: ${rem(48 / 2)} 0;
    text-align: left;
    width: 100%;

    @media ${media.stUp} {
      width: var(--st-column-large);
      padding: 0 0 ${rem(60)} 0;
      text-align: left;
    }
  }

  .footer__products,
  .footer__hardware,
  .footer__customers,
  .footer__company {
    flex: 0 0 calc(50% - ${rem(24)});

    @media ${media.stUp} {
      flex: 0 0 auto;
      width: var(--st-column-small);
      padding: 0 0 ${rem(60)} 0;
    }
  }

  .footer-status-indicator__link {
    margin-bottom: 0;
  }

  .footer__contact {
    @media ${media.stUp} {
      width: var(--st-column-small);
    }
  }

  .footer__bottom {
    @media ${media.stUp} {
      flex: 1 0 50%;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    font-size: ${rem(14)};
    text-decoration: none;

    @media ${media.stUp} {
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  p small {
    font-size: ${rem(12)};
    color: var(--color-primary);
  }
`
