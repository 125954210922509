import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { CtaBanner } from "@sr/gatsby-theme-ui"

const AppCtaBanner = () => {
  const {
    sanityCtaBannerComponent: {
      showComponent,
      bannerContent,
      bannerTag,
      bannerTagText,
      learnMoreUrl,
    },
  } = useStaticQuery(graphql`
    query ctaBannerComponentQuery {
      sanityCtaBannerComponent {
        showComponent
        bannerContent
        bannerTag
        bannerTagText
        learnMoreUrl
      }
    }
  `)

  const isTermsPage =
    typeof window !== "undefined"
      ? window?.location?.href.includes("/terms/")
      : false

  return (
    !isTermsPage && (
      <CtaBanner
        showComponent={showComponent}
        bannerContent={bannerContent}
        bannerTag={bannerTag}
        bannerTagText={bannerTagText}
        learnMoreUrl={learnMoreUrl}
      />
    )
  )
}

export default AppCtaBanner
