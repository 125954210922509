import React from "react"
import { IconProps } from "./iconTypes"

const IconSmartHome = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M13.242.385l8.916 8.517a.95.95 0 01.399.94.92.92 0 01-.912.74h-.683V23.03c-.057.513-.456.912-.969.912H4.611c-.513 0-.968-.399-.968-.912V10.554h-.655c-.314 0-.627-.114-.827-.399a.985.985 0 01.114-1.225L11.22.385c.541-.513 1.481-.513 2.022 0zm6.78 9.343h1.538s.029.028.029-.029 0-.085-.03-.114L12.616 1.04c-.028-.143-.199-.2-.341-.2a.517.517 0 00-.399.171L2.931 9.557s-.029.057-.029.114c.029.057.057.057.057.057h1.538v13.33c0 .03.029.058.057.058H7.83v-4.13l-.826-.827c-.17.057-.342.114-.541.114-.712 0-1.31-.598-1.31-1.31 0-.74.57-1.31 1.281-1.31.712 0 1.31.57 1.31 1.31 0 .2-.028.37-.113.541l1.082 1.083v4.5h1.083v-8.602l-1.083-1.083a1.22 1.22 0 01-.627.171c-.712 0-1.31-.598-1.31-1.31 0-.712.598-1.31 1.31-1.31a1.307 1.307 0 011.225 1.766l1.396 1.395v9.002h1.082V9.785a1.306 1.306 0 01-.854-1.225c0-.712.57-1.31 1.31-1.31.712 0 1.31.57 1.31 1.31 0 .57-.37 1.025-.854 1.225v13.33h1.082v-6.437l1.31-1.31a1.325 1.325 0 011.225-1.795c.713 0 1.311.598 1.311 1.31 0 .713-.598 1.31-1.31 1.31-.228 0-.428-.056-.598-.142l-.998.997v6.068h.997v-3.219l1.31-1.31a1.325 1.325 0 011.225-1.795c.713 0 1.311.598 1.311 1.31 0 .712-.598 1.31-1.31 1.31-.228 0-.428-.056-.599-.142l-.997.997v2.849h3.305a.061.061 0 00.057-.057V9.728zM11.875 8.56a.39.39 0 01.399-.399.39.39 0 01.399.399.39.39 0 01-.4.399c-.227-.029-.398-.2-.398-.4zm-5.811 8.403a.39.39 0 01.399-.399.39.39 0 01.399.399.39.39 0 01-.4.399.407.407 0 01-.398-.399zm2.05-4.301a.39.39 0 01-.398-.399.39.39 0 01.399-.399.39.39 0 01.399.399c0 .228-.2.399-.4.399zm7.834 2.222a.39.39 0 01.4-.4.39.39 0 01.398.4.39.39 0 01-.399.398.39.39 0 01-.399-.398zm1.909 3.218a.39.39 0 01.399-.398.39.39 0 01.398.398.39.39 0 01-.398.4.39.39 0 01-.4-.4z" />
  </svg>
)

export default IconSmartHome
