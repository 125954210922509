import React from "react"
import { IconProps } from "./iconTypes"

const IconCommunityParking = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path
      d="M20.7302 22.7938C20.8622 22.9258 21.0413 23 21.2279 23C21.4146 23 21.5936 22.9258 21.7256 22.7938C21.8576 22.6618 21.9318 22.4828 21.9318 22.2961V6.73653C21.9318 6.60955 21.8975 6.48493 21.8324 6.37588C21.7673 6.26683 21.674 6.17742 21.5623 6.1171L12.2548 1.08543C12.1516 1.02937 12.0361 1 11.9187 1C11.8013 1 11.6857 1.02937 11.5826 1.08543L2.3666 6.11886C2.25567 6.17942 2.16309 6.26873 2.09859 6.37741C2.03408 6.48609 2.00003 6.61014 2 6.73653V10.3909C2 10.5776 2.07416 10.7566 2.20616 10.8886C2.33816 11.0206 2.51719 11.0948 2.70387 11.0948C2.89055 11.0948 3.06958 11.0206 3.20158 10.8886C3.33358 10.7566 3.40774 10.5776 3.40774 10.3909V7.15417L11.9216 2.50436L20.5241 7.15828V22.2961C20.5241 22.4828 20.5982 22.6618 20.7302 22.7938Z"
      fill="#231F20"
    />
    <path d="M15.7172 15.6862C16.7785 14.8486 17.3092 13.6522 17.3092 12.097C17.3092 9.11205 15.3884 7.6196 11.5468 7.6196H8.45684C8.21991 7.6196 7.99269 7.71373 7.82516 7.88126C7.65763 8.0488 7.56351 8.27603 7.56351 8.51296V22.0917C7.56351 22.3286 7.65763 22.5558 7.82516 22.7234C7.99269 22.8909 8.21991 22.985 8.45684 22.985C8.57417 22.9851 8.69037 22.962 8.79879 22.9172C8.90721 22.8723 9.00572 22.8065 9.08869 22.7235C9.17166 22.6406 9.23746 22.5421 9.28232 22.4336C9.32719 22.3252 9.35024 22.209 9.35017 22.0917V16.9421H11.1579C13.1362 16.9425 14.656 16.5239 15.7172 15.6862ZM10.9585 15.4076H9.35017V9.16465H11.3509C12.7586 9.16543 13.7949 9.41414 14.4597 9.91078C15.1244 10.4074 15.4568 11.1641 15.4568 12.1808C15.4568 13.309 15.1029 14.1289 14.3951 14.6404C13.6874 15.1519 12.5418 15.4076 10.9585 15.4076Z" />
  </svg>
)

export default IconCommunityParking
