import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media } from "@sr/common"
import { RegBody, SemiBdTitle4 } from "../../components/Typography"
import { Link } from "gatsby"

export interface CTAPropsShape {
  header: string
  subHeader?: string
  btnText: string
  btnLink: string
  marginTop?: number[]
  maxWidth?: number
}

const CTA = ({
  header,
  subHeader,
  btnText,
  btnLink,
  marginTop = [88, 112, 70],
  maxWidth = 1312,
}: CTAPropsShape) => {
  if (!header || !btnText || !btnLink) return null
  return (
    <CTAStyled marginTop={marginTop} maxWidth={maxWidth}>
      <div className="cta__container">
        <div className="cta__content-container">
          <div className="cta__text-container">
            {header ? (
              <SemiBdTitle4 className="cta__header">{header}</SemiBdTitle4>
            ) : null}
            {subHeader ? (
              <RegBody className="cta__subheader">{subHeader}</RegBody>
            ) : null}
          </div>
          {btnLink && btnText ? (
            <Link to={btnLink}>
              <button className="cta__btn">
                <p>{btnText}</p>
              </button>
            </Link>
          ) : null}
        </div>
      </div>
    </CTAStyled>
  )
}

export default CTA

interface CTASC {
  marginTop: number[]
  maxWidth: number
}

const CTAStyled = styled.section<CTASC>`
  width: 100%;
  .cta__container {
    display: flex;
    justify-content: center;
    margin-top: ${({ marginTop }) => `${marginTop[0]}px`};
    background: var(--color-primary);
    min-height: ${rem(168)};
    height: fit-content;
    width: 100%;
    padding: ${rem(24)} 0;

    @media ${media.midUp} {
      margin-top: ${({ marginTop }) => `${marginTop[1]}px`};
      min-height: ${rem(144)};
      padding: ${rem(32)} ${rem(64)};
    }

    @media ${media.stMidUp} {
      margin-top: ${({ marginTop }) => `${marginTop[2]}px`};
      min-height: ${rem(136)};
      padding: ${rem(48)} ${rem(152)};
    }
  }

  .cta__content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: ${rem(16)};
    max-width: ${({ maxWidth }) => `${maxWidth}px`};

    @media ${media.stMidUp} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .cta__text-container {
    display: flex;
    flex-direction: column;
    gap: ${rem(8)};
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: ${rem(360)};
    color: var(--color-white);

    @media ${media.midUp} {
      max-width: ${rem(513)};
    }

    @media ${media.stMidUp} {
      text-align: left;
      align-items: flex-start;
      max-width: ${rem(800)};
    }

  .cta__header {
    max-width: ${rem(400)};
    padding: 0 ${rem(16)};

    @media ${media.midUp} {
      padding: 0;
      font-size: ${rem(32)};
      max-width: ${rem(513)};
    }

    @media ${media.stMidUp} {
      max-width: ${rem(721)};
    }
  }

  .cta__subheader {
    max-width: ${rem(310)};

    @media ${media.midUp} {
      max-width: ${rem(464)};
    }

    @media ${media.stMidUp} {
      max-width: ${rem(800)};
    }
  }
}

  .cta__btn {
    height: ${rem(40)};
    width: ${rem(191)};
    border-radius: ${rem(4)};
    color: var(--color-primary);
    background: white;
    font-size: ${rem(16)};
    font-weight: 600;
  }
`
