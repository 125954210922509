import React, { ReactNode, createContext, useState } from "react"

interface SearchProviderProps {
  children?: ReactNode
}

const SearchContext = createContext<any | null>(null)

const SearchProvider = ({ children }: SearchProviderProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [searchVal, setSearchVal] = useState<string | null>(null)

  return (
    <SearchContext.Provider
      value={{ isVisible, setIsVisible, searchVal, setSearchVal }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export { SearchProvider as default, SearchContext }
