import imageUrlBuilder from "@sanity/image-url"

// init without sanity client
// https://github.com/sanity-io/image-url/issues/2
const sanityImageUrlBuilder = imageUrlBuilder({
  projectId: process.env.SANITY_PROJECT_ID || "",
  dataset: process.env.SANITY_DATASET || "",
})

const sanityImageUrl = (source: any) => sanityImageUrlBuilder.image(source)

export { sanityImageUrl as default, sanityImageUrlBuilder }
