import React, { ReactNode, cloneElement } from "react"

import SearchProvider from "./SearchProvider"
import PopupProvider from "./PopupProvider"

interface ProviderComposerProps {
  contexts: any[]
  children: ReactNode
}

const ProviderComposer = ({ contexts, children }: ProviderComposerProps) => {
  return contexts.reduceRight(
    (kids, parent) => cloneElement(parent, { children: kids }),
    children
  )
}

interface GlobalStateContextProvider {
  children: ReactNode
}

const GlobalStateContextProvider = ({
  children,
}: GlobalStateContextProvider) => {
  return (
    <ProviderComposer contexts={[<SearchProvider />, <PopupProvider />]}>
      {children}
    </ProviderComposer>
  )
}

export { GlobalStateContextProvider as default }
