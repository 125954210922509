import React from "react"
import { IconProps } from "./iconTypes"

const IconQuestionCircleBorder = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.0028 1.00879C18.0693 1.00879 22.9874 5.92691 22.9874 11.9935C22.9874 18.06 18.0693 22.9777 12.0028 22.9777C5.93619 22.9777 1.01807 18.0605 1.01807 11.9935C1.01807 5.92645 5.93619 1.00879 12.0028 1.00879ZM12.0028 2.04C10.6719 2.03738 9.35427 2.30314 8.12854 2.82145C3.43818 4.80264 1.01072 10.0143 2.5122 14.8795C4.01368 19.7447 8.95603 22.6819 13.9471 21.6752C18.9382 20.6686 22.3557 16.0452 21.8539 10.9784C21.3522 5.91156 17.0944 2.04827 12.0028 2.04ZM11.9934 16.8451H11.9882C11.8515 16.8437 11.7209 16.788 11.6251 16.6904C11.5294 16.5927 11.4764 16.461 11.4778 16.3243L11.497 14.4108C11.5087 13.1789 12.1804 12.1857 13.387 11.6133C14.6188 11.0293 15.4232 9.80301 15.4359 8.48957C15.4447 7.57941 15.0813 6.70516 14.4299 6.06941C13.7671 5.41297 12.8733 5.0427 11.9404 5.03816H11.9062C9.97869 5.03816 8.39572 6.55645 8.37744 8.42254C8.37459 8.70731 8.14143 8.93586 7.85666 8.93301C7.57189 8.93016 7.34334 8.697 7.34614 8.41223C7.3701 5.98316 9.41572 4.00598 11.9062 4.00598H11.9531C13.1542 4.0127 14.3049 4.49015 15.1579 5.33582C16.0081 6.16712 16.4822 7.30944 16.4704 8.49848C16.454 10.2043 15.4181 11.7924 13.8323 12.5443C12.9773 12.9497 12.5395 13.5807 12.5315 14.4193L12.5123 16.3332C12.5102 16.6178 12.278 16.8469 11.9934 16.8451ZM11.9999 19.9636C11.7191 19.9636 11.4913 19.7359 11.4913 19.455C11.4913 19.1742 11.7191 18.9464 11.9999 18.9464C12.2808 18.9464 12.5085 19.1742 12.5085 19.455C12.5085 19.7359 12.2808 19.9636 11.9999 19.9636Z" />
  </svg>
)

export default IconQuestionCircleBorder
