import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Video, Transformation } from "cloudinary-react"

export type MediaBgVideoOnTimeUpdate = (time: number) => void
export interface MediaBgVideoProps {
  bgBlur?: number
  autoPlay?: boolean
  className?: string
  loop?: boolean
  onTimeUpdate?: MediaBgVideoOnTimeUpdate
  overlayColor?: string
  overlayOpacity?: number
  pause?: boolean
  source: string
}

/**
 * A component for rendering background videos within
 * a containing component
 */
const MediaBgVideo = ({
  bgBlur,
  autoPlay = true,
  className,
  loop = true,
  onTimeUpdate,
  overlayColor = "transparent",
  overlayOpacity = 0,
  pause = false,
  source,
}: MediaBgVideoProps) => {
  if (!source || typeof source !== "string") return null

  const videoRef = useRef<HTMLVideoElement | undefined>(undefined)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playTime, setPlayTime] = useState(0)

  useLayoutEffect(() => {
    const onVideoPlay = () => setIsPlaying(true)

    if (videoRef && videoRef.current) {
      videoRef.current.addEventListener("play", onVideoPlay)
    }
    if (isPlaying && pause && source && videoRef && videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
    if (!isPlaying && !pause && source && videoRef && videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener("play", onVideoPlay)
      }
    }
  }, [pause, isPlaying])

  useLayoutEffect(() => {
    if (!onTimeUpdate || !videoRef || !videoRef.current) return

    const updateCurrentPlayTime = () => {
      if (videoRef && videoRef.current) {
        setPlayTime(videoRef.current.currentTime)
      }
    }
    videoRef.current.addEventListener("timeupdate", updateCurrentPlayTime)

    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener(
          "timeupdate",
          updateCurrentPlayTime
        )
      }
    }
  }, [videoRef, isPlaying, onTimeUpdate])

  useEffect(() => {
    if (onTimeUpdate) {
      onTimeUpdate(playTime)
    }
  }, [playTime, onTimeUpdate])

  return (
    <MediaBgVideoStyled
      className={`${className} bg-video`}
      overlayColor={overlayColor}
      overlayOpacity={overlayOpacity}
    >
      <div className="bg-video__inner">
        <Video
          autoPlay={autoPlay}
          className="bg-video__inner__video"
          cloudName="smartrent-com"
          innerRef={videoRef}
          loop={loop}
          muted={true}
          publicId={source}
          playsInline={true}
          resourceType="video"
        >
          <Transformation effect="preview" fetchFormat="auto" quality="auto" />
        </Video>
        {bgBlur && bgBlur !== 0 ? (
          <div
            className="hero-bg-img__blur"
            style={{
              backdropFilter: `blur(${bgBlur}px)`,
              WebkitBackdropFilter: `blur(${bgBlur}px)`,
            }}
          />
        ) : null}
      </div>
    </MediaBgVideoStyled>
  )
}

export default MediaBgVideo

interface BgVideoSC {
  overlayColor: string
  overlayOpacity: number
}

const MediaBgVideoStyled = styled.div<BgVideoSC>`
  overflow: hidden;

  .bg-video__inner__video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .bg-video__inner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: ${({ overlayOpacity }) => overlayOpacity / 100};
    background-color: ${({ overlayColor }) => overlayColor};
  }

  .hero-bg-img__blur {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .bg-video__inner__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`
