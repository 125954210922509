import React from "react"
import { IconProps } from "./iconTypes"

const IconMap = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M21.4235 1.1678C21.253 1.1678 21.0826 1.19976 20.9121 1.26901L15.3774 3.48502C15.3455 3.49567 15.2709 3.51165 15.175 3.51165C15.0791 3.51165 15.0045 3.501 14.9726 3.48502L9.02237 1.10388C8.6708 0.965375 8.09549 0.965375 7.74391 1.10388L1.79371 3.48502C1.33027 3.66613 1 4.15621 1 4.65162V19.599C1 20.2915 1.49008 20.8189 2.13997 20.8189C2.31043 20.8189 2.48089 20.7869 2.65135 20.7177L8.18604 18.507C8.21801 18.4963 8.29258 18.4804 8.38847 18.4804C8.48435 18.4804 8.55893 18.491 8.59089 18.507L14.5411 20.8881C14.7222 20.9627 14.9459 21 15.1803 21C15.4147 21 15.6384 20.9627 15.8196 20.8881L21.7751 18.507C22.2332 18.3259 22.5635 17.8305 22.5635 17.3404V2.38767C22.5581 1.68984 22.0681 1.1678 21.4235 1.1678ZM7.79185 17.3883C7.77587 17.3937 7.75989 17.399 7.74391 17.4043L2.21454 19.6203C2.20389 19.6257 2.19323 19.631 2.18258 19.631C2.18258 19.6203 2.18258 19.6097 2.18258 19.599V4.65694C2.18791 4.63564 2.21987 4.58769 2.23052 4.57704L7.79718 2.35038V17.3883H7.79185ZM14.589 19.631L9.02237 17.4043C9.00639 17.399 8.99041 17.3937 8.97443 17.3883V2.35038L14.5411 4.57704C14.5571 4.58237 14.573 4.58769 14.5944 4.59835V19.631H14.589ZM21.3329 17.4043L15.7663 19.631V4.59302C15.7823 4.58769 15.7982 4.58237 15.8142 4.57704L21.3489 2.36636C21.3596 2.36103 21.3702 2.35571 21.3809 2.35571C21.3809 2.36636 21.3809 2.37701 21.3809 2.38767V17.3297C21.3756 17.351 21.3436 17.399 21.3329 17.4043ZM21.4128 2.35038V2.1373V2.35038Z" />
  </svg>
)

export default IconMap
