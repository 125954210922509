import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdTitle4Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-bold);
  font-style: normal;
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: 700;

  @media ${media.stUp} {
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  }
`

const BdTitle4 = styled.h4<TypographyTypeProps>`
  ${BdTitle4Style}
`
BdTitle4.displayName = "BdTitle4"

export default BdTitle4
