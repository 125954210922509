import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

type offsetMobile = number
type offsetStandard = number

export interface CardLiftProps {
  children?: ReactNode
  className?: string
  /** Prop to trigger hover state
   * @defaults false
   */
  lift?: boolean
  /** Offset card from content to simulate padding
   * @default [16, 24]
   */
  offsets?: [offsetMobile, offsetStandard]
}

/** Card wrapper to lift children on hover or when lift prop is passed */
const CardLift = ({
  children,
  className,
  lift = false,
  offsets = [16, 24],
}: CardLiftProps) => {
  return (
    <CardLiftStyled
      className={className}
      lift={lift}
      offsetM={offsets[0]}
      offsetSt={offsets[1]}
    >
      {children}
    </CardLiftStyled>
  )
}

export default CardLift

interface CardLiftSC {
  lift: boolean
  offsetM: offsetMobile
  offsetSt: offsetStandard
}

const CardLiftStyled = styled.div<CardLiftSC>`
  position: relative;
  transition: transform 250ms ease-in-out;

  ${({ lift }) => {
    if (lift) {
      return `transform: scale(1.05);`
    }
    return null
  }}

  &::before {
    content: "";
    position: absolute;
    top: ${({ offsetM }) => rem(-offsetM)};
    left: ${({ offsetM }) => rem(-offsetM)};
    z-index: -1;
    width: calc(100% + ${({ offsetM }) => rem(offsetM * 2)});
    height: calc(100% + ${({ offsetM }) => rem(offsetM * 2)});
    background: transparent;
    box-shadow: none;
    transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;

    ${({ lift }) => {
      if (lift) {
        return `
          background: var(--color-content-bg);
          box-shadow: var(--shadow);
        `
      }
      return null
    }}

    @media ${media.stUp} {
      top: ${({ offsetSt }) => rem(-offsetSt)};
      left: ${({ offsetSt }) => rem(-offsetSt)};
      width: calc(100% + ${({ offsetSt }) => rem(offsetSt * 2)});
      height: calc(100% + ${({ offsetSt }) => rem(offsetSt * 2)});
    }
  }

  &:hover {
    transform: scale(1.05);

    &::before {
      background: var(--color-content-bg);
      box-shadow: var(--shadow);
    }
  }
`
