import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

const baseLineHeight = 16

export const Title6Style = css<TypographyTypeProps>`
  margin-bottom: ${(props) =>
    props.marginBottom
      ? `${rem(props.marginBottom)}`
      : `${rem(baseLineHeight * 8)}`};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  ${SharedDynamicPropsStyled}
`

const Title6 = styled.h6<TypographyTypeProps>`
  ${Title6Style}
`
Title6.displayName = "Title6"

export default Title6
