import React from "react"
import { IconProps } from "./iconTypes"

const IconStar = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M10.5769 5.3819C9.83863 7.24185 9.19342 8.8128 9.14308 8.87301C9.0929 8.93322 7.45582 9.11853 5.50507 9.28477C3.55448 9.45102 1.97731 9.63683 2.00025 9.69771C2.02972 9.77531 6.67887 14.0548 7.34128 14.6141C7.36422 14.6335 7.01756 16.2852 6.5707 18.2843C6.12383 20.2834 5.79215 21.9547 5.83357 21.9984C5.87499 22.0419 7.27724 21.2028 8.94936 20.1336L11.9898 18.1898L15.0611 20.1242C16.7503 21.1881 18.151 22.0004 18.1737 21.9293C18.1964 21.8582 17.857 20.2015 17.4196 18.2475L16.6243 14.695L17.219 14.1358C17.5462 13.828 18.7458 12.7324 19.8849 11.701C21.024 10.6696 21.9757 9.76929 21.9997 9.70038C22.0238 9.63164 20.4466 9.44148 18.4949 9.27792C16.5432 9.11435 14.9052 8.93188 14.855 8.87217C14.8046 8.81247 14.1594 7.24185 13.4212 5.3819C12.6828 3.52195 12.0428 2 11.999 2C11.9552 2 11.3153 3.52195 10.5769 5.3819Z" />
  </svg>
)

export default IconStar
