import React, { ReactNode } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { rem } from "polished"

const colorThemes = {
  success: "var(--color-success)",
  warning: "var(--color-warning)",
  danger: "var(--color-danger)",
}

export type AlertBoxType = "success" | "warning" | "danger"
export type AlertBoxStyle = "default" | "outline"

export interface AlertBoxProps {
  alertBoxStyle?: AlertBoxStyle
  children: string | ReactNode
  className?: string
  closeIcon?: ReactNode
  onCloseClick?: () => void
  icon?: ReactNode
  /** set box shadow of alert
   * @default "var(--shadow)"
   */
  shadow?: string
  /** set to show or hide alert
   * @default true
   */
  show?: boolean
  /**
   * set color coding type of alert
   * @default "danger"
   */
  type?: AlertBoxType
}

const AlertBox = ({
  alertBoxStyle = "default",
  children,
  className,
  closeIcon,
  onCloseClick,
  icon,
  shadow = "var(--shadow)",
  show = true,
  type = "danger",
}: AlertBoxProps) => {
  const colorTheme = colorThemes[type]
  const bgColor =
    alertBoxStyle === "default" ? colorTheme : "var(--color-content-bg)"
  const textColor =
    alertBoxStyle === "default" ? "var(--color-text-subtract)" : colorTheme
  const borderColor = colorTheme

  return (
    <AnimatePresence>
      {show && (
        <AlertBoxStyled
          bgColor={bgColor}
          borderColor={borderColor}
          className={className}
          shadow={shadow}
          textColor={textColor}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
        >
          {icon && <span className="prefix-icon">{icon}</span>}
          <span className="content">{children}</span>
          {closeIcon && (
            <button className="close" type="button" onClick={onCloseClick}>
              {closeIcon}
            </button>
          )}
        </AlertBoxStyled>
      )}
    </AnimatePresence>
  )
}

export default AlertBox

interface BoxSC {
  bgColor: string
  borderColor: string
  shadow: string
  textColor: string
}

const AlertBoxStyled = styled(motion.div)<BoxSC>`
  display: flex;
  align-items: center;
  min-height: ${rem(48)};
  margin-bottom: ${rem(16)};
  padding: ${rem(16)};
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  border: 2px solid ${({ borderColor }) => borderColor};
  box-shadow: ${({ shadow }) => shadow};

  .prefix-icon {
    margin-right: ${rem(8)};
    line-height: 0;

    .icon {
      fill: ${({ textColor }) => textColor};
    }
  }

  .close {
    margin-left: ${rem(8)};
  }

  .content {
    flex: 1 0 auto;
  }
`
