import React from "react"
import BlockContent from "@sanity/block-content-to-react"
const merge = require("deepmerge")

import {
  inlineSerializers,
  BlockContentSerializerType,
} from "./DefaultSerializers"

export interface BlockContentInlineProps {
  blocks?: any[]
  className?: string
  serializers?: BlockContentSerializerType
}

/**
 * Component for rendering simple, inline Block Content with support for
 * - Normal style text
 * - Default marks (bold, italic, underline)
 * - Annotations for internal and external links
 */
const BlockContentInline = ({
  blocks,
  className,
  serializers = {},
}: BlockContentInlineProps) => {
  if (!blocks) return null

  const mergedSerializers = merge(inlineSerializers, serializers)

  if (className && process.env.NODE_ENV !== "production") {
    console.warn(
      `className prop is deprecated on this component. ${className} will not be applied`
    )
  }

  return <BlockContent blocks={blocks} serializers={mergedSerializers} />
}

export default BlockContentInline
