import React from "react"
import { motion } from "framer-motion"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { appHeaderHeightMobile, appHeaderHeight } from "../../App/Layout/index"
import AppLayoutWidth from "../../App/Layout/Width"
import BlockContentBlock from "../../BlockContent/Block"
import { BioShape } from "./index"
import { IconClose, IconChevronLeft, IconChevronRight } from "../../Icon/index"
import { BdTitle4, BdTitle5 } from "../../Typography/index"

export interface SectionBiosBioProps {
  bio: BioShape
  className?: string
  onClose: () => void
  onNext: () => void
  onPrev: () => void
}

const SectionBiosBio = ({
  bio,
  className,
  onClose,
  onNext,
  onPrev,
}: SectionBiosBioProps) => {
  if (!bio) return null

  const { content, img, name, title } = bio

  return (
    <SectionBiosBioStyled
      className={className}
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 200 }}
    >
      <nav className="nav">
        <button className="nav__close" type="button" onClick={onClose}>
          <IconClose />
          <span className="u-hidden-visually">Close Bio</span>
        </button>
        <button
          className="nav__change nav__change--next"
          type="button"
          onClick={onNext}
        >
          <IconChevronRight />
          <span className="u-hidden-visually">Next Bio</span>
        </button>
        <button
          className="nav__change nav__change--prev"
          type="button"
          onClick={onPrev}
        >
          <IconChevronLeft />
          <span className="u-hidden-visually">Previous Bio</span>
        </button>
      </nav>

      <AppLayoutWidth className="layout" constraint="lg">
        {img && img?.asset ? (
          <SanityImage
            alt={img.alt || ""}
            asset={img.asset}
            className="img"
            crop={img.crop}
            hotspot={img.hotspot}
            width={300}
            height={300}
          />
        ) : null}
        <div className="content">
          <hgroup className="content__hgroup">
            <BdTitle4 as="h2">{name}</BdTitle4>
            <BdTitle5 as="h3" color="var(--color-primary)">
              {title}
            </BdTitle5>
          </hgroup>
          {content && <BlockContentBlock blocks={content} />}
        </div>
      </AppLayoutWidth>
    </SectionBiosBioStyled>
  )
}

export default SectionBiosBio

const SectionBiosBioStyled = styled(motion.article)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding-top: ${rem(appHeaderHeightMobile)};
  overflow: hidden;
  overflow-y: auto;
  background: var(--color-interactive-bg);

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ${rem(appHeaderHeight)};
  }

  .layout {
    padding: 0 ${rem(48)};

    @media ${media.stUp} {
      display: flex;
      align-items: flex-start;
    }
  }

  .img {
    margin-bottom: ${rem(16)};

    @media ${media.stUp} {
      width: 25%;
      margin-top: ${rem(16)};
      margin-right: ${rem(48)};
      margin-bottom: 0;
    }
  }

  .content {
    @media ${media.stUp} {
      width: 75%;
    }
  }

  .content__hgroup {
    margin-bottom: ${rem(24)};
  }

  .nav {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${rem(16)};
  }

  .nav__close {
    width: ${rem(48)};
    height: ${rem(48)};
    line-height: 0;

    @media ${media.stUp} {
      position: fixed;
      top: ${rem(appHeaderHeight)};
      right: ${rem(48)};
    }

    .icon {
      fill: var(--color-primary);

      @media ${media.stUp} {
        width: ${rem(48)};
        height: ${rem(48)};
      }
    }
  }

  .nav__change {
    position: fixed;
    top: 50%;
    width: ${rem(40)};
    height: ${rem(40)};
    transform: translateY(-50%);
    line-height: 0;

    .icon {
      fill: var(--color-gray400);

      @media ${media.stUp} {
        width: ${rem(48)};
        height: ${rem(48)};
      }
    }
  }

  .nav__change--next {
    right: 0;

    @media ${media.stUp} {
      right: ${rem(48)};
    }
  }

  .nav__change--prev {
    left: 0;

    @media ${media.stUp} {
      left: ${rem(48)};
    }
  }
`
