import React from "react"
import { IconProps } from "./iconTypes"

const IconHelp = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.003 1.009c6.066 0 10.984 4.918 10.984 10.985 0 6.066-4.918 10.984-10.984 10.984-6.067 0-10.985-4.918-10.985-10.985 0-6.067 4.918-10.984 10.985-10.984ZM16.21 16.2a5.914 5.914 0 0 0 1.742-4.208 5.953 5.953 0 1 0-1.742 4.208ZM8.13 2.821a9.91 9.91 0 0 0-2.782 1.773L7.45 6.698a6.982 6.982 0 0 1 9.088-.013l2.105-2.105A9.938 9.938 0 0 0 8.13 2.821ZM2.83 15.867a9.912 9.912 0 0 0 1.785 2.795l2.105-2.104a6.984 6.984 0 0 1 0-9.133L4.616 5.32a9.944 9.944 0 0 0-1.785 10.547Zm13.046 5.297a9.913 9.913 0 0 0 2.77-1.758L16.541 17.3a6.982 6.982 0 0 1-9.094-.013l-2.104 2.105a9.938 9.938 0 0 0 10.533 1.772Zm3.5-2.487a9.942 9.942 0 0 0 0-13.37l-2.104 2.104a6.982 6.982 0 0 1 0 9.163l2.105 2.103Zm-7.33-3.68h-.005a.516.516 0 0 1-.51-.521l.01-1.045c.007-.767.421-1.384 1.165-1.735.592-.281.979-.868.985-1.496a1.591 1.591 0 0 0-.479-1.153 1.711 1.711 0 0 0-1.197-.495H12c-.924 0-1.684.725-1.693 1.616a.516.516 0 0 1-.515.51h-.005a.516.516 0 0 1-.511-.52C9.291 8.705 10.512 7.52 12 7.52h.027c.717.004 1.404.289 1.913.794.508.498.792 1.183.785 1.895-.01 1.02-.628 1.969-1.574 2.418-.385.182-.574.449-.577.815l-.012 1.041a.516.516 0 0 1-.515.512ZM12 16.48a.494.494 0 1 1 0-.988.494.494 0 0 1 0 .988Z" />
  </svg>
)

export default IconHelp
