import React from "react"
import styled from "styled-components"

export interface SectionHotspotSliderPinProps {
  className?: string
  fillColor?: string
  strokeColor?: string
}

const SectionHotspotSliderPin = ({
  className,
  fillColor = "var(--color-primary)",
  strokeColor = "var(--color-primary)",
}: SectionHotspotSliderPinProps) => {
  return (
    <SectionHotspotSliderPinStyled
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="solid"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6882 6.40032L12.6451 2.16123C12.4678 2.05505 12.2339 2 12 2C11.7661 2 11.5322 2.05505 11.3549 2.16123L4.31177 6.40032C3.95716 6.61267 3.66669 7.1396 3.66669 7.56823V11.9568V12.0433V16.4318C3.66669 16.8604 3.95716 17.3834 4.31177 17.5997L11.3549 21.8388C11.5322 21.945 11.7661 22 12 22C12.2339 22 12.4678 21.945 12.6451 21.8388L19.6882 17.5918C20.0428 17.3795 20.3334 16.8525 20.3334 16.4239V12.0393V11.9528V7.5643C20.3334 7.13567 20.0428 6.61267 19.6882 6.40032"
        fill={fillColor}
      />
      <path
        className="outline"
        d="M19.6882 6.4003L12.6451 2.16121C12.4678 2.05504 12.2339 1.99998 12 1.99998C11.7661 1.99998 11.5322 2.05504 11.3549 2.16121L4.31174 6.4003C3.95713 6.61265 3.66666 7.13959 3.66666 7.56821V11.9567V12.0432V16.4318C3.66666 16.8604 3.95713 17.3834 4.31174 17.5997L11.3549 21.8388C11.5322 21.9449 11.7661 22 12 22C12.2339 22 12.4678 21.9449 12.6451 21.8388L19.6882 17.5918C20.0428 17.3795 20.3333 16.8525 20.3333 16.4239V12.0393V11.9528V7.56428C20.3333 7.13565 20.0428 6.61265 19.6882 6.4003"
        stroke={strokeColor}
        strokeWidth="2"
      />
    </SectionHotspotSliderPinStyled>
  )
}

export default SectionHotspotSliderPin

const SectionHotspotSliderPinStyled = styled.svg``
