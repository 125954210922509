import React from "react"
import { IconProps } from "./iconTypes"

const IconParking = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm6.407 16.407A9.032 9.032 0 105.651 5.617a9.032 9.032 0 0012.756 12.79zm-5.193-12.28a3.423 3.423 0 010 6.846h-3.04v5.382a.47.47 0 01-.939 0V6.596c0-.259.21-.469.47-.469h3.509zm0 5.907a2.484 2.484 0 100-4.968h-3.04v4.968h3.04z" />
  </svg>
)

export default IconParking
