import React from "react"
import { IconProps } from "./iconTypes"

const IconOutlet = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M14.4357 7.3313H16.501C16.7 7.3313 16.8907 7.41032 17.0314 7.55097C17.172 7.69162 17.251 7.88239 17.251 8.0813V13.0871C17.251 13.286 17.172 13.4768 17.0314 13.6174C16.8907 13.7581 16.7 13.8371 16.501 13.8371H14.4357C14.2368 13.8371 14.0461 13.7581 13.9054 13.6174C13.7647 13.4768 13.6857 13.286 13.6857 13.0871V8.0813C13.6857 7.88239 13.7647 7.69162 13.9054 7.55097C14.0461 7.41032 14.2368 7.3313 14.4357 7.3313ZM14.8107 12.7121H16.126V8.4563H14.8107V12.7121Z" />
    <path d="M10.2433 8.0813C10.2433 7.88239 10.1642 7.69162 10.0236 7.55097C9.88294 7.41032 9.69217 7.3313 9.49326 7.3313H7.42795C7.22904 7.3313 7.03827 7.41032 6.89762 7.55097C6.75697 7.69162 6.67795 7.88239 6.67795 8.0813V13.0871C6.67795 13.286 6.75697 13.4768 6.89762 13.6174C7.03827 13.7581 7.22904 13.8371 7.42795 13.8371H9.49326C9.69217 13.8371 9.88294 13.7581 10.0236 13.6174C10.1642 13.4768 10.2433 13.286 10.2433 13.0871V8.0813ZM9.11826 12.7121H7.80295V8.4563H9.11826V12.7121Z" />
    <path d="M11.9448 14.658C12.7483 14.658 13.2756 15.0001 13.4917 15.1736C13.6061 15.232 13.7016 15.3216 13.7672 15.432C13.8328 15.5424 13.8658 15.6691 13.8625 15.7975V17.9748C13.8625 18.3578 13.5854 18.6583 13.232 18.6583H10.7139C10.36 18.6583 10.0829 18.3578 10.0829 17.9748V15.7975C10.0794 15.6722 10.1105 15.5484 10.1727 15.4396C10.2348 15.3308 10.3257 15.2412 10.4354 15.1806C10.6464 14.9987 11.1414 14.658 11.9448 14.658ZM11.1184 17.6205H12.8223V15.9681C12.5671 15.7849 12.2594 15.6894 11.9453 15.6958C11.4765 15.6958 11.2032 15.8903 11.1184 15.962V17.6205Z" />
    <path d="M16.1351 1.84748C17.4619 2.40317 18.6659 3.2156 19.6778 4.23808C20.7002 5.24995 21.5127 6.4539 22.0684 7.78076C22.6241 9.10761 22.9121 10.5312 22.9158 11.9697C22.9196 13.4082 22.639 14.8333 22.0902 16.163C21.5415 17.4928 20.7353 18.701 19.7181 19.7181C18.701 20.7353 17.4928 21.5415 16.163 22.0902C14.8333 22.639 13.4082 22.9196 11.9697 22.9158C10.5312 22.9121 9.10761 22.6241 7.78076 22.0684C6.4539 21.5127 5.24995 20.7002 4.23808 19.6778C3.2156 18.6659 2.40317 17.4619 1.84748 16.1351C1.29179 14.8082 1.00379 13.3846 1.00004 11.9461C0.996285 10.5076 1.27685 9.08254 1.82562 7.75281C2.37438 6.42307 3.18052 5.21489 4.19771 4.19771C5.21489 3.18052 6.42307 2.37438 7.75281 1.82562C9.08254 1.27685 10.5076 0.996285 11.9461 1.00004C13.3846 1.00379 14.8082 1.29179 16.1351 1.84748ZM2.16527 11.9579C2.16527 17.3579 6.55792 21.7506 11.9579 21.7506C17.3579 21.7506 21.7506 17.3579 21.7506 11.9579C21.7506 6.55792 17.3579 2.16527 11.9579 2.16527C6.55792 2.16527 2.16527 6.55792 2.16527 11.9579Z" />
  </svg>
)

export default IconOutlet
