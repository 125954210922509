export const mediaSizes = {
  sm: 320,
  smMid: 375,
  mid: 576,
  md: 768,
  st: 1024,
  stMid: 1280,
  stLg: 1366,
  lg: 1920,
  xl: 2550,
  HD4k: 4000,
  HD5k: 5000,
}

export const media = {
  smUp: `(min-width: ${mediaSizes.sm / 16}em)`,
  smMidUp: `(min-width: ${mediaSizes.smMid / 16}em)`,
  midUp: `(min-width: ${mediaSizes.mid / 16}em)`,
  mdUp: `(min-width: ${mediaSizes.md / 16}em)`,
  stUp: `(min-width: ${mediaSizes.st / 16}em)`,
  stMidUp: `(min-width: ${mediaSizes.stMid / 16}em)`,
  stLgUp: `(min-width: ${mediaSizes.stLg / 16}em)`,
  lgUp: `(min-width: ${mediaSizes.lg / 16}em)`,
  xlUp: `(min-width: ${mediaSizes.xl / 16}em)`,
  HD4kUp: `(min-width: ${mediaSizes.HD4k / 16}em)`,
  HD5kUp: `(min-width: ${mediaSizes.HD5k / 16}em)`,
  ipad: `
    only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
  `,
}
