import React, { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { Button } from "../../Button/index"
import { RegBody, Caption } from "../../Typography/index"

export interface SectionFeatureTableGroupHeaderProps {
  className?: string
  cta?: string | ReactNode
  ctaPath?: string
  description?: string | ReactNode
  stHeight: number
  title: string
}

const SectionFeatureTableGroupHeader = ({
  className,
  cta = "Learn More",
  ctaPath,
  description,
  stHeight,
  title,
}: SectionFeatureTableGroupHeaderProps) => {
  return (
    <SectionFeatureTableGroupHeaderStyled
      className={`feature-table-header ${className || ""}`}
      stHeight={stHeight}
    >
      <RegBody as="h3">{title}</RegBody>
      {description && <Caption as="p">{description}</Caption>}
      {ctaPath && (
        <Link className="feature-table-header__cta" to={ctaPath}>
          <Button
            btnColor="var(--color-primary)"
            btnStyle="outline"
            expand={true}
            shadow={false}
          >
            {cta}
          </Button>
        </Link>
      )}
    </SectionFeatureTableGroupHeaderStyled>
  )
}

export default SectionFeatureTableGroupHeader

interface HeaderSC {
  stHeight: number
}

const SectionFeatureTableGroupHeaderStyled = styled.header<HeaderSC>`
  padding: ${rem(16)} ${rem(16)} ${rem(24)};
  text-align: center;

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: ${({ stHeight }) => rem(stHeight)};
    padding: ${rem(32)} ${rem(16)};
    background: var(--color-content-bg);
    border-bottom: 1px solid var(--color-border-light);
  }

  .feature-table-header__cta {
    display: none;

    @media ${media.stUp} {
      display: block;
      width: 100%;
      margin-top: ${rem(16)};
    }
  }
`
