import React from "react"
import { IconProps } from "./iconTypes"

const IconPhone = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M6.61999948,10.7899993 C8.05999898,13.619999 10.3799995,15.9299993 13.2099992,17.38 L15.4099989,15.1800003 C15.6799994,14.9099999 16.0799989,14.8200007 16.4299993,14.9400005 C17.55,15.3100004 18.7599991,15.5100002 19.9999998,15.5100002 C20.549998,15.5100002 20.9999998,15.9599999 20.9999998,16.5100011 L20.9999998,19.9999998 C20.9999998,20.549998 20.549998,20.9999998 19.9999998,20.9999998 C10.609999,20.9999998 2.99999982,13.3899985 2.99999982,3.99999982 C2.99999982,3.44999983 3.44999983,2.99999982 3.99999982,2.99999982 L7.49999982,2.99999982 C8.04999971,2.99999982 8.49999982,3.44999983 8.49999982,3.99999982 C8.49999982,5.24999968 8.69999928,6.44999941 9.06999915,7.56999924 C9.1799988,7.91999911 9.09999887,8.30999896 8.81999916,8.58999963 L6.61999948,10.7899993 Z" />
  </svg>
)

export default IconPhone
