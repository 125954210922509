import React, { ReactNode } from "react"

export interface SeoProps {
  title?: string
  description?: string
  keywords?: string
  meta?: any[] | []
  canonical?: string
  noIndex?: boolean
  lang?: string
  twitterHandle?: string
  children?: ReactNode
}

//decide if we want to pass og:image metas as a prop or as a child

//add lang to gatsby-ssr.js: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#editing-html-and-body

const Seo = ({
  title = "",
  description = "",
  keywords = "",
  meta = [],
  canonical,
  noIndex = false,
  twitterHandle,
  children,
}: SeoProps) => {
  let metaDefault = [
    {
      name: `description`,
      content: description,
    },
    {
      name: `keywords`,
      content: keywords,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]

  if (twitterHandle) {
    metaDefault.push(
      {
        name: `twitter:creator`,
        content: twitterHandle,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      }
    )
  }

  if (noIndex) {
    metaDefault.push({
      name: `robots`,
      content: `noindex`,
    })
  }

  return (
    <>
      <title>{title}</title>
      {metaDefault.concat(meta).map((meta, i) => {
        return (
          <meta
            name={meta.name ? meta.name : undefined}
            content={meta.content}
            property={meta.property ? meta.property : undefined}
            key={i}
          />
        )
      })}
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {children}
    </>
  )
}

export default Seo
