import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

// use development dataset when in dev mode
const sanityDataSet =
  process.env.NODE_ENV === "production"
    ? process.env.GATSBY_SANITY_DATASET
    : process.env.GATSBY_SANITY_DATASET_DEV

const useSanity = () => {
  const client = sanityClient({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || "",
    dataset: sanityDataSet || "",
    apiVersion: "2022-04-04", // API version as UTC date string
    // use CDN data in prod, fresh data in dev
    useCdn: process.env.NODE_ENV !== "development", // `false` if you want to ensure fresh data
  })

  const builder = imageUrlBuilder(client)

  const imgBuilder = (source: any) => builder.image(source)

  return { client, imgBuilder }
}

export default useSanity
