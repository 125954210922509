import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { IconName, SanityImg } from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import MediaBgImg from "../../Media/BgImg"
import { Caption, BdTitle1 } from "../../Typography/index"
import { iconMapper } from "../../Icon/index"

interface SectionGlobalKpisBgImgShape extends SanityImg {
  alt?: string
}
export interface SectionGlobalKpisProps {
  /** Sets a blur effect over background media
   * Converts number to pixel value
   * @default 0
   */
  bgBlur?: number
  /** Background color for component  */
  bgColor?: string
  /** Image object to be used as background */
  bgImg?: SectionGlobalKpisBgImgShape
  /** Color to be overlayed on background image */
  bgOverlayColor?: string
  /** Overlay color transparency */
  bgOverlayColorAlpha?: number
  className?: string
  globalkpisList?: {
    icon?: IconName
    title?: string
    value?: string
  }[]
  /** Color of kpi text */
  textColor?: string
}

/**
 * KPIs section component
 * - Renders key metrics as section
 */
const SectionGlobalKpis = ({
  bgBlur = 0,
  bgColor = "var(--color-content-bg-invert)",
  bgImg,
  bgOverlayColor = "transparent",
  bgOverlayColorAlpha = 0,
  className,
  globalkpisList,
  textColor = "var(--color-text-subtract)",
}: SectionGlobalKpisProps) => {
  return (
    <SectionGlobalKpisStyled
      className={className}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <AppLayoutWidth constraint="lg">
        {globalkpisList && globalkpisList.length > 0 ? (
          <ul className="list">
            {globalkpisList.map((kpi, index) => (
              <li className="item" key={index}>
                {kpi.icon && (
                  <span className="item__icon">
                    {iconMapper(kpi.icon, {
                      color: textColor,
                      className: "test-classname",
                    })}
                  </span>
                )}
                <Caption as="h3" className="title">
                  {kpi.title}
                </Caption>
                <BdTitle1 as="p" className="value">
                  {kpi.value}
                </BdTitle1>
              </li>
            ))}
          </ul>
        ) : null}
      </AppLayoutWidth>
      {bgImg ? (
        <MediaBgImg
          bgBlur={bgBlur}
          className="kpis__bg-img"
          bgImg={bgImg}
          overlayColor={bgOverlayColor}
          overlayOpacity={bgOverlayColorAlpha}
        />
      ) : null}
    </SectionGlobalKpisStyled>
  )
}

export default SectionGlobalKpis

const SectionGlobalKpisStyled = styled.section`
  position: relative;
  z-index: 1;
  padding: ${rem(72)} 0;

  @media ${media.stUp} {
    padding: 10vh 0;
  }

  .list {
    @media ${media.stUp} {
      display: flex;
      align-items: stretch;
    }
  }

  .item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: ${rem(40)};
    text-align: center;

    @media ${media.stUp} {
      flex: 1 0 auto;
    }
  }

  .item__icon {
    order: 1;
    display: block;
    margin-bottom: ${rem(16)};

    .icon {
      width: ${rem(80)};
      height: ${rem(80)};
    }
  }

  .kpis__bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`
