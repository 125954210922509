import React, { forwardRef, ReactNode, Ref } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { layoutPadding, layoutWidths } from "./sizes"

const { max, xl, lg, md, readableLg, readable } = layoutWidths

export type AppLayoutWidthValues =
  | "max"
  | "xl"
  | "lg"
  | "md"
  | "readableLg"
  | "readable"
  | "full"
  | number
export type AppLayoutWidthPaddingMobile = number
export type AppLayoutWidthPaddingStandard = number
export type AppLayoutWidthPaddingsValue = [
  AppLayoutWidthPaddingMobile,
  AppLayoutWidthPaddingStandard
]
export type AppLayoutWidthPaddingMobileCancel = boolean
export type AppLayoutWidthPaddingStandardCancel = boolean
export type AppLayoutWidthPaddingsCancelValue = [
  AppLayoutWidthPaddingMobileCancel,
  AppLayoutWidthPaddingStandardCancel
]

export interface AppLayoutWidthProps {
  as?: "div" | "section" | "article" | "span" | "ul" | "ol"
  center?: boolean
  children?: ReactNode
  className?: string
  constraint?: AppLayoutWidthValues
  paddings?: AppLayoutWidthPaddingsValue
  paddingsCancel?: AppLayoutWidthPaddingsCancelValue
}

const AppLayoutWidth = forwardRef(
  (
    {
      as,
      center = true,
      children,
      className,
      constraint = "max",
      paddings = [layoutPadding.mobile, layoutPadding.standard],
      paddingsCancel = [false, false],
    }: AppLayoutWidthProps,
    ref?: Ref<HTMLDivElement | HTMLElement>
  ) => {
    return (
      <AppLayoutWidthStyled
        as={as}
        center={center}
        className={className}
        constraint={constraint}
        mPadding={paddings[0]}
        mPaddingCancel={paddingsCancel[0]}
        stPadding={paddings[1]}
        stPaddingCancel={paddingsCancel[1]}
        ref={ref}
      >
        {children}
      </AppLayoutWidthStyled>
    )
  }
)

export default AppLayoutWidth

interface WidthSC {
  center: boolean
  constraint: AppLayoutWidthValues
  mPadding: AppLayoutWidthPaddingMobile
  mPaddingCancel: AppLayoutWidthPaddingMobileCancel
  stPadding: AppLayoutWidthPaddingStandard
  stPaddingCancel: AppLayoutWidthPaddingStandardCancel
}

const AppLayoutWidthStyled = styled.div<WidthSC>`
  width: 100%;
  padding: 0
    ${({ mPadding, mPaddingCancel }) => (mPaddingCancel ? 0 : rem(mPadding))};
  box-sizing: border-box;

  ${({ center }) => {
    if (center) {
      return `
        margin-right: auto;
        margin-left: auto;
      `
    }
  }}

  ${({ constraint }) => {
    let size: string | number = 0

    if (typeof constraint === "number") {
      size = constraint
    }

    switch (constraint) {
      case "max":
        size = rem(max)
        break
      case "xl":
        size = rem(xl)
        break
      case "lg":
        size = rem(lg)
        break
      case "md":
        size = rem(md)
        break
      case "readableLg":
        size = rem(readableLg)
        break
      case "readable":
        size = rem(readable)
        break
      case "full":
        size = `100%`
        break
    }

    return `max-width: ${size};`
  }}

  @media ${media.stUp} {
    padding: 0
      ${({ stPadding, stPaddingCancel }) =>
        stPaddingCancel ? 0 : rem(stPadding)};
  }
`
