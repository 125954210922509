import React from "react"
import { IconProps } from "./iconTypes"

const IconChevronRight = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M9.40797389,18 C9.5113078,18 9.61037828,17.9588087 9.68318171,17.8854769 L15.2722615,12.2608068 C15.4232515,12.1094993 15.4232515,11.8645178 15.2722615,11.7132102 L9.66521039,6.11708288 C9.56770571,6.01704054 9.4239114,5.97720472 9.2888272,6.01281218 C9.153743,6.04841963 9.04826935,6.15396111 9.01274875,6.28906817 C8.97722815,6.42417524 9.0171564,6.5679439 9.1172614,6.66538425 L14.449457,11.9887704 L9.13311844,17.3392898 C9.02287028,17.450338 8.99020633,17.616785 9.05030934,17.7612634 C9.11041234,17.9057418 9.25149269,18 9.40797389,18 Z" />
  </svg>
)

export default IconChevronRight
