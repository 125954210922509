import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdCapsLgStyle = css<TypographyTypeProps>`
  font-size: ${rem(24)};
  line-height: ${rem(40)};
  font-weight: 700;
  text-transform: uppercase;
`

const BdCapsLg = styled.p<TypographyTypeProps>`
  ${BdCapsLgStyle}
`
BdCapsLg.displayName = "BdCapsLg"

export default BdCapsLg
