import React, { ReactNode } from "react"
import styled from "styled-components"

import { media } from "@sr/common"

import {
  AlignXType,
  SanityImg,
  WidthConstraintType,
} from "../../../types/index"
import MediaBgImg from "../../Media/BgImg"
import { BdTitle1, BdTitle2 } from "../../Typography/index"
import AppLayoutWidth from "../../App/Layout/Width"

type SectionHeroLegacyMobileHeight = string | number
type SectionHeroLegacyStandardHeight = string | number

type SectionHeroLegacyContentAlignType = AlignXType

interface SectionHeroLegacyContentBgImageShape extends SanityImg {
  alt?: string
}

export interface SectionHeroLegacyProps {
  /** Sets a blur effect over background media
   * Converts number to pixel value
   * @default 0
   */
  bgBlur?: number
  /** Background color for component  */
  bgColor?: string
  /** Array of image objects for background image.
   * [mobileObject, standardObject]
   */
  bgImg?: SectionHeroLegacyContentBgImageShape
  /** Array of mobile image dimensions [width, height] */
  bgImgMobileDimensions?: [number, number]
  /** Array of standard viewport size image dimensions [width, height] */
  bgImgStandardDimensions?: [number, number]
  className?: string
  /**
   * Controls the horizontal alignment of content
   * @default "left"
   */
  contentAlign?: SectionHeroLegacyContentAlignType

  /** Constrain the width of the content section
   * @default "max"
   */
  contentWidth?: WidthConstraintType
  header?: string | ReactNode
  /** Array of heights (vh units as a number or pixels as a string) to set hero max-height
   * @default [90, 90]
   */
  heights?: [SectionHeroLegacyMobileHeight, SectionHeroLegacyStandardHeight]
  onDark?: boolean
  /** Opacity of color overlay on top of image/media
   * @default 0
   */
  overlayOpacity?: number
  subheader?: string | ReactNode
}

const SectionHeroLegacy = ({
  bgBlur = 0,
  bgColor = "var(--color-content-bg-invert)",
  bgImg,
  bgImgMobileDimensions,
  bgImgStandardDimensions,
  className,
  contentAlign = "start",
  contentWidth = "max",
  header,
  heights = [90, 90],
  onDark = true,
  overlayOpacity,
  subheader,
}: SectionHeroLegacyProps) => {
  return (
    <SectionHeroLegacyStyled
      alignContent={contentAlign}
      bgColor={bgColor}
      className={`${className} hero`}
      heightM={heights[0]}
      heightSt={heights[1]}
      isOnDark={onDark}
    >
      <AppLayoutWidth constraint="max" className="hero__content-wrapper">
        <AppLayoutWidth
          constraint={contentWidth}
          className="hero__content"
          paddings={[0, 0]}
        >
          {header && <BdTitle1 className="hero__header">{header}</BdTitle1>}
          {subheader && (
            <BdTitle2 className="hero__subheader">{subheader}</BdTitle2>
          )}
        </AppLayoutWidth>
      </AppLayoutWidth>
      {bgImg && (
        <MediaBgImg
          bgBlur={bgBlur}
          className="hero__bg-img"
          bgImg={bgImg}
          bgImgMobileHeight={bgImgMobileDimensions?.[1]}
          bgImgMobileWidth={bgImgMobileDimensions?.[0]}
          bgImgStandardHeight={bgImgStandardDimensions?.[1]}
          bgImgStandardWidth={bgImgStandardDimensions?.[0]}
          overlayColor="transparent"
          overlayOpacity={overlayOpacity}
        />
      )}
    </SectionHeroLegacyStyled>
  )
}

export default SectionHeroLegacy

interface SectionHeroLegacySC {
  alignContent: SectionHeroLegacyContentAlignType
  bgColor: string
  heightM: string | number
  heightSt: string | number
  isOnDark: boolean
}

const SectionHeroLegacyStyled = styled.section<SectionHeroLegacySC>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ heightM }) =>
    typeof heightM === "number"
      ? `min-height: calc(var(--vh, 1vh) * ${heightM})`
      : typeof heightM === "string"
      ? `min-height: ${heightM}`
      : null};
  color: ${({ isOnDark }) =>
    isOnDark ? "var(--color-text-subtract)" : "var(--color-text)"};
  background-color: ${({ bgColor }) => bgColor};
  transition: min-height 500ms ease-in-out;

  @media ${media.stUp} {
    ${({ heightSt }) =>
      typeof heightSt === "number"
        ? `min-height: calc(var(--vh, 1vh) * ${heightSt})`
        : typeof heightSt === "string"
        ? `min-height: ${heightSt}`
        : null};
  }

  .hero__content-wrapper {
    @media ${media.stUp} {
      display: flex;
      flex-direction: column;
      align-items: ${({ alignContent }) => {
        switch (alignContent) {
          case "start":
            return "flex-start"
          case "center":
            return "center"
          case "end":
            return "flex-end"
          default:
            return "normal"
        }
      }};
      justify-content: center;
    }
  }

  .hero__content {
    margin: 0;
    text-align: ${({ alignContent }) => {
      switch (alignContent) {
        case "start":
          return "left"
        case "end":
          return "left"
        case "center":
          return "center"
        default:
          return "left"
      }
    }};
  }

  .hero__bg-img,
  .hero__bg-component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`
