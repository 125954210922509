import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import AppLayoutWidth from "../../App/Layout/Width"
import BlockContentInline from "../../BlockContent/Inline"
import { BdTitle3 } from "../../Typography/index"
import SectionFeatureTableFeatureList from "./FeatureList"
import SectionFeatureTableFeatureGrid from "./FeatureGrid"

type SectionFeatureTableGroupFeatures = {
  description: string
  included: boolean
}

export type SectionFeatureTableGroup = {
  groupTitle: string
  groupDescription?: string
  groupCta?: string | ReactNode
  groupCtaPath?: string
  features?: SectionFeatureTableGroupFeatures[]
}

export interface SectionFeatureTableProps {
  bgColor?: string
  className?: string
  content?: any[]
  groups?: SectionFeatureTableGroup[]
  header?: string | ReactNode
  textColor?: string
}

const SectionFeatureTable = ({
  bgColor = "var(--color-interactive-bg)",
  className,
  content,
  groups,
  header,
  textColor = "var(--color-text)",
}: SectionFeatureTableProps) => {
  const groupStFlexBasis =
    groups && groups.length > 0 ? `${100 / groups.length}%` : "100%"

  return (
    <SectionFeatureTableStyled
      className={`feature-table ${className || ""}`}
      style={{ color: textColor, backgroundColor: bgColor }}
      groupStFlexBasis={groupStFlexBasis}
    >
      <AppLayoutWidth className="feature-table__intro" constraint="readable">
        {header && (
          <BdTitle3 className="feature-table__intro__header">{header}</BdTitle3>
        )}
        {content && <BlockContentInline blocks={content} />}
      </AppLayoutWidth>
      <AppLayoutWidth className="feature-table__content" constraint="max">
        {groups && groups.length > 0 ? (
          <>
            <SectionFeatureTableFeatureGrid
              className="feature-table__grid"
              groups={groups}
            />
            <SectionFeatureTableFeatureList
              className="feature-table__list"
              groups={groups}
            />
          </>
        ) : null}
      </AppLayoutWidth>
    </SectionFeatureTableStyled>
  )
}

export default SectionFeatureTable

interface TableSC {
  groupStFlexBasis: string
}

const SectionFeatureTableStyled = styled.section<TableSC>`
  padding: ${rem(72)} 0;

  @media ${media.stUp} {
    padding: 10vh 0;
  }

  .feature-table__intro {
    margin-bottom: ${rem(48)};
    text-align: center;
  }

  .feature-table__intro__header {
    margin-bottom: ${rem(24)};
  }

  .feature-table__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature-table__list {
    @media ${media.stUp} {
      display: none;
    }
  }

  .feature-table__grid {
    display: none;

    @media ${media.stUp} {
      display: block;
    }
  }
`
