import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdTitle5Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-semibold);
  font-style: normal;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 600;
  scroll-margin-top: ${rem(
    72
  )}; /*fixes anchor link scrolling too far due to main site nav height */

  @media ${media.stUp} {
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  }
`

const SemiBdTitle5 = styled.h5<TypographyTypeProps>`
  ${SemiBdTitle5Style}
`
SemiBdTitle5.displayName = "SemiBdTitle5"

export default SemiBdTitle5
