import React from "react"
import { IconProps } from "./iconTypes"

const IconCircleCheck = ({
  size = 32,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 32 32"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1667 3.66667C9.26311 3.66667 3.66667 9.26311 3.66667 16.1667C3.66667 23.0702 9.26311 28.6667 16.1667 28.6667C23.0702 28.6667 28.6667 23.0702 28.6667 16.1667C28.6667 9.26311 23.0702 3.66667 16.1667 3.66667ZM2 16.1667C2 8.34263 8.34263 2 16.1667 2C23.9907 2 30.3333 8.34263 30.3333 16.1667C30.3333 23.9907 23.9907 30.3333 16.1667 30.3333C8.34263 30.3333 2 23.9907 2 16.1667Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2482 7.50355C14.2825 7.44611 12.3577 8.07202 10.8018 9.27463C9.24588 10.4772 8.15507 12.1821 7.71525 14.0989C7.27543 16.0156 7.5138 18.0255 8.38976 19.7861C9.26572 21.5468 10.7251 22.9492 12.5191 23.7545C14.3132 24.5598 16.331 24.7182 18.2287 24.2025C20.1264 23.6869 21.7866 22.5292 22.9265 20.9267C23.9047 19.5515 24.4477 17.9217 24.4964 16.2471C24.5097 15.7871 24.8519 15.3885 25.3111 15.3568V15.3568C25.7702 15.3251 26.1717 15.672 26.1658 16.1322C26.139 18.1923 25.4864 20.2031 24.2846 21.8927C22.9213 23.8094 20.9355 25.1941 18.6657 25.8109C16.3959 26.4276 13.9825 26.2382 11.8366 25.275C9.69077 24.3118 7.94528 22.6344 6.89757 20.5285C5.84986 18.4226 5.56474 16.0186 6.0908 13.7261C6.61686 11.4336 7.92156 9.39436 9.78257 7.95594C11.6436 6.51753 13.9458 5.76889 16.2969 5.83759C18.3694 5.89816 20.3669 6.59053 22.0262 7.81179C22.3969 8.0846 22.4314 8.6141 22.1291 8.96119V8.96119C21.8269 9.30827 21.3026 9.34083 20.9276 9.07401C19.5626 8.1028 17.9351 7.55284 16.2482 7.50355Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9227 10.0773C26.2481 10.4028 26.2481 10.9304 25.9227 11.2558L16.5893 20.5892C16.4331 20.7455 16.2211 20.8333 16.0001 20.8333C15.7791 20.8333 15.5671 20.7455 15.4108 20.5892L11.4108 16.5892C11.0854 16.2637 11.0854 15.7361 11.4108 15.4107C11.7363 15.0852 12.2639 15.0852 12.5893 15.4107L16.0001 18.8214L24.7442 10.0773C25.0696 9.75189 25.5972 9.75189 25.9227 10.0773Z"
    />
  </svg>
)

export default IconCircleCheck
