import React from "react"
import { IconProps } from "./iconTypes"

const IconUnlock = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M15.703 14.893c0 .96-.647 1.8-1.575 2.044a.533.533 0 01.005.07v1.823a.543.543 0 01-1.086 0v-1.824c0-.023.001-.046.005-.069a2.113 2.113 0 112.651-2.044zm-2.11 1.027a1.028 1.028 0 000-2.053 1.027 1.027 0 000 2.053zm6.822-6.037A1.585 1.585 0 0122 11.47v8.945C22 21.29 21.29 22 20.415 22H6.765c-.876 0-1.585-.71-1.585-1.585V11.47a1.585 1.585 0 011.584-1.587h2.61v-3.71A3.086 3.086 0 006.29 3.087h-.116a3.086 3.086 0 00-3.086 3.086v1.592a.543.543 0 11-1.087 0V6.173A4.173 4.173 0 016.173 2h.116a4.172 4.172 0 014.172 4.173v3.71h9.954zm.498 10.532V11.47a.5.5 0 00-.498-.498H6.765a.499.499 0 00-.498.498v8.945c0 .275.222.498.497.498h13.651a.5.5 0 00.498-.498z" />
  </svg>
)

export default IconUnlock
