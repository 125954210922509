import React from "react"
import { Video, Transformation } from "cloudinary-react"

export interface SectionSplitContentMediaVideoProps {
  className?: string
  source: string
}

const SectionSplitContentMediaVideo = ({
  className,
  source,
}: SectionSplitContentMediaVideoProps) => {
  return (
    <Video
      autoPlay={true}
      className={className}
      cloudName="smartrent-com"
      loop={true}
      muted={true}
      publicId={source}
      resourceType="video"
    >
      <Transformation effect="preview" fetchFormat="auto" quality="auto" />
    </Video>
  )
}

export default SectionSplitContentMediaVideo
