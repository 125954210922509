export const getContainerWidth = (containerRef: any) => {
  if (containerRef.current) {
    return containerRef.current.clientWidth
  }
}

export const getContainerHeight = (containerRef: any) => {
  if (containerRef.current) {
    return containerRef.current.clientHeight
  }
}
