import React, { ReactNode } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { rem } from "polished"

export interface AccordionContentProps {
  active: boolean
  children?: ReactNode
  className?: string
}

const AccordionContent = ({
  active,
  children,
  className,
}: AccordionContentProps) => {
  return (
    <AccordionContentStyled
      active={active}
      className={`${className || ""} accordion-content`}
    >
      <motion.div
        initial={{ height: active ? "auto" : 0 }}
        animate={{ height: active ? "auto" : 0 }}
        transition={{ duration: 0.4 }}
      >
        <div className="accordion-content__container">{children}</div>
      </motion.div>
    </AccordionContentStyled>
  )
}

export default AccordionContent

interface ContentSC {
  active: boolean
}

const AccordionContentStyled = styled.div<ContentSC>`
  overflow: hidden;

  .accordion-content__container {
    padding: ${rem(16)} ${rem(8)};
  }
`
