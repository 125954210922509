import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"
import type { SanityImg } from "../../types/sanityImg"

import { BodyText, Title3 } from "../Typography/index"
import MediaImgZoom from "../Media/ImgZoom"

export interface ArticlePreviewFeaturedProps {
  className?: string
  excerpt?: string | ReactNode
  img?: SanityImg
  /** Zoom media, on hover, focus, etc. */
  mediaZoom?: boolean
  /** Human readable time string */
  time?: string
  /** ISO datetime string  */
  timeISO?: string
  title: string | ReactNode
}

const ArticlePreviewFeatured = ({
  className,
  excerpt,
  img,
  mediaZoom,
  time,
  timeISO,
  title,
}: ArticlePreviewFeaturedProps) => {
  return (
    <ArticlePreviewFeaturedStyled className={`${className} preview`}>
      <header>
        <Title3 className="preview__title">{title}</Title3>
        {time && (
          <BodyText
            as="time"
            className="preview__caption__time"
            dateTime={timeISO}
          >
            {time}
          </BodyText>
        )}
      </header>
      {img && (
        <div className="preview__media">
          <MediaImgZoom
            className="preview__media__img"
            height={840}
            img={img}
            width={840}
            zoom={mediaZoom}
          />
        </div>
      )}
      {excerpt && <div className="preview__excerpt">{excerpt}</div>}
    </ArticlePreviewFeaturedStyled>
  )
}

export default ArticlePreviewFeatured

const ArticlePreviewFeaturedStyled = styled.article`
  .preview__title {
    margin-bottom: ${rem(16)};
  }

  .preview__media {
    margin: ${rem(24)} 0;

    @media ${media.stUp} {
      position: relative;
      width: 100%;
      height: 50vh;
      margin: ${rem(40)} 0;
      overflow: hidden;
      line-height: 0;
    }

    .preview__media__img {
      @media ${media.stUp} {
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
      }
    }

    /** forces gatsby image elements to fill space of parent */
    .zoom__media-container,
    .zoom__media__img {
      @media ${media.stUp} {
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
      }
    }
  }
`
