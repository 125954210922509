const esMX = {
  app: {
    footer: {
      text: "Texto de pie de página",
      contact: {
        title: "Ponerse en contacto",
      },
      legal: {
        title: "Cosas legales",
      },
      location: {
        title: "Donde estamos",
      },
    },
    header: {
      brandMarkAlt: "SmartRent.com, LLC",
      toggle: "Alternar encabezado",
      navMain: {
        item1: "Artículo 1",
        item2: "Artículo 2",
        custom: {
          mobileSubMenuExit: "Regresa",
        },
      },
      navSub: {
        login: "Iniciar sesión",
        support: "Apoyo",
      },
    },
  },
  components: {
    nav: {
      pagination: {
        nextLabel: "Próximo",
        previousLabel: "Previo",
      },
    },
    section: {
      newsletterCallout: {
        titleDefault:
          "Suscríbase a nuestro boletín para recibir noticias, actualizaciones, instrucciones y consejos.",
        btnTextDefault: "Suscríbete al boletín",
        placeholderDefault: "Introduzca su correo electrónico aquí",
      },
    },
  },
  pages: {
    index: {
      h1: "Página de inicio de la guía de estilo",
      button: "Botón",
    },
  },
}

export default esMX
