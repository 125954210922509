import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

export const Title2Style = css<TypographyTypeProps>`
  margin-bottom: ${props =>
    props.marginBottom ? `${rem(props.marginBottom)}` : `auto`};
  font-size: ${rem(31)};
  line-height: ${rem(47.7)};
  ${SharedDynamicPropsStyled}

  @media ${media.stUp} {
    font-size: ${rem(48)};
    line-height: ${rem(67.5)};
  }
`

const Title2 = styled.h2<TypographyTypeProps>`
  ${Title2Style}
`
Title2.displayName = "Title2"

export default Title2
