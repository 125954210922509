import React, { ReactNode } from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem } from "polished"

import { SanityImg } from "../../../types/index"
import { RegBody, BdTitle1, BdTitle3 } from "../../Typography/index"

export interface SectionFeaturedEntriesFirstProps {
  articleImg?: SanityImg
  articlePublishDate?: string
  articlePublishDateIso?: string
  articleTitle?: string | ReactNode
  className?: string
  header?: string | ReactNode
  path: string
}

const SectionFeaturedEntriesFirst = ({
  articleImg,
  articlePublishDate,
  articlePublishDateIso,
  articleTitle,
  className,
  header,
  path,
}: SectionFeaturedEntriesFirstProps) => {
  return (
    <SectionFeaturedEntriesFirstStyled
      className={`${className || ""} featured-entries-first`}
    >
      {header && (
        <BdTitle1 as="h2" className="featured-entries-first__header">
          {header}
        </BdTitle1>
      )}
      <Link className="u-text-decoration-none featured-entries__link" to={path}>
        {articleTitle && <BdTitle3>{articleTitle}</BdTitle3>}
        {articlePublishDate && (
          <RegBody
            as="time"
            className="preview__caption__time"
            dateTime={articlePublishDateIso}
          >
            {articlePublishDate}
          </RegBody>
        )}
        {articleImg && articleImg?.asset && (
          <SanityImage
            alt={articleImg.alt || ""}
            asset={articleImg.asset}
            className="featured-entries-first__img"
            crop={articleImg.crop}
            hotspot={articleImg.hotspot}
            width={840}
            height={840}
          />
        )}
      </Link>
    </SectionFeaturedEntriesFirstStyled>
  )
}

export default SectionFeaturedEntriesFirst

const SectionFeaturedEntriesFirstStyled = styled.div`
  .featured-entries-first__header {
    margin-bottom: ${rem(24)};
  }

  .featured-entries-first__img {
    margin-top: ${rem(16)};
  }
`
