import React from "react"
import { IconProps } from "./iconTypes"

const Auto = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M11.75 3a8.713 8.713 0 00-6.203 2.58L4.1 4.117a.155.155 0 00-.27.105L3.75 8.24a.156.156 0 00.157.154l3.87-.175a.155.155 0 00.114-.26L6.337 6.383a7.594 7.594 0 015.412-2.257c4.204 0 7.624 3.422 7.624 7.624a7.6 7.6 0 01-1.172 4.062.564.564 0 00.954.6 8.726 8.726 0 001.343-4.662C20.498 6.925 16.574 3 11.75 3zM16.891 17.378a7.634 7.634 0 01-7.482 1.63.563.563 0 00-.345 1.07 8.763 8.763 0 008.586-1.87.563.563 0 00-.759-.83zM4.859 15.014a7.644 7.644 0 002.724 3.122.564.564 0 01-.195 1.015.562.562 0 01-.42-.074 8.765 8.765 0 01-3.127-3.58.563.563 0 111.018-.483zM4.255 13.16a7.703 7.703 0 01-.13-1.41v-.098A.563.563 0 103 11.638v.111c0 .543.05 1.084.15 1.617a.562.562 0 101.105-.206z" />
    <path d="M13.246 11.506a1.515 1.515 0 11-2.992.485 1.515 1.515 0 012.992-.485z" />
  </svg>
)

export default Auto
