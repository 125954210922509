import React from "react"
import { IconProps } from "./iconTypes"

const IconClose = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.729375,12 L21.08625,3.64265625 C21.2194141,3.51303674 21.2725285,3.32178923 21.2252774,3.14206378 C21.1780263,2.96233832 21.0377205,2.82194233 20.8580255,2.7745757 C20.6783305,2.72720908 20.4870488,2.78020047 20.3573438,2.91328125 L12,11.270625 L3.64265625,2.91328125 C3.51236596,2.78299095 3.32246371,2.7321068 3.14448386,2.77979635 C2.966504,2.82748591 2.82748591,2.966504 2.77979635,3.14448386 C2.7321068,3.32246371 2.78299095,3.51236596 2.91328125,3.64265625 L11.270625,12 L2.91328125,20.3573437 C2.78020047,20.4870488 2.72720908,20.6783305 2.7745757,20.8580255 C2.82194233,21.0377205 2.96233832,21.1780263 3.14206378,21.2252774 C3.32178923,21.2725285 3.51303674,21.2194141 3.64265625,21.08625 L12,12.729375 L20.3573438,21.08625 C20.5602077,21.2778407 20.8786751,21.2732908 21.075983,21.075983 C21.2732908,20.8786751 21.2778407,20.5602077 21.08625,20.3573437 L12.729375,12 Z" />
  </svg>
)

export default IconClose
