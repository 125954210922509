import React, { forwardRef, Ref, useState } from "react"
import styled from "styled-components"
import { rem } from "polished"

import FormInputScaffold from "./Scaffold"

interface FormInputTextAreaProps {
  ariaInvalid?: boolean | "grammar" | "spelling"
  defaultHeight?: number
  error?: boolean
  errorMessage?: string
  id: string
  label?: string
  name: string
  onBlur?: React.FormEventHandler<HTMLTextAreaElement>
  onChange?: React.FormEventHandler<HTMLTextAreaElement>
  onClick?: React.FormEventHandler<HTMLTextAreaElement>
  onFocus?: React.FormEventHandler<HTMLTextAreaElement>
  placeholder?: string
  required?: boolean
  value?: any
}

const FormInputTextArea = forwardRef(
  (
    {
      ariaInvalid,
      defaultHeight = 150,
      error,
      errorMessage,
      id,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      placeholder,
      required,
      value,
    }: FormInputTextAreaProps,
    ref?: Ref<HTMLTextAreaElement>
  ) => {
    const [focus, setFocus] = useState(false)
    const handleOnFocus = (
      event: React.SyntheticEvent<HTMLTextAreaElement>
    ): void => {
      setFocus(true)
      if (onFocus) {
        onFocus(event)
      }
    }
    const handleOnBlur = (
      event: React.SyntheticEvent<HTMLTextAreaElement>
    ): void => {
      setFocus(false)
      if (onBlur) {
        onBlur(event)
      }
    }
    return (
      <FormInputScaffold
        error={error}
        errorMessage={errorMessage}
        id={id}
        isFocused={focus}
        label={label}
        required={required}
      >
        <FormInputTextareaStyled
          aria-invalid={ariaInvalid}
          aria-required={required}
          defaultHeight={defaultHeight || "auto"}
          id={id}
          name={name}
          onBlur={handleOnBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={handleOnFocus}
          placeholder={placeholder}
          ref={ref}
          value={value}
        />
      </FormInputScaffold>
    )
  }
)

export default FormInputTextArea

interface TextareaSC {
  defaultHeight: number | string
}

const FormInputTextareaStyled = styled.textarea<TextareaSC>`
  min-height: ${props => rem(props.defaultHeight)};
  line-height: ${rem(24)} !important;
`
