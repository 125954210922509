import React from "react"
import { IconProps } from "./iconTypes"

const IconSearch = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M15.4999998,13.9999998 L14.7099991,13.9999998 L14.4299994,13.7299987 C15.4099989,12.5899993 15.9999998,11.1099989 15.9999998,9.49999982 C15.9999998,5.90999949 13.0899993,2.99999982 9.49999982,2.99999982 C5.90999949,2.99999982 2.99999982,5.90999949 2.99999982,9.49999982 C2.99999982,13.0899993 5.90999949,15.9999998 9.49999982,15.9999998 C11.1099989,15.9999998 12.5899993,15.4099989 13.7299987,14.4299994 L13.9999998,14.7099991 L13.9999998,15.4999998 L18.9999998,20.4899985 L20.4899985,18.9999998 L15.4999998,13.9999998 Z M9.4999997,13.9999997 C7.0099998,13.9999997 4.9999997,11.989999 4.9999997,9.4999997 C4.9999997,7.0099998 7.0099998,4.9999997 9.4999997,4.9999997 C11.989999,4.9999997 13.9999997,7.0099998 13.9999997,9.4999997 C13.9999997,11.989999 11.989999,13.9999997 9.4999997,13.9999997 Z" />
  </svg>
)

export default IconSearch
