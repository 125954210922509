import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdCapsStyle = css<TypographyTypeProps>`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 700;
  text-transform: uppercase;
`

const BdCaps = styled.p<TypographyTypeProps>`
  ${BdCapsStyle}
`
BdCaps.displayName = "BdCaps"

export default BdCaps
