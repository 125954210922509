import React from "react"
import { IconProps } from "./iconTypes"

const IconKey = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M14.585 10.033l-.425-.425A6.172 6.172 0 008.166 2 6.172 6.172 0 002 8.165a6.172 6.172 0 008.213 5.815l3.287 3.235 2.024.018 1.635 1.444.404 1.83 4.437.04V17.13l-7.415-7.097zm6.328 9.417l-2.474-.022-.294-1.332-2.206-1.947-1.99-.017-3.492-3.437a5.043 5.043 0 01-2.292.548 5.083 5.083 0 01-5.078-5.078c0-2.8 2.278-5.078 5.078-5.078a5.083 5.083 0 014.766 6.83l.889.889 7.093 6.789v1.855z" />
    <path d="M7.022 4.964c-1.224 0-2.22.996-2.22 2.22 0 1.224.996 2.22 2.22 2.22 1.224 0 2.22-.996 2.22-2.22 0-1.224-.996-2.22-2.22-2.22zm0 3.352a1.133 1.133 0 010-2.264 1.133 1.133 0 010 2.265zM15.7 15.152a.541.541 0 01-.769 0l-2.908-2.908a.543.543 0 11.769-.768l2.908 2.907a.544.544 0 010 .77z" />
  </svg>
)

export default IconKey
;("M7.022 4.964c-1.224 0-2.22.996-2.22 2.22 0 1.224.996 2.22 2.22 2.22 1.224 0 2.22-.996 2.22-2.22 0-1.224-.996-2.22-2.22-2.22zm0 3.352a1.133 1.133 0 010-2.264 1.133 1.133 0 010 2.265zM15.7 15.152a.541.541 0 01-.769 0l-2.908-2.908a.543.543 0 11.769-.768l2.908 2.907a.544.544 0 010 .77z")
