import React, { ReactNode } from "react"
import styled from "styled-components/macro"
import { rem } from "polished"

import { CalloutStyle } from "./Callout"
import { BodyTextStyle, BodyTextSmallTypeStyle } from "./BodyText"
import { CaptionStyle, CaptionLargeTypeStyle } from "./Caption"

// typography v2 styles

import { SemiBdTitle1Style } from "./typographyv2/SemiBdTitle1"
import { SemiBdTitle2Style } from "./typographyv2/SemiBdTitle2"
import { SemiBdTitle3Style } from "./typographyv2/SemiBdTitle3"
import { SemiBdTitle4Style } from "./typographyv2/SemiBdTitle4"
import { SemiBdTitle5Style } from "./typographyv2/SemiBdTitle5"
import { SemiBdTitle6Style } from "./typographyv2/SemiBdTitle6"
import { RegBodyMedStyle } from "./typographyv2/RegBodyMed"

// Typography Container Copmponent

interface TypographyProps {
  bodyTextSize?: "small"
  className?: string
  children?: ReactNode
}

const Typography = ({ bodyTextSize, className, children }: TypographyProps) => {
  return (
    <TypographyStyled bodyTextSize={bodyTextSize} className={className}>
      {children}
    </TypographyStyled>
  )
}

export default Typography

Typography.displayName = "Typography"

const TypographyStyled = styled.div<{ bodyTextSize?: "small" }>`
  h1 {
    ${SemiBdTitle1Style}
  }

  h2 {
    ${SemiBdTitle2Style}
  }

  h3 {
    ${SemiBdTitle3Style}
  }

  h4 {
    ${SemiBdTitle4Style}
  }

  h5 {
    ${SemiBdTitle5Style}
  }

  h6 {
    ${SemiBdTitle6Style}
  }

  p.callout {
    ${CalloutStyle}
  }

  p {
    ${RegBodyMedStyle}
    ${(props) =>
      props.bodyTextSize === "small" ? BodyTextSmallTypeStyle : null}
    margin-bottom: ${rem(24)};
  }

  p.small {
    ${BodyTextSmallTypeStyle}
  }

  .caption {
    ${CaptionStyle}
  }

  .caption.large {
    ${CaptionLargeTypeStyle}
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .lowercase {
    text-transform: lowercase;
  }

  strong,
  b {
    font-weight: 600;
  }

  em,
  i {
    font-style: italic;
  }

  code {
    padding: ${rem(4)} ${rem(8)};
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    background: #e6e7eb;
    border-radius: ${rem(4)};
  }

  ol,
  ul {
    margin-bottom: ${rem(24)};
    margin-left: ${rem(24)};

    li {
      margin-bottom: ${rem(8)};
      padding-left: ${rem(16)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  li {
    ${BodyTextStyle}
  }
`
