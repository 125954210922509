import React from "react"
import { IconProps } from "./iconTypes"

const IconClipboardFilled = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M12.352 4.692a.937.937 0 1 0 0-1.874.937.937 0 0 0 0 1.874ZM8.315 9.443h8.05a.562.562 0 1 1 0 1.125h-8.05a.562.562 0 1 1 0-1.125ZM8.315 13.51h3.477a.562.562 0 0 0 0-1.124H8.315a.562.562 0 0 0 0 1.125ZM8.315 15.328h6.34a.562.562 0 1 1 0 1.125h-6.34a.562.562 0 0 1 0-1.125Z" />
    <path d="M18.986 2.868h-3.99a.755.755 0 0 0-.01-.032c-.026-.074-.654-1.823-2.62-1.836h-.02C10.57.998 9.848 2.591 9.737 2.868H5.716A1.72 1.72 0 0 0 4 4.586v15.583a1.72 1.72 0 0 0 1.718 1.718h13.268a1.72 1.72 0 0 0 1.717-1.718V4.586a1.72 1.72 0 0 0-1.717-1.718Zm-8.902 1.125a.562.562 0 0 0 .504-.312l.164-.33a.502.502 0 0 0 .024-.055c.018-.046.458-1.171 1.572-1.171h.01c1.11.007 1.515.952 1.561 1.068l.11.39a.563.563 0 0 0 .54.411h2.222v2.391H7.913v-2.39l2.171-.002Zm9.494 16.177a.593.593 0 0 1-.592.592H5.718a.593.593 0 0 1-.593-.593V4.586a.593.593 0 0 1 .593-.593h1.07V6.76a.75.75 0 0 0 .75.75h9.627a.75.75 0 0 0 .75-.75V3.993h1.07a.593.593 0 0 1 .593.593v15.583Z" />
  </svg>
)

export default IconClipboardFilled
