import React from "react"
import { IconProps } from "./iconTypes"

const IconGrid = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M3.503 2.476H10a1.008 1.008 0 011.005 1.005v6.496a1.008 1.008 0 01-1.005 1.004H3.503a1.008 1.008 0 01-1.005-1.004V3.48a1.008 1.008 0 011.005-1.005zm.2 7.3h6.094V3.682H3.703v6.094zM13.998 2.476h6.496A1.008 1.008 0 0121.5 3.481v6.496a1.008 1.008 0 01-1.005 1.004h-6.496a1.008 1.008 0 01-1.005-1.004V3.48a1.008 1.008 0 011.005-1.005zm.201 7.3h6.094V3.682H14.2v6.094zM9.999 13H3.503a1.008 1.008 0 00-1.005 1.005v6.496a1.008 1.008 0 001.005 1.005H10a1.008 1.008 0 001.005-1.005v-6.496A1.008 1.008 0 009.999 13zm-.202 7.3H3.703v-6.094h6.094V20.3zM13.998 13h6.496a1.008 1.008 0 011.005 1.005v6.496a1.008 1.008 0 01-1.005 1.005h-6.496a1.008 1.008 0 01-1.005-1.005v-6.496A1.008 1.008 0 0113.998 13zm.201 7.3h6.094v-6.094H14.2V20.3z" />
  </svg>
)

export default IconGrid
