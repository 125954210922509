import React from "react"
import { IconProps } from "./iconTypes"

const IconChevronUp = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M6,14.9775292 C5.99968664,14.8743477 6.04052371,14.7752988 6.11346776,14.7023215 L11.7391941,9.11324252 C11.8905017,8.96225249 12.1354831,8.96225249 12.2867906,9.11324252 L17.8829171,14.7202928 C17.9829595,14.8177974 18.0227953,14.9615917 17.9871878,15.0966759 C17.9515804,15.2317601 17.8460389,15.3372337 17.7109319,15.3727543 C17.5758248,15.4082749 17.4320562,15.3683467 17.3346158,15.2682417 L12.0112305,9.93604684 L6.66071192,15.2523846 C6.54966367,15.3626328 6.38321672,15.3952967 6.23873836,15.3351937 C6.09426,15.2750907 6,15.1340104 6,14.9775292 Z" />
  </svg>
)

export default IconChevronUp
