import { AlignXType } from "../types/index"

/**
 * Takes a string union and returns the correct flexbox property
 * @param union {AlignXType} - string union
 * @returns string - flexbox property
 */

type GetFlexAlignValue = "flex-start" | "center" | "flex-end" | "normal"

const getFlexAlign = (union: AlignXType): GetFlexAlignValue => {
  switch (union) {
    case "start":
      return "flex-start"
    case "center":
      return "center"
    case "end":
      return "flex-end"
    default:
      return "normal"
  }
}

export default getFlexAlign
