import React from "react"
import { IconProps } from "./iconTypes"

const IconScreen = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M3.93548 14.5714V4.92857H20.0645V14.5714H3.93548ZM4.58065 5.57143V13.9286H19.4194V5.57143H4.58065ZM11.6774 14.8929H12.3226V15.5357H11.6774V14.8929ZM12.9677 14.8929H13.6129V15.5357H12.9677V14.8929ZM10.3871 14.8929H11.0323V15.5357H10.3871V14.8929ZM22 16.5H15.2258V19.0714H18.7742V21H5.22581V19.0714H8.77419V16.5H2V3H22V16.5ZM18.129 19.7143H5.87097V20.3571H18.129V19.7143ZM9.41935 19.0714H14.5806V16.5H9.41935V19.0714ZM2.64516 3.64286V15.8571H21.3548V3.64286H2.64516Z" />
  </svg>
)

export default IconScreen
