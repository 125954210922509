import React from "react"
import { IconProps } from "./iconTypes"

const IconLinkedIn = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M21.5954 21.6L21.6002 21.5992V14.5576C21.6002 11.1128 20.8586 8.4592 16.8314 8.4592C14.8953 8.4592 13.5961 9.52161 13.0657 10.5288H13.0097V8.7808H9.19134V21.5992H13.1673V15.252C13.1673 13.5808 13.4841 11.9648 15.5537 11.9648C17.593 11.9648 17.6234 13.872 17.6234 15.3592V21.6H21.5954Z" />
    <path d="M2.71684 8.78157H6.69765V21.6H2.71684V8.78157Z" />
    <path d="M4.7056 2.40002C3.4328 2.40002 2.39999 3.43283 2.39999 4.70563C2.39999 5.97843 3.4328 7.03283 4.7056 7.03283C5.9784 7.03283 7.0112 5.97843 7.0112 4.70563C7.0104 3.43283 5.9776 2.40002 4.7056 2.40002Z" />
  </svg>
)

export default IconLinkedIn
