import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "./TypographyTypeProps"
import SharedDynamicPropsStyled from "./SharedDynamicPropsStyled"

const baseLineHeight = 16

export interface BodyTextTypographyTypeProps extends TypographyTypeProps {
  size?: "small"
}

export const BodyTextSmallTypeStyle = css<BodyTextTypographyTypeProps>`
  font-size: ${rem(14)};
  line-height: ${rem(24)};

  @media ${media.stUp} {
    font-size: ${rem(16)};
    line-height: ${rem(27.8)};
  }
`

export const BodyTextTypeStyle = css<BodyTextTypographyTypeProps>`
  font-size: ${rem(16)};
  line-height: ${rem(27.8)};

  @media ${media.stUp} {
    font-size: ${rem(20)};
    line-height: ${rem(32)};
  }
`

export const BodyTextStyle = css<BodyTextTypographyTypeProps>`
  margin-bottom: ${props =>
    props.marginBottom
      ? `${rem(props.marginBottom)}`
      : `${rem(baseLineHeight * 4)}`};
  ${props =>
    props.size === "small" ? BodyTextSmallTypeStyle : BodyTextTypeStyle};
  ${SharedDynamicPropsStyled}
  font-weight: 400;
`

const BodyText = styled.p<BodyTextTypographyTypeProps>`
  ${BodyTextStyle}
`
BodyText.displayName = "BodyText"

export default BodyText
