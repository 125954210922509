import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { Caption } from "../../Typography/index"
import { IconCloseCircleSolid, IconCheckCircleSolid } from "../../Icon/index"

export interface SectionFeatureTableFeatureProps {
  className?: string
  description?: string | ReactNode
  included?: boolean
}

const SectionFeatureTableFeature = ({
  className,
  description,
  included = false,
}: SectionFeatureTableFeatureProps) => {
  return (
    <SectionFeatureTableFeatureStyled
      className={`feature-table-feature ${className || ""}`}
    >
      <Caption as="h4" className="feature-table-feature__description">
        {description}
      </Caption>
      <p className="feature-table-feature__included">
        {included ? (
          <IconCheckCircleSolid color="var(--color-primary)" />
        ) : (
          <IconCloseCircleSolid color="var(--color-danger)" />
        )}
        <span className="u-hidden-visually">
          {included ? "Included" : "Not Included"}
        </span>
      </p>
    </SectionFeatureTableFeatureStyled>
  )
}

export default SectionFeatureTableFeature

const SectionFeatureTableFeatureStyled = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .feature-table-feature__description {
    margin: auto ${rem(16)} auto;
    padding: ${rem(16)};
  }

  .feature-table-feature__included {
    flex: 0 0 ${rem(56)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${rem(56)};
    border-left: 1px solid var(--color-border-light);
  }
`
