import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface NavIconBarContainerProps {
  children?: ReactNode
  className?: string
  /** Sets a dark background and adjusts item's contrast
   * @default false
   */
  onDark?: boolean
}

const NavIconBarContainer = ({
  children,
  className,
  onDark = false,
}: NavIconBarContainerProps) => {
  return (
    <IconBarContainerStyled
      className={`${className || ""}icon-list-container`}
      isOnDark={onDark}
    >
      {children}
    </IconBarContainerStyled>
  )
}

export default NavIconBarContainer

interface ContainerSC {
  isOnDark: boolean
}

const IconBarContainerStyled = styled.ul<ContainerSC>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${({ isOnDark }) =>
    isOnDark ? "var(--color-text-subtract)" : "var(--color-text)"};
  background-color: ${({ isOnDark }) =>
    isOnDark ? "var(--color-content-bg-invert)" : "var(--color-content-bg)"};

  @media ${media.stUp} {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: ${rem(24)} 0;
  }

  > li {
    flex: 0 0 33.333%;
    text-align: center;

    @media ${media.stUp} {
      flex: 1 0 auto;
      justify-content: center;
      padding: ${rem(16)} 0;
    }
  }
`
