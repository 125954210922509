/**
 * Util to convert and adapt colors set in CMS color picker fields
 * This is a bandaid fix to convert the brand primary color set in the CMS
 * to the new accessible color. We should refactor this away once we update the CMS
 * and migrate the data
 * @param hex {string} - string
 * @returns string - hex color string
 */

const getCMSColor = (color: string) => {
  const oldBrandPrimaryHex = "#00aca0"
  const brandPrimaryOnLightHex = "#127D89"

  return color === oldBrandPrimaryHex ? brandPrimaryOnLightHex : color
}

export default getCMSColor
