import React, { ReactNode } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { createI18n, I18nProvider, useI18n } from "react-simple-i18n"
const merge = require("deepmerge")

import { default as defaultLang } from "../../lang/index"

export interface LanguageProviderProps {
  lang?: { [key: string]: string }
  children?: ReactNode
}

const LanguageProvider = ({ lang = {}, children }: LanguageProviderProps) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          lang
        }
      }
    }
  `)
  const languageSetting =
    site && site.siteMetadata && site.siteMetadata.lang
      ? site.siteMetadata.lang
      : "enUS"
  const mergedLangs = merge(defaultLang, lang)

  return (
    <I18nProvider i18n={createI18n(mergedLangs, { lang: languageSetting })}>
      <>{children}</>
    </I18nProvider>
  )
}

export { LanguageProvider as default, useI18n }
