// EASE IN
const easeInQuad = "cubic-bezier(.550, .085, .680, .530)"
const easeInCubic = "cubic-bezier(.550, .055, .675, .190)"
const easeInQuart = "cubic-bezier(.895, .030, .685, .220)"
const easeInQuint = "cubic-bezier(.755, .050, .855, .060)"
const easeInSine = "cubic-bezier(.470, .000, .745, .715)"
const easeInExpo = "cubic-bezier(.950, .050, .795, .035)"
const easeInCirc = "cubic-bezier(.600, .040, .980, .335)"
const easeInBack = "cubic-bezier(.600, -.280, .735, .045)"

// EASE OUT
const easeOutQuad = "cubic-bezier(.250, .460, .450, .940)"
const easeOutCubic = "cubic-bezier(.215, .610, .355, 1)"
const easeOutQuart = "cubic-bezier(.165, .840, .440, 1)"
const easeOutQuint = "cubic-bezier(.230, 1, .320, 1)"
const easeOutSine = "cubic-bezier(.390, .575, .565, 1)"
const easeOutExpo = "cubic-bezier(.190, 1, .220, 1)"
const easeOutCirc = "cubic-bezier(.075, .820, .165, 1)"
const easeOutBack = "cubic-bezier(.175, .885, .320, 1.275)"

// EASE IN OUT
const easeInOutQuad = "cubic-bezier(.455, .030, .515, .955)"
const easeInOutCubic = "cubic-bezier(.645, .045, .355, 1)"
const easeInOutQuart = "cubic-bezier(.770, .000, .175, 1)"
const easeInOutQuint = "cubic-bezier(.860, .000, .070, 1)"
const easeInOutSine = "cubic-bezier(.445, .050, .550, .950)"
const easeInOutExpo = "cubic-bezier(1, .000, .000, 1)"
const easeInOutCirc = "cubic-bezier(.785, .135, .150, .860)"
const easeInOutBack = "cubic-bezier(.680, -.550, .265, 1.550)"

const timingFunctions = {
  easeInQuad: easeInQuad,
  easeInCubic: easeInCubic,
  easeInQuart: easeInQuart,
  easeInQuint: easeInQuint,
  easeInSine: easeInSine,
  easeInExpo: easeInExpo,
  easeInCirc: easeInCirc,
  easeInBack: easeInBack,
  easeOutQuad: easeOutQuad,
  easeOutCubic: easeOutCubic,
  easeOutQuart: easeOutQuart,
  easeOutQuint: easeOutQuint,
  easeOutSine: easeOutSine,
  easeOutExpo: easeOutExpo,
  easeOutCirc: easeOutCirc,
  easeOutBack: easeOutBack,
  easeInOutQuad: easeInOutQuad,
  easeInOutCubic: easeInOutCubic,
  easeInOutQuart: easeInOutQuart,
  easeInOutQuint: easeInOutQuint,
  easeInOutSine: easeInOutSine,
  easeInOutExpo: easeInOutExpo,
  easeInOutCirc: easeInOutCirc,
  easeInOutBack: easeInOutBack,
}

export default timingFunctions
