import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import SanityImage from "gatsby-plugin-sanity-image"
import AppLayoutWidth from "../../App/Layout/Width"

import { media } from "@sr/common"

import BlockContentInline from "../../BlockContent/Inline"
import { iconMapper } from "../../Icon/index"
import { IconName } from "../../Icon/iconTypes"
import { SemiBdTitle2, SemiBdTitle4, RegBody } from "../../Typography/index"
import { WidthConstraintType } from "@sr/common"
import { SanityImg } from "../../../types"

export type SectionMultiColumnFeatureBoxColumnLinkType = {
  blank?: boolean
  path?: string
  text?: string
  url?: string
}

export type SectionMultiColumnFeatureBoxColumnType = {
  content?: any[]
  header?: string
  icon?: string
  img?: SanityImg
  link?: SectionMultiColumnFeatureBoxColumnLinkType
}

export interface SectionMultiColumnFeatureBoxProps {
  bgColor?: string
  header?: string
  subHeader?: string
  columns?: SectionMultiColumnFeatureBoxColumnType[]
  className?: string

  /** Constrain the width of the content section
   * @default "full"
   */
  contentWidth?: WidthConstraintType
  /** Pass the min height in vh to be applied on desktop
   * @default 30
   */
}

const SectionMultiColumnFeatureBox = ({
  bgColor = "#fff",
  header,
  columns,
  contentWidth = "full",
  subHeader,
}: SectionMultiColumnFeatureBoxProps) => {
  const getContentColorTheme = (
    hex: string
  ): "onDark" | "onLight" | "subtract" => {
    switch (hex) {
      case "#fff":
        return "onLight"
      case "#f3f5f8":
        return "onLight"
      case "#1a1f27":
        return "onDark"
      case "#00aca0":
        return "subtract"
      default:
        return "onLight"
    }
  }

  const renderInternalLink = (
    link: SectionMultiColumnFeatureBoxColumnLinkType
  ) => {
    if (link.path) {
      return <Link to={link.path}>{link.text || "Learn More"}</Link>
    }

    return null
  }

  const renderExternalLink = (
    link: SectionMultiColumnFeatureBoxColumnLinkType
  ) => {
    if (link.url) {
      return (
        <a
          href={link.url}
          target={link.blank ? "_blank" : undefined}
          rel={link.blank ? "noopener noreferrer" : undefined}
        >
          {link.text || "Learn More"}
        </a>
      )
    }

    return null
  }

  return (
    <MultiColumnFeatureBoxStyled
      bgColor={bgColor}
      contentColorTheme={getContentColorTheme(bgColor)}
    >
      <AppLayoutWidth
        className="multicolumnfeaturebox__content-wrapper"
        constraint="max"
      >
        <AppLayoutWidth
          className="multicolumnfeaturebox__content"
          constraint={contentWidth}
          paddings={[0, 0]}
        >
          <div style={{ backgroundColor: bgColor }}>
            {header && (
              <SemiBdTitle2 as="h2" className="multicolumnfeaturebox__header">
                {header}
              </SemiBdTitle2>
            )}
            {subHeader && (
              <SemiBdTitle4
                as="h3"
                className="multicolumnfeaturebox__subheader"
              >
                {subHeader}
              </SemiBdTitle4>
            )}
            {columns && columns.length ? (
              <ul className="multicolumnfeaturebox_column_wrapper">
                {columns.map((column, index) => (
                  <li key={index} className="multicolumnfeaturebox__column">
                    {column.icon && (
                      <div className="multicolumnfeaturebox__icon">
                        {iconMapper(column.icon as IconName)}
                      </div>
                    )}
                    {column.img && column?.img?.asset && (
                      <SanityImage
                        alt={column.img.alt || ""}
                        asset={column.img.asset}
                        className="img"
                        crop={column.img.crop}
                        hotspot={column.img.hotspot}
                        width={300}
                        height={200}
                      />
                    )}

                    <RegBody
                      as="h3"
                      className="multicolumnfeaturebox_column_title"
                    >
                      {column.header}
                    </RegBody>
                    {column.content && column.content.length ? (
                      <div className="multicolumnfeaturebox_column_text">
                        <BlockContentInline blocks={column.content} />
                      </div>
                    ) : null}
                    {column.link && (
                      <div className="multicolumnfeaturebox__link">
                        {column.link.path
                          ? renderInternalLink(column.link)
                          : renderExternalLink(column.link)}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </AppLayoutWidth>
      </AppLayoutWidth>
    </MultiColumnFeatureBoxStyled>
  )
}

export default SectionMultiColumnFeatureBox

interface MultiColumnFeatureBoxSC {
  bgColor: string
  contentColorTheme: "onDark" | "onLight" | "subtract"
}

const MultiColumnFeatureBoxStyled = styled.section<MultiColumnFeatureBoxSC>`
  position: relative;
  z-index: 1;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  background-color: ${({ bgColor }) => bgColor};

  .multicolumnfeaturebox__header {
    width: 100%;
    color: ${({ contentColorTheme }) =>
      contentColorTheme === "subtract" || contentColorTheme === "onDark"
        ? `var(--color-text-subtract)`
        : `var(--color-gray900)`};
}

  .multicolumnfeaturebox__subheader {
    width: 100%;
    color: ${({ contentColorTheme }) =>
      contentColorTheme === "subtract"
        ? `var(--color-text-subtract)`
        : `var(--color-primary)`};
  }

  .multicolumnfeaturebox__icon {
    margin-bottom: 1rem;
    fill: ${({ contentColorTheme }) =>
      contentColorTheme === "subtract"
        ? `var(--color-text-subtract)`
        : `var(--color-primary)`};}

    .icon {
      width: ${rem(48)};
      height: ${rem(48)};
    }
  }

  .multicolumnfeaturebox_column_title {
    font-size: 1.25rem;
    color: ${({ contentColorTheme }) =>
      contentColorTheme === "subtract" || contentColorTheme === "onDark"
        ? `var(--color-text-subtract)`
        : `var(--color-gray900)`};
  }

  .multicolumnfeaturebox_column_text p {
    font-size: 1rem;
    color: ${({ contentColorTheme }) =>
      contentColorTheme === "subtract" || contentColorTheme === "onDark"
        ? `var(--color-text-subtract)`
        : `var(--color-gray900)`};
  }
  

  .multicolumnfeaturebox__link a {
    color: ${({ contentColorTheme }) =>
      contentColorTheme === "subtract"
        ? `var(--color-text-subtract)`
        : `var(--color-primary)`};
  }

  .multicolumnfeaturebox_column_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5%;
  }

  .multicolumnfeaturebox__img {
    margin-bottom: 1rem;
  }

  .multicolumnfeaturebox__column {
    list-style: none;
    width: 100%;
    text-align: left;
    padding: 2.5rem 0 0 0;
  }

  @media ${media.stUp} {
    .multicolumnfeaturebox__column {
      width: calc(80% / 4);
    }
    .multicolumnfeaturebox__subheader {
      width: 65%;
    }
    .multicolumnfeaturebox__header {
      width: 65%;
    }
    .multicolumnfeaturebox_column_title {
      font-size: 1.25em;
    }
  }
`
