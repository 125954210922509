import React, { ReactNode } from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface SlideshowNavItem {
  id: number
  content: ReactNode
}

type MobileSize = number
type StandardSize = number

export interface SlideshowNavProps {
  className?: string
  current?: number
  items?: SlideshowNavItem[]
  itemSizes?: [MobileSize, StandardSize]
  onClick: (id: number) => void
}

/**
 * Component to render navigation of slideshow
 * - Takes a list of React nodes
 * - Passes id of item clicked
 */
const SlideshowNav = ({
  className,
  current = 0,
  items,
  itemSizes = [90, 90],
  onClick,
}: SlideshowNavProps) => {
  if (!items || items.length === 0) return null

  return (
    <SlideshowNavStyled
      className={`${className} slideshow-nav`}
      itemSizeMobile={itemSizes[0]}
      itemSizeStandard={itemSizes[1]}
    >
      <h2 className="u-hidden-visually">Navigate Slideshow</h2>
      <ul className="slideshow-nav__list">
        {items.map(item => (
          <SlideshowNavItemStyled
            className="slideshow-nav-item"
            current={current === item.id}
            key={item.id}
            itemSizeMobile={itemSizes[0]}
            itemSizeStandard={itemSizes[1]}
          >
            <button
              className="slideshow-nav-item__button"
              onClick={() => onClick(item.id)}
            >
              <span className="slideshow-nav-item__content">
                {item.content}
              </span>
            </button>
          </SlideshowNavItemStyled>
        ))}
      </ul>
    </SlideshowNavStyled>
  )
}

export default SlideshowNav

interface NavSC {
  itemSizeMobile: MobileSize
  itemSizeStandard: StandardSize
}

const SlideshowNavStyled = styled.nav<NavSC>`
  .slideshow-nav__list {
    display: flex;
    height: ${({ itemSizeMobile }) => rem(itemSizeMobile)};
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @media ${media.stUp} {
      height: ${({ itemSizeStandard }) => rem(itemSizeStandard)};
    }
  }
`

interface ItemSC {
  current: boolean
  itemSizeMobile: MobileSize
  itemSizeStandard: StandardSize
}

const SlideshowNavItemStyled = styled.li<ItemSC>`
  flex: 0 0 ${({ itemSizeMobile }) => rem(itemSizeMobile)};
  height: 100%;
  background: ${({ current }) =>
    current ? `var(--color-content-bg)` : `var(--color-interactive-bg)`};

  @media ${media.stUp} {
    flex: 0 0 ${({ itemSizeMobile }) => rem(itemSizeMobile)};
    background: ${({ current }) =>
      current ? `var(--color-interactive-bg)` : `var(--color-content-bg)`};
  }

  .slideshow-nav-item__button {
    display: block;
    width: 100%;
    height: 100%;

    &:focus {
      outline: 0;
    }
  }

  .slideshow-nav-item__content {
    display: block;
    width: 100%;
    height: 100%;
    padding: ${rem(16)};
  }
`
