import React from "react"
import { IconProps } from "./iconTypes"

const IconSmartPackageRoom = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M23.3746 5.09625L20.3689 2.08687C20.2635 1.98141 20.12 1.92188 19.971 1.92188H11.9816C11.9816 1.92188 11.9807 1.92188 11.9802 1.92188C11.9797 1.92188 11.9793 1.92188 11.9788 1.92188H3.98941C3.83988 1.92188 3.69691 1.98141 3.59144 2.08687L0.586286 5.09625C0.425504 5.25703 0.377692 5.49891 0.464879 5.70938C0.552067 5.91938 0.756911 6.05625 0.984254 6.05625H3.9191V18.8377C3.9191 19.0758 4.06863 19.2877 4.29269 19.3673L11.788 22.0373C11.8489 22.0589 11.9127 22.0702 11.9769 22.0702C12.0411 22.0702 12.1035 22.0594 12.1639 22.0383L19.7357 19.3683C19.9607 19.2891 20.1111 19.0767 20.1111 18.8381L20.1205 6.05625H22.9761C23.2035 6.05625 23.4088 5.91938 23.4955 5.70938C23.5827 5.49938 23.5344 5.2575 23.3741 5.09625H23.3746ZM4.22285 3.04688H10.4619L8.2705 4.93125H2.34082L4.22285 3.04688ZM5.0441 6.05625H8.4791C8.61363 6.05625 8.74394 6.00797 8.84566 5.92031L11.4191 3.70734L11.428 20.7145L5.0441 18.4406V6.05625ZM18.9866 18.4392L12.553 20.708L12.5441 3.71016L15.1147 5.92031C15.2169 6.00797 15.3468 6.05625 15.4813 6.05625H18.9955L18.9866 18.4392ZM15.6899 4.93125L13.4985 3.04688H19.7375L21.6196 4.93125H15.6899Z" />
  </svg>
)

export default IconSmartPackageRoom
