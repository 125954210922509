export const favicon = require("../images/favicon.png")

import { default as themeColors } from "./colors"
import { shadows, textShadows } from "./shadows"

export const colors = themeColors

export const fontColor = colors.gray.gray800
export const fontColorOnDark = colors.gray.white

const theme = {
  name: "Default",
  colors,
  shadows,
  textShadows,
  favicon,
  fontColor: fontColor,
  fontColorOnDark: fontColorOnDark,
}

export { theme as default }
