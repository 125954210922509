import React, { ReactNode, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"

export interface SlideshowMainItem {
  id: number
  content: ReactNode
}

type SlideshowOnDragDireciton = "left" | "right"

export interface SlideshowMainProps {
  aspectRatio: number
  current?: number
  className?: string
  onDrag?: (direction: SlideshowOnDragDireciton) => void
  slides: SlideshowMainItem[]
}

/**
 * Component to render main section of slideshow
 * - Takes a list of React nodes
 * - Reveals the currently selected node
 */
const SlideshowMain = ({
  aspectRatio,
  className,
  current = 0,
  onDrag,
  slides,
}: SlideshowMainProps) => {
  if (!slides || slides.length === 0) return null
  const currentSlide = slides.find(slide => slide.id === current)
  if (!currentSlide) return null

  const [dragDirection, setDragDirection] = useState<SlideshowOnDragDireciton>(
    "left"
  )
  const variants = {
    enter: (dragDirection: SlideshowOnDragDireciton) => ({
      x: dragDirection === "left" ? 250 : -250,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (dragDirection: SlideshowOnDragDireciton) => ({
      x: dragDirection === "left" ? -250 : 250,
      opacity: 0,
    }),
  }

  return (
    <SlideshowMainStyled
      className={`${className || ""} slideshow-main`}
      style={{ paddingBottom: `${100 / aspectRatio}%` }}
    >
      <AnimatePresence custom={dragDirection}>
        <motion.figure
          animate="center"
          className="slideshow-main__item"
          custom={dragDirection}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          exit="exit"
          initial="enter"
          key={currentSlide.id}
          onDragEnd={(_, { offset }) => {
            if (!onDrag) return
            setDragDirection(offset.x < 0 ? "left" : "right")
            onDrag(dragDirection)
          }}
          variants={variants}
        >
          {currentSlide.content}
        </motion.figure>
      </AnimatePresence>
    </SlideshowMainStyled>
  )
}

export default SlideshowMain

const SlideshowMainStyled = styled.div`
  position: relative;

  .slideshow-main__item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
`
