import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const SemiBdTitle2Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-semibold);
  font-style: normal;
  font-size: ${rem(32)};
  line-height: ${rem(40)};
  font-weight: 600;
  scroll-margin-top: ${rem(
    72
  )}; /*fixes anchor link scrolling too far due to main site nav height */

  @media ${media.stUp} {
    font-size: ${rem(56)};
    line-height: ${rem(72)};
  }
`

const SemiBdTitle2 = styled.h2<TypographyTypeProps>`
  ${SemiBdTitle2Style}
`
SemiBdTitle2.displayName = "SemiBdTitle2"

export default SemiBdTitle2
