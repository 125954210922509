import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { media } from "@sr/common"
import { rem } from "polished"
import { SemiBdTitle6, SemiBdTitle5 } from "../../Typography"
import SanityImage from "gatsby-plugin-sanity-image"
import { SanityImg } from "../../../types"

export interface SectionCtaSectionProps {
  on: boolean
  ctaSectionTag: boolean
  ctaSectionTagText: string
  ctaSectionTitle: string
  ctaSectionTextContent: string
  ctaSectionSanityImage?: SanityImg
  ctaSectionStringImage?: string
  ctaSectionButton: {
    color: string
    externalHref?: string
    href?: {
      reference: {
        path: {
          current: string
        }
      }
    }
    blank?: boolean
    text: string
  }
  marginTop?: Array<number>
  marginBottom?: Array<number>
}

const SectionCtaSection = ({
  on,
  ctaSectionTag,
  ctaSectionTagText,
  ctaSectionTitle,
  ctaSectionTextContent,
  ctaSectionSanityImage,
  ctaSectionStringImage,
  ctaSectionButton,
  marginTop = [40, 40],
  marginBottom = [40, 40],
}: SectionCtaSectionProps) => {
  if (!on) return null

  return (
    <SectionCtaSectionStyled marginTop={marginTop} marginBottom={marginBottom}>
      <div className="ctasection__outer-container">
        <div className="ctasection__content-container">
          {ctaSectionTag && (
            <SemiBdTitle6 className="ctasection__Banner-tag">
              {ctaSectionTagText}
            </SemiBdTitle6>
          )}
          {ctaSectionTitle && (
            <SemiBdTitle6 className="ctasection__title-text">
              {ctaSectionTitle}
            </SemiBdTitle6>
          )}
          {ctaSectionTextContent && (
            <SemiBdTitle5 className="ctasection__text-content">
              {ctaSectionTextContent}
            </SemiBdTitle5>
          )}
          {ctaSectionButton?.externalHref && (
            <div className="ctasection__button">
              <a
                className="ctasection__button-link"
                href={ctaSectionButton?.externalHref}
                target={ctaSectionButton?.blank ? "_blank" : "_self"}
              >
                <p className="ctasection__button-text">
                  {ctaSectionButton?.text}
                </p>
              </a>
            </div>
          )}
          {ctaSectionButton?.href && (
            <div className="ctasection__button">
              <Link
                className="ctasection__button-link"
                to={ctaSectionButton?.href?.reference?.path?.current}
              >
                <p className="ctasection__button-text">
                  {ctaSectionButton?.text}
                </p>
              </Link>
            </div>
          )}
        </div>
        <div className="ctasection__img-container">
          {ctaSectionSanityImage && ctaSectionSanityImage?.asset && (
            <SanityImage
              alt={ctaSectionSanityImage?.alt || ""}
              asset={ctaSectionSanityImage?.asset}
              className="ctasection__img"
              crop={ctaSectionSanityImage?.crop}
              hotspot={ctaSectionSanityImage?.hotspot}
              height={300}
              width={418}
            />
          )}
          {ctaSectionStringImage && <img src={ctaSectionStringImage} />}
        </div>
        <div className="ctasection__background-bubble bubble-one"></div>
        <div className="ctasection__background-bubble bubble-two"></div>
      </div>
    </SectionCtaSectionStyled>
  )
}

export default SectionCtaSection

interface SectionCtaSectionSC {
  marginTop: Array<number>
  marginBottom: Array<number>
}

export const SectionCtaSectionStyled = styled.div<SectionCtaSectionSC>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginTop }) => `${marginTop[0]}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom[0]}px`};
  padding: ${rem(0)} ${rem(24)};
  width: 100%;

  .ctasection__outer-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background: var(--color-gray900);
    padding: ${rem(40)};
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: ${rem(1312)};
  }

  .ctasection__img-container {
    width: 100%;
    height: 304px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 2;

    img {
      border-radius: 8px;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .ctasection__title-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    padding-top: ${rem(16)};
  }

  .ctasection__content-container {
    color: var(--color-gray100);
    width: 100%;
    border-radius: 0 0 ${rem(8)} ${rem(8)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${rem(24)};
    position: relative;
    z-index: 2;
  }

  .ctasection__Banner-tag {
    color: var(--color-primary);
    font-size: 20px;
  }

  .ctasection__text-content {
    padding: ${rem(16)} ${rem(0)};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-gray100);
  }

  .ctasection__button {
    background: var(--color-primary);
    width: ${rem(154)};
    height: ${rem(40)};
    border-radius: ${rem(4)};
    padding-top: ${rem(8)};

    .ctasection__button-link {
      width: 100%;
      height: 100%;
      text-align: center;
      color: white;
      text-decoration: none;
    }

    .ctasection__button-text {
      font-size: ${rem(16)};
      font-weight: 600;
    }
  }

  .ctasection__background-bubble {
    display: none;
    background: #2d3238;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
  }

  .bubble-one {
    width: 270px;
    height: 270px;
    top: -30%;
    left: 50%;
  }

  .bubble-two {
    width: 373px;
    height: 373px;
    top: 50%;
    right: -7%;
  }

  @media ${media.stUp} {
    flex-direction: row-reverse;
    margin-top: ${({ marginTop }) => `${marginTop[1]}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom[1]}px`};

    .ctasection__outer-container {
      flex-direction: row;
      justify-content: space-between;
      padding: ${rem(56)};
    }

    .ctasection__outer-container {
      flex-direction: row;
      justify-content: space-between;
      padding: ${rem(56)};
    }

    .ctasection__img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 418px;
      height: 300px;
    }

    .ctasection__content-container {
      width: 575px;
      min-height: 288px;
      border-radius: ${rem(8)} 0 0 ${rem(8)};
      padding: 0 ${rem(24)};
    }

    .ctasection__button {
      padding-top: ${rem(8)};
      margin-top: ${rem(8)};
    }
    .ctasection__background-bubble {
      display: inline;
    }
  }
`
