import { useState, useEffect } from "react"

const useVh = () => {
  const isWindow = typeof window !== "undefined"
  const [vh, setVh] = useState<number | null>(null)

  useEffect(() => {
    const setVhCSSVar = () => {
      setVh(window.innerHeight * 0.01)
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    if (isWindow) {
      setVhCSSVar()
      window.addEventListener("resize", setVhCSSVar)
    }

    return () => {
      if (isWindow) {
        window.removeEventListener("resize", setVhCSSVar)
      }
    }
  }, [vh, isWindow])

  return vh
}

export default useVh
