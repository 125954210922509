import React, { ReactNode, useContext, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { PopupContext } from "@sr/gatsby-theme-ui"

export interface PopupContextSetterProps {
  children?: ReactNode
}

const PopupContextSetter = ({ children }: PopupContextSetterProps) => {
  const { setPopupContent } = useContext(PopupContext)

  const { sanityPage: popupData, sanityImageAsset } = useStaticQuery(graphql`
    query AppPopupContentQuery {
      sanityPage(path: { current: { eq: "/sgt-whitepaper/" } }) {
        path {
          current
        }
        title
        _id
      }
      sanityImageAsset(
        originalFilename: { eq: "self-guided-tours-popup.png" }
      ) {
        _id
      }
    }
  `)

  useEffect(() => {
    if (popupData) {
      setPopupContent({
        id: popupData._id,
        img: sanityImageAsset,
        title: "The Impact of Self-Guided Tours on Leasing Decisions",
        path: popupData?.path.current,
      })
    }
  }, [popupData])

  return <>{children}</>
}

export default PopupContextSetter
