import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdCapsSmStyle = css<TypographyTypeProps>`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  font-weight: 700;
  text-transform: uppercase;
`

const BdCapsSm = styled.p<TypographyTypeProps>`
  ${BdCapsSmStyle}
`
BdCapsSm.displayName = "BdCapsSm"

export default BdCapsSm