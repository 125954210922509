import styled, { css } from "styled-components/macro"
import { rem } from "polished"

import { media } from "@sr/common"

import TypographyTypeProps from "../TypographyTypeProps"

export const BdTitle2Style = css<TypographyTypeProps>`
  font-family: var(--secondary-font-family) !important;
  font-optical-sizing: auto;
  font-weight: var(--secondary-font-bold);
  font-style: normal;
  font-size: ${rem(32)};
  line-height: ${rem(40)};
  font-weight: 700;

  @media ${media.stUp} {
    font-size: ${rem(56)};
    line-height: ${rem(72)};
  }
`

const BdTitle2 = styled.h2<TypographyTypeProps>`
  ${BdTitle2Style}
`
BdTitle2.displayName = "BdTitle2"

export default BdTitle2
