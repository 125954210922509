import React from "react"
import { IconProps } from "./iconTypes"

const IconChevronDown = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <path d="M6,9.40797383 C5.99968664,9.51115535 6.04052371,9.61020427 6.11346776,9.68318161 L11.7391941,15.2722606 C11.8905017,15.4232506 12.1354831,15.4232506 12.2867906,15.2722606 L17.8829171,9.6652103 C17.9829595,9.56770562 18.0227953,9.42391134 17.9871878,9.28882716 C17.9515804,9.15374298 17.8460389,9.04826934 17.7109319,9.01274875 C17.5758248,8.97722815 17.4320562,9.0171564 17.3346158,9.11726138 L12.0112305,14.4494562 L6.66071192,9.13311842 C6.54966367,9.02287028 6.38321672,8.99020633 6.23873836,9.05030933 C6.09426,9.11041233 6,9.25149265 6,9.40797383 Z" />
  </svg>
)

export default IconChevronDown
