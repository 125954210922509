import React, { ReactNode } from "react"
import { CookiesProvider } from "react-cookie"
import styled from "styled-components"

import { useVh } from "@sr/common"

import {
  layoutHeaderHeightMobile,
  layoutHeaderMainHeight,
  layoutHeaderSuppHeight,
  layoutHeaderHeight,
  layoutWidths,
  layoutPadding,
} from "./sizes"
import { layoutZIndexes } from "./zIndexes"
import AppLayoutWidth from "./Width"
import AppCtaBanner from "../CtaBanner"
import AppHeader from "../Header/index"
import AppFooter from "../Footer/index"
import Popup from "../Popup/index"

export interface AppLayoutProps {
  children: ReactNode
}

export const appHeaderHeightMobile = layoutHeaderHeightMobile
export const appHeaderMainHeight = layoutHeaderMainHeight
export const appHeaderSuppHeight = layoutHeaderSuppHeight
export const appHeaderHeight = layoutHeaderHeight

export const appBehindIndex = layoutZIndexes.sub
export const appMainIndex = layoutZIndexes.base
export const appHeaderIndex = layoutZIndexes.mid
export const appSearchIndex = layoutZIndexes.top

const AppLayout = ({ children }: AppLayoutProps) => {
  const windowVh = useVh()

  return (
    <AppLayoutStyled
      windowVh={windowVh}
      headerIndex={appHeaderIndex}
      mainIndex={appMainIndex}
    >
      <CookiesProvider>
        <AppCtaBanner />
        <Popup />
        <AppHeader />
        <main role="main" className="main">
          {children}
        </main>
        <AppFooter />
      </CookiesProvider>
    </AppLayoutStyled>
  )
}

export {
  AppLayout as default,
  AppLayoutWidth,
  layoutWidths,
  layoutPadding,
  layoutZIndexes,
}

interface LayoutSC {
  windowVh: number | null
  headerIndex: number
  mainIndex: number
}

const AppLayoutStyled = styled.div<LayoutSC>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${({ windowVh }) =>
    windowVh ? "calc(var(--vh, 1vh) * 100)" : "100vh"};

  .ie11 & {
    display: block;
    min-height: 0;
  }

  .header {
    position: sticky;
    top: 0;
    z-index: ${({ headerIndex }) => headerIndex};
  }

  .main {
    flex: 1 0 auto;
    position: relative;
    z-index: 1;
    z-index: ${({ mainIndex }) => mainIndex};
  }
`
