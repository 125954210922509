import React, { forwardRef, ReactNode, Ref, useState } from "react"
import styled from "styled-components"
import { rem } from "polished"

interface FormInputBinaryProps {
  ariaInvalid?: boolean | "grammar" | "spelling"
  checked?: boolean
  className?: string
  error?: boolean
  errorMessage?: string
  id: string
  label?: string | ReactNode
  name: string
  onBlur?: React.FormEventHandler<HTMLInputElement>
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: React.FormEventHandler<HTMLInputElement>
  onFocus?: React.FormEventHandler<HTMLInputElement>
  required?: boolean
  type: "checkbox" | "radio"
  value: string
}

const FormInputBinary = forwardRef(
  (
    {
      ariaInvalid,
      checked,
      className,
      error,
      errorMessage,
      id,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      required,
      type,
      value,
    }: FormInputBinaryProps,
    ref?: Ref<HTMLInputElement>
  ) => {
    const [focus, setFocus] = useState(false)
    const handleOnFocus = (
      event: React.SyntheticEvent<HTMLInputElement>
    ): void => {
      setFocus(true)
      if (onFocus) {
        onFocus(event)
      }
    }

    const handleOnBlur = (
      event: React.SyntheticEvent<HTMLInputElement>
    ): void => {
      setFocus(false)
      if (onBlur) {
        onBlur(event)
      }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(value, event)
      }
    }

    return (
      <FormInputBinaryStyled className={className}>
        <input
          aria-invalid={ariaInvalid}
          aria-required={required}
          checked={checked}
          className="input"
          data-focus={focus}
          id={id}
          name={name}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onClick={onClick}
          onFocus={handleOnFocus}
          ref={ref}
          type={type}
          value={value}
        />
        <label className="label" htmlFor={id}>
          {label}
        </label>
        {error && errorMessage && <div>{errorMessage}</div>}
      </FormInputBinaryStyled>
    )
  }
)

export default FormInputBinary

const FormInputBinaryStyled = styled.div`
  display: flex;

  .label {
    margin: 0 ${rem(8)};
    font-weight: 600;
  }

  .input {
    margin: ${rem(6)} ${rem(8)};
  }
`
