import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import AppLayoutWidth from "../../App/Layout/Width"
import SectionBiosBio from "./Bio"
import BlockContentInline from "../../BlockContent/Block"
import { SanityImg } from "../../../types/index"
import SectionBioCard from "./Card"
import { BdTitle3 } from "../../Typography/index"

export type BioShape = {
  content?: any[]
  img?: SanityImg
  name?: string
  title?: string
}

export interface SectionBiosProps {
  bios?: BioShape[]
  content?: any[]
  header?: string
  className?: string
}

const SectionBios = ({
  bios,
  className,
  content,
  header,
}: SectionBiosProps) => {
  const [visibleBio, setVisibleBio] = useState<number | undefined>()

  const handleBioChange = (id: number) => {
    if (!bios) return undefined

    const bioCount = bios.length

    if (id > bioCount - 1) {
      return setVisibleBio(0)
    }

    if (id < 0) {
      return setVisibleBio(bioCount - 1)
    }

    return setVisibleBio(id)
  }
  return (
    <SectionBiosStyled className={className}>
      {header || content ? (
        <AppLayoutWidth className="intro" constraint="readableLg">
          {header && (
            <BdTitle3 as="h2" className="intro__header">
              {header}
            </BdTitle3>
          )}
          {content && <BlockContentInline blocks={content} />}
        </AppLayoutWidth>
      ) : null}
      <AppLayoutWidth constraint="lg">
        {bios && bios.length > 0 ? (
          <ul className="list">
            {bios.map((bio, index) => (
              <li
                aria-pressed={false}
                className="item"
                key={index}
                onClick={() => setVisibleBio(index)}
                role="button"
                tabIndex={0}
              >
                <SectionBioCard bio={bio} />
              </li>
            ))}
          </ul>
        ) : null}
      </AppLayoutWidth>
      {bios && typeof visibleBio !== "undefined" ? (
        <AnimatePresence>
          <SectionBiosBio
            bio={bios[visibleBio]}
            onClose={() => setVisibleBio(undefined)}
            onNext={() => handleBioChange(visibleBio + 1)}
            onPrev={() => handleBioChange(visibleBio - 1)}
          />
        </AnimatePresence>
      ) : null}
    </SectionBiosStyled>
  )
}

export default SectionBios

const SectionBiosStyled = styled.section`
  padding: ${rem(72)} 0;
  background: var(--color-interactive-bg);

  @media ${media.stUp} {
    padding: 10vh 0;
  }

  .intro {
    margin-bottom: ${rem(24)};
    text-align: center;

    @media ${media.stUp} {
      margin-bottom: ${rem(48)};
    }
  }

  .intro__header {
    margin-bottom: ${rem(16)};
  }

  .list {
    @media ${media.stUp} {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
    }
  }

  .item {
    padding: ${rem(24)} 0;

    @media ${media.stUp} {
      flex: 0 0 33.333%;
      padding: ${rem(32)};
    }
  }
`
