import React, { ReactNode, useState } from "react"

import AccordionContent from "./Content"
import AccordionGroup from "./Group"
import AccordionHeader from "./Header"
import { BlockContentBlock } from "../BlockContent"

export interface AccordionItemShape {
  content?: string | ReactNode
  _rawContent?: any
  id: string | number
  title: string | ReactNode
}

export interface AccordionProps {
  className?: string
  /**
   * Flag that allows open items to be closed
   * @default false
   */
  collapsible?: boolean
  /** ID of item wished to be active by default */
  defaultActiveItem?: string | number | null
  items?: AccordionItemShape[]
  /**
   * Click handler to set active accordion
   * @param {string | number} id
   */
  onClick?: (id: string | number) => void
  /**
   * Flag to make headers stick on scroll
   * @default true
   */
  stickyHeader?: boolean
  headerBgColor?: string
}

/**
 * Component that takes a list of accordion items <AccordionItemShape[]>
 * and renders them an accordion
 */
const Accordion = ({
  className,
  collapsible = false,
  defaultActiveItem = null,
  items,
  onClick,
  stickyHeader = true,
  headerBgColor = "var(--color-white)",
}: AccordionProps) => {
  if (!items) return null
  const [activeId, setActiveId] = useState<string | number | null>(
    defaultActiveItem
  )

  const handleClick = (id: string | number) => {
    if (collapsible && activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }

    if (onClick) {
      onClick(id)
    }
  }

  return (
    <div className={className}>
      {items.map((item, index) => {
        const { content, id, title, _rawContent } = item
        return (
          <AccordionGroup
            active={activeId === item.id}
            id={id}
            key={`${index} + ${id}`}
            onClick={() => handleClick(id)}
            stickyHeader={stickyHeader}
            title={title}
            headerBgColor={headerBgColor}
          >

            {content ? content : null}
            {_rawContent ? <BlockContentBlock blocks={_rawContent} /> : null}
          </AccordionGroup>
        )
      })}
    </div>
  )
}

export {
  Accordion as default,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
}
